// Customizable Area Start
import React from "react";
import MyBookingController from "./MyBookingController.web";
import { white_left_arrow, info_icon, info_last, doubleQuoteUpcoming, warningIcon, invoice, invoice_mail, cancel_appointment, quote, star_rate, unfilled_star, green_check, defultPng, } from "../../../blocks/Squeez_me_in/src/assets";
import "../../../blocks/Squeez_me_in/assets/style/BusinessSevices.web.scss";
import "../../../blocks/Squeez_me_in/assets/style/ConfirmAndPay.web.scss";
import "../../../blocks/Squeez_me_in/assets/style/MyBookings.web.scss";
import "../../../blocks/Squeez_me_in/assets/style/ProfileDrawer.web.scss";
import "../../ContentModeration/assets/Styles/ManageWorkingHours.scss";
import { withRouter } from "react-router-dom";
import { Button, Modal, TextField } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import moment from "moment";
import { withTranslation } from "react-i18next";
import Loader from "../../../components/src/Loader.web";
const Cookies = require("js-cookie");
class MyBookingsDetail extends MyBookingController {
    constructor() {
        super(...arguments);
        this.getStatus = (status) => {
            switch (status) {
                case "scheduled":
                    return "Upcoming";
                case "completed":
                    return "Completed";
                case "started":
                    return "Started";
                case "pending":
                    return "Upcoming";
                case "re_scheduled":
                    return "Re-scheduled";
                case "refunded":
                    return "Refunded";
                case "refund_initiated":
                    return "Refund-initiated";
                case "created":
                    return "Created";
                case "cancelled":
                    return "Cancelled";
                case "no_show":
                    return "No-show";
                default:
                    break;
            }
        };
        this.getcolor = (status) => {
            switch (status) {
                case "pending":
                    return "#ff9f0a";
                case "started":
                    return "#34C759";
                case "completed":
                    return "#34C759";
                case "re_scheduled":
                    return "#ff9f0a";
                case "scheduled":
                    return "#ff9f0a";
                case "refund_initiated":
                    return "#777777";
                case "refunded":
                    return "#8D35FF";
                case "no_show":
                    return "#de350b";
                case "created":
                    return "#02AFCE";
                case "cancelled":
                    return "#de350b";
                default:
                    break;
            }
        };
        this.getRescheduleInfo = () => {
            var _a, _b;
            let { t } = this.props;
            return ((_b = (_a = this.state.appointmentdata) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.customer_rescheduled_count) >=
                5 ? (React.createElement("div", { className: "info-div", style: {
                    background: "#f8f8f7",
                    marginTop: 20,
                    borderRadius: "8px",
                    height: "auto",
                } },
                React.createElement("img", { src: info_icon, alt: "" }),
                " \u00A0",
                React.createElement("p", null, t("You have reached your maximum allowable attempts to reschedule.")))) : ("");
        };
        this.getRenderData = () => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43, _44, _45, _46, _47, _48, _49, _50, _51, _52, _53, _54, _55, _56, _57, _58, _59, _60, _61, _62, _63, _64, _65, _66, _67, _68, _69, _70, _71, _72, _73, _74, _75, _76, _77, _78, _79, _80, _81, _82, _83, _84, _85, _86, _87, _88, _89, _90, _91, _92, _93, _94, _95, _96, _97, _98, _99, _100, _101, _102;
            let { t } = this.props;
            let webAppDirection = localStorage.getItem("webAppDirection") ||
                Cookies.get("webAppDirection") ||
                "ltr";
            let cancelledCondition = ((_b = (_a = this.state.appointmentdata) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.paid_by) === "pay_at_shop" &&
                ((_d = (_c = this.state.appointmentdata) === null || _c === void 0 ? void 0 : _c.attributes) === null || _d === void 0 ? void 0 : _d.status) === "cancelled";
            let noShowondition = ((_f = (_e = this.state.appointmentdata) === null || _e === void 0 ? void 0 : _e.attributes) === null || _f === void 0 ? void 0 : _f.paid_by) === "pay_at_shop" &&
                ((_h = (_g = this.state.appointmentdata) === null || _g === void 0 ? void 0 : _g.attributes) === null || _h === void 0 ? void 0 : _h.status) === "no_show";
            return (React.createElement("div", { className: "main-div" },
                React.createElement("div", { style: { maxWidth: "100%", backgroundColor: "#3d40c3" } },
                    React.createElement("div", { className: "services-header" },
                        React.createElement("img", { src: white_left_arrow, alt: "", id: "service_left_arrow", onClick: () => {
                                var _a;
                                let { clickFromProfile } = ((_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state) || "";
                                if (clickFromProfile) {
                                    this.props.history.push({
                                        pathname: "/customer/profile",
                                    });
                                }
                                else {
                                    this.props.history.push({
                                        pathname: "/MyBookings",
                                    });
                                }
                                this.getAppointmentFromindexDb(true);
                            } }),
                        React.createElement("h1", null, t("Bookings")))),
                React.createElement("div", { className: "mybookingdetail-main-div" },
                    React.createElement("div", { className: "booking-details-wrapper", style: { height: "auto" } },
                        React.createElement("div", null,
                            cancelledCondition || noShowondition ? (React.createElement(React.Fragment, null)) : (React.createElement("div", { className: "suberServee-item", style: { cursor: "pointer" }, onClick: () => {
                                    this.senEmailInvoice();
                                } },
                                React.createElement("h1", { style: {
                                        marginLeft: webAppDirection === "ltr" ? "auto" : 0,
                                        marginRight: webAppDirection === "rtl" ? "auto" : 0,
                                        color: "#007aff",
                                        paddingRight: "6px",
                                    } },
                                    t("Invoice"),
                                    " "),
                                React.createElement("img", { src: invoice, alt: "" }))),
                            React.createElement("div", { className: "suberServ-item" },
                                React.createElement("p", null, t("Services")),
                                React.createElement("h1", { style: {
                                        marginLeft: webAppDirection === "ltr" ? "auto" : 0,
                                        marginRight: webAppDirection === "rtl" ? "auto" : 0,
                                        color: this.getcolor((_k = (_j = this.state.appointmentdata) === null || _j === void 0 ? void 0 : _j.attributes) === null || _k === void 0 ? void 0 : _k.status),
                                    } }, t(this.getStatus((_m = (_l = this.state.appointmentdata) === null || _l === void 0 ? void 0 : _l.attributes) === null || _m === void 0 ? void 0 : _m.status)))),
                            React.createElement("div", { className: "suber-item" },
                                React.createElement("p", null,
                                    t("Booking ID:"),
                                    " ", (_p = (_o = this.state.appointmentdata) === null || _o === void 0 ? void 0 : _o.attributes) === null || _p === void 0 ? void 0 :
                                    _p.booking_id),
                                React.createElement("p", { style: {
                                        marginLeft: webAppDirection === "ltr" ? "auto" : 0,
                                        marginRight: webAppDirection === "rtl" ? "auto" : 0,
                                    } },
                                    t("Booking code:"),
                                    " ", (_s = (_r = (_q = this.state.appointmentdata) === null || _q === void 0 ? void 0 : _q.attributes) === null || _r === void 0 ? void 0 : _r.booking_code) === null || _s === void 0 ? void 0 :
                                    _s.pin)),
                            React.createElement("div", { style: { display: "flex", justifyContent: "space-between" } },
                                React.createElement("div", { className: "booking-detail" },
                                    React.createElement("h2", null, ((_y = (_x = (_w = (_v = (_u = (_t = this.state.appointmentdata) === null || _t === void 0 ? void 0 : _t.attributes) === null || _u === void 0 ? void 0 : _u.business_profile) === null || _v === void 0 ? void 0 : _v.data) === null || _w === void 0 ? void 0 : _w.attributes) === null || _x === void 0 ? void 0 : _x.service_provided) === null || _y === void 0 ? void 0 : _y.name) +
                                        `'s ` + ((_1 = (_0 = (_z = this.state.appointmentdata) === null || _z === void 0 ? void 0 : _z.attributes) === null || _0 === void 0 ? void 0 : _0.service) === null || _1 === void 0 ? void 0 : _1.service_name)),
                                    React.createElement("div", { className: "booking-with-detail", style: { alignItems: "end" } },
                                        React.createElement("p", { style: { color: "#757575" } }, t("By")),
                                        React.createElement("img", { src: ((_6 = (_5 = (_4 = (_3 = (_2 = this.state.appointmentdata) === null || _2 === void 0 ? void 0 : _2.attributes) === null || _3 === void 0 ? void 0 : _3.employee_details) === null || _4 === void 0 ? void 0 : _4.data) === null || _5 === void 0 ? void 0 : _5.attributes) === null || _6 === void 0 ? void 0 : _6.team_member_profile) || defultPng, style: {
                                                width: "16px",
                                                height: "16px",
                                                borderRadius: "50px",
                                                marginLeft: "6px",
                                            } }),
                                        React.createElement("p", { style: { marginLeft: "6px" } }, ((_11 = (_10 = (_9 = (_8 = (_7 = this.state.appointmentdata) === null || _7 === void 0 ? void 0 : _7.attributes) === null || _8 === void 0 ? void 0 : _8.employee_details) === null || _9 === void 0 ? void 0 : _9.data) === null || _10 === void 0 ? void 0 : _10.attributes) === null || _11 === void 0 ? void 0 : _11.first_name) +
                                            " " + ((_16 = (_15 = (_14 = (_13 = (_12 = this.state.appointmentdata) === null || _12 === void 0 ? void 0 : _12.attributes) === null || _13 === void 0 ? void 0 : _13.employee_details) === null || _14 === void 0 ? void 0 : _14.data) === null || _15 === void 0 ? void 0 : _15.attributes) === null || _16 === void 0 ? void 0 : _16.last_name)))),
                                React.createElement("h2", { className: "omr-h2" },
                                    "OMR ", (_18 = (_17 = this.state.appointmentdata) === null || _17 === void 0 ? void 0 : _17.attributes) === null || _18 === void 0 ? void 0 :
                                    _18.total_price)),
                            React.createElement("div", { className: "booking-schedule" },
                                React.createElement("p", null, t("Booking Schedule")),
                                React.createElement("p", { className: "time-p" }, moment((_20 = (_19 = this.state.appointmentdata) === null || _19 === void 0 ? void 0 : _19.attributes) === null || _20 === void 0 ? void 0 : _20.start_time).format("hh:mm A") +
                                    " " +
                                    moment((_22 = (_21 = this.state.appointmentdata) === null || _21 === void 0 ? void 0 : _21.attributes) === null || _22 === void 0 ? void 0 : _22.start_time)
                                        .format("dddd")
                                        .substring(0, 3) +
                                    ", " +
                                    moment((_24 = (_23 = this.state.appointmentdata) === null || _23 === void 0 ? void 0 : _23.attributes) === null || _24 === void 0 ? void 0 : _24.booking_date).format("DD MMM YYYY"))),
                            ((_26 = (_25 = this.state.appointmentdata) === null || _25 === void 0 ? void 0 : _25.attributes) === null || _26 === void 0 ? void 0 : _26.notes) ? (React.createElement("div", { style: {
                                    display: "flex",
                                    background: "#f9f9f8",
                                    borderRadius: "8px",
                                    padding: 15,
                                    marginTop: 25,
                                } },
                                React.createElement("img", { src: doubleQuoteUpcoming, style: {
                                        alignSelf: "baseline",
                                        marginTop: "4px",
                                        marginRight: "7px",
                                    }, alt: "" }),
                                React.createElement("p", { style: { color: "#2c2c2e", fontSize: 12 } }, (_28 = (_27 = this.state.appointmentdata) === null || _27 === void 0 ? void 0 : _27.attributes) === null || _28 === void 0 ? void 0 : _28.notes))) : (""),
                            React.createElement("div", { style: { display: "flex" } },
                                React.createElement("h2", { className: "summery", style: { marginTop: 20 } }, t("Summary")),
                                React.createElement("h3", { style: {
                                        marginLeft: webAppDirection === "ltr" ? "auto" : 0,
                                        marginRight: webAppDirection === "rtl" ? "auto" : 0,
                                        alignSelf: "end",
                                        color: ((_30 = (_29 = this.state.appointmentdata) === null || _29 === void 0 ? void 0 : _29.attributes) === null || _30 === void 0 ? void 0 : _30.paid_by) ===
                                            "pay_at_shop"
                                            ? "#ff9f0a"
                                            : "#34c759",
                                        fontSize: 14,
                                    } }, ((_32 = (_31 = this.state.appointmentdata) === null || _31 === void 0 ? void 0 : _31.attributes) === null || _32 === void 0 ? void 0 : _32.paid_by) ===
                                    "pay_at_shop"
                                    ? t("Pay at shop")
                                    : t("Pay by card"))),
                            React.createElement("div", { className: "booking-price-details" },
                                React.createElement("h2", null, t("Services")),
                                React.createElement("h3", null,
                                    "OMR",
                                    " ", (_35 = (_34 = (_33 = this.state.appointmentdata) === null || _33 === void 0 ? void 0 : _33.attributes) === null || _34 === void 0 ? void 0 : _34.service) === null || _35 === void 0 ? void 0 :
                                    _35.service_cost)),
                            React.createElement("div", { className: "booking-price-details" },
                                React.createElement("p", null, t("Service Fee")),
                                React.createElement("h3", null,
                                    "OMR ", (_37 = (_36 = this.state.appointmentdata) === null || _36 === void 0 ? void 0 : _36.attributes) === null || _37 === void 0 ? void 0 :
                                    _37.service_fee)),
                            React.createElement("div", { className: "booking-price-details" },
                                React.createElement("p", null, t("Taxes")),
                                React.createElement("h3", null,
                                    "OMR ", (_39 = (_38 = this.state.appointmentdata) === null || _38 === void 0 ? void 0 : _38.attributes) === null || _39 === void 0 ? void 0 :
                                    _39.tax)),
                            React.createElement("div", { className: "booking-price-details", style: { marginTop: "20px" } },
                                React.createElement("h2", null, t("Total")),
                                React.createElement("h5", null,
                                    "OMR ", (_41 = (_40 = this.state.appointmentdata) === null || _40 === void 0 ? void 0 : _40.attributes) === null || _41 === void 0 ? void 0 :
                                    _41.total_price)),
                            ((((_43 = (_42 = this.state.appointmentdata) === null || _42 === void 0 ? void 0 : _42.attributes) === null || _43 === void 0 ? void 0 : _43.status) === "no_show" &&
                                ((_45 = (_44 = this.state.appointmentdata) === null || _44 === void 0 ? void 0 : _44.attributes) === null || _45 === void 0 ? void 0 : _45.paid_by) !==
                                    "pay_at_shop") ||
                                (((_47 = (_46 = this.state.appointmentdata) === null || _46 === void 0 ? void 0 : _46.attributes) === null || _47 === void 0 ? void 0 : _47.paid_by) !==
                                    "pay_at_shop" &&
                                    ((_49 = (_48 = this.state.appointmentdata) === null || _48 === void 0 ? void 0 : _48.attributes) === null || _49 === void 0 ? void 0 : _49.status) ===
                                        "cancelled") ||
                                ((_51 = (_50 = this.state.appointmentdata) === null || _50 === void 0 ? void 0 : _50.attributes) === null || _51 === void 0 ? void 0 : _51.status) ===
                                    "refund_initiated" ||
                                ((_53 = (_52 = this.state.appointmentdata) === null || _52 === void 0 ? void 0 : _52.attributes) === null || _53 === void 0 ? void 0 : _53.status) === "refunded" ||
                                ((_55 = (_54 = this.state.appointmentdata) === null || _54 === void 0 ? void 0 : _54.attributes) === null || _55 === void 0 ? void 0 : _55.status) === "pending" ||
                                ((_57 = (_56 = this.state.appointmentdata) === null || _56 === void 0 ? void 0 : _56.attributes) === null || _57 === void 0 ? void 0 : _57.status) ===
                                    "re_scheduled" ||
                                ((_59 = (_58 = this.state.appointmentdata) === null || _58 === void 0 ? void 0 : _58.attributes) === null || _59 === void 0 ? void 0 : _59.status) ===
                                    "scheduled" ||
                                ((_61 = (_60 = this.state.appointmentdata) === null || _60 === void 0 ? void 0 : _60.attributes) === null || _61 === void 0 ? void 0 : _61.status) ===
                                    "created") && (React.createElement("div", { className: "info-div", style: {
                                    background: "#f8f8f7",
                                    marginTop: 20,
                                    height: "auto",
                                    borderRadius: "8px",
                                } },
                                React.createElement("img", { src: info_last, alt: "" }),
                                " \u00A0",
                                ((((_63 = (_62 = this.state.appointmentdata) === null || _62 === void 0 ? void 0 : _62.attributes) === null || _63 === void 0 ? void 0 : _63.status) ===
                                    "no_show" &&
                                    ((_65 = (_64 = this.state.appointmentdata) === null || _64 === void 0 ? void 0 : _64.attributes) === null || _65 === void 0 ? void 0 : _65.paid_by) !==
                                        "pay_at_shop") ||
                                    (((_67 = (_66 = this.state.appointmentdata) === null || _66 === void 0 ? void 0 : _66.attributes) === null || _67 === void 0 ? void 0 : _67.paid_by) !==
                                        "pay_at_shop" &&
                                        ((_69 = (_68 = this.state.appointmentdata) === null || _68 === void 0 ? void 0 : _68.attributes) === null || _69 === void 0 ? void 0 : _69.status) ===
                                            "cancelled") ||
                                    ((_71 = (_70 = this.state.appointmentdata) === null || _70 === void 0 ? void 0 : _70.attributes) === null || _71 === void 0 ? void 0 : _71.status) ===
                                        "refund_initiated" ||
                                    ((_73 = (_72 = this.state.appointmentdata) === null || _72 === void 0 ? void 0 : _72.attributes) === null || _73 === void 0 ? void 0 : _73.status) ===
                                        "refunded") && (React.createElement("p", null, this.props.t("Refund will be initiated in 7-14 business working days provided the cancellation was done as per the policy i.e 4 hours prior to the booking time."))),
                                (((_75 = (_74 = this.state.appointmentdata) === null || _74 === void 0 ? void 0 : _74.attributes) === null || _75 === void 0 ? void 0 : _75.status) ===
                                    "pending" ||
                                    ((_77 = (_76 = this.state.appointmentdata) === null || _76 === void 0 ? void 0 : _76.attributes) === null || _77 === void 0 ? void 0 : _77.status) ===
                                        "re_scheduled" ||
                                    ((_79 = (_78 = this.state.appointmentdata) === null || _78 === void 0 ? void 0 : _78.attributes) === null || _79 === void 0 ? void 0 : _79.status) ===
                                        "scheduled" ||
                                    ((_81 = (_80 = this.state.appointmentdata) === null || _80 === void 0 ? void 0 : _80.attributes) === null || _81 === void 0 ? void 0 : _81.status) ===
                                        "created") && (React.createElement("p", null, this.props.t((_84 = (_83 = (_82 = this.state.appointmentdata) === null || _82 === void 0 ? void 0 : _82.attributes) === null || _83 === void 0 ? void 0 : _83.reschedule_cantellation_time) === null || _84 === void 0 ? void 0 : _84.message))))),
                            ((_86 = (_85 = this.props.location) === null || _85 === void 0 ? void 0 : _85.state) === null || _86 === void 0 ? void 0 : _86.upcoming) ? this.getRescheduleInfo()
                                : "",
                            ((_88 = (_87 = this.state.appointmentdata) === null || _87 === void 0 ? void 0 : _87.attributes) === null || _88 === void 0 ? void 0 : _88.status) != "cancelled" &&
                                ((_90 = (_89 = this.state.appointmentdata) === null || _89 === void 0 ? void 0 : _89.attributes) === null || _90 === void 0 ? void 0 : _90.status) !=
                                    "refund_initiated" &&
                                ((_92 = (_91 = this.state.appointmentdata) === null || _91 === void 0 ? void 0 : _91.attributes) === null || _92 === void 0 ? void 0 : _92.status) != "refunded" &&
                                (this.state.hoursBrefore > ((_95 = (_94 = (_93 = this.state.appointmentdata) === null || _93 === void 0 ? void 0 : _93.attributes) === null || _94 === void 0 ? void 0 : _94.reschedule_cantellation_time) === null || _95 === void 0 ? void 0 : _95.reschedule_time) ||
                                    this.state.hoursBrefore > ((_98 = (_97 = (_96 = this.state.appointmentdata) === null || _96 === void 0 ? void 0 : _96.attributes) === null || _97 === void 0 ? void 0 : _97.reschedule_cantellation_time) === null || _98 === void 0 ? void 0 : _98.cancellation_time)) ? (React.createElement("div", { className: "btn-pair-of-filters" },
                                React.createElement(Button, { className: "up-btn", id: "cancel-btn-need", onClick: () => {
                                        this.setState({
                                            invoiceorCancel: "cancel",
                                            openModalInvoice: true,
                                        });
                                    } }, t("Cancel")),
                                "\u00A0\u00A0\u00A0",
                                React.createElement(Button, { className: "past-btn", id: "updateData_btn", disabled: this.checkDisablity(), onClick: () => {
                                        this.gotoThatPage();
                                    } }, t("Reschedule")))) : (""),
                            (!((_100 = (_99 = this.props.location) === null || _99 === void 0 ? void 0 : _99.state) === null || _100 === void 0 ? void 0 : _100.upcoming) ||
                                localStorage.getItem("appointmentIdFromIndexDb")) &&
                                ((_102 = (_101 = this.state.appointmentdata) === null || _101 === void 0 ? void 0 : _101.attributes) === null || _102 === void 0 ? void 0 : _102.status) === "completed" ? (React.createElement(React.Fragment, null,
                                React.createElement(Button, { className: "r-btn", onClick: () => {
                                        this.setState({ openModalReview: true });
                                    }, style: { width: "100%" } }, t("Write a review")),
                                React.createElement("p", { className: "p-query" }, t("Any Queries?")),
                                React.createElement(Button, { className: "a-btn", onClick: () => {
                                        this.moveToissueScreen("Booking ");
                                    }, style: { width: "100%" } }, t("Booking issue")),
                                React.createElement(Button, { className: "p-btn", onClick: () => {
                                        this.moveToissueScreen("Payment ");
                                    }, style: { width: "100%" } }, t("Payment issue")),
                                React.createElement(Button, { className: "s-btn", onClick: () => {
                                        this.moveToissueScreen("Service ");
                                    }, style: { width: "100%" } }, t("Service issue")),
                                React.createElement(Button, { className: "o-btn", onClick: () => {
                                        this.moveToissueScreen("Others ");
                                    }, style: { width: "100%" } }, t("Others")))) : (""))))));
        };
    }
    render() {
        let { t } = this.props;
        return (React.createElement(React.Fragment, null,
            React.createElement(Loader, { loading: this.state.isloading }),
            this.getRenderData(),
            React.createElement(Modal, { open: this.state.openModalInvoice, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" }, this.state.invoiceorCancel === "invoice" ? (React.createElement("div", { className: "modal-booknow-invoice" },
                React.createElement("div", { className: "img-debit-card-div-invoice" },
                    React.createElement("div", { className: "img-debit-card-invoice" },
                        React.createElement("div", { style: {
                                textAlign: "center",
                                margin: 50,
                                marginBottom: 10,
                            } },
                            React.createElement("img", { style: { marginLeft: "auto" }, src: invoice_mail, alt: "" })),
                        React.createElement("p", { className: "p-tag-invoice" }, t("Invoice has been sent to your mail.")))),
                React.createElement("div", null,
                    React.createElement(Button, { className: "book-now-btn-modal-debit-invoice", onClick: () => {
                            this.setState({ openModalInvoice: false });
                        } },
                        " ",
                        t("Done"))))) : (React.createElement("div", { className: "modal-booknow-cancel" },
                React.createElement("div", { className: "img-debit-card-div-cancel" },
                    React.createElement("div", { className: "img-debit-card-cancel" },
                        React.createElement("div", { style: {
                                textAlign: "center",
                                margin: 50,
                                marginBottom: 10,
                            } },
                            React.createElement("img", { style: { marginLeft: "auto" }, src: cancel_appointment, alt: "" })),
                        React.createElement("h1", { className: "h1-tag-cancel" }, t("Cancelling Appointment?")),
                        React.createElement("p", { className: "p-tag-cancel" }, t("Are you sure you want to cancel the appointment?")))),
                React.createElement("div", null,
                    React.createElement(Button, { className: "book-now-btn-modal-debit-cancel", onClick: () => {
                            this.setState({ openModalInvoice: false });
                        } },
                        " ",
                        t("Don’t Cancel"))),
                React.createElement("div", null,
                    React.createElement(Button, { className: "book-now-btn-modal-debit-cancel-yes", onClick: () => {
                            this.CancelAppointmentAccount();
                        } },
                        " ",
                        t("Yes")))))),
            React.createElement(Modal, { open: this.state.openModalReview, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" }, this.state.showSubmittedresp ? (React.createElement("div", { className: "modal-booknow-confirmed-review" },
                React.createElement("div", { className: "img-debit-card-div-confirmed-review" },
                    React.createElement("div", { className: "img-debit-card-confirmed-review" },
                        React.createElement("div", { style: {
                                textAlign: "center",
                                margin: 50,
                                marginBottom: 10,
                            } },
                            React.createElement("img", { style: { marginLeft: "auto", width: 120 }, src: this.state.alreadySubmitted ? warningIcon : green_check, alt: "" })),
                        React.createElement("h1", { className: "h1-review-done" }, this.state.alreadySubmitted
                            ? t("Review Already Submitted!")
                            : t("Congrats!")),
                        React.createElement("p", { className: "p-tag-confirmed-review" }, this.state.alreadySubmitted
                            ? ""
                            : t("Your review has been submitted.")))),
                React.createElement("div", null,
                    React.createElement(Button, { className: "book-now-btn-modal-debit-confirmed-review", onClick: () => {
                            this.props.history.push({
                                pathname: "/CustomerHomeScreen",
                            });
                        } },
                        " ",
                        t("Go Home"))))) : (React.createElement("div", { className: "modal-booknow-cancel-review" },
                React.createElement("div", { className: "img-debit-card-div-cancel-review" },
                    React.createElement("div", { className: "img-debit-card-cancel-review" },
                        React.createElement("h1", { className: "h1-tag-cancel-review" }, t("So what do you think?")),
                        React.createElement(TextField, { className: "textArea-issue-review", variant: "outlined", multiline: true, rows: 5, onChange: (e) => {
                                this.setState({ reviewInput: e.target.value });
                            }, InputProps: {
                                startAdornment: (React.createElement("img", { src: quote, style: {
                                        marginTop: "-80px",
                                        marginLeft: "10px",
                                        marginRight: "10px",
                                        width: 21,
                                    }, alt: "" })),
                            } }))),
                React.createElement("div", { className: "review-rating" },
                    React.createElement("h1", { className: "rating-h1" }, t("Ratings")),
                    React.createElement(Rating, { name: "size-large", defaultValue: 2, size: "large", value: this.state.ratingvalue, className: "rating-star", onChange: (event, newValue) => {
                            this.setState({ ratingvalue: newValue });
                        }, icon: React.createElement("img", { src: star_rate }), emptyIcon: React.createElement("img", { src: unfilled_star }) })),
                this.state.requireRating ? (React.createElement("p", { style: {
                        marginTop: 25,
                        color: "red",
                        fontSize: 14,
                        paddingLeft: 12,
                    } }, t("Write something and give rating to continue."))) : (""),
                React.createElement("div", { className: "btn-pair-of-filters" },
                    React.createElement(Button, { className: "up-btn review-cancel0button", onClick: () => {
                            this.setState({ openModalReview: false });
                        } }, t("Cancel")),
                    "\u00A0\u00A0\u00A0",
                    React.createElement(Button, { className: "past-btn review-submit0button", id: "updateData_btn", onClick: () => {
                            if (!this.state.reviewInput || !this.state.ratingvalue) {
                                this.setState({ requireRating: true });
                            }
                            else {
                                this.setState({ requireRating: false });
                                this.CreateReview();
                            }
                        } }, t("Submit"))))))));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(MyBookingsDetail));
// Customizable Area End
