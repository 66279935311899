var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Customizable Area Start
import React from "react";
import { logo, leftAArrow, eyeOpen, eyeClose, errorSymbol } from "./assets";
import { IconButton, InputAdornment, OutlinedInput, Checkbox, Button, Dialog, } from "@material-ui/core";
import "../assets/Styles/registration.css";
import RegistartionWebController from "./RegistrationWebController";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { warningIcon } from "../../dashboard/src/assets";
import Loader from "../../../components/src/Loader.web";
class Registration extends RegistartionWebController {
    constructor(props) {
        super(props);
        this.renderPasswordErrors = () => {
            let { t } = this.props;
            return (React.createElement(React.Fragment, null,
                this.state.passwordError && (React.createElement("span", { className: "error-message-text" }, t("This password is invalid."))),
                this.state.passwordEmptyError && (React.createElement("span", { className: "error-message-text" }, t("Please enter password"))),
                this.state.passwordWithSpaceError && (React.createElement("span", { className: "error-message-text" }, t("Please remove space from the password")))));
        };
        this.renderConfirmPasswordErrors = () => {
            let { t } = this.props;
            return (React.createElement(React.Fragment, null, this.state.passwordNotMatchedError && (React.createElement("span", { className: "error-message-text" }, t("The password do not match.")))));
        };
        this.renderEmailErrors = () => {
            let { t } = this.props;
            return (React.createElement(React.Fragment, null,
                this.state.emailEmptyError && (React.createElement("span", { className: "error-message-text" }, t("Please enter email"))),
                this.state.emailError && (React.createElement("span", { className: "error-message-text" }, t("This email is invalid"))),
                this.state.emailAlreadyRegisteredError && (React.createElement("span", { className: "error-message-text" }, t("This email is already registered")))));
        };
        this.renderBusinessNameErrors = () => {
            let { t } = this.props;
            return (React.createElement(React.Fragment, null,
                this.state.businessNameError && (React.createElement("span", { className: "error-message-text" }, t("Enter business name."))),
                this.state.businessNameAlreadyTakenError && (React.createElement("span", { className: "error-message-text" }, t("Entered business name already taken.")))));
        };
    }
    componentDidMount() {
        const _super = Object.create(null, {
            componentDidMount: { get: () => super.componentDidMount }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.componentDidMount.call(this);
            let isUserLoggedIn = localStorage.getItem("token") || "";
            if (isUserLoggedIn && isUserLoggedIn.length > 0) {
                this.handleNavigation("LandingPage");
            }
        });
    }
    render() {
        const { email, businessName, password, confirmPassword, isShowPassword, isShowConfirmPassword, isTermsAccepted, emailError, emailAlreadyRegisteredError, passwordNotMatchedError, passwordError, termsNotAcceptedError, businessNameError, businessNameAlreadyTakenError, userType, emailEmptyError, passwordEmptyError, passwordWithSpaceError, fullName, fullNameError, } = this.state;
        let { t } = this.props;
        let mainContainerClassName = this.state.userType === "business"
            ? "business-registration-form-container"
            : "registration-form-container";
        return (React.createElement(React.Fragment, null,
            React.createElement(Loader, { loading: this.state.loading }),
            React.createElement("div", { className: "registration-main-container" },
                React.createElement("div", { className: "registration-form-fields-main-container" },
                    React.createElement("div", { className: mainContainerClassName },
                        React.createElement("div", { className: "form-images-container" },
                            React.createElement("div", { className: "left-arrow-image-container" },
                                React.createElement("img", { src: leftAArrow, "data-testid": "left-arrow", onClick: () => this.handleNavigation("LandingPage") })),
                            React.createElement("div", { style: { width: "58%" } },
                                React.createElement("img", { src: logo, className: "logo-image-style" }))),
                        this.state.userType === "business" ? (React.createElement("span", { className: "account-creation-text-style" },
                            t("Create an account or log in to grow and"),
                            React.createElement("br", null),
                            " ",
                            t("manage your business."))) : (React.createElement("span", { className: "account-creation-text-style" },
                            t("Create an account or log in to book and"),
                            React.createElement("br", null),
                            " ",
                            t("manage your appointments."))),
                        React.createElement("div", { className: "registration-form-registration-input-container" },
                            userType === "business" ? (React.createElement("div", { className: "registration-input-container" },
                                React.createElement("span", { className: "registration-form-label" }, t("Business Name")),
                                React.createElement(OutlinedInput, { type: "text", inputProps: { maxLength: 50, min: 0 }, name: "businessName", value: businessName, onBlur: this.validateBusinessName, error: businessNameError || businessNameAlreadyTakenError, onChange: (e) => this.handleChange(e.target.name, e.target.value), placeholder: t("Business name"), className: "registration-form-input-style business-name-text-input" }),
                                this.renderBusinessNameErrors())) : (React.createElement("div", { className: "registration-input-container" },
                                React.createElement("span", { className: "registration-form-label" }, t("Full name")),
                                React.createElement(OutlinedInput, { type: "text", inputProps: { maxLength: 50, min: 0 }, name: "fullName", value: fullName, onBlur: this.validateFullName, error: fullNameError, onChange: (e) => this.setState(Object.assign(Object.assign({}, this.state), { fullName: e.target.value })), placeholder: t("Full name"), className: "registration-form-input-style business-name-text-input" }),
                                this.state.fullNameError && (React.createElement("span", { className: "error-message-text" }, t("Please enter full name"))))),
                            React.createElement("div", { className: "registration-input-container" },
                                React.createElement("span", { className: "registration-form-label" }, t("Enter your E-mail")),
                                React.createElement(OutlinedInput, { type: "text", name: "email", value: email, onBlur: this.validateEmail, error: emailError ||
                                        emailAlreadyRegisteredError ||
                                        emailEmptyError, onChange: (e) => this.handleChange(e.target.name, e.target.value), placeholder: t("Email"), className: "registration-form-input-style email-text-input" }),
                                this.renderEmailErrors()),
                            React.createElement("div", { className: "registration-input-container" },
                                React.createElement("span", { className: "registration-form-label" }, t("Password")),
                                React.createElement(OutlinedInput, { type: isShowPassword ? "text" : "password", placeholder: t("Password"), name: "password", value: password, autoComplete: "off", onBlur: this.validatePassword, error: passwordNotMatchedError ||
                                        passwordError ||
                                        passwordEmptyError ||
                                        passwordWithSpaceError, onChange: (e) => this.handleChange(e.target.name, e.target.value), className: "registration-form-input-style password-text-input", endAdornment: React.createElement(InputAdornment, { position: "end" },
                                        React.createElement(IconButton, { onClick: this.showPasswordChange, edge: "end", style: { width: 24, height: 24, marginRight: 1 } },
                                            React.createElement("img", { src: isShowPassword ? eyeOpen : eyeClose, height: 24, width: 24, alt: "eye" }))) }),
                                this.renderPasswordErrors()),
                            React.createElement("div", { className: "registration-input-container" },
                                React.createElement("span", { className: "registration-form-label" }, t("Confirm password")),
                                React.createElement(OutlinedInput, { type: isShowConfirmPassword ? "text" : "password", placeholder: t("Confirm Password"), name: "confirmPassword", value: confirmPassword, error: passwordNotMatchedError, onBlur: this.validatePassword, autoComplete: "off", onChange: (e) => this.handleChange(e.target.name, e.target.value), className: "registration-form-input-style confirm-password-text-input", endAdornment: React.createElement(InputAdornment, { position: "end" },
                                        React.createElement(IconButton, { onClick: this.showConfirmPasswordChange, edge: "end", style: { width: 24, height: 24, marginRight: 1 } },
                                            React.createElement("img", { src: isShowConfirmPassword ? eyeOpen : eyeClose, height: 24, width: 24, alt: "eye" }))) }),
                                this.renderConfirmPasswordErrors()),
                            React.createElement("div", { className: "t-and-c-policy-text-container" },
                                React.createElement(Checkbox, { checked: isTermsAccepted, name: "isTermsAccepted", style: {
                                        color: termsNotAcceptedError ? "#FF453A" : "#2C2C2E",
                                    }, onChange: (e) => this.handleChange(e.target.name, e.target.checked), className: "terms-checkbox-input" }),
                                React.createElement("span", { className: `t-and-c-policy-text ${termsNotAcceptedError &&
                                        "Mui-error"}` }, t("By clicking Join SqueezMeIn, you are agreeing to the Terms and conditions and the Privacy Policy."))),
                            termsNotAcceptedError && (React.createElement("div", { className: "t-and-c-error-container" },
                                React.createElement("img", { src: errorSymbol, alt: "error", height: 14, width: 12, className: "error-image" }),
                                React.createElement("span", { className: "t-and-c-error-text" }, t("Please check this box if you want to proceed.")))),
                            React.createElement(Button, { className: "join-now-button-style", onClick: this.signupUsingEmailApi }, t("Join Now"))),
                        React.createElement("span", { className: "already-member-text" },
                            t("Already a member?"),
                            " ",
                            React.createElement("span", { className: "login-now-text", onClick: () => this.handleNavigation("Login") }, t("Login now"))))),
                React.createElement(Dialog, { open: this.state.blockedModal, id: "close", onClose: () => this.setState({ blockedModal: false }) },
                    React.createElement("div", { className: "del-modal-container", style: {
                            width: "400px",
                        } },
                        React.createElement("img", { src: warningIcon, alt: "logo", width: "120", height: "120" }),
                        React.createElement("p", { id: "errorMsg" }, t("Your account is blocked")),
                        React.createElement(Button, { className: "add-member-cancel-button", id: "errorClose", onClick: () => {
                                this.setState({
                                    blockedModal: false,
                                });
                            } }, t("Close")))))));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(Registration));
// Customizable Area End
