import React from "react";
import AvailableController from "./AvailableController.web";
import { white_left_arrow, uncheck_icon, check_icon, defultPng } from "./assets";
import { search_Bitmap } from "../../ContentModeration/src/assets";
import "../assets/style/BusinessSevices.web.scss";
import "../assets/style/availableslot.web.scss";
import "../../Squeez_me_in/assets/style/ProfileDrawer.web.scss";
import "../../ContentModeration/assets/Styles/ManageWorkingHours.scss";
import { withRouter } from "react-router-dom";
import { TextField, CircularProgress, InputAdornment, Button, RadioGroup, FormControlLabel, FormControl, Radio, } from "@material-ui/core";
import { withTranslation } from "react-i18next";
const Cookies = require("js-cookie");
class AvailableSlot extends AvailableController {
    render() {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        let { t } = this.props;
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "main-div" },
                React.createElement("div", { style: { maxWidth: "100%", backgroundColor: "#3d40c3" } },
                    React.createElement("div", { className: "services-header" },
                        React.createElement("img", { src: white_left_arrow, alt: "", id: "service_left_arrow", onClick: () => {
                                var _a;
                                let { serviceId, shopSubResponse, subServiceid, name, categoryId, gender, currentCategory, staffId, } = (_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state;
                                this.props.history.push({
                                    pathname: "/customer/service/booking",
                                    state: {
                                        subServiceid,
                                        serviceId,
                                        shopSubResponse,
                                        name: name,
                                        currentCategory,
                                        gender,
                                        categoryId,
                                        staffId,
                                    },
                                });
                            } }),
                        React.createElement("h1", null, t("Available Staff")))),
                React.createElement("div", { className: "main-available-div" },
                    React.createElement("h1", { className: "h1-tag-title" }, t(`For ${(_e = (_d = (_c = (_b = (_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.shopSubResponse) === null || _c === void 0 ? void 0 : _c.attributes) === null || _d === void 0 ? void 0 : _d.service_provided) === null || _e === void 0 ? void 0 : _e.name}'s`) +
                        " " + ((_g = (_f = this.props.location) === null || _f === void 0 ? void 0 : _f.state) === null || _g === void 0 ? void 0 : _g.name[0])),
                    React.createElement("div", { className: "input-div" },
                        React.createElement(TextField, { InputProps: {
                                startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                    React.createElement("img", { src: search_Bitmap, className: "input-icon", alt: "" }))),
                            }, id: "outlined-basic", className: "seache-sevice-input-avaialabe", variant: "outlined", placeholder: t("Search for Staff") })),
                    React.createElement("div", { className: "main-scroll-list" },
                        React.createElement(FormControl, null,
                            React.createElement(RadioGroup, { "aria-labelledby": "demo-radio-buttons-group-label", value: this.state.checkBoxValue, name: "radio-buttons-group" }, (_h = this.state.StaffData) === null || _h === void 0 ? void 0 : _h.map((ele) => {
                                var _a, _b, _c, _d;
                                return (React.createElement("div", { className: `data-div ${webAppDirection === "rtl" &&
                                        "arabic-data-div"}`, key: ele.id, onClick: () => this.setState({ checkBoxValue: ele }) },
                                    React.createElement("img", { className: "img-tag", src: ((_a = ele === null || ele === void 0 ? void 0 : ele.attributes) === null || _a === void 0 ? void 0 : _a.team_member_profile) || defultPng, alt: "" }),
                                    React.createElement("div", { className: `detail-div ${webAppDirection === "rtl" &&
                                            "arabic-detail-div"}` },
                                        React.createElement("h1", null, ((_b = ele === null || ele === void 0 ? void 0 : ele.attributes) === null || _b === void 0 ? void 0 : _b.first_name) +
                                            " " + ((_c = ele === null || ele === void 0 ? void 0 : ele.attributes) === null || _c === void 0 ? void 0 : _c.last_name)),
                                        React.createElement("p", null, (_d = ele === null || ele === void 0 ? void 0 : ele.attributes) === null || _d === void 0 ? void 0 : _d.title)),
                                    React.createElement(FormControlLabel, { value: ele, control: React.createElement(Radio, { icon: React.createElement("img", { src: uncheck_icon }), checkedIcon: React.createElement("img", { src: check_icon }), className: "radio-group-component-item" }), label: "" })));
                            })))),
                    React.createElement("div", { className: "btn-pair-of-skip-cont" },
                        React.createElement(Button, { className: "skip-btn", style: { textTransform: "capitalize" }, onClick: () => {
                                var _a;
                                let { StaffData } = (_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state;
                                this.setState({ checkBoxValue: StaffData[0] }, () => {
                                    this.setState({ skipLoadingButton: true });
                                    this.createAppointmentAccount();
                                });
                            } }, this.state.skipLoadingButton ? (React.createElement(CircularProgress, { style: { color: "rgba(0, 0, 0, 0.87)" } })) : (t("Skip"))),
                        " ",
                        "\u00A0\u00A0\u00A0",
                        React.createElement(Button, { className: "cont-btn", style: { textTransform: "capitalize" }, onClick: () => {
                                var _a, _b;
                                this.setState({ loadingButton: true });
                                if ((_b = (_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.reschedule) {
                                    this.updateAppointmentAccount();
                                }
                                else {
                                    this.createAppointmentAccount();
                                }
                            } },
                            " ",
                            this.state.loadingButton ? (React.createElement(CircularProgress, { style: { color: "#ffffff" } })) : (t("Continue"))))))));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(AvailableSlot));
