// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { exit } from "../../dashboard/src/assets";
import "../assets/Styles/manage-catalouge.css";
import { Dialog, Button } from "@material-ui/core";
import { withTranslation } from "react-i18next";
class DeleteModal extends BlockComponent {
    render() {
        const { type } = this.props;
        const { deleteCategoryFunction, handleDeletePopupChange, t } = this.props;
        return (React.createElement(Dialog, { open: true },
            React.createElement("div", { className: "logout_modal" },
                React.createElement("img", { alt: "exit-icon", className: "modal-icon", src: exit }),
                React.createElement("div", { className: "logout_confirmation_text" },
                    t("Are you sure you want to delete this"),
                    " ",
                    type,
                    "?"),
                React.createElement(Button, { className: "delete-modal-no-button-style", variant: "contained", color: "primary", style: { width: "100%", marginTop: 1 }, onClick: handleDeletePopupChange }, t("No")),
                React.createElement(Button, { className: "delete-modal-yes-button-style", "data-testid": "delete-modal-yes-button-style", variant: "outlined", color: "secondary", style: { width: "100%", marginTop: 1 }, onClick: deleteCategoryFunction }, t("Yes")))));
    }
}
export default withTranslation()(DeleteModal);
// Customizable Area End
