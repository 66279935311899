var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
export const configJSON = require("./config");
export default class BookingConfirmController extends BlockComponent {
    constructor(props) {
        super(props);
        this.payByCardAddingCard = () => {
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.payCardApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.checkPayment + localStorage.getItem('paid_sessionId') + '&id=' + localStorage.getItem('paid_appointmentId'));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getHttpRequest);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.handleReceiveCardsPaid = (responseJson) => {
            this.setState({ isLoadingData: false });
            if ((responseJson === null || responseJson === void 0 ? void 0 : responseJson.message) === 'payment is paid') {
                this.setState({ paymentFailed: false });
                let bId = localStorage.getItem('paid_BookingId');
                let bcode = localStorage.getItem('paid_BookingCode');
                let obj = {
                    attributes: {
                        booking_id: bId,
                        booking_code: {
                            pin: bcode
                        }
                    }
                };
                setTimeout(() => {
                    this.props.history.push({
                        pathname: '/bookingConfirm',
                        state: { payAtShop: false, confirmAndPay: obj }
                    });
                }, 3000);
            }
            else {
                this.setState({ paymentFailed: true });
            }
        };
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            payAtshop: true,
            openDebitCardModal: true,
            paymentFailed: true,
            confirmPay: '',
            isLoadingData: true
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    componentDidMount() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            let user = localStorage.getItem("userType") || "";
            let token = localStorage.getItem("token") || "";
            if (user != "customer" || !token) {
                localStorage.clear();
                window.location.replace("/LandingPage");
            }
            else {
                if (window.location.pathname == '/paymentSuccessfull') {
                    this.payByCardAddingCard();
                }
                if ((_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state) {
                    let { confirmAndPay, payAtShop } = (_b = this.props.location) === null || _b === void 0 ? void 0 : _b.state;
                    this.setState({ payAtshop: payAtShop, confirmPay: confirmAndPay });
                }
            }
        });
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                if (apiRequestCallId === this.payCardApiCallId) {
                    this.handleReceiveCardsPaid(responseJson);
                }
            }
            else {
                runEngine.debugLog("GOIT");
            }
        });
    }
}
