import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../src/assets/toastNotification.scss';
import { withRouter } from 'react-router-dom';

const notification_squeez = require('../src/assets/Oval.png');
const ReactNotificationComponent = ({
  title,
  body,
  redirect_to,
  handleCloseToast,
  appointmentId,
  history
}) => {
  localStorage.setItem('appointmentIdFromIndexDb', appointmentId || '');
  localStorage.setItem('appointmentIdFromIndexDb1', appointmentId || '');

  toast.info(<Display />, {
    icon: false,
    position: toast.POSITION.BOTTOM_RIGHT,
    hideProgressBar: true,
    autoClose: 4000,
    onClose: handleCloseToast
  });

  function Display() {
    return (
      <div
        className="main-div-toast"
        onClick={() => {
          if (redirect_to) {
            handleCloseToast();
            window.history.replaceState(null, '', redirect_to);
            window.location.replace(redirect_to);
          }
        }}
      >
        <div className="header-div-toast">
          <img src={notification_squeez} alt="" />
          &nbsp;&nbsp;&nbsp;
          <p>SqueezMeln</p>
        </div>
        <h2>{title || ''}</h2>
        <p>{body}</p>
      </div>
    );
  }

  return '';
};

ReactNotificationComponent.defaultProps = {
  title: 'This is title',
  body: 'Some body'
};

ReactNotificationComponent.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string
};

export default withRouter(ReactNotificationComponent);
