var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { ArrowDown, ArrowUp, } from "./assets";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";
// Customizable Area Start
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
// Customizable Area End
export const configJSON = require("./config");
export default class AddTeamMembersController extends BlockComponent {
    constructor(props) {
        super(props);
        this.handleSuccessResponse = (responseJsonData) => {
            this.setState({ isLoader: false });
            if (responseJsonData !== null && !(responseJsonData === null || responseJsonData === void 0 ? void 0 : responseJsonData.errors) && (responseJsonData === null || responseJsonData === void 0 ? void 0 : responseJsonData.message) === "team member successfully created") {
                this.updateBusinessProfile();
            }
            if (responseJsonData === null || responseJsonData === void 0 ? void 0 : responseJsonData.errors) {
                toast.error(this.props.t(responseJsonData === null || responseJsonData === void 0 ? void 0 : responseJsonData.errors[0].message), {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    style: {
                        direction: "inherit"
                    }
                });
            }
        };
        this.getServiceProvidedName = () => {
            if (this.state.serviceData) {
                return this.state.serviceData.metadata && this.state.serviceData.metadata.service_provided;
            }
        };
        this.getAllServicesLength = () => {
            const services = this.state.selectedServices ? this.state.selectedServices.length : 0;
            return services;
        };
        this.handleRadioButton = (checked, value) => {
            let newSelectedServices = [...this.state.selectedServices];
            if (checked) {
                const selectedIndex = newSelectedServices.indexOf(value.toString());
                newSelectedServices.splice(selectedIndex, 1);
            }
            else {
                newSelectedServices.push(value.toString());
            }
            this.setState({ selectedServices: [...newSelectedServices] });
        };
        this.selectAll = (service, mainId, identifier) => {
            let newselectAll = this.state.selectAll;
            let newSelectedServices = [...this.state.selectedServices];
            if (identifier) {
                let array = [];
                service.map((subService) => {
                    array.push(subService.id.toString());
                });
                let uniqueArr = [...new Set([...array, ...newSelectedServices])];
                newselectAll.push(mainId.toString());
                this.setState({ selectedServices: uniqueArr, selectAll: newselectAll });
            }
            else {
                let selectAllData = this.state.selectAll;
                service.map((subService) => {
                    if (newSelectedServices.includes(subService.id.toString())) {
                        const selectedIndex = newSelectedServices.indexOf(subService.id.toString());
                        newSelectedServices.splice(selectedIndex, 1);
                    }
                });
                this.setState({ selectedServices: [...newSelectedServices] });
                const selectedIndex = newselectAll.indexOf(mainId.toString());
                selectAllData.splice(selectedIndex, 1);
                this.setState({ selectAll: [...selectAllData] });
            }
        };
        this.handleSearch = (value) => {
            if (!value) {
                this.setState({ teamServices: this.state.serviceData.data });
            }
            else { // Add this check
                const newServices = this.state.teamServices.filter((service) => {
                    return service.attributes.name.toLowerCase().includes(value.toLowerCase());
                });
                this.setState({ searchTerm: value, teamServices: newServices });
            }
            return this.state.teamServices;
        };
        this.optionDisable = (value) => {
            return value === "" ? true : false;
        };
        this.showTimeInUppercase = (value) => {
            return value.toUpperCase();
        };
        this.setGender = (value) => {
            if (value.length > 0) {
                this.setState({ selectedGender: value, selectedGenderError: '' });
            }
        };
        this.setLanguage = (value) => {
            this.setState({ selectedLanguage: value, selectedLanguageError: '' });
        };
        // web events
        // web events
        this.setInputValue = (text) => {
            this.setState({ txtInputValue: text, firstName: text, firstNameError: "" });
        };
        this.setTitleValue = (text) => {
            this.setState({ title: text, titleError: '' });
        };
        // web events
        this.handleImage = (event) => {
            let imageUrls = '';
            for (const file of event.target.files) {
                const url = URL.createObjectURL(file);
                imageUrls = url;
            }
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = () => {
                const base64Image = reader.result;
                this.setState({
                    image: { data: base64Image },
                });
            };
            this.setState({
                profileImages: imageUrls,
                profileImagesError: ''
            });
        };
        this.getErrorBorderStyle = (error) => {
            return error ? "1px solid #FF453A" : "1px solid #757575";
        };
        this.handleSelectToggle = (key) => {
            this.setState(prevState => {
                const newState = Object.assign(Object.assign({}, prevState), { [key]: !prevState[key] });
                return newState;
            });
        };
        this.renderErrorMessageforProfile = (Message) => {
            const errorMsg = Message ? Message : null;
            return errorMsg;
        };
        this.handleDayBackgroundColor = (item) => {
            const Weekend = item.isWeekend;
            const selectionDayMode = this.state.getSelectionMode;
            let backgroundColor = "#f9f9f9";
            const Weekday = item.isWeekday;
            if ((selectionDayMode === "weekdays" && Weekday) || (selectionDayMode === "weekends" && Weekend)) {
                backgroundColor = "#1E5FEA";
            }
            return backgroundColor;
        };
        this.handleCountryCodeChange = (value) => {
            if (value) {
                this.setState({ countryCode: value, countryCodeModal: false });
            }
            else {
                this.setState({ countryCodeModal: !this.state.countryCodeModal });
            }
        };
        this.handleDayColor = (item) => {
            const isWeekend = item.isWeekend;
            const selectionMode = this.state.getSelectionMode;
            let color = "#808080";
            const isWeekday = item.isWeekday;
            if ((selectionMode === "weekdays" && isWeekday) || (selectionMode === "weekends" && isWeekend)) {
                color = "#FFFFFF";
            }
            return color;
        };
        this.handleWorkingHoursSelection = (itemId) => {
            const { getSelectionMode, workingDaysError, weekendDaysError } = this.state;
            const updatedWorkingDays = this.state.workingDaysData.map((item) => {
                if (itemId !== item.id)
                    return item;
                if (getSelectionMode === "weekdays" && !item.isWeekend) {
                    item.isWeekday = !item.isWeekday;
                    item.isDisableWeekend = item.isWeekday;
                }
                else if (getSelectionMode === "weekends" && !item.isWeekday) {
                    item.isWeekend = !item.isWeekend;
                    item.isDisableWeekday = item.isWeekend;
                }
                return item;
            });
            const weekendDay = updatedWorkingDays.filter((item) => item.isWeekend);
            const workingDay = updatedWorkingDays.filter((item) => item.isWeekday);
            this.setState({
                workingDays: workingDay,
                weekendDays: weekendDay,
                workingDaysData: updatedWorkingDays,
                workingDaysError: workingDay.length ? "" : workingDaysError,
                weekendDaysError: weekendDay.length ? "" : weekendDaysError,
            });
        };
        this.getTextColor = (selectionMode, weekType) => {
            return selectionMode === weekType ? 'white' : 'black';
        };
        this.getBackGroundColor = (selectionMode, weekType) => {
            return selectionMode === weekType ? "#1e5fea" : undefined;
        };
        this.onWeekendFromSelection = (selectedItem) => {
            this.setState({ isWeekendToDisable: false, weekendFrom: selectedItem, weekendHoursError: "" });
            let count;
            this.state.workingSlot.forEach((item, index) => {
                if (item === selectedItem) {
                    count = index;
                    let indexFrom = this.state.workingSlot.filter((item, index) => {
                        if (index > count) {
                            return item;
                        }
                    });
                    indexFrom.unshift("");
                    this.setState({ weekendSlotTo: indexFrom });
                    let lastIndex = this.state.workingSlot.length - 1;
                    if (lastIndex === count) {
                        let timeTo = this.state.workingSlot.filter((item, index) => {
                            if (index !== count) {
                                return item;
                            }
                        });
                        timeTo.unshift("");
                        this.setState({ weekendSlotTo: timeTo });
                    }
                }
            });
        };
        this.onWorkingFromSelection = (selectedItem) => {
            this.setState({ isWorkingToDisable: false, workingFrom: selectedItem, workingHoursError: "" });
            let count;
            this.state.workingSlot.forEach((item, index) => {
                if (item === selectedItem) {
                    count = index;
                    let indexLast = this.state.workingSlot.length - 1;
                    let indexFrom = this.state.workingSlot.filter((item, index) => {
                        if (index > count) {
                            return item;
                        }
                    });
                    indexFrom.unshift("");
                    this.setState({ workingSlotTo: indexFrom });
                    if (indexLast === count) {
                        let toTime = this.state.workingSlot.filter((item, index) => {
                            if (index !== count) {
                                return item;
                            }
                        });
                        toTime.unshift("");
                        this.setState({ workingSlotTo: toTime });
                    }
                }
            });
        };
        this.onBlockFromSelection = (selectedItem) => {
            this.setState({ isBlockToDisable: false, blockTimeFrom: selectedItem, blockWorkingHoursError: "" });
        };
        this.handleCancel = () => {
            this.resetForm();
        };
        this.handleSuccesssModal = () => {
            this.setState({ successModal: true, isLoader: false });
        };
        this.resetForm = () => {
            this.setState({
                profileImages: '',
                title: '',
                firstName: '',
                lastName: '',
                emailAddress: '',
                contact: '',
                selectedGender: '',
                birthDate: null,
                selectedLanguage: '',
                aboutUsText: '',
                startDate: null,
                endDate: null,
                workingFrom: '',
                weekendFrom: '',
                weekendTo: '',
                blockTimeFrom: '',
                blockTimeTo: '',
                blockTimeToWeekends: '',
                blockTimeFromWeekends: '',
                workingTo: '',
                workingDays: [],
                weekendDays: [],
            });
        };
        this.getDate = () => {
            if (this.state.modaltype === "startDate") {
                return this.state.startDate;
            }
            else if (this.state.modaltype === "birthDate") {
                return this.state.birthDate;
            }
            else {
                return this.state.endDate;
            }
        };
        this.handleDateChange = (date) => {
            const formattedDate = moment(date).format("DD-MM-YYYY");
            if (this.state.modaltype === "startDate") {
                this.setState({ startDate: formattedDate, startDateError: '' });
            }
            else if (this.state.modaltype === "birthDate") {
                this.setState({ birthDate: formattedDate, birthDateError: '' });
            }
            else {
                this.setState({ endDate: formattedDate, endDateError: "" });
            }
        };
        this.handleOngoing = () => {
            if (this.state.modaltype === "startDate") {
                this.setState({ startDate: 'ongoing', openCalenderDialog: false });
            }
            else {
                this.setState({ endDate: 'ongoing', endDateError: "", openCalenderDialog: false });
            }
        };
        this.addTeamMembers = () => {
            const errors = {
                firstName: this.state.firstName === "",
                lastName: this.state.lastName === "",
                emailAddress: this.state.emailAddress === "" || !this.state.emailAddress.match(configJSON.emailRegex),
                contact: this.state.contact === "" || !this.state.contact.match(configJSON.contactRegex),
                birthDate: this.state.birthDate === null,
                title: this.state.title === '',
                blockWorkingHours: this.state.blockTimeFrom === "" || this.state.blockTimeTo === "" || this.state.blockTimeFrom === this.state.workingTo,
                blockWeekendHours: this.state.blockTimeFromWeekends === "" || this.state.blockTimeToWeekends === "" || this.state.blockTimeFromWeekends === this.state.blockTimeToWeekends,
                selectedGender: this.state.selectedGender === '',
                selectedLanguage: this.state.selectedLanguage === '',
                startDate: this.state.startDate === null,
                endDate: this.state.endDate === null,
                workingHours: this.state.workingFrom === "" || this.state.workingTo === "" || this.state.workingFrom === this.state.workingTo,
                weekendHours: this.state.weekendFrom === "" || this.state.weekendTo === "" || this.state.weekendFrom === this.state.weekendTo,
                workingDays: this.state.workingDays.length === 0,
                weekendDays: this.state.weekendDays.length === 0
            };
            const errorMessages = {
                firstName: "This field can't be empty",
                title: "This field can't be empty",
                lastName: "This field can't be empty",
                emailAddress: "Invalid email address",
                contact: "Please add your contact number",
                birthDate: "This field can't be empty",
                selectedGender: "Please choose your gender",
                selectedLanguage: "Please choose your language",
                startDate: "This field can't be empty",
                endDate: "This field can't be empty",
                workingHours: "Please choose your working time",
                weekendHours: "Please choose your weekend time",
                workingDays: "Please choose your working days",
                weekendDays: "Please choose your weekend days",
                blockWorkingHours: "Please choose your block time for weekdays",
                blockWeekendHours: "Please choose your block time for weekends"
            };
            let hasErrors = false;
            const errorState = {};
            Object.keys(errors).forEach((key) => {
                if (errors[key]) {
                    hasErrors = true;
                    errorState[`${key}Error`] = errorMessages[key];
                }
                else {
                    errorState[`${key}Error`] = "";
                }
            });
            if (hasErrors) {
                this.setState((prevState) => (Object.assign(Object.assign({}, prevState), errorState)));
            }
            else {
                this.PostTeamMembersAPICall();
            }
        };
        this.disableModalType = () => {
            const isDisabled = this.state.modaltype === "startDate" || this.state.modaltype === "birthDate";
            return isDisabled;
        };
        this.getHoursFromDuration = (data) => {
            if (moment(data.duration, 'HH:mm:ss').hours() === 1) {
                return "60";
            }
            else {
                return moment(data.duration, 'HH:mm:ss').minutes();
            }
        };
        this.getAllServices = () => {
            const header = {
                "Content-Type": "application/json",
                token: this.state.token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.apiAllServiceCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/show_business_category");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.handleArrow = (newState) => {
            if (newState) {
                return ArrowUp;
            }
            else {
                return ArrowDown;
            }
        };
        this.handleMinDate = () => {
            if (this.state.modaltype === "endDate") {
                if (!this.state.startDate) {
                    return new Date();
                }
                else {
                    return this.state.startDate;
                }
            }
        };
        this.PostTeamMembersAPICall = () => __awaiter(this, void 0, void 0, function* () {
            var _a;
            this.setState({ isLoader: true });
            let workingDays = [];
            let weekendDays = [];
            const header = {
                "Content-Type": "application/json",
                token: this.state.token
            };
            this.state.workingDays.map((item) => {
                workingDays.push(item.day);
            });
            this.state.weekendDays.map((item) => {
                weekendDays.push(item.day);
            });
            const attrs = {
                title: this.state.title,
                first_name: this.state.firstName,
                last_name: this.state.lastName,
                email_address: this.state.emailAddress,
                country_code: this.state.countryCode,
                mobile_number: this.state.contact,
                gender: this.state.selectedGender,
                date_of_birth: this.state.birthDate,
                prefered_language: this.state.selectedLanguage,
                about_me: this.state.aboutUsText,
                emp_start_date: this.state.startDate,
                emp_end_date: this.state.endDate,
                working_time_from: this.state.workingFrom,
                working_time_to: this.state.workingTo,
                block_time_from: this.state.blockTimeFrom,
                block_time_to: this.state.blockTimeTo,
                weekend_time_from: this.state.weekendFrom,
                weekend_time_to: this.state.weekendTo,
                weekend_working_days: weekendDays,
                weekend_block_time_from: this.state.blockTimeFromWeekends,
                weekend_block_time_to: this.state.blockTimeToWeekends,
                working_days: workingDays,
                business_sub_categories_ids: this.state.selectedServices,
                team_member_profile: ((_a = this.state.image) === null || _a === void 0 ? void 0 : _a.data) ? this.state.image : ""
            };
            const bodyData = { data: attrs };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.apiPostBusinessSetUpCallId = requestMessage.messageId;
            const httpBody = Object.assign({}, bodyData);
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), 'account_block/team_members');
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), 'POST');
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        // Customizable Area Start
        this.updateBusinessProfile = () => {
            this.setState({ isLoader: true });
            const header = {
                "Content-Type": "application/json",
                token: localStorage.getItem("token") || "",
            };
            let httpBody = {
                is_profile_completed: true,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.updateBusinessProfileApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/business_profiles/update_profile");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.updateBusinessProfileResponse = (responseJson) => {
            if ((responseJson === null || responseJson === void 0 ? void 0 : responseJson.message) === "Busienss Profile update successfully") {
                this.handleSuccesssModal();
            }
        };
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            isSelectOpenWeekHoursTo: false,
            isSelectOpenWeekEndsFrom: false,
            isSelectOpenWeekHoursFrom: false,
            isSelectOpenWeekEndsTo: false,
            isSelectOpen: false,
            isSelectLanguage: false,
            loginSuccess: false,
            isBlockToDisable: true,
            isSelectOpenBlockTimeHoursFrom: false,
            isSelectOpenBlockTimeHoursTo: false,
            openCalenderDialog: false,
            modaltype: 'start',
            blockTimeFrom: '',
            blockTimeToWeekends: '',
            isSelectOpenBlockTimeHoursWeekendsTo: false,
            BlockTimeHoursError: '',
            blockTimeTo: '',
            BlockTimeToError: '',
            blockTimeFromWeekends: '',
            isBlockToWeekendDisable: true,
            aboutUsText: "",
            backgroundColor: "#f9f9f9",
            color: "#808080",
            paymentOptionError: "",
            submisionError: '',
            token: '',
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            BlockTimeWeekendHoursError: '',
            isSelectOpenBlockTimeHoursWeekendsFrom: false,
            openAddedDetailsDialog: false,
            showImages: false,
            countryCode: '+91',
            countryCodeModal: false,
            deleteModal: false,
            teamServices: [],
            isLoader: false,
            searchTerm: '',
            // Customizable Area Start
            // Customizable Area End
            allowTeam: false,
            enablePayAtShop: false,
            enableCardPayment: false,
            showServiceList: false,
            service: "",
            selectedGender: '',
            selectedLanguage: '',
            selectedLanguageError: '',
            startDate: null,
            startDateError: '',
            endDate: null,
            endDateError: '',
            serviceValue: "Choose",
            serviceData: null,
            imageModalVisible: false,
            workingFrom: "",
            workingTo: "",
            profileImages: '',
            profileImagesError: "",
            selectedServices: [],
            selectAll: [],
            successModal: false,
            gender: [{
                    id: 1,
                    name: "Male"
                },
                {
                    id: 2,
                    name: "Female"
                },
            ],
            language: [{
                    id: 1,
                    name: "English"
                },
                {
                    id: 2,
                    name: "Arabic"
                },
            ],
            selectedTeamSize: null,
            removePhotoEnable: false,
            selectedPhoto: null,
            serviceCategory: [],
            firstName: "",
            title: '',
            firstNameError: "",
            titleError: '',
            selectedGenderError: '',
            lastName: "",
            lastNameError: "",
            emailAddress: "",
            emailAddressError: "",
            website: "",
            websiteError: "",
            birthDate: null,
            birthDateError: '',
            contact: "",
            contactError: "",
            bankAccountPlaceholder: "Add bank details",
            accountDetails: [],
            getRoundCorner: 25,
            getSelectionMode: 'weekdays',
            weekendFrom: "",
            weekendTo: "",
            serviceError: "",
            bankDetailsError: "",
            workingHoursError: "",
            workingDaysError: "",
            workingDays: [],
            isTakePhotoModalVisible: false,
            capturedImage: "",
            height: null,
            width: null,
            uri: "",
            weekendHoursError: "",
            blockWeekendHoursError: '',
            blockWorkingHoursError: '',
            weekendDays: [],
            isWorkingDayDisable: false,
            weekendDaysError: "",
            selectedWorkingFromIndex: "",
            selectedWorkingToIndex: '',
            workingSlot: [
                "",
                "12:00 am",
                "12:30 am",
                "1:00 am",
                "1:30 am",
                "2:00 am",
                "2:30 am",
                "3:00 am",
                "3:30 am",
                "4:00 am",
                "4:30 am",
                "5:00 am",
                "5:30 am",
                "6:00 am",
                "6:30 am",
                "7:00 am",
                "7:30 am",
                "8:00 am",
                "8:30 am",
                "9:00 am",
                "9:30 am",
                "10:00 am",
                "10:30 am",
                "11:00 am",
                "11:30 am",
                "12:00 pm",
                "12:30 pm",
                "1:00 pm",
                "1:30 pm",
                "2:00 pm",
                "2:30 pm",
                "3:00 pm",
                "3:30 pm",
                "4:00 pm",
                "4:30 pm",
                "5:00 pm",
                "5:30 pm",
                "6:00 pm",
                "6:30 pm",
                "7:00 pm",
                "7:30 pm",
                "8:00 pm",
                "8:30 pm",
                "9:00 pm",
                "9:30 pm",
                "10:00 pm",
                "10:30 pm",
                "11:00 pm",
                "11:30 pm",
            ],
            workingSlotTo: [],
            weekendSlotTo: [],
            isWorkingToDisable: true,
            isWeekendToDisable: true,
            image: null,
            workingDaysData: [
                {
                    id: 1,
                    isWeekday: false,
                    isWeekend: false,
                    day: "Sunday",
                    isDisableWeekend: false,
                    isDisableWeekday: false,
                },
                {
                    id: 2,
                    isWeekday: false,
                    isWeekend: false,
                    day: "Monday",
                    isDisableWeekend: false,
                    isDisableWeekday: false,
                },
                {
                    id: 3,
                    isWeekday: false,
                    isWeekend: false,
                    day: "Tuesday",
                    isDisableWeekend: false,
                    isDisableWeekday: false,
                },
                {
                    id: 4,
                    isWeekday: false,
                    isWeekend: false,
                    day: "Wednesday",
                    isDisableWeekend: false,
                    isDisableWeekday: false,
                },
                {
                    id: 5,
                    isWeekday: false,
                    isWeekend: false,
                    day: "Thursday",
                    isDisableWeekend: false,
                    isDisableWeekday: false,
                },
                {
                    id: 6,
                    isWeekday: false,
                    isWeekend: false,
                    day: "Friday",
                    isDisableWeekend: false,
                    isDisableWeekday: false,
                },
                {
                    id: 7,
                    isWeekday: false,
                    isWeekend: false,
                    day: "Saturday",
                    isDisableWeekend: false,
                    isDisableWeekday: false,
                },
            ],
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            runEngine.debugLog("Message Recived", message);
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                let responseJsonData = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                if (apiRequestId === this.apiAllServiceCallId) {
                    if (responseJsonData !== null && !responseJsonData.errors) {
                        this.setState({ serviceData: responseJsonData, teamServices: responseJsonData.data });
                    }
                }
                if (apiRequestId === this.apiPostBusinessSetUpCallId) {
                    this.handleSuccessResponse(responseJsonData);
                }
                if (apiRequestId === this.updateBusinessProfileApiCallId) {
                    this.updateBusinessProfileResponse(responseJsonData);
                }
            }
            // Customizable Area Start
            // Customizable Area End
        });
    }
    renderErrorMessage() {
        const errorMsg = this.state.getSelectionMode === "weekdays"
            ? this.state.workingDaysError
            : this.state.weekendDaysError;
        return errorMsg ? errorMsg : null;
    }
    componentDidUpdate(prevProps, prevState) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.state.openAddedDetailsDialog || this.state.loginSuccess) {
                setTimeout(() => {
                    this.setState({ openAddedDetailsDialog: false, loginSuccess: false });
                }, 3000);
            }
        });
    }
    componentDidMount() {
        const _super = Object.create(null, {
            componentDidMount: { get: () => super.componentDidMount }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.componentDidMount.call(this);
            this.setState({ loginSuccess: true, token: localStorage.getItem("token") }, () => { this.getAllServices(); });
            // Customizable Area Start
            setTimeout(() => {
                let userToken = localStorage.getItem("token") || "";
                let userType = localStorage.getItem("userType") || "";
                if (!userToken || userType != "business") {
                    localStorage.clear();
                    window.location.replace("/LandingPage");
                }
            }, 100);
            // Customizable Area End
        });
    }
}
