// Customizable Area Start
import React from "react";
import TeamBusinessWorkingController from "./TeamBusinessWorkingController.web";
import "../assets/Styles/ManageWorkingHours.scss";
import { rightBlackArrow, team_working, business_days, business_holiday, block_mytime, } from "./assets";
import { withRouter } from "react-router-dom";
import ManageBusinessWeb from "./ManageBusiness.web";
import { withTranslation } from "react-i18next";
//@ts-ignore
import Cookies from "js-cookie";
class ManageWorkingHours extends TeamBusinessWorkingController {
    //istanbul ignore next
    render() {
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        //@ts-ignore
        let { t } = this.props;
        return (React.createElement("div", { className: "manage-catalouge-main-container" },
            React.createElement(ManageBusinessWeb, null),
            React.createElement("div", { className: webAppDirection === "ltr"
                    ? "main-manage-hours-detail-list"
                    : "arabic-main-manage-hours-detail-list" },
                React.createElement("h1", null, t("Manage working days & time")),
                "\u00A0\u00A0\u00A0\u00A0",
                React.createElement("div", { className: "items-hours", onClick: () => {
                        this.props.history.push("/ManageBusiness/TimeWorkingDays");
                    } },
                    "\u00A0\u00A0",
                    React.createElement("img", { src: team_working, alt: "" }),
                    "\u00A0\u00A0\u00A0\u00A0\u00A0",
                    React.createElement("p", null, t("Team working days & time")),
                    React.createElement("img", { className: "arrow", src: rightBlackArrow, alt: "" })),
                "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0",
                React.createElement("div", { className: "items-hours", onClick: () => {
                        this.props.history.push("/ManageBusiness/BusinessWorkingDays");
                    } },
                    "\u00A0\u00A0",
                    React.createElement("img", { src: business_days, alt: "" }),
                    "\u00A0\u00A0\u00A0\u00A0\u00A0",
                    React.createElement("p", null, t("Business working days & time")),
                    React.createElement("img", { className: "arrow", src: rightBlackArrow, alt: "" })),
                "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0",
                React.createElement("div", { className: "items-hours", onClick: () => {
                        this.props.history.push("/ManageBusiness/ManageBlockMyTime");
                    } },
                    "\u00A0\u00A0",
                    React.createElement("img", { src: block_mytime, alt: "" }),
                    "\u00A0\u00A0\u00A0\u00A0\u00A0",
                    React.createElement("p", null, t("Block my employee days & time")),
                    React.createElement("img", { className: "arrow", src: rightBlackArrow, alt: "" })),
                "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0",
                React.createElement("div", { className: "items-hours", onClick: () => {
                        this.props.history.push("/ManageBusiness/ManageBusinessHoliday");
                    } },
                    "\u00A0\u00A0",
                    React.createElement("img", { src: business_holiday, alt: "" }),
                    "\u00A0\u00A0\u00A0\u00A0\u00A0",
                    React.createElement("p", null, t("Business Holiday")),
                    React.createElement("img", { className: "arrow", src: rightBlackArrow, alt: "" })))));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(ManageWorkingHours));
// Customizable Area End
