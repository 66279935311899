// Customizable Area Start
import React from "react";
import "../assets/Styles/manage-business.css";
import { defultprofile } from "../../dashboard/src/assets";
import { astar, dstar } from "../../appointmentmanagement/src/assets";
import BusinessManageReviewsWebController from "./BusinessManageReviewsWebController";
import { withTranslation } from "react-i18next";
//@ts-ignore
import Cookies from "js-cookie";
class ReviewsListing extends BusinessManageReviewsWebController {
    //istanbul ignore next
    render() {
        var _a;
        //@ts-ignore
        let { t } = this.props;
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        return (React.createElement("div", { className: webAppDirection === "ltr"
                ? "manage-catalouge-content-container"
                : "arabic-manage-catalouge-content-container" },
            React.createElement("span", { className: "manage-catalouge-header-text" }, t("Reviews")),
            this.state.allReviews.length === 0 && (React.createElement("div", { className: "add-new-categories-message-text" }, t("No reviews found"))),
            this.state.allReviews.length > 0 && ((_a = this.state.allReviews) === null || _a === void 0 ? void 0 : _a.map((review) => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
                return (React.createElement("div", { className: "manage-reviews-listing-details-container", key: review === null || review === void 0 ? void 0 : review.id },
                    React.createElement("div", { className: "review-detail-giver-container" },
                        React.createElement("div", { className: "manage-review-image-name-date-container" },
                            React.createElement("img", { src: ((_d = (_c = (_b = (_a = review === null || review === void 0 ? void 0 : review.attributes) === null || _a === void 0 ? void 0 : _a.reviewer) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.attributes) === null || _d === void 0 ? void 0 : _d.profile_image) || defultprofile, alt: "aa" }),
                            React.createElement("div", { className: "manage-review-name-date-container " },
                                React.createElement("div", { className: "manage-reviews-name-text-style" }, `${((_h = (_g = (_f = (_e = review === null || review === void 0 ? void 0 : review.attributes) === null || _e === void 0 ? void 0 : _e.reviewer) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.attributes) === null || _h === void 0 ? void 0 : _h.full_name) ? (_m = (_l = (_k = (_j = review === null || review === void 0 ? void 0 : review.attributes) === null || _j === void 0 ? void 0 : _j.reviewer) === null || _k === void 0 ? void 0 : _k.data) === null || _l === void 0 ? void 0 : _l.attributes) === null || _m === void 0 ? void 0 : _m.full_name : `${(_r = (_q = (_p = (_o = review === null || review === void 0 ? void 0 : review.attributes) === null || _o === void 0 ? void 0 : _o.reviewer) === null || _p === void 0 ? void 0 : _p.data) === null || _q === void 0 ? void 0 : _q.attributes) === null || _r === void 0 ? void 0 : _r.first_name} ${(_v = (_u = (_t = (_s = review === null || review === void 0 ? void 0 : review.attributes) === null || _s === void 0 ? void 0 : _s.reviewer) === null || _t === void 0 ? void 0 : _t.data) === null || _u === void 0 ? void 0 : _u.attributes) === null || _v === void 0 ? void 0 : _v.last_name}`}`),
                                React.createElement("div", { className: "manage-reviews-date-text-style" }, (_w = review === null || review === void 0 ? void 0 : review.attributes) === null || _w === void 0 ? void 0 : _w.review_date))),
                        React.createElement("div", { className: "manage-reviews-given-star-container" }, this.state.ratingStars.map((rating) => {
                            var _a;
                            return (React.createElement("img", { src: rating <= ((_a = review === null || review === void 0 ? void 0 : review.attributes) === null || _a === void 0 ? void 0 : _a.rating) ? astar : dstar, alt: "star", className: "rating-star-image", key: rating }));
                        }))),
                    React.createElement("div", { className: "manage-reviews-description-text-style" }, (_x = review === null || review === void 0 ? void 0 : review.attributes) === null || _x === void 0 ? void 0 : _x.description),
                    React.createElement("div", { className: "manage-reviews-employee-name-text-style" }, `${t("Employee :")} ${(_y = review === null || review === void 0 ? void 0 : review.attributes) === null || _y === void 0 ? void 0 : _y.employee_name}`),
                    React.createElement("div", { className: "manage-reviews-booking-code-text-style" }, `${t("Booking ID :")} ${(_z = review === null || review === void 0 ? void 0 : review.attributes) === null || _z === void 0 ? void 0 : _z.booking_id}`)));
            }))));
    }
}
//@ts-ignore
export default withTranslation()(ReviewsListing);
// Customizable Area End
