// Customizable Area Start
import React from "react";
import { withRouter } from "react-router-dom";
import ManageBusiness from "./ManageBusiness.web";
import { IconButton, Button, OutlinedInput, Dialog } from "@material-ui/core";
import { backdrop, editIcon, whiteAddbutton, } from "../../CustomisableUserProfiles/src/assets";
import moment from "moment";
import Calendar from "react-calendar";
import { calender_next_arrow, calender_prev_arrow, } from "../../Squeez_me_in/src/assets";
import BusinessManageBookingsWebController from "./BusinessManageBookingsWebController";
import "../assets/Styles/business-holday.scss";
import { withTranslation } from "react-i18next";
import { arrowRight, exit } from "../../dashboard/src/assets";
import Loader from "../../../components/src/Loader.web";
const Cookies = require("js-cookie");
class ManageBusinessHoliday extends BusinessManageBookingsWebController {
    render() {
        var _a;
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        let { t } = this.props;
        return (React.createElement(React.Fragment, null,
            React.createElement(Loader, { loading: this.state.loading }),
            React.createElement("div", { className: "manage-business-holiday-main-container" },
                React.createElement(ManageBusiness, null),
                React.createElement("div", { className: webAppDirection === "ltr"
                        ? "manage-business-holiday-content-container"
                        : "arabic-manage-business-holiday-content-container" },
                    this.state.displayType === "list" && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "manage-employee-heading-container" },
                            React.createElement("div", { className: "employee-back-icon", style: { display: "flex", alignItems: "center" } },
                                React.createElement(IconButton, { style: { width: "30px", height: "30px" }, id: "removeImage", onClick: this.handaleBackArrowButton },
                                    React.createElement("img", { src: backdrop, alt: "logo", width: "30px", height: "30px" })),
                                React.createElement("span", { className: "manage-employee-header-text" }, t("Business holidays")))),
                        React.createElement("div", { className: "add-holiday-card-container" }, (_a = this.state.leaveDetails) === null || _a === void 0 ? void 0 :
                            _a.map((holiday) => {
                                return (React.createElement("div", { key: holiday === null || holiday === void 0 ? void 0 : holiday.description, className: "business-holiday-card-box-class", onClick: () => this.handleItemClick(holiday) },
                                    React.createElement("div", { className: "business-holiday-details-container" },
                                        React.createElement("div", { className: "business-holiday-description-text" }, holiday === null || holiday === void 0 ? void 0 : holiday.description),
                                        React.createElement("div", { className: "business-holiday-date-text" }, moment(holiday === null || holiday === void 0 ? void 0 : holiday.date).format("DD MMM YYYY"))),
                                    React.createElement("div", { className: "business-holiday-image-container" },
                                        React.createElement("img", { src: arrowRight, className: "business-holiday-arrow" }))));
                            }),
                            !this.state.leaveDetails.length && (React.createElement("div", { style: { textAlign: "center", margin: "20px 0px" } }, "Holidays not found")),
                            React.createElement("div", { style: { textAlign: "center" } },
                                React.createElement(Button, { variant: "contained", className: "block-days-time-Add-button", "data-testid": "add-button-holiday", color: "primary", onClick: () => {
                                        this.handleAddEditButtonClick("add");
                                    } },
                                    React.createElement("img", { src: whiteAddbutton, alt: "type", width: "17px", height: "17px" }),
                                    " ",
                                    "\u00A0 ",
                                    t("Add another")))))),
                    (this.state.displayType === "add" ||
                        this.state.displayType === "edit") && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "manage-employee-heading-container " },
                            React.createElement("div", { className: "employee-back-icon ", style: { display: "flex", alignItems: "center" } },
                                React.createElement(IconButton, { style: { width: "30px", height: "30px" }, onClick: this.handleAddEditBackButton, "data-testid": "back-add-edit-button-holiday", id: "add-edit-back-button" },
                                    React.createElement("img", { src: backdrop, alt: "logo", width: "30px", height: "30px" })),
                                React.createElement("span", { className: "manage-employee-header-text " }, t("Business holidays")))),
                        React.createElement("div", { className: "add-holiday-card-container" },
                            React.createElement(Calendar, { minDate: new Date(), goToRangeStartOnSelect: false, value: this.state.selectedHolidayDate, onChange: this.handleHolidayDateChange, tileDisabled: ({ date }) => this.state.disabledDays.includes(date.getDay()), prevLabel: React.createElement("img", { src: calender_prev_arrow }), nextLabel: React.createElement("img", { src: calender_next_arrow }), tileClassName: ({ date, view }) => {
                                    var _a;
                                    //istanbul ignore next
                                    if ((_a = this.state.holidays) === null || _a === void 0 ? void 0 : _a.find((x) => x == moment(date).format("YYYY-MM-DD"))) {
                                        return "disable-date-highlight";
                                    }
                                    //istanbul ignore next
                                    if (moment(date).format("LL") ==
                                        moment(new Date()).format("LL")) {
                                        return "current-date-highlight";
                                    }
                                }, calendarType: "US", formatMonthYear: (locale, date) => date.toLocaleString(locale, {
                                    month: "short",
                                    year: "numeric",
                                }) }),
                            this.state.holidayDateError && (React.createElement("span", { className: "error-message-text" }, t("Please select holiday date."))),
                            React.createElement("div", { className: "booking-dialog-label" }, t("Description")),
                            React.createElement(OutlinedInput, { type: "text", multiline: true, rows: 4, style: {
                                    height: "150px",
                                }, placeholder: t("Holiday description"), "data-tesid": "add-edit-description-input", className: "business-holiday-description-input", value: this.state.holidayDescription || "", onChange: (e) => this.handleHolidayDescriptionChange(e.target.value), inputProps: {
                                    min: 0,
                                    maxLength: 100,
                                    style: { marginLeft: 5, height: "100%" },
                                }, error: this.state.holidayDescriptionError }),
                            this.state.holidayDescriptionError && (React.createElement("span", { className: "error-message-text" }, t("Please enter the description."))),
                            React.createElement("div", { className: "manage-business-holiday-buttons-container" },
                                React.createElement(Button, { className: "manage-business-holiday-cancel-button", "data-testid": "cancel-button-holiday", onClick: this.handleAddEditBackButton }, t("Cancel")),
                                React.createElement(Button, { className: "manage-business-holiday-save-button", "data-testid": "save-button-holiday", onClick: this.handleSaveButton }, t("Save")))))),
                    this.state.displayType === "detail" && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "manage-employee-heading-container 1" },
                            React.createElement("div", { className: "employee-back-icon 1", style: { display: "flex", alignItems: "center" } },
                                React.createElement(IconButton, { style: { width: "30px", height: "30px" }, onClick: () => this.handleChangeViewType("list"), id: "detail-back-button" },
                                    React.createElement("img", { src: backdrop, alt: "logo", width: "30px", height: "30px" })),
                                React.createElement("span", { className: "manage-employee-header-text 1" }, t("Business holidays")))),
                        React.createElement("div", { className: "add-holiday-card-container edit-view-holiday-card-container" },
                            React.createElement(Calendar, { minDate: new Date(), goToRangeStartOnSelect: false, value: this.state.selectedHolidayDate, onChange: () => { }, onClickDay: () => { }, tileDisabled: ({ date }) => this.state.disabledDays.includes(date.getDay()), prevLabel: React.createElement("img", { src: calender_prev_arrow }), nextLabel: React.createElement("img", { src: calender_next_arrow }), tileClassName: ({ date, view }) => {
                                    //istanbul ignore next
                                    if (this.state.selectedHolidayDate ===
                                        moment(date).format("YYYY-MM-DD")) {
                                        return "disable-date-highlight";
                                    }
                                    //istanbul ignore next
                                    if (moment(date).format("LL") ==
                                        moment(new Date()).format("LL")) {
                                        return "current-date-highlight";
                                    }
                                }, calendarType: "US", formatMonthYear: (locale, date) => date.toLocaleString(locale, {
                                    month: "short",
                                    year: "numeric",
                                }) }),
                            React.createElement("div", { className: "booking-dialog-label" }, t("Description")),
                            React.createElement(OutlinedInput, { type: "text", multiline: true, rows: 4, style: {
                                    height: "150px",
                                }, placeholder: t("Holiday description"), className: "business-holiday-description-input", value: this.state.holidayDescription || "", readOnly: true, inputProps: {
                                    min: 0,
                                    style: { marginLeft: 5, height: "100%" },
                                }, error: this.state.holidayDescriptionError }),
                            React.createElement("div", { className: "manage-business-holiday-buttons-container" },
                                React.createElement(Button, { className: "manage-business-holiday-save-button", "data-testid": "edit-button-holiday", onClick: () => this.handleAddEditButtonClick("edit") },
                                    React.createElement("img", { src: editIcon, alt: "edit", height: 18, width: 18 }),
                                    "\u00A0",
                                    t("Edit")),
                                React.createElement(Button, { className: "manage-business-holiday-cancel-button delete-holiday", "data-testid": "delete-modal-button-holiday", onClick: this.holidayModalHandle }, t("Delete")))))),
                    this.state.isDeletingHoliday && (React.createElement(Dialog, { open: this.state.isDeletingHoliday },
                        React.createElement("div", { className: "logout_modal" },
                            React.createElement("img", { alt: "exit-icon", className: "modal-icon", src: exit }),
                            React.createElement("div", { className: "logout_confirmation_text" },
                                t("Are you sure you want to delete this"),
                                " ",
                                t("holiday"),
                                "?"),
                            React.createElement(Button, { className: "delete-modal-no-button-style", variant: "contained", color: "primary", style: { width: "100%", marginTop: 1 }, onClick: this.holidayModalHandle }, t("No")),
                            React.createElement(Button, { className: "delete-modal-yes-button-style", "data-testid": "delete-modal-yes-button-style", variant: "outlined", color: "secondary", style: { width: "100%", marginTop: 1 }, onClick: this.deleteBusinessHoliday }, t("Yes")))))))));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(ManageBusinessHoliday));
// Customizable Area End
