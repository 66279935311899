// Customizable Area Start
import React from "react";
import PaymentMethodsInBusinessWebController from "./PaymentMethodsInBusinessWebController";
import "../assets/Styles/PaymentMethodsInBusiness.web.css";
import { no_saved_card_image } from "./assets";
import Loader from "../../../components/src/Loader.web";
import { master_card_icon, visa_icon } from "../../Squeez_me_in/src/assets";
import { withTranslation } from "react-i18next";
class PaymentMethodsInBusiness extends PaymentMethodsInBusinessWebController {
    render() {
        const { t } = this.props;
        return (React.createElement("div", { className: "payments-method-in-business-main-container" },
            React.createElement(Loader, { loading: this.state.loading }),
            React.createElement("h3", { className: "payments-method-in-business-heading-text" }, t("Payment Methods")),
            React.createElement("div", { className: "payments-method-in-business-cards-container" },
                React.createElement("div", { className: "payments-method-in-business-cards-sub-container" },
                    this.state.savedCardsList.length === 0 && (React.createElement(React.Fragment, null,
                        React.createElement("img", { src: no_saved_card_image, className: "no-saved-card-image" }),
                        React.createElement("div", { className: "no-card-attached-text" }, t("No card attached yet!")),
                        React.createElement("div", { className: "please-attach-card-message-text" }, t("Please, attach your card to pay for your booking. Thanks!")))),
                    this.state.savedCardsList.length > 0 &&
                        this.state.savedCardsList.map((card) => {
                            var _a, _b;
                            return (React.createElement("div", { className: "payment-details-in-business-card-details-container", key: card.id },
                                React.createElement("img", { src: ((_a = card === null || card === void 0 ? void 0 : card.brand) === null || _a === void 0 ? void 0 : _a.toLowerCase()) == "visa"
                                        ? visa_icon
                                        : master_card_icon, className: "payment-details-in-business-card-details-brand-image" }),
                                React.createElement("div", { className: "payment-details-in-business-card-details" },
                                    React.createElement("span", { className: "payment-details-in-business-card-brand-details", "data-test-id": "payment-details-in-business-card-brand-details" }, `${card === null || card === void 0 ? void 0 : card.brand} Card (${card === null || card === void 0 ? void 0 : card.card_type})`),
                                    React.createElement("span", { className: "payment-details-in-business-card-number-details", "data-test-id": "payment-details-in-business-card-number-details" }, `${(_b = card === null || card === void 0 ? void 0 : card.masked_card) === null || _b === void 0 ? void 0 : _b.replace(/ /g, "-")}`))));
                        })))));
    }
}
//@ts-ignore
export default withTranslation()(PaymentMethodsInBusiness);
// Customizable Area End
