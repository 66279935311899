// Customizable Area Start
import React from "react";
import "../../assets/Styles/ManageEmployee.css";
import { rightBlackArrow } from "../../../ContentModeration/src/assets";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
class ManageEmployeeList extends React.Component {
    //istanbul ignore next
    render() {
        let pathname = window.location.pathname;
        //@ts-ignore
        let { t } = this.props;
        return (React.createElement("div", { className: "manage-employee-list-main-container" },
            React.createElement("div", { className: "manage-employee-list-heading-container" },
                React.createElement("span", { className: "manage-employee-list-heading-text" }, t("Manage"))),
            React.createElement("div", { className: "manage-employee-services-list-item-container" },
                React.createElement("div", { className: pathname === "/ManageEmployee/EmployeeManageBookings"
                        ? "active-manage-services-list-item"
                        : "manage-services-list-item", onClick: () => 
                    //@ts-ignore
                    this.props.history.push("/ManageEmployee/EmployeeManageBookings", { from: "listing" }) },
                    React.createElement("div", { className: "manage-employee-services-list-details-container" },
                        React.createElement("span", { className: "manage-employee-services-list-item-heading" }, t("Manage bookings")),
                        React.createElement("span", { className: "manage-employee-services-list-item-details" }, t("List of all the bookings"))),
                    React.createElement("div", { className: "manage-employee-services-list-item-arrow-image-container" },
                        React.createElement("img", { src: rightBlackArrow, alt: "arrow", className: "manage-employee-services-list-item-arrow-image" }))),
                React.createElement("div", { className: pathname === "/ManageEmployee/EmployeeManageTabHome"
                        ? "active-manage-services-list-item"
                        : "manage-services-list-item", onClick: () => 
                    //@ts-ignore
                    this.props.history.push("/ManageEmployee/EmployeeManageTabHome") },
                    React.createElement("div", { className: "manage-employee-services-list-details-container" },
                        React.createElement("span", { className: "manage-employee-services-list-item-heading" }, t("Block my time")),
                        React.createElement("span", { className: "manage-employee-services-list-item-details" }, t("Manage working hours of your team"))),
                    React.createElement("div", { className: "manage-employee-services-list-item-arrow-image-container" },
                        React.createElement("img", { src: rightBlackArrow, alt: "arrow", className: "manage-employee-services-list-item-arrow-image" }))))));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(ManageEmployeeList));
// Customizable Area End
