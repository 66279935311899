import React from "react";
import CustomerHomeScreenController from "./CustomerHomeScreenController";
import { whiteLogo } from "../../dashboard/src/assets";
import "../assets/style/Footer.web.scss";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import CustomerSupportWeb from "../../dashboard/src/CustomerSupport.web";
import RequestDemoWeb from "../../dashboard/src/RequestDemo.web";
class Footer extends CustomerHomeScreenController {
    render() {
        let { t } = this.props;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "landing-page-footer-main-container" },
                React.createElement("div", { className: "landing-page-footer" },
                    React.createElement("div", { className: "footer-logo-container" },
                        React.createElement("img", { src: whiteLogo, alt: "logo", className: "landing-page-footer-logo-image" })),
                    React.createElement("div", { className: "footer-items-main-container" },
                        React.createElement("div", { className: "footer-item-container" },
                            React.createElement("span", { className: "footer-heading" }, t("About SqueezMeIn")),
                            React.createElement("span", { className: "footer-items-text", onClick: this.handleChangeCustomerSupport }, t("Customer Support"))),
                        React.createElement("div", { className: "footer-item-container" },
                            React.createElement("span", { className: "footer-heading" }, t("Business")),
                            React.createElement("span", { className: "footer-items-text", onClick: this.handleChangeRequestModal }, t("Request Demo"))),
                        React.createElement("div", { className: "footer-item-container" },
                            React.createElement("span", { className: "footer-heading" }, t("Legal")),
                            React.createElement("span", { className: "footer-items-text", onClick: () => {
                                    this.props.history.push({
                                        pathname: "/customer/profile",
                                        state: {
                                            isFromFooter: true,
                                            privacyOrTerms: "Privacy policy",
                                        },
                                    });
                                } }, t("Privacy Policy")),
                            React.createElement("span", { className: "footer-items-text", onClick: () => {
                                    this.props.history.push({
                                        pathname: "/customer/profile",
                                        state: {
                                            isFromFooter: true,
                                            privacyOrTerms: "Terms & conditions",
                                        },
                                    });
                                } }, t("Terms & Conditions"))))),
                React.createElement("hr", null)),
            this.state.customerSupportModal && (React.createElement(CustomerSupportWeb, { open: this.state.customerSupportModal, onClose: this.handleChangeCustomerSupport })),
            React.createElement(RequestDemoWeb, { navigation: this.props.navigation, open: this.state.requestDemo, onClose: this.handleChangeRequestModal })));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(Footer));
