import React from "react";
import ProfileDrawerController from "../../Squeez_me_in/src/ProfileDrawerController.web";
import "../../Squeez_me_in/assets/style/ProfileDrawer.web.scss";
import { squeezMein } from "./assets";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
class SettingPrivacyTemsaboutContentmyProfile extends ProfileDrawerController {
    constructor() {
        super(...arguments);
        //istanbul ignore next
        this.handleMail = () => {
            let div1 = document.getElementsByClassName("hiperlink");
            let mail1 = div1[0].textContent || "squeezmein.tech@gmail.com";
            if (mail1) {
                const mailtoLink = `mailto:${mail1}`;
                window.location.href = mailtoLink;
            }
        };
    }
    //istanbul ignore next
    render() {
        let { t } = this.props;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "contact-div" },
                React.createElement("h5", { className: "contact-titile" }, t(this.props.setBackgroundSettingdata)),
                React.createElement("div", { style: {
                        height: this.props.setBackgroundSettingdata === "Contact Us"
                            ? "274px"
                            : "664px",
                    }, className: this.props.setBackgroundSettingdata === "Contact Us"
                        ? "setting-div"
                        : "other-setting-div" }, this.props.setBackgroundSettingdata === "Contact Us" ? (React.createElement(React.Fragment, null,
                    React.createElement("img", { src: squeezMein, alt: "" }),
                    React.createElement("h2", null,
                        t("Contact Details"),
                        ":"),
                    React.createElement("div", { className: "contact-email-div" },
                        React.createElement("p", null,
                            t("Email"),
                            ":"),
                        React.createElement("a", { onClick: this.handleMail, className: "hiperlink", dangerouslySetInnerHTML: { __html: this.props.response } })))) : (React.createElement("h1", { style: { textAlign: "initial" }, dangerouslySetInnerHTML: { __html: this.props.response } }))))));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(SettingPrivacyTemsaboutContentmyProfile));
