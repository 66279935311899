import React from "react";
import { OutlinedInput, InputAdornment, IconButton, TextField, Button, Dialog, DialogContent, Box, } from "@material-ui/core";
import ImageUploading from "react-images-uploading";
import { warningIcon } from "../../dashboard/src/assets";
import { search_Bitmap, walkInPencil } from "./assets";
import Modal from "@material-ui/core/Modal";
import Checkbox from "@material-ui/core/Checkbox";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import { ArrowUp, ArrowDown, backdrop, } from "../../CustomisableUserProfiles/src/assets";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import AfterLoginHeader from "../../dashboard/src/AfterLoginHeader.web";
import CountryCodeModal from "../../social-media-account-login/src/CountryCodeModal.web";
import EditTeamMemberWebController from "./EditTeamMemberWebController";
import "../assets/Styles/add-team-member.css";
import moment from "moment";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "../assets/Styles/custom-calendar.scss";
import { withTranslation } from "react-i18next";
//@ts-ignore
import Cookies from "js-cookie";
class EditTeamMember extends EditTeamMemberWebController {
    constructor(props) {
        super(props);
        this.handleDateChange = (date) => {
            const formattedDate = moment(date).format("YYYY-MM-DD");
            if (this.state.DateModalType === "startDate") {
                this.setState({ StartDate: formattedDate, StartDateError: "" });
            }
            else {
                this.setState({ EndDate: formattedDate, EndDateError: "" });
            }
        };
        this.handleDateOfBirthChange = (date) => {
            const formattedDate = moment(date).format("YYYY-MM-DD");
            this.setState({ Dob: formattedDate, DobError: "" });
        };
        this.handaleClose = () => {
            this.setState({
                openCalenderDialog: false,
                openStartCalenderDialog: false,
            });
        };
        this.handleOngoing = () => {
            if (this.state.DateModalType === "startDate") {
                this.setState({
                    StartDate: "ongoing",
                    StartDateError: "",
                    openCalenderDialog: false,
                });
            }
            else {
                this.setState({
                    EndDate: "ongoing",
                    EndDateError: "",
                    openCalenderDialog: false,
                });
            }
        };
        //istanbul ignore next
        this.borderRight = () => {
            let webAppDirection = localStorage.getItem("webAppDirection") ||
                Cookies.get("webAppDirection") ||
                "ltr";
            return webAppDirection === "ltr"
                ? this.state.MobileError
                    ? "1px solid #ff453a"
                    : "1px solid #979797"
                : "";
        };
        //istanbul ignore next
        this.borderLeft = () => {
            let webAppDirection = localStorage.getItem("webAppDirection") ||
                Cookies.get("webAppDirection") ||
                "ltr";
            return webAppDirection === "rtl"
                ? this.state.MobileError
                    ? "1px solid #ff453a"
                    : "1px solid #979797"
                : "";
        };
    }
    render() {
        var _a, _b, _c, _d;
        //@ts-ignore
        const { handleAddTeamMember, t } = this.props;
        const { workingHoursError, weekendHoursError } = this.state;
        const borderStyleworkingHours = this.getBorderStyle(workingHoursError);
        const borderStyleweekendHours = this.getBorderStyle(weekendHoursError);
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        let editDropDownClassName = webAppDirection === "ltr" ? "dropdown-image" : "arabic-dropdown-image";
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "add-member-main-container" },
                React.createElement(AfterLoginHeader, { navigation: this.props.navigation }),
                React.createElement("div", { className: "add-member-subheader-container" },
                    React.createElement("div", { className: "add-member-icon" },
                        React.createElement(IconButton, { style: {
                                width: "24px",
                                height: "24px",
                            }, id: "removeImage" },
                            React.createElement("img", { src: backdrop, alt: "logo", width: "24px", height: "24px", onClick: () => handleAddTeamMember("none", null), id: "addMemberIcon" }))),
                    React.createElement("span", { className: "add-member-subheader-text" }, t("Team Member"))),
                React.createElement("div", { className: "add-member-paper-box-container" },
                    React.createElement("div", { className: "add-member-paper-box" },
                        React.createElement("div", { className: "add-member-paper-box-image-div" },
                            React.createElement(ImageUploading, { multiple: true, value: this.state.profileImage, maxNumber: 1, onChange: this.onImageChange, acceptType: ["jpg", "jpeg", "png"] }, ({ imageList, onImageUpload, onImageUpdate, isDragging, dragProps, }) => (
                            // write your building UI
                            React.createElement("div", { className: "add-member-upload__image-wrapper" }, this.state.profileImage ? (imageList.map((image, index) => (React.createElement("div", { key: index, className: "add-member-image-item" },
                                React.createElement("img", { src: image.dataURL, className: "contain-image", alt: "", 
                                    //@ts-ignore
                                    onClick: () => onImageUpdate(index) }))))) : (React.createElement(AddPhotoAlternateIcon, Object.assign({ style: isDragging ? { color: "red" } : undefined, onClick: onImageUpload }, dragProps))))))),
                        React.createElement("div", { className: "add-member-field-container" },
                            React.createElement("span", { className: "add-member-form-label" }, t("Title")),
                            React.createElement(OutlinedInput, { type: "text", id: "memberTitle", className: "add-member-form-input-style", error: this.state.NameError, value: this.state.Name, onChange: (e) => {
                                    this.setState({ NameError: "", Name: e.target.value });
                                }, onBlur: (e) => this.validateName(), inputProps: { min: 0, maxLength: 50 } }),
                            React.createElement("span", { className: "add-member-error-message" }, t(this.renderErrorMessageforProfile(this.state.NameError)))),
                        React.createElement("div", { className: "add-member-field-container-nth-child" },
                            React.createElement("span", { className: "add-member-form-label" }, t("First name")),
                            React.createElement(OutlinedInput, { type: "text", id: "memberFname", className: "add-member-form-input-style", error: this.state.FnameError, value: this.state.Fname, onChange: (e) => {
                                    this.setState({ FnameError: "", Fname: e.target.value });
                                }, onBlur: (e) => this.validateFname(), inputProps: { min: 0, maxLength: 25 } }),
                            React.createElement("span", { className: "add-member-error-message" }, t(this.renderErrorMessageforProfile(this.state.FnameError)))),
                        React.createElement("div", { className: "add-member-field-container-nth-child" },
                            React.createElement("span", { className: "add-member-form-label" }, t("Last name")),
                            React.createElement(OutlinedInput, { type: "text", id: "memberLname", className: "add-member-form-input-style", error: this.state.LnameError, value: this.state.Lname, onChange: (e) => {
                                    this.setState({ LnameError: "", Lname: e.target.value });
                                }, onBlur: (e) => this.validateLname(), inputProps: { min: 0, maxLength: 25 } }),
                            React.createElement("span", { className: "add-member-error-message" }, t(this.renderErrorMessageforProfile(this.state.LnameError)))),
                        React.createElement("div", { className: "add-member-field-container-nth-child" },
                            React.createElement("span", { className: "add-member-form-label" }, t("Email Address")),
                            React.createElement(OutlinedInput, { type: "text", disabled: true, id: "memberEmail", className: "add-member-form-input-style", error: this.state.EmailError, value: this.state.Email, onChange: (e) => {
                                    this.setState({ EmailError: "", Email: e.target.value });
                                }, onBlur: (e) => this.validateEmail() }),
                            React.createElement("span", { className: "add-member-error-message" }, t(this.renderErrorMessageforProfile(this.state.EmailError)))),
                        React.createElement("div", { className: "add-member-field-container-nth-child" },
                            React.createElement("span", { className: "add-member-form-label" }, t("Mobile number")),
                            React.createElement(OutlinedInput, { type: "text", id: "memberMobile", disabled: true, className: "add-member-form-input-style", error: this.state.MobileError, value: this.state.Mobile, startAdornment: React.createElement(InputAdornment, { position: webAppDirection === "ltr" ? "start" : "end", id: "mobile-input" },
                                    React.createElement(IconButton, { disabled: true, style: {
                                            width: 66,
                                            height: 54,
                                            borderRight: this.borderRight(),
                                            borderLeft: this.borderLeft(),
                                            cursor: "pointer",
                                            justifyContent: "center",
                                            borderRadius: "0%",
                                        } },
                                        React.createElement("span", { className: "country-code-text-style", style: {
                                                color: this.state.MobileError
                                                    ? "#ff453a"
                                                    : "#2c2c2e",
                                            } }, this.state.selectedCountryCode))) }),
                            React.createElement("span", { className: "add-member-error-message" }, t(this.renderErrorMessageforProfile(this.state.MobileError)))),
                        React.createElement("div", { className: "add-member-field-container-nth-child" },
                            React.createElement("span", { className: "add-member-form-label" }, t("Gender")),
                            React.createElement("div", { className: "add-member-form-custom-select large-input form-custom-select" },
                                React.createElement("select", { className: "add-member-form-input-style", id: "memberGender", name: "Gender", value: this.state.Gender, onChange: (e) => {
                                        this.setState({
                                            GenderError: "",
                                            Gender: e.target.value,
                                        });
                                    } },
                                    React.createElement("option", { value: "male" }, t("Male")),
                                    React.createElement("option", { value: "female" }, t("Female"))),
                                React.createElement("img", { src: ArrowDown, alt: "Custom Icon", className: editDropDownClassName }))),
                        React.createElement("div", { className: "add-member-field-container-nth-child" },
                            React.createElement("span", { className: "add-member-form-label" }, t("Date of birth")),
                            React.createElement(OutlinedInput, { id: "dateOfBirth", type: "text", className: "add-member-form-input-style", error: this.state.DobError, onClick: () => {
                                    this.setState({
                                        openDobCalenderDialog: true,
                                        DateModalType: "dateOfBirth",
                                    });
                                }, onBlur: () => {
                                    this.onDateChange(this.state.Dob, "dateOfBirth");
                                }, placeholder: "Select date", value: this.state.Dob, style: { fontFamily: "Poppins-Medium, sans-serif" }, endAdornment: React.createElement(InputAdornment, { position: "end" },
                                    React.createElement(IconButton, { id: "downArrowIcon", style: { fontWeight: 10 }, "aria-label": "toggle password visibility", edge: "end" },
                                        React.createElement("img", { src: ArrowDown, className: editDropDownClassName, alt: "type", width: "25px", height: "26px" }))) })),
                        React.createElement("div", { className: "add-member-field-container-nth-child" },
                            React.createElement("span", { className: "add-member-form-label" }, t("Preferred language")),
                            React.createElement("div", { className: "add-member-form-custom-select large-input form-custom-select" },
                                React.createElement("select", { className: "add-member-form-input-style", id: "memberLanguage", name: "Language", value: this.state.Language, onChange: (e) => {
                                        this.setState({
                                            LanguageError: "",
                                            Language: e.target.value,
                                        });
                                    } },
                                    React.createElement("option", { value: "english" }, t("English")),
                                    React.createElement("option", { value: "arabic" }, t("Arabic"))),
                                React.createElement("img", { src: ArrowDown, alt: "Custom Icon", className: editDropDownClassName }))),
                        React.createElement("div", { className: "add-member-field-container-nth-child" },
                            React.createElement("span", { className: "add-member-form-label" }, t("About me")),
                            React.createElement(OutlinedInput, { style: {
                                    height: "auto",
                                    padding: "10px",
                                }, value: this.state.AboutMe, id: "memberAbout", name: "AboutMe", multiline: true, rows: 6, type: "text", className: "add-member-form-input-style", onChange: (e) => {
                                    this.setState({ AboutMe: e.target.value });
                                }, inputProps: { min: 0, maxLength: 100 } }))),
                    React.createElement("div", { className: "add-member-paper-box", style: { backgroundColor: "transparent" } },
                        React.createElement("div", { className: "add-member-right-subheader-container" },
                            React.createElement("span", { className: "add-member-subheader-text" }, t("Employment"))),
                        React.createElement("div", { className: "add-member-Employment-card-container" },
                            React.createElement("div", { className: "add-member-field-container-nth-child" },
                                React.createElement("span", { className: "add-member-card-form-label" }, t("Start date")),
                                React.createElement(OutlinedInput, { id: "calenderstartTime", type: "text", error: this.state.StartDateError, onClick: () => {
                                        this.setState({
                                            StartDate: new Date(),
                                            openStartCalenderDialog: true,
                                            DateModalType: "startDate",
                                        });
                                    }, onBlur: () => {
                                        this.onDateChange(this.state.StartDate, "start");
                                    }, placeholder: "Select date", value: this.state.StartDate, style: { fontFamily: "Poppins-Medium, sans-serif" }, endAdornment: React.createElement(InputAdornment, { position: "end" },
                                        React.createElement(IconButton, { "aria-label": "toggle password visibility", edge: "end" },
                                            React.createElement("img", { src: ArrowDown, alt: "type", className: editDropDownClassName, width: "25px", height: "26px" }))) })),
                            React.createElement("div", { className: "add-member-field-container-nth-child" },
                                React.createElement("span", { className: "add-member-card-form-label" }, t("End date")),
                                React.createElement(OutlinedInput, { id: "calender-endtime", type: "text", error: this.state.EndDateError, value: t(this.state.EndDate), placeholder: "Select date", onClick: () => {
                                        this.setState({
                                            EndDate: new Date(),
                                            openCalenderDialog: true,
                                            DateModalType: "endDate",
                                        });
                                    }, onBlur: () => {
                                        this.onDateChange(this.state.EndDate, "end");
                                    }, style: { fontFamily: "Poppins-Medium, sans-serif" }, endAdornment: React.createElement(InputAdornment, { position: "end" },
                                        React.createElement(IconButton, { "aria-label": "toggle password visibility", edge: "end" },
                                            React.createElement("img", { src: ArrowDown, alt: "type", width: "25px", className: editDropDownClassName, height: "26px" }))) }))),
                        React.createElement("div", { className: "add-member-right-header-container-nth-child" },
                            React.createElement("span", { className: "add-member-subheader-text" }, t("Working Days & Time"))),
                        React.createElement("div", { className: "add-member-workingday-card-container" },
                            React.createElement("div", { className: "add-member-workingday-card-content" },
                                React.createElement(ToggleButtonGroup, { value: "value", "aria-label": "workingdays" },
                                    React.createElement(ToggleButton, { id: "toggleButton", style: {
                                            color: this.getTextColor(this.state.getSelectionMode, "weekdays"),
                                            backgroundColor: this.getBackGroundColor(this.state.getSelectionMode, "weekdays"),
                                        }, className: "add-member-myCustomButton", value: "Weekdays", onClick: () => {
                                            this.updatedSwitchData("weekdays");
                                        } }, t("Weekdays")),
                                    React.createElement(ToggleButton, { className: "add-member-myCustomButton", style: {
                                            marginLeft: 10,
                                            color: this.getTextColor(this.state.getSelectionMode, "weekends"),
                                            backgroundColor: this.getBackGroundColor(this.state.getSelectionMode, "weekends"),
                                        }, value: "Weekends", onClick: () => {
                                            this.updatedSwitchData("weekends");
                                        } }, t("Weekends")))),
                            this.state.getSelectionMode === "weekdays" ? (React.createElement("div", { className: "input-container-item-time add-member-input-container-item-time" },
                                React.createElement("div", { className: "add-member-hours-form hours-form" },
                                    React.createElement("div", { style: {
                                            position: "absolute",
                                            marginBottom: 50,
                                        } },
                                        React.createElement("span", { className: "profile-form-sub-header add-member-profile-form-sub-header" }, t("From"))),
                                    React.createElement("div", { className: "add-member-form-custom-select form-custom-select " },
                                        React.createElement("select", { id: "SelectWeekHours", onChange: (event) => {
                                                this.onWorkingFromSelection(event.target.value);
                                            }, onClick: () => {
                                                this.handleSelectToggle("isSelectOpenWeekHoursFrom");
                                            }, style: {
                                                borderRadius: 8,
                                                direction: "inherit",
                                                border: borderStyleworkingHours,
                                            }, value: this.state.workingFrom }, this.state.workingSlot.map((option, index) => {
                                            let [time, amPm] = this.showTimeInUppercase(option).split(" ");
                                            return (React.createElement("option", { value: option, key: index, id: index, disabled: this.optionDisable(option) },
                                                time,
                                                " ",
                                                t(amPm)));
                                        })),
                                        React.createElement("img", { className: editDropDownClassName, src: this.state.isSelectOpenWeekHoursFrom
                                                ? ArrowUp
                                                : ArrowDown, alt: "Custom Icon" }))),
                                React.createElement("div", { className: "add-member-hours-to hours-to" },
                                    React.createElement("div", { style: {
                                            position: "absolute",
                                            marginBottom: 50,
                                        } },
                                        React.createElement("span", { className: "add-member-profile-form-sub-header profile-form-sub-header " }, t("To"))),
                                    React.createElement("div", { className: "add-member-form-custom-select form-custom-select" },
                                        React.createElement("select", { id: "mySelectWorkingSlotTo", disabled: this.state.isWorkingToDisable, value: this.state.workingTo, onChange: (event) => {
                                                this.setState({
                                                    workingTo: event.target.value,
                                                    workingHoursError: "",
                                                });
                                            }, onClick: () => {
                                                this.handleSelectToggle("isSelectOpenWeekHoursTo");
                                            }, style: {
                                                border: borderStyleworkingHours,
                                                direction: "inherit",
                                                borderRadius: 8,
                                            } }, this.state.workingSlotTo.map((option, index) => {
                                            let [time, amPm] = this.showTimeInUppercase(option).split(" ");
                                            return (React.createElement("option", { key: index, value: option, disabled: this.optionDisable(option), id: index },
                                                time,
                                                " ",
                                                t(amPm)));
                                        })),
                                        React.createElement("img", { className: editDropDownClassName, src: this.state.isSelectOpenWeekHoursTo
                                                ? ArrowUp
                                                : ArrowDown, alt: "Custom Icon" }))))) : (React.createElement("div", { className: "input-container-item-time add-member-input-container-item-time" },
                                React.createElement("div", { className: "add-member-hours-form hours-form" },
                                    React.createElement("div", { style: {
                                            position: "absolute",
                                            marginBottom: 50,
                                        } },
                                        React.createElement("span", { className: "profile-form-sub-header add-member-profile-form-sub-header " }, t("From"))),
                                    React.createElement("div", { className: "add-member-form-custom-select form-custom-select" },
                                        React.createElement("select", { id: "mySelectWeekendFrom", value: this.state.weekendFrom, onChange: (event) => {
                                                this.onWeekendFromSelection(event.target.value);
                                            }, onClick: () => {
                                                this.handleSelectToggle("isSelectOpenWeekEndsFrom");
                                            }, style: {
                                                borderRadius: 8,
                                                border: borderStyleweekendHours,
                                                direction: "inherit",
                                            } }, this.state.workingSlot.map((option, index) => {
                                            let [time, amPm] = this.showTimeInUppercase(option).split(" ");
                                            return (React.createElement("option", { key: index, disabled: this.optionDisable(option), value: option, id: index },
                                                time,
                                                " ",
                                                t(amPm)));
                                        })),
                                        React.createElement("img", { src: this.state.isSelectOpenWeekEndsFrom
                                                ? ArrowUp
                                                : ArrowDown, className: editDropDownClassName, alt: "Custom Icon" }))),
                                React.createElement("div", { className: "add-member-hours-to hours-to" },
                                    React.createElement("div", { style: {
                                            position: "absolute",
                                            marginBottom: 50,
                                        } },
                                        React.createElement("span", { className: "add-member-profile-form-sub-header profile-form-sub-header " }, t("To"))),
                                    React.createElement("div", { className: "add-member-form-custom-select form-custom-select" },
                                        React.createElement("select", { id: "mySelectWeekendTo", disabled: this.state.isWeekendToDisable, value: this.state.weekendTo, onChange: (event) => {
                                                this.setState({
                                                    weekendTo: event.target.value,
                                                    weekendHoursError: "",
                                                });
                                            }, onClick: () => {
                                                this.handleSelectToggle("isSelectOpenWeekEndsTo");
                                            }, style: {
                                                borderRadius: 8,
                                                border: borderStyleweekendHours,
                                                direction: "inherit",
                                            } }, this.state.weekendSlotTo.map((option, index) => {
                                            let [time, amPm] = this.showTimeInUppercase(option).split(" ");
                                            return (React.createElement("option", { key: index, id: index, value: option, disabled: this.optionDisable(option) },
                                                time,
                                                " ",
                                                t(amPm)));
                                        })),
                                        React.createElement("img", { className: editDropDownClassName, src: this.state.isSelectOpenWeekEndsTo
                                                ? ArrowUp
                                                : ArrowDown, alt: "Custom Icon" }))))),
                            React.createElement("div", { className: "add-member-input-container-item-time" },
                                this.state.workingHoursError && (React.createElement("div", { style: {
                                        position: "relative",
                                        marginLeft: 60,
                                        marginBottom: 10,
                                    } },
                                    React.createElement("span", { className: "add-member-profile-error-message-text" }, t(this.state.workingHoursError)))),
                                this.state.weekendHoursError && (React.createElement("div", { style: {
                                        position: "relative",
                                        marginLeft: 60,
                                        marginBottom: 10,
                                    } },
                                    React.createElement("span", { className: "add-member-profile-error-message-text" }, t(this.state.weekendHoursError))))),
                            React.createElement("div", { className: "add-member-right-header-container-nth-child subHeader" },
                                React.createElement("span", { className: "add-member-card-form-label" }, t("Block time"))),
                            this.state.getSelectionMode === "weekdays" ? (React.createElement("div", { className: "input-container-item-time add-member-input-container-item-time" },
                                React.createElement("div", { className: "add-member-hours-form hours-form" },
                                    React.createElement("div", { style: {
                                            position: "absolute",
                                            marginBottom: 50,
                                        } },
                                        React.createElement("span", { className: "profile-form-sub-header add-member-profile-form-sub-header" }, t("From"))),
                                    React.createElement("div", { className: "add-member-form-custom-select form-custom-select " },
                                        React.createElement("select", { id: "SelectWeekHourss", onChange: (event) => {
                                                this.onBlockTimeFromSelection(event.target.value);
                                            }, onClick: () => {
                                                this.handleSelectToggle("isSelectOpenBlockWeekHoursFrom");
                                            }, style: {
                                                borderRadius: 8,
                                                direction: "inherit",
                                                border: borderStyleworkingHours,
                                            }, value: this.state.BlockTimeFrom }, this.state.workingSlot.map((option, index) => {
                                            let [time, amPm] = this.showTimeInUppercase(option).split(" ");
                                            return (React.createElement("option", { disabled: this.optionDisable(option), key: index, id: index, value: option },
                                                time,
                                                " ",
                                                t(amPm)));
                                        })),
                                        React.createElement("img", { src: this.state.isSelectOpenBlockWeekHoursFrom
                                                ? ArrowUp
                                                : ArrowDown, className: editDropDownClassName, alt: "Custom Icon" }))),
                                React.createElement("div", { className: "add-member-hours-to hours-to" },
                                    React.createElement("div", { style: {
                                            position: "absolute",
                                            marginBottom: 50,
                                        } },
                                        React.createElement("span", { className: "add-member-profile-form-sub-header profile-form-sub-header " }, t("To"))),
                                    React.createElement("div", { className: "add-member-form-custom-select form-custom-select" },
                                        React.createElement("select", { id: "mySelectBlockTo", value: this.state.BlockTimeTo, onChange: (event) => {
                                                this.setState({
                                                    BlockTimeTo: event.target.value,
                                                    workingHoursError: "",
                                                });
                                            }, style: {
                                                borderRadius: 8,
                                                border: borderStyleworkingHours,
                                                direction: "inherit",
                                            }, onClick: () => {
                                                this.handleSelectToggle("isSelectOpenBlockWeekHoursTo");
                                            } }, this.state.blockWorkingSlotTo.map((option, index) => {
                                            let [time, amPm] = this.showTimeInUppercase(option).split(" ");
                                            return (React.createElement("option", { id: index, key: index, disabled: this.optionDisable(option), value: option },
                                                time,
                                                " ",
                                                t(amPm)));
                                        })),
                                        React.createElement("img", { src: this.state.isSelectOpenBlockWeekHoursTo
                                                ? ArrowUp
                                                : ArrowDown, className: editDropDownClassName, alt: "Custom Icon" }))))) : (React.createElement("div", { className: "input-container-item-time add-member-input-container-item-time" },
                                React.createElement("div", { className: "add-member-hours-form hours-form" },
                                    React.createElement("div", { style: {
                                            position: "absolute",
                                            marginBottom: 50,
                                        } },
                                        React.createElement("span", { className: "profile-form-sub-header add-member-profile-form-sub-header " }, t("From"))),
                                    React.createElement("div", { className: "add-member-form-custom-select form-custom-select " },
                                        React.createElement("select", { id: "mySelectWeekendBlockFrom", value: this.state.weekendBlockFrom, onChange: (event) => {
                                                this.onBlockWeekEndTimeFromSelection(event.target.value);
                                            }, style: {
                                                direction: "inherit",
                                                border: borderStyleweekendHours,
                                                borderRadius: 8,
                                            }, onClick: () => {
                                                this.handleSelectToggle("isSelectOpenBlockWeekEndsFrom");
                                            } }, this.state.workingSlot.map((option, index) => {
                                            let [time, amPm] = this.showTimeInUppercase(option).split(" ");
                                            return (React.createElement("option", { key: index, id: index, value: option, disabled: this.optionDisable(option) },
                                                time,
                                                " ",
                                                t(amPm)));
                                        })),
                                        React.createElement("img", { src: this.state.isSelectOpenBlockWeekEndsFrom
                                                ? ArrowUp
                                                : ArrowDown, alt: "Custom Icon", className: editDropDownClassName }))),
                                React.createElement("div", { className: "add-member-hours-to hours-to" },
                                    React.createElement("div", { style: {
                                            position: "absolute",
                                            marginBottom: 50,
                                        } },
                                        React.createElement("span", { className: "add-member-profile-form-sub-header profile-form-sub-header " }, t("To"))),
                                    React.createElement("div", { className: "add-member-form-custom-select form-custom-select" },
                                        React.createElement("select", { id: "mySelectWeekendBlockTo", disabled: this.state.isWeekendToDisable, value: this.state.weekendBlockTo, onChange: (event) => {
                                                this.setState({
                                                    weekendBlockTo: event.target.value,
                                                    weekendHoursError: "",
                                                });
                                            }, style: {
                                                direction: "inherit",
                                                borderRadius: 8,
                                                border: borderStyleweekendHours,
                                            }, onClick: () => {
                                                this.handleSelectToggle("isSelectOpenBlockWeekEndsTo");
                                            } }, this.state.blockWeekendSlotTo.map((option, index) => {
                                            let [time, amPm] = this.showTimeInUppercase(option).split(" ");
                                            return (React.createElement("option", { key: index, disabled: this.optionDisable(option), value: option, id: index },
                                                time,
                                                " ",
                                                t(amPm)));
                                        })),
                                        React.createElement("img", { className: editDropDownClassName, src: this.state.isSelectOpenBlockWeekEndsTo
                                                ? ArrowUp
                                                : ArrowDown, alt: "Custom Icon" }))))),
                            React.createElement("div", { className: "add-member-input-container-item-time" },
                                this.state.workingHoursError && (React.createElement("div", { style: {
                                        position: "relative",
                                        marginLeft: 60,
                                        marginBottom: 10,
                                    } },
                                    React.createElement("span", { className: "add-member-profile-error-message-text" }, this.state.workingHoursError))),
                                this.state.weekendHoursError && (React.createElement("div", { style: {
                                        position: "relative",
                                        marginLeft: 60,
                                        marginBottom: 10,
                                    } },
                                    React.createElement("span", { className: "add-member-profile-error-message-text" }, this.state.weekendHoursError)))),
                            React.createElement("div", { className: "add-member-right-header-container-nth-child subHeader" },
                                React.createElement("span", { className: "add-member-card-form-label" }, t("Working Days"))),
                            React.createElement("div", { className: "add-member-weekDays-map-container" }, this.state.workingDaysData.map((item) => {
                                return (React.createElement(Button, { className: "add-member-employess-weekdays-button", id: "workingDays", key: item.id, onClick: () => {
                                        this.handleWorkingHoursSelection(item.id);
                                    }, style: {
                                        padding: 0,
                                        border: 0,
                                        marginRight: 10,
                                        borderRadius: "27px",
                                        width: "144px",
                                        height: "54px",
                                        fontSize: "18px",
                                        marginTop: 10,
                                        background: "#1E5FEA",
                                        backgroundColor: this.handleBackgroundColor(item),
                                        color: this.handleColor(item),
                                    }, color: "primary" },
                                    React.createElement("span", { className: "add-member-fontfamily-profile" }, t(item.day))));
                            }))),
                        React.createElement("div", { className: "add-member-right-header-container-nth-child" },
                            React.createElement("span", { className: "add-member-subheader-text" }, t("Services"))),
                        React.createElement("div", { className: "add-member-right-header-container-nth-child HeaderNote" },
                            React.createElement("span", null, t("Add the services this team member can offer."))),
                        React.createElement("div", { className: "add-member-right-header-container-services" },
                            React.createElement(OutlinedInput, { id: "services", type: "text", className: "add-member-allService-form-input-style", onClick: () => {
                                    this.getServicesListApi();
                                    this.setState({
                                        showServiceList: !this.state.showServiceList,
                                    });
                                }, value: this.state.Services.length == 0
                                    ? t("Choose Services")
                                    : `${t("All services")}(${this.state.Services.length})` })),
                        React.createElement("div", { className: "add-member-buttons-container" },
                            React.createElement(Button, { id: "deleteButton", className: "add-member-delete-button", onClick: () => this.setState({
                                    showDeleteModal: !this.state.showDeleteModal,
                                }) }, t("Delete")),
                            React.createElement(Button, { id: "saveButton", className: "add-member-save-button", onClick: () => this.handleUpdateTeamMember() },
                                React.createElement("img", { src: walkInPencil, style: { height: "15px", marginRight: "5px" } }),
                                t("Edit"))))),
                this.state.showServiceList && (React.createElement(React.Fragment, null,
                    React.createElement("div", { style: {
                            position: "fixed",
                            content: " ",
                            width: "100%",
                            height: "100vh",
                            background: "rgba(0,0,0, 0.4)",
                            zIndex: 0,
                            top: "0",
                            left: "0",
                        } }, "\u00A0"),
                    React.createElement(Modal, { open: this.state.showServiceList, className: webAppDirection === "rtl"
                            ? "arabic-add-member-service-modal"
                            : "add-member-service-modal", onClose: () => this.setState({
                            showServiceList: !this.state.showServiceList,
                        }) },
                        React.createElement("div", { className: webAppDirection === "rtl"
                                ? "arabic-add-member-service-modal-container"
                                : "add-member-service-modal-container" },
                            React.createElement("div", { className: "add-member-service-category" },
                                React.createElement("span", { className: "service-header" }, t("Services")),
                                React.createElement("span", { className: "service-category" }, (_c = (_b = (_a = this.state.serviceList[0]) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.business_provided) === null || _c === void 0 ? void 0 : _c.name)),
                            React.createElement("div", { className: "add-member-search-service-input" },
                                React.createElement(TextField, { InputProps: {
                                        startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                            React.createElement("img", { src: search_Bitmap, className: "search-icon", alt: "" }))),
                                    }, id: "outlined-basic", variant: "outlined", placeholder: t("Search for Services") })), (_d = this.state.serviceList) === null || _d === void 0 ? void 0 :
                            _d.map((service, index) => (React.createElement("div", { key: service.attributes.name },
                                React.createElement("div", { className: "add-member-service-header" },
                                    React.createElement("h4", null, service.attributes.display_name),
                                    React.createElement(Button, { id: "serviceButton" + service.id, style: { color: "#ffffff" }, onClick: () => this.selectCall(service) }, this.state.providedService.includes(service.id)
                                        ? t("Deselect All")
                                        : t("Select All"))),
                                service.attributes.business_sub_categories.map((serviceItem) => (React.createElement("div", { key: serviceItem.service_name, className: "add-member-manage-services-item-container" },
                                    React.createElement("div", { className: "add-member-user-details" },
                                        React.createElement("span", { className: "add-member-service-sub-category" },
                                            this.state.Services.includes(serviceItem.id),
                                            serviceItem.display_name),
                                        React.createElement("span", { className: "add-member-service-sub-category-details" },
                                            serviceItem.duration.slice(3, -3),
                                            " ",
                                            t("min"),
                                            " - OMR ",
                                            serviceItem.service_cost)),
                                    React.createElement("span", { className: "caret-right" },
                                        React.createElement(Checkbox, { icon: React.createElement(CircleUnchecked, null), checkedIcon: React.createElement(CircleCheckedFilled, null), id: "serviceCheckbox" + serviceItem.id, key: serviceItem.service_name, onClick: (e) => this.onInputChange(e), name: serviceItem.service_name, value: serviceItem.id, inputProps: { "aria-label": "A" } })))))))),
                            React.createElement("div", { className: "add-member-buttons-container" },
                                React.createElement(Button, { className: "add-member-cancel-button responsiveBtn", id: "serviceCancelButton", onClick: () => this.setState({
                                        showServiceList: !this.state.showServiceList,
                                    }) }, t("Cancel")),
                                React.createElement(Button, { className: "add-member-save-button responsiveBtn .rgtBtn", onClick: () => this.setState({
                                        showServiceList: !this.state.showServiceList,
                                    }) }, t("Save"))))))),
                this.state.showDeleteModal && (React.createElement(Modal, { open: this.state.showDeleteModal, className: "delete-member-modal", onClose: () => this.setState({ showDeleteModal: !this.state.showDeleteModal }) },
                    React.createElement("div", { className: "del-modal-container", style: {
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: 40,
                        } },
                        React.createElement("img", { src: warningIcon, alt: "logo", width: "120", height: "120" }),
                        React.createElement("p", null, t("Are you sure you want to delete this member?")),
                        React.createElement(Button, { className: "add-member-save-button delete-modal-btn", onClick: () => this.setState({
                                showDeleteModal: !this.state.showDeleteModal,
                            }) }, t("No")),
                        React.createElement(Button, { className: "add-member-cancel-button", onClick: () => {
                                this.handleDeleteTeamMember();
                                this.setState({
                                    showDeleteModal: !this.state.showDeleteModal,
                                });
                            } }, t("Yes"))))),
                this.state.openCalenderDialog && (React.createElement(Dialog, { open: this.state.openCalenderDialog, PaperProps: {
                        style: {
                            width: "403px",
                            height: "auto",
                            borderRadius: "24px",
                            boxShadow: "none",
                            background: "#FFFFFF",
                        },
                    } },
                    React.createElement(DialogContent, null,
                        React.createElement(Box, { mb: 0.5 },
                            React.createElement("div", { style: {
                                    marginLeft: "10px",
                                } },
                                React.createElement("div", { className: "add-member-calendar-container" },
                                    React.createElement("button", { className: "calendar-close", onClick: () => this.setState({ openCalenderDialog: false }) }, "\u00D7"),
                                    React.createElement(Calendar, { minDate: new Date(), value: this.state.EndDate, className: "date-picker", onChange: this.handleDateChange, calendarType: "US", formatMonthYear: (locale, date) => date.toLocaleString(locale, {
                                            month: "short",
                                            year: "numeric",
                                        }) }),
                                    React.createElement("div", { style: {
                                            display: "flex",
                                            gap: 10,
                                            marginTop: "35px",
                                            marginBottom: "20px",
                                        } },
                                        React.createElement("button", { style: {
                                                width: "130px",
                                                height: "52px",
                                                border: "1px solid #2C2C2E",
                                                borderRadius: "28px",
                                                marginLeft: "30px",
                                            }, id: "handleOngoing", disabled: this.state.DateModalType === "startDate", onClick: () => {
                                                this.handleOngoing();
                                            } }, t("Ongoing")),
                                        React.createElement("button", { style: {
                                                width: "130px",
                                                height: "52px",
                                                borderRadius: "28px",
                                                backgroundColor: "#1E5FEA",
                                                color: "#FFFFFF",
                                                border: "none",
                                            }, id: "handleDone", onClick: () => {
                                                this.setState({ openCalenderDialog: false });
                                            } }, t("Done"))))))))),
                this.state.openStartCalenderDialog && (React.createElement(Dialog, { open: this.state.openStartCalenderDialog, PaperProps: {
                        style: {
                            width: "403px",
                            height: "auto",
                            borderRadius: "24px",
                            boxShadow: "none",
                            background: "#FFFFFF",
                        },
                    } },
                    React.createElement(DialogContent, null,
                        React.createElement(Box, { mb: 0.5 },
                            React.createElement("div", { style: {
                                    marginLeft: "10px",
                                } },
                                React.createElement("div", { className: "add-member-calendar-container" },
                                    React.createElement("button", { className: "calendar-close", onClick: () => this.setState({ openStartCalenderDialog: false }) }, "\u00D7"),
                                    React.createElement(Calendar, { value: this.state.StartDate, onChange: this.handleDateChange, calendarType: "US", formatMonthYear: (locale, date) => date.toLocaleString(locale, {
                                            month: "short",
                                            year: "numeric",
                                        }) }),
                                    React.createElement("div", { style: {
                                            display: "flex",
                                            gap: 10,
                                            marginTop: "35px",
                                        } },
                                        React.createElement("button", { style: {
                                                width: "130px",
                                                height: "52px",
                                                border: "1px solid #2C2C2E",
                                                borderRadius: "28px",
                                                marginLeft: "30px",
                                            }, disabled: this.state.DateModalType === "startDate", onClick: () => {
                                                this.handleOngoing();
                                            } }, t("Ongoing")),
                                        React.createElement("button", { style: {
                                                width: "130px",
                                                height: "52px",
                                                borderRadius: "28px",
                                                backgroundColor: "#1E5FEA",
                                                color: "#FFFFFF",
                                                border: "none",
                                            }, onClick: () => {
                                                this.setState({ openStartCalenderDialog: false });
                                            } }, t("Done"))))))))),
                this.state.openDobCalenderDialog && (React.createElement(Dialog, { open: this.state.openDobCalenderDialog, PaperProps: {
                        style: {
                            width: "403px",
                            height: "auto",
                            borderRadius: "24px",
                            boxShadow: "none",
                            background: "#FFFFFF",
                        },
                    } },
                    React.createElement(DialogContent, null,
                        React.createElement(Box, { mb: 0.5 },
                            React.createElement("div", { style: {
                                    marginLeft: "10px",
                                } },
                                React.createElement("div", { className: "add-member-calendar-container" },
                                    React.createElement("button", { className: "calendar-close", onClick: () => this.setState({ openDobCalenderDialog: false }) }, "\u00D7"),
                                    React.createElement(Calendar, { maxDate: new Date(new Date().setFullYear(new Date().getFullYear() - 18)), value: this.state.Dob, onChange: this.handleDateOfBirthChange, calendarType: "US", formatMonthYear: (locale, date) => date.toLocaleString(locale, {
                                            month: "short",
                                            year: "numeric",
                                        }) }),
                                    React.createElement("div", { style: {
                                            display: "flex",
                                            gap: 10,
                                            marginTop: "35px",
                                        } },
                                        React.createElement("button", { style: {
                                                width: "130px",
                                                height: "52px",
                                                border: "1px solid #2C2C2E",
                                                borderRadius: "28px",
                                                marginLeft: "30px",
                                            }, id: "handleOngoing", disabled: this.state.DateModalType === "startDate", onClick: () => {
                                                this.handleOngoing();
                                            } }, t("Ongoing")),
                                        React.createElement("button", { style: {
                                                width: "130px",
                                                height: "52px",
                                                borderRadius: "28px",
                                                backgroundColor: "#1E5FEA",
                                                color: "#FFFFFF",
                                                border: "none",
                                            }, id: "handleDone", onClick: () => {
                                                this.setState({ openDobCalenderDialog: false });
                                            } }, t("Done"))))))))),
                this.state.openCountryCodeModal && (React.createElement(React.Fragment, null,
                    React.createElement(CountryCodeModal, { open: this.state.openCountryCodeModal, onSelect: this.handleCountryCodeChange }))))));
    }
}
//@ts-ignore
export default withTranslation()(EditTeamMember);
