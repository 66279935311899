// Customizable Area Start
//@ts-nocheck
import React from "react";
import { logo, whiteLogo, app_store, play_store, barber_service_icon, salon_service_icon, spa_service_icon, fitness_service_icon, landingPageImage1, landingPageImage2, landingPageImage3, landingPageImage4, landingPageImage5, navigationImage, cancelIcon, rightBlackAArrow, } from "./assets";
import { IconButton, InputAdornment, OutlinedInput, Button, } from "@material-ui/core";
import "../assets/Styles/landingPage.css";
import LandingPageWebController from "./LandingPageWebController";
import RequestDemo from "./RequestDemo.web";
import { LoginSignupModal } from "../../social-media-account-login/src/Login.web";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { withTranslation } from "react-i18next";
import Cookies from "js-cookie";
import CustomerSupportWeb from "./CustomerSupport.web";
class LandingPage extends LandingPageWebController {
    constructor(props) {
        super(props);
    }
    render() {
        //@ts-ignore
        let { t } = this.props;
        const { requestDemoModal, loginSignupModal, customerSupport } = this.state;
        let appDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        return (React.createElement(React.Fragment, null,
            this.state.isMobileWebView && (React.createElement("div", { className: "mobile-web-button-and-text-container" },
                React.createElement("div", { className: "request-demo-and-close-button-container" },
                    React.createElement(Button, { className: "mobile-web-request-demo-button", "data-testid": "mobile-request-demo", onClick: this.handleRequestModalChange }, t("Request Demo")),
                    React.createElement("img", { src: cancelIcon, alt: "navigation_menu_close", className: "navigation-menu-close-image", onClick: this.handleHeaderView })),
                React.createElement("span", { className: "landing-page-header-item-text login-text", "data-testid": "mobile-login-button", onClick: () => {
                        localStorage.clear();
                        this.handleLoginSignupModal("Login");
                    } }, t("Login")),
                React.createElement("span", { className: "landing-page-header-item-text signup-text", onClick: () => this.handleLoginSignupModal("Registration"), "data-testid": "mobile-registration-button" }, t("Signup")),
                React.createElement("span", { className: "landing-page-header-item-text", onClick: this.handleCustomerSupoortChange }, t("Customer Support")))),
            React.createElement("div", { className: "landing-page-main-container" },
                React.createElement("div", { style: { maxWidth: "100%", backgroundColor: "#ffffff" } },
                    React.createElement("div", { className: "landing-page-header" },
                        React.createElement("div", { className: "header-logo-container" },
                            React.createElement("img", { src: logo, alt: "logo", className: "landing-page-header-logo", width: "237px", height: "48px" })),
                        React.createElement("img", { src: navigationImage, "data-testid": "navigation_menu_open_close", alt: "navigation_menu", className: "navigation-menu-image", onClick: this.handleHeaderView }),
                        React.createElement("div", { className: "button-and-text-container" },
                            React.createElement(Button, { className: "request-demo-button", onClick: this.handleRequestModalChange }, t("Request Demo")),
                            React.createElement("span", { className: "landing-page-header-item-text login-text", onClick: () => {
                                    localStorage.clear();
                                    this.handleLoginSignupModal("Login");
                                } }, t("Login")),
                            React.createElement("span", { className: "landing-page-header-item-text signup-text", onClick: () => this.handleLoginSignupModal("Registration") }, t("Signup")),
                            React.createElement("span", { className: "landing-page-header-item-text", onClick: this.handleCustomerSupoortChange }, t("Customer Support"))))),
                React.createElement("div", { className: "landing-page-main-content-container" },
                    React.createElement("div", { className: "book-a-visit-main-container" },
                        React.createElement("div", { className: "text-and-input-container" },
                            React.createElement("span", { className: "book-a-visit-text" }, t("Book a visit")),
                            React.createElement("span", { className: "easy-and-fast-text" },
                                t("easy"),
                                " & ",
                                t("fast")),
                            React.createElement("span", { className: "find-the-best-service-text" }, t("Find the best Services in your local and book a dream visit.")),
                            React.createElement("span", { className: "find-your-service-input-container", id: "find-your-service-input-container", onClick: () => {
                                    localStorage.clear();
                                    this.handleLoginSignupModal("Login");
                                } },
                                React.createElement(OutlinedInput, { className: "find-your-service-input", placeholder: t("Start your journey"), readOnly: true, endAdornment: React.createElement(InputAdornment, { position: appDirection === "ltr" ? "end" : "start" },
                                        React.createElement(IconButton, { style: {
                                                height: 57,
                                                width: 55,
                                                alignItems: "center",
                                                backgroundColor: "#ffffff",
                                                borderRadius: "50%",
                                                cursor: "pointer",
                                            } },
                                            React.createElement("img", { src: rightBlackAArrow, alt: "right-arrow", height: 24, width: 24 }))) }))),
                        React.createElement("div", { className: "landing-page-images-container" },
                            React.createElement("div", { className: "first-landing-page-image-container" },
                                React.createElement("img", { src: landingPageImage1, alt: "landingPageImage1" }),
                                React.createElement("div", { className: "text-image-style salon-button" }, t("Salon"))),
                            React.createElement("div", { className: "second-landing-page-image-container" },
                                React.createElement("img", { src: landingPageImage2, alt: "landingPageImage2" }),
                                React.createElement("div", { className: "text-image-style spa-button" }, t("Spa"))),
                            React.createElement("div", { className: "third-landing-page-image-container" },
                                React.createElement("img", { src: landingPageImage3, alt: "landingPageImage3" }),
                                React.createElement("div", { className: "text-image-style fitness-button" }, t("Fitness"))),
                            React.createElement("div", { className: "fourth-landing-page-image-container" },
                                React.createElement("img", { src: landingPageImage4, alt: "landingPageImage4" }),
                                React.createElement("div", { className: "text-image-style barber-button" }, t("Barbershop")))),
                        React.createElement("div", { className: "landing-page-images-slideshow-container" },
                            React.createElement(Carousel, { showArrows: false, showThumbs: false, showStatus: false, axis: "horizontal", autoPlay: true, infiniteLoop: true, interval: 5000 },
                                React.createElement("div", { className: "first-landing-page-image-container" },
                                    React.createElement("img", { src: landingPageImage1, alt: "landingPageImageMobile1" }),
                                    React.createElement("div", { className: "salon-button text-image-style" },
                                        t("Salon"),
                                        " ")),
                                React.createElement("div", { className: "second-landing-page-image-container" },
                                    React.createElement("img", { src: landingPageImage2, alt: "landingPageImageMobile2" }),
                                    React.createElement("div", { className: "spa-button text-image-style" }, t("Spa"))),
                                React.createElement("div", { className: "third-landing-page-image-container" },
                                    React.createElement("img", { src: landingPageImage3, alt: "landingPageImageMobile3" }),
                                    React.createElement("div", { className: "fitness-button text-image-style" }, t("Fitness"))),
                                React.createElement("div", { className: "fourth-landing-page-image-container" },
                                    React.createElement("img", { src: landingPageImage4, alt: "landingPageImageMobile4" }),
                                    React.createElement("div", { className: "barber-button text-image-style" }, t("Barbershop")))))),
                    React.createElement("div", { className: "available-service-main-container" },
                        React.createElement("span", { className: "available-service-text" }, t("Available Services")),
                        React.createElement("span", { className: "service-available-at-text" }, t("The services availabe at SqueezMeIn")),
                        React.createElement("div", { className: "services-listing-container", style: {
                                flexDirection: appDirection === "rtl" ? "row-reverse" : "row",
                            } },
                            React.createElement("div", { className: "service-container" },
                                React.createElement("img", { src: barber_service_icon, alt: "barber_icon" }),
                                React.createElement("span", { className: "service-name-text" }, t("Barbershop"))),
                            React.createElement("div", { className: "service-container" },
                                React.createElement("img", { src: salon_service_icon, alt: "barber_icon" }),
                                React.createElement("span", { className: "service-name-text" }, t("Salon"))),
                            React.createElement("div", { className: "service-container" },
                                React.createElement("img", { src: fitness_service_icon, alt: "barber_icon" }),
                                React.createElement("span", { className: "service-name-text" }, t("Fitness"))),
                            React.createElement("div", { className: "service-container" },
                                React.createElement("img", { src: spa_service_icon, alt: "barber_icon" }),
                                React.createElement("span", { className: "service-name-text" }, t("Spa")))),
                        React.createElement("div", { className: "web-mobile-services-listing-container" },
                            React.createElement(Carousel, { showArrows: false, showThumbs: false, showStatus: false, axis: "horizontal", autoPlay: true, infiniteLoop: true, interval: 5000, className: "service-name-carousel" },
                                React.createElement("div", { className: "service-container" },
                                    React.createElement("img", { src: salon_service_icon, alt: "barber_icon_mobile", className: "service_icon_mobile_image" }),
                                    React.createElement("span", { className: "service-name-text" }, t("Salon"))),
                                React.createElement("div", { className: "service-container" },
                                    React.createElement("img", { src: spa_service_icon, alt: "barber_icon_mobile", className: "service_icon_mobile_image" }),
                                    React.createElement("span", { className: "service-name-text" }, t("Spa"))),
                                React.createElement("div", { className: "service-container" },
                                    React.createElement("img", { src: fitness_service_icon, alt: "barber_icon_mobile", className: "service_icon_mobile_image" }),
                                    React.createElement("span", { className: "service-name-text" }, t("Fitness"))),
                                React.createElement("div", { className: "service-container" },
                                    React.createElement("img", { src: barber_service_icon, alt: "barber_icon_mobile", className: "service_icon_mobile_image" }),
                                    React.createElement("span", { className: "service-name-text" }, t("Barbershop")))))),
                    React.createElement("div", { className: "download-app-main-container", style: {
                            flexDirection: appDirection === "rtl" ? "row-reverse" : "row",
                        } },
                        React.createElement("div", { className: "download-app-content-container" },
                            React.createElement("span", { className: "download-our-app-text" }, t("Download our app")),
                            React.createElement("span", { className: "download-pargraph-text" }, t("Book unforgettable beauty and wellness experiences with the SqueezMeln mobile app – the best way to discover top-rated services")),
                            React.createElement("span", { className: "play-and-app-store" },
                                React.createElement("img", { src: play_store, alt: "play store" }),
                                React.createElement("img", { src: app_store, alt: "app store" }))),
                        React.createElement("img", { src: landingPageImage5, alt: "landingPageImage5", className: "landing-page-bottom-page" }))),
                React.createElement("div", { className: "landing-page-footer-main-container" },
                    React.createElement("div", { className: "landing-page-footer" },
                        React.createElement("div", { className: "footer-logo-container" },
                            React.createElement("img", { src: whiteLogo, alt: "logo", className: "landing-page-footer-logo-image" })),
                        React.createElement("div", { className: "footer-items-main-container" },
                            React.createElement("div", { className: "footer-item-container" },
                                React.createElement("span", { className: "footer-heading" }, t("About SqueezMeIn")),
                                React.createElement("span", { className: "footer-items-text", "data-testid": "footer-customer-support", onClick: this.handleCustomerSupoortChange }, t("Customer Support"))),
                            React.createElement("div", { className: "footer-item-container" },
                                React.createElement("span", { className: "footer-heading" }, t("Business")),
                                React.createElement("span", { className: "footer-items-text", "data-testid": "footer-request-demo", onClick: this.handleRequestModalChange }, t("Request Demo"))),
                            React.createElement("div", { className: "footer-item-container" },
                                React.createElement("span", { className: "footer-heading" }, t("Legal")),
                                React.createElement("span", { className: "footer-items-text", "data-testid": "footer-privacy-policy", onClick: () => {
                                        this.props.navigation.navigate("PrivacyAndPolicy");
                                    } }, t("Privacy Policy")),
                                React.createElement("span", { className: "footer-items-text", "data-testid": "footer-terms-conditions", onClick: () => {
                                        this.props.navigation.navigate("TermsAndConditions");
                                    } }, t("Terms & Conditions"))))),
                    React.createElement("div", { className: "footer-horizontal-line" })),
                React.createElement(RequestDemo, { open: requestDemoModal, onClose: this.handleRequestModalChange }),
                customerSupport && (React.createElement(CustomerSupportWeb, { open: customerSupport, onClose: this.handleCustomerSupoortChange })),
                loginSignupModal && (React.createElement(LoginSignupModal, { open: loginSignupModal, onClose: this.handleLoginSignupModal, onSelect: this.handleRedirectPage, t: t })))));
    }
}
export default withTranslation()(LandingPage);
// Customizable Area End
