//@ts-nocheck
import React from "react";
import SubscriptionBusinessController from "./SubscriptionsBusinessController.web";
import { green_check, red_check } from "../../Squeez_me_in/src/assets";
import { withRouter } from "react-router-dom";
import { CircularProgress, Dialog } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import AfterLoginHeader from "../../dashboard/src/AfterLoginHeader.web";
export class SubscriptionPaymentSuccessfull extends SubscriptionBusinessController {
    render() {
        let { t } = this.props;
        return (React.createElement("div", { className: "booking-main-div" },
            React.createElement(AfterLoginHeader, { navigation: this.props.navigation }),
            React.createElement(Dialog, { open: true },
                React.createElement("div", { style: { padding: "20px 70px", textAlign: "center" } }, this.state.paymentStatusLoader ? (React.createElement(CircularProgress, { size: 80 })) : (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "img-div" },
                        React.createElement("img", { src: this.state.paymentFailed ? red_check : green_check, alt: "" })),
                    React.createElement("h3", { style: { marginTop: 20 } }, this.state.paymentFailed
                        ? t("Payment unsuccessful!")
                        : t("Payment successful!")),
                    React.createElement("p", { style: { paddingBottom: 50, marginTop: 15 } }, this.state.paymentFailed
                        ? t("Please try again.")
                        : t("An invoice has been sent to your email.")),
                    React.createElement("button", { className: "subcrption-modal-popup", onClick: this.handleNavigate }, t("Go Back"))))))));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(SubscriptionPaymentSuccessfull));
