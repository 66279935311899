// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { leftAArrow } from "../../dashboard/src/assets";
import "../assets/Styles/manage-catalouge.css";
import { OutlinedInput, Button, TextField } from "@material-ui/core";
import { withTranslation } from "react-i18next";
class AddEditService extends BlockComponent {
    render() {
        const { isAddEditService, editableCategoryName, editableServiceData, categoryNameError, serviceCostError, serviceNameError, serviceDescriptionError, serviceDurationError, editableCategoryId, editableArabicCategoryName, arabicCategoryNameError, arabicServiceNameError, } = this.props;
        const { handleAddService, handleServiceChange, handleCategoryChange, createServiceFunction, handleDeletePopupChange, createCategoryFunction, handleArabicCategoryChange, } = this.props;
        //@ts-ignore
        let { t } = this.props;
        let durations = [
            {
                key: `15 ${t("Mins")}`,
                value: "00:15:00",
            },
            {
                key: `30 ${t("Mins")}`,
                value: "00:30:00",
            },
            {
                key: `45 ${t("Mins")}`,
                value: "00:45:00",
            },
            {
                key: `60 ${t("Mins")}`,
                value: "01:00:00",
            },
        ];
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "manage-catalouge-add-edit-category-heading-container" },
                React.createElement("img", { src: leftAArrow, alt: "backarrow", "data-testid": "back-arrow-catalouge", onClick: () => handleAddService("none", "", "", null, null, {
                        service_name: "",
                        service_cost: "",
                        arabic_name: "",
                        duration: "",
                        description: "",
                    }) }),
                React.createElement("span", { className: "manage-catalouge-header-text" }, isAddEditService === "edit" ? t("Edit Service") : t("Add Service"))),
            React.createElement("div", { className: "add-edit-service-main-container" },
                React.createElement("div", { className: "add-edit-service-input-container" },
                    React.createElement("span", null, t("Category Name *")),
                    React.createElement(OutlinedInput, { type: "text", "data-testid": "Category Name", value: editableCategoryName, placeholder: t("Category Name"), className: "add-edit-category-input-style", error: categoryNameError, inputProps: { maxLength: 50, min: 0 }, onChange: (e) => {
                            handleCategoryChange(e.target.value);
                        } }),
                    categoryNameError && (React.createElement("span", { className: "error-message-text" }, t("Please enter category name.")))),
                React.createElement("div", { className: "add-edit-service-input-container" },
                    React.createElement("span", null, t("Category Name Arabic *")),
                    React.createElement(OutlinedInput, { type: "text", value: editableArabicCategoryName, "data-testid": "Category Name Arabic", inputProps: { maxLength: 50, min: 0 }, placeholder: t("Category Name Arabic"), error: arabicCategoryNameError, className: "add-edit-category-input-style", onChange: (e) => {
                            handleArabicCategoryChange(e.target.value);
                        } }),
                    arabicCategoryNameError && (React.createElement("span", { className: "error-message-text" }, t("Please enter category name in arabic.")))),
                React.createElement("div", { className: "add-edit-service-input-container" },
                    React.createElement("span", null, t("Service Name *")),
                    React.createElement(OutlinedInput, { type: "text", name: "service_name", value: editableServiceData.service_name, placeholder: t("Service Name"), className: "add-edit-category-input-style", "data-testid": "Service Name", error: serviceNameError, onChange: (e) => {
                            handleServiceChange(e.target.name, e.target.value);
                        }, inputProps: { maxLength: 50, min: 0 } }),
                    serviceNameError && (React.createElement("span", { className: "error-message-text" }, t("Please enter service name.")))),
                React.createElement("div", { className: "add-edit-service-input-container" },
                    React.createElement("span", null, t("Service Name Arabic *")),
                    React.createElement(OutlinedInput, { type: "text", value: editableServiceData.arabic_name, name: "arabic_name", "data-testid": "Service Name Arabic", className: "add-edit-category-input-style", error: arabicServiceNameError, placeholder: t("Service Name Arabic"), onChange: (e) => {
                            handleServiceChange(e.target.name, e.target.value);
                        }, inputProps: { maxLength: 50, min: 0 } }),
                    arabicServiceNameError && (React.createElement("span", { className: "error-message-text" }, t("Please enter service name in arabic.")))),
                React.createElement("div", { className: "add-edit-service-input-container" },
                    React.createElement("span", null, t("Description *")),
                    React.createElement(OutlinedInput, { type: "text", name: "description", "data-testid": "Service Description", multiline: true, rows: 4, value: editableServiceData.description, placeholder: t("Description"), className: "add-edit-category-input-style", error: serviceDescriptionError, onChange: (e) => {
                            handleServiceChange(e.target.name, e.target.value);
                        }, inputProps: { maxLength: 100, min: 0 } }),
                    serviceDescriptionError && (React.createElement("span", { className: "error-message-text" }, t("Please enter service description.")))),
                React.createElement("div", { className: "add-edit-service-price-duration-input-container" },
                    React.createElement("div", { className: "add-edit-service-price-input-container" },
                        React.createElement("span", null, t("Service Cost *")),
                        React.createElement(OutlinedInput, { type: "number", name: "service_cost", "data-testid": "Service Cost", value: editableServiceData.service_cost, placeholder: "OMR", className: "add-edit-category-input-style", error: serviceCostError, onChange: (e) => {
                                handleServiceChange(e.target.name, e.target.value);
                            } }),
                        serviceCostError && (React.createElement("span", { className: "error-message-text" }, t("Please enter service cost.")))),
                    React.createElement("div", { className: "add-edit-service-duration-input-container" },
                        React.createElement("span", null, t("Service Duration *")),
                        React.createElement(TextField, { select: true, name: "duration", "data-testid": "Service Duration", className: "add-edit-category-input-style", error: serviceDurationError, value: editableServiceData.duration, SelectProps: {
                                native: true,
                            }, style: {
                                width: "100%",
                                borderRadius: "8px",
                                height: "54px",
                                direction: "initial",
                            }, variant: "outlined", onChange: (e) => {
                                handleServiceChange(e.target.name, e.target.value);
                            } }, durations.map((option) => (React.createElement("option", { key: option.value, disabled: option.key === "", value: option.value }, option.key)))),
                        serviceDurationError && (React.createElement("span", { className: "error-message-text" }, t("Please select service duration."))))),
                React.createElement(Button, { "data-testid": "save-button", className: "add-service-save-button-style", onClick: editableCategoryId === null
                        ? createCategoryFunction
                        : createServiceFunction }, t("Save")),
                isAddEditService === "edit" && (React.createElement(Button, { "data-testid": "delete-button", className: "add-category-delete-button-style", onClick: handleDeletePopupChange }, t("Delete"))),
                isAddEditService === "add" && (React.createElement(Button, { "data-testid": "cancel-button", className: "add-category-cancel-button-style", onClick: () => handleAddService("none", "", "", null, null, {
                        service_name: "",
                        service_cost: "",
                        arabic_name: "",
                        duration: "",
                        description: "",
                    }) }, t("Cancel"))))));
    }
}
//@ts-ignore
export default withTranslation()(AddEditService);
// Customizable Area End
