// Customizable Area Start
import React from "react";
import "../assets/Styles/manage-catalouge.css";
import { withRouter } from "react-router-dom";
import ManageBusiness from "./ManageBusiness.web";
import ManageCatalougeWebController from "./ManageCatalougeWebController";
import { editWhiteIcon, editBlackIcon, addBlackIcon, pendingCatalouge, unsbscribedModalImage, } from "./assets";
import AddEditCatergory from "./AddEditCategory.web";
import DeleteModal from "./DeleteModal.web";
import AddEditService from "./AddEditService.web";
import { Button, Dialog } from "@material-ui/core";
import { errorSymbol } from "../../social-media-account-registration/src/assets";
import { withTranslation } from "react-i18next";
//@ts-ignore
import Cookies from "js-cookie";
class ManageCatalouge extends ManageCatalougeWebController {
    render() {
        const { serviceDetails, allCatergories, isAddEditCatergory, isAddEditService, editableCategoryName, categoryNameError, deleteModal, editableServiceData, editableCategoryId, serviceCostError, serviceNameError, serviceDescriptionError, serviceDurationError, editableArabicCategoryName, arabicCategoryNameError, arabicServiceNameError, } = this.state;
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        //@ts-ignore
        let { t } = this.props;
        return (React.createElement("div", { className: "manage-catalouge-main-container" },
            React.createElement(ManageBusiness, null),
            React.createElement("div", { className: webAppDirection === "ltr"
                    ? "manage-catalouge-content-container"
                    : "arabic-manage-catalouge-content-container" },
                isAddEditCatergory === "none" && isAddEditService === "none" && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "manage-catalouge-heading-container" },
                        React.createElement("span", { className: "manage-catalouge-header-text" }, t("Catalogue")),
                        serviceDetails.service_provided ? (React.createElement("span", { className: "manage-catalouge-service-type-container" }, t(serviceDetails.service_provided))) : (React.createElement("span", null))),
                    !serviceDetails.is_subscribed &&
                        serviceDetails.panding_draft &&
                        allCatergories.length > 0 && (React.createElement("div", { className: "unsubscribed-user-message-container" },
                        React.createElement("img", { src: pendingCatalouge, alt: "error", height: 14, width: 12, className: "error-image" }),
                        React.createElement("span", { className: "unsubscribed-user-message-text" }, t("Manage catalogue request sent for admin's approval.")))),
                    !serviceDetails.is_subscribed &&
                        !serviceDetails.panding_draft &&
                        allCatergories.length > 0 && (React.createElement("div", { className: "unsubscribed-user-message-container" },
                        React.createElement("img", { src: errorSymbol, alt: "error", height: 14, width: 12, className: "error-image" }),
                        React.createElement("span", { className: "unsubscribed-user-message-text" }, t("Any changes to the Catalogue can be done only admin's approval.")))),
                    React.createElement("div", { className: "catalouge-main-content-container" },
                        allCatergories.map((category) => {
                            //istanbul ignore next
                            return (React.createElement("div", { className: "catalouge-main-container", key: category.attributes.name },
                                React.createElement("div", { className: "catalouge-category-header-container" },
                                    React.createElement("span", null,
                                        category.attributes.display_name,
                                        " ",
                                        category.attributes.have_draft_changes && (React.createElement("img", { src: pendingCatalouge, alt: "pending" }))),
                                    React.createElement("img", { src: editWhiteIcon, alt: "editWhiteIcon", onClick: () => this.handleAddEditCategory("edit", category.attributes.name, category.attributes.arabic_name, category.attributes.id) })),
                                category.attributes.business_sub_categories.map((subCategory) => {
                                    //istanbul ignore next
                                    return (React.createElement("div", { className: "catalouge-service-container", key: subCategory.service_name },
                                        React.createElement("div", { className: "catalouge-service-name-price-container" },
                                            React.createElement("span", null,
                                                subCategory.display_name,
                                                " ",
                                                subCategory.have_draft_changes && (React.createElement("img", { src: pendingCatalouge, alt: "pending" }))),
                                            React.createElement("span", null,
                                                this.timeDurationFormat(subCategory.duration),
                                                " ",
                                                t("min"),
                                                " - ",
                                                subCategory.currency,
                                                " ",
                                                subCategory.service_cost)),
                                        React.createElement("img", { src: editBlackIcon, alt: "editBlackIcon", onClick: () => this.handleAddService("edit", category.attributes.name, category.attributes.arabic_name, category.attributes.id, subCategory.id, subCategory) })));
                                }),
                                React.createElement("div", { className: "add-another-service-container" },
                                    React.createElement("span", null, t("Add another service")),
                                    React.createElement("img", { src: addBlackIcon, alt: "addBlackIcon", onClick: () => this.handleAddService("add", category.attributes.name, category.attributes.arabic_name, category.attributes.id, null, {
                                            service_name: "",
                                            arabic_name: "",
                                            service_cost: "",
                                            duration: "",
                                            description: "",
                                        }) }))));
                        }),
                        allCatergories.length === 0 && (React.createElement("span", { className: "add-new-categories-message-text" }, t("Please start adding services to your catalogue."))),
                        React.createElement(Button, { className: "add-another-category-button", onClick: () => this.handleAddEditCategory("add", "", "", null) },
                            React.createElement("img", { src: addBlackIcon, alt: "addBlackIcon" }),
                            t("Add another category"))))),
                (isAddEditCatergory === "edit" || isAddEditCatergory === "add") && (React.createElement(AddEditCatergory, { isAddEditCatergory: isAddEditCatergory, editableCategoryName: editableCategoryName, arabicCategoryNameError: arabicCategoryNameError, editableArabicCategoryName: editableArabicCategoryName, categoryNameError: categoryNameError, handleAddService: this.handleAddService, editableCategoryId: editableCategoryId, handleAddEditCategory: this.handleAddEditCategory, handleCategoryChange: this.handleCategoryChange, handleArabicCategoryChange: this.handleArabicCategoryChange, createCategoryFunction: this.createCatalogeCategoryApi, updateCategoryFunction: this.updateCatalogeCategoryApi, handleDeletePopupChange: this.handleDeletePopupChange })),
                (isAddEditService === "edit" || isAddEditService === "add") && (React.createElement(AddEditService, { editableCategoryId: editableCategoryId, isAddEditService: isAddEditService, editableServiceData: editableServiceData, editableCategoryName: editableCategoryName, arabicCategoryNameError: arabicCategoryNameError, editableArabicCategoryName: editableArabicCategoryName, categoryNameError: categoryNameError, serviceCostError: serviceCostError, serviceDescriptionError: serviceDescriptionError, serviceDurationError: serviceDurationError, serviceNameError: serviceNameError, arabicServiceNameError: arabicServiceNameError, handleAddService: this.handleAddService, handleCategoryChange: this.handleCategoryChange, handleArabicCategoryChange: this.handleArabicCategoryChange, handleServiceChange: this.handleServiceChange, createCategoryFunction: this.createCatalogeCategoryApi, createServiceFunction: this.createCatalogeServiceApi, updateServiceFunction: this.createCatalogeServiceApi, handleDeletePopupChange: this.handleDeletePopupChange })),
                isAddEditCatergory === "edit" && deleteModal && (React.createElement(DeleteModal, { type: t("category"), deleteCategoryFunction: this.deleteCatalogeCategoryApi, handleDeletePopupChange: this.handleDeletePopupChange })),
                isAddEditService === "edit" && deleteModal && (React.createElement(DeleteModal, { type: t("service"), deleteCategoryFunction: this.deleteCatalogeServiceApi, handleDeletePopupChange: this.handleDeletePopupChange })),
                !serviceDetails.is_subscribed && (React.createElement(Dialog, { open: this.state.unsubscribedUserModal },
                    React.createElement("div", { className: "unsubscribed-user-confirmation-modal" },
                        React.createElement("img", { alt: "exit-icon", className: "modal-icon", src: unsbscribedModalImage }),
                        React.createElement("div", { className: "logout_confirmation_text" }, t("As an unsubscribed user, any changes to the catalogue can be done only post admin's approval. Do you wish to continue?")),
                        React.createElement(Button, { className: "delete-modal-no-button-style", variant: "contained", color: "primary", style: { width: "100%", marginTop: 1 }, onClick: () => {
                                this.setState(Object.assign(Object.assign({}, this.state), { unsubscribedUserModal: false }));
                            } }, t("Yes")),
                        React.createElement(Button, { className: "unsubscribed-modal-no-button-style", variant: "outlined", color: "secondary", style: { width: "100%", marginTop: 1 }, onClick: () => this.props.history.push("/ManageBusiness") }, t("No"))))))));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(ManageCatalouge));
// Customizable Area End
