import React from "react";
import BookingConfirmController from "./BookingConfirmController.web";
import { green_check } from "./assets";
import "../assets/style/BookingConfirm.web.scss";
import { withRouter } from "react-router-dom";
import HeaderWeb from "./Header.web";
import { Button } from "@material-ui/core";
import { withTranslation } from "react-i18next";
class BookingConfirmed extends BookingConfirmController {
    render() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        let { t } = this.props;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "booking-main-div" },
                React.createElement(HeaderWeb, null),
                React.createElement("div", { className: "booking-confirm-detail-div" },
                    React.createElement("div", { className: "booking-confirm-list-div" },
                        React.createElement("div", { className: "img-div" },
                            React.createElement("img", { src: green_check, alt: "" })),
                        React.createElement("h3", null, t("Booking Confirmed!!")),
                        this.state.payAtshop ? (React.createElement("p", null, t("Please pay") +
                            " " + ((_c = (_b = (_a = this.state.confirmPay) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.service) === null || _c === void 0 ? void 0 : _c.currency) +
                            " " + ((_e = (_d = this.state.confirmPay) === null || _d === void 0 ? void 0 : _d.attributes) === null || _e === void 0 ? void 0 : _e.total_price) +
                            " " +
                            t("at the Shop"))) : (React.createElement("p", { style: { paddingLeft: "14px", paddingRight: "14px" } }, t("Thank you for Booking with us, we look forward to having you promptly at your booked appointment. An Email has been sent with your Payment Information."))),
                        React.createElement("div", { className: "div-booking-numbr" },
                            React.createElement("h4", { style: { paddingTop: "14px" } }, t("Booking ID:") +
                                " " + ((_g = (_f = this.state.confirmPay) === null || _f === void 0 ? void 0 : _f.attributes) === null || _g === void 0 ? void 0 : _g.booking_id)),
                            React.createElement("h4", { style: { marginTop: "9px" } }, t("Booking code:") +
                                " " + ((_k = (_j = (_h = this.state.confirmPay) === null || _h === void 0 ? void 0 : _h.attributes) === null || _j === void 0 ? void 0 : _j.booking_code) === null || _k === void 0 ? void 0 : _k.pin))),
                        React.createElement("div", null,
                            React.createElement(Button, { className: "book-now-btn-booking-confirmed", onClick: () => {
                                    this.props.history.push({
                                        pathname: "/MyBookings",
                                    });
                                } }, t("Go to bookings"))))))));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(BookingConfirmed));
