var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Customizable Area Start
import React from "react";
import moment from "moment";
import { Divider, OutlinedInput, IconButton, InputAdornment, Button, Modal, } from "@material-ui/core";
import { appointmentOval, searchCloseIcon, whiteSearchIcon } from "./assets";
import "../assets/Styles/manage-bookings-business.web.css";
import { BlockComponent } from "framework/src/BlockComponent";
import { withTranslation } from "react-i18next";
import { close_modal_icon, defultPng, filterBookings, } from "../../Squeez_me_in/src/assets";
import { checkRightIcon, uncheckCircle, } from "../../categoriessubcategories/src/assets";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
const Cookies = require("js-cookie");
class ManageBookingsListing extends BlockComponent {
    constructor(props) {
        super(props);
        //istanbul ignore next
        this.handleChange = (value) => {
            //istanbul ignore next
            if (value === "") {
                this.setState(Object.assign(Object.assign({}, this.state), { listingData: this.props.data }));
            }
            //istanbul ignore next
            const newData = this.props.data.filter((appointment) => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10;
                let cusName = ((_d = (_c = (_b = (_a = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _a === void 0 ? void 0 : _a.customer) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.attributes) === null || _d === void 0 ? void 0 : _d.full_name) ? `${(_h = (_g = (_f = (_e = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _e === void 0 ? void 0 : _e.customer) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.attributes) === null || _h === void 0 ? void 0 : _h.full_name}`
                    : `${(_m = (_l = (_k = (_j = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _j === void 0 ? void 0 : _j.customer) === null || _k === void 0 ? void 0 : _k.data) === null || _l === void 0 ? void 0 : _l.attributes) === null || _m === void 0 ? void 0 : _m.first_name} ${(_r = (_q = (_p = (_o = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _o === void 0 ? void 0 : _o.customer) === null || _p === void 0 ? void 0 : _p.data) === null || _q === void 0 ? void 0 : _q.attributes) === null || _r === void 0 ? void 0 : _r.last_name}`;
                let empName = `${(_v = (_u = (_t = (_s = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _s === void 0 ? void 0 : _s.employee_details) === null || _t === void 0 ? void 0 : _t.data) === null || _u === void 0 ? void 0 : _u.attributes) === null || _v === void 0 ? void 0 : _v.first_name} ${(_z = (_y = (_x = (_w = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _w === void 0 ? void 0 : _w.employee_details) === null || _x === void 0 ? void 0 : _x.data) === null || _y === void 0 ? void 0 : _y.attributes) === null || _z === void 0 ? void 0 : _z.last_name}`.replace(/\s{2,}/g, ' ');
                let { t } = this.props;
                return (((_1 = (_0 = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _0 === void 0 ? void 0 : _0.booking_id) === null || _1 === void 0 ? void 0 : _1.toLowerCase().includes(value.toLowerCase())) || (cusName === null || cusName === void 0 ? void 0 : cusName.toLowerCase().includes(value.toLowerCase())) || ((_4 = t(`${((_2 = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _2 === void 0 ? void 0 : _2.paid_by.replace(/[. _-]/g, " ").charAt(0).toUpperCase()) + ((_3 = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _3 === void 0 ? void 0 : _3.paid_by.replace(/[. _-]/g, " ").slice(1))}`)) === null || _4 === void 0 ? void 0 : _4.toLowerCase().includes(value.toLowerCase())) || ((_7 = (_6 = (_5 = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _5 === void 0 ? void 0 : _5.service) === null || _6 === void 0 ? void 0 : _6.display_name) === null || _7 === void 0 ? void 0 : _7.toLowerCase().includes(value.toLowerCase())) || (empName === null || empName === void 0 ? void 0 : empName.toLowerCase().includes(value.toLowerCase())) || ((_10 = t(((_8 = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _8 === void 0 ? void 0 : _8.appointment_type.replace(/[. _]/g, "-").charAt(0).toUpperCase()) + ((_9 = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _9 === void 0 ? void 0 : _9.appointment_type.replace(/[. _]/g, "-").slice(1)))) === null || _10 === void 0 ? void 0 : _10.toLowerCase().includes(value.toLowerCase())));
            });
            //istanbul ignore next
            this.setState(Object.assign(Object.assign({}, this.state), { searchTerm: value, listingData: newData }));
        };
        //istanbul ignore next
        this.handleSearchClose = () => {
            this.setState(Object.assign(Object.assign({}, this.state), { searchTerm: "" }));
            this.props.handleSearchOpenClose();
        };
        //istanbul ignore next
        this.renderStatus = (status) => {
            if (status === "pending") {
                return `Upcoming`;
            }
            else {
                return (status.replace("_", "-").charAt(0).toUpperCase() +
                    status.replace("_", "-").slice(1));
            }
        };
        this.state = {
            listingData: [],
            searchTerm: "",
        };
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            //istanbul ignore next
            if (this.props.data)
                this.setState(Object.assign(Object.assign({}, this.state), { listingData: this.props.data }));
        });
    }
    componentDidUpdate(prevProps, prevState) {
        return __awaiter(this, void 0, void 0, function* () {
            //istanbul ignore next
            if (this.props.data != prevProps.data) {
                this.setState(Object.assign(Object.assign({}, this.state), { listingData: this.props.data }), () => {
                    this.handleChange(this.state.searchTerm);
                });
            }
        });
    }
    render() {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        //@ts-ignore
        let { t } = this.props;
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "business-manage-booking-filter-button-main-container" },
                React.createElement("img", { src: filterBookings, alt: "filterIcon", onClick: this.props.handleFilterModalChange, className: "manage-booking-business-filter-icon" })),
            React.createElement("div", { className: "business-manage-booking-header-main-container" },
                React.createElement("div", { className: "business-manage-booking-header-heading-and-search-container" },
                    React.createElement("span", { className: "business-manage-booking-header-bookings-text" }, t("Bookings")),
                    !this.props.state.isSearchOpen ? (React.createElement("img", { src: whiteSearchIcon, alt: "serach", onClick: this.props.handleSearchOpenClose, className: "manage-booking-business-search-icon" })) : (React.createElement(OutlinedInput, { type: "text", className: "manage-booking-business-search-bar", value: this.state.searchTerm, 
                        //istanbul ignore next
                        onChange: (e) => this.handleChange(e.target.value), endAdornment: React.createElement(InputAdornment, { className: "showpassword-icon", position: "end" },
                            React.createElement(IconButton, { "aria-label": "close", onClick: this.handleSearchClose, edge: "end" },
                                React.createElement("img", { src: searchCloseIcon, height: 24, width: 24, alt: "eye" }))) }))),
                React.createElement("div", { className: "business-manage-booking-header-upcoming-past-button-container" },
                    React.createElement("div", { className: "business-manage-booking-header-upcoming-past-button-sub-container" },
                        React.createElement("div", { className: this.props.state.selectedDataView === "upcoming"
                                ? "business-manage-booking-header-upcoming-past-button-style-selected"
                                : "business-manage-booking-header-upcoming-past-button-style", onClick: this.props.handleUpcomingBookingsView }, t("UPCOMING")),
                        React.createElement("div", { className: this.props.state.selectedDataView === "past"
                                ? "business-manage-booking-header-upcoming-past-button-style-selected"
                                : "business-manage-booking-header-upcoming-past-button-style", onClick: this.props.handlePastBookingsView }, t("PAST"))))),
            (this.props.data.length === 0 ||
                this.state.listingData.length === 0) && (React.createElement("span", { className: "add-new-categories-message-text" }, t("No record found"))),
            this.state.listingData.length > 0 &&
                this.state.listingData.map((appointment) => {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24;
                    //istanbul ignore next
                    return (React.createElement("div", { className: "business-manage-booking-appointment-details-container", onClick: () => this.props.handleAppointmentOnclick(appointment), key: appointment.id },
                        React.createElement("div", { className: "manage-booking-business-booking-id-and-status-container" },
                            React.createElement("span", { className: "manage-booking-business-booking-id-text" }, `#${(_a = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _a === void 0 ? void 0 : _a.booking_id}`),
                            React.createElement("span", { className: `manage-booking-business-appointment-status-text manage-booking-business-orange-color ${(((_b = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _b === void 0 ? void 0 : _b.status) === "cancelled" ||
                                    ((_c = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _c === void 0 ? void 0 : _c.status) === "no_show") &&
                                    "manage-booking-business-red-color"} ${((_d = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _d === void 0 ? void 0 : _d.status) === "refunded" &&
                                    "manage-booking-business-purple-color"} ${((_e = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _e === void 0 ? void 0 : _e.status) === "refund_initiated" &&
                                    "manage-booking-business-grey-color"} ${(((_f = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _f === void 0 ? void 0 : _f.status) === "started" ||
                                    ((_g = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _g === void 0 ? void 0 : _g.status) === "completed") &&
                                    "manage-booking-business-green-color"}` }, t(this.renderStatus((_h = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _h === void 0 ? void 0 : _h.status)))),
                        React.createElement("div", { className: "manage-booking-business-booking-date-container" },
                            React.createElement("span", { className: "manage-booking-business-booking-date-text" }, `on ${moment((_j = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _j === void 0 ? void 0 : _j.start_time).format("ddd , DD MMM YYYY")}`)),
                        React.createElement("div", { className: "manage-booking-business-customer-image-name-appointmnet-type-container" },
                            React.createElement("div", { className: "manage-booking-business-customer-image-name-container" },
                                React.createElement("img", { src: ((_o = (_m = (_l = (_k = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _k === void 0 ? void 0 : _k.customer) === null || _l === void 0 ? void 0 : _l.data) === null || _m === void 0 ? void 0 : _m.attributes) === null || _o === void 0 ? void 0 : _o.profile_image) || defultPng, alt: "employeeAvtar", className: "manage-booking-business-customer-image-style" }),
                                React.createElement("span", { className: "manage-booking-business-customer-name-text" }, ((_s = (_r = (_q = (_p = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _p === void 0 ? void 0 : _p.customer) === null || _q === void 0 ? void 0 : _q.data) === null || _r === void 0 ? void 0 : _r.attributes) === null || _s === void 0 ? void 0 : _s.full_name) ? `${(_w = (_v = (_u = (_t = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _t === void 0 ? void 0 : _t.customer) === null || _u === void 0 ? void 0 : _u.data) === null || _v === void 0 ? void 0 : _v.attributes) === null || _w === void 0 ? void 0 : _w.full_name}`
                                    : `${(_0 = (_z = (_y = (_x = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _x === void 0 ? void 0 : _x.customer) === null || _y === void 0 ? void 0 : _y.data) === null || _z === void 0 ? void 0 : _z.attributes) === null || _0 === void 0 ? void 0 : _0.first_name} ${(_4 = (_3 = (_2 = (_1 = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _1 === void 0 ? void 0 : _1.customer) === null || _2 === void 0 ? void 0 : _2.data) === null || _3 === void 0 ? void 0 : _3.attributes) === null || _4 === void 0 ? void 0 : _4.last_name}`)),
                            React.createElement("span", { className: "manage-booking-business-appointment-type-container" },
                                React.createElement("img", { src: appointmentOval, alt: "appointmentOval" }),
                                t(((_5 = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _5 === void 0 ? void 0 : _5.appointment_type.replace(/[. _]/g, "-").charAt(0).toUpperCase()) + ((_6 = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _6 === void 0 ? void 0 : _6.appointment_type.replace(/[. _]/g, "-").slice(1))))),
                        React.createElement(Divider, null),
                        React.createElement("div", { className: "manage-booking-business-service-details-container" },
                            React.createElement("span", { className: "manage-booking-business-service-details-text" }, `${t("Service")} : ${((_8 = (_7 = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _7 === void 0 ? void 0 : _7.service) === null || _8 === void 0 ? void 0 : _8.display_name) || ""}`),
                            ((_10 = (_9 = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _9 === void 0 ? void 0 : _9.employee_details) === null || _10 === void 0 ? void 0 : _10.data) ? (React.createElement("span", { className: "manage-booking-business-employee-details-text" }, `${t("By")} ${(_14 = (_13 = (_12 = (_11 = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _11 === void 0 ? void 0 : _11.employee_details) === null || _12 === void 0 ? void 0 : _12.data) === null || _13 === void 0 ? void 0 : _13.attributes) === null || _14 === void 0 ? void 0 : _14.first_name} ${(_18 = (_17 = (_16 = (_15 = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _15 === void 0 ? void 0 : _15.employee_details) === null || _16 === void 0 ? void 0 : _16.data) === null || _17 === void 0 ? void 0 : _17.attributes) === null || _18 === void 0 ? void 0 : _18.last_name}`)) : (React.createElement("span", { className: "manage-booking-business-employee-details-text" }, t("Employee is deleted by business.")))),
                        React.createElement(Divider, null),
                        React.createElement("div", { className: "manage-booking-business-billing-details-container" },
                            React.createElement("span", { className: "manage-booking-business-bill-details-text" }, `${t("Bill:")} ${(_20 = (_19 = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _19 === void 0 ? void 0 : _19.service) === null || _20 === void 0 ? void 0 : _20.currency} ${(_21 = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _21 === void 0 ? void 0 : _21.total_price}`),
                            React.createElement("span", { className: `manage-booking-business-payment-method-details-text ${((_22 = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _22 === void 0 ? void 0 : _22.paid_by) === "pay_at_shop"
                                    ? "manage-booking-business-orange-color"
                                    : "manage-booking-business-green-color"}` }, t(`${((_23 = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _23 === void 0 ? void 0 : _23.paid_by.replace(/[. _-]/g, " ").charAt(0).toUpperCase()) + ((_24 = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _24 === void 0 ? void 0 : _24.paid_by.replace(/[. _-]/g, " ").slice(1))}`)))));
                }),
            React.createElement(Modal, { open: this.props.state.filterModalBusiness, "aria-labelledby": "modal-modal-title ", "aria-describedby": "modal-modal-description " },
                React.createElement("div", { className: "modal-booknow-confirmed-review " },
                    React.createElement("div", { className: "img-debit-card-div-confirmed-review " },
                        React.createElement("div", { className: "img-debit-card-confirmed-review ", style: {
                                paddingBottom: 32,
                                padding: "20px",
                                paddingTop: "0px",
                            } },
                            React.createElement("div", { style: { display: "flex" } },
                                React.createElement("h1", { style: {
                                        fontSize: "32px",
                                        marginBottom: "24px",
                                    } }, t("Filters")),
                                " ",
                                React.createElement("img", { id: "closeFilterModal", src: close_modal_icon, style: {
                                        marginRight: webAppDirection === "rtl" ? "auto" : "0",
                                        marginLeft: webAppDirection === "ltr" ? "auto" : "0",
                                        cursor: "pointer",
                                        width: "48px",
                                    }, alt: "close", onClick: this.props.handleFilterModalChange })),
                            React.createElement(FormControl, { className: "filter-buttons-main-conatiner " },
                                React.createElement(RadioGroup, { "aria-labelledby": "demo-radio-buttons-group-label ", defaultValue: this.props.state.filterValue, name: "radio-buttons-group", style: { width: "100%", alignItems: "baseline" } },
                                    React.createElement(FormControlLabel, { id: "allRadio", value: "all", onClick: this.props.handleFilterValueChanges, style: {
                                            marginTop: 18,
                                            width: "100%",
                                            marginBottom: 15,
                                        }, checked: (_a = this.props.state.allFiltersBusiness) === null || _a === void 0 ? void 0 : _a.all, control: React.createElement(Radio, { style: {
                                                marginLeft: webAppDirection === "ltr" ? "auto" : "0px",
                                                marginRight: webAppDirection === "rtl" ? "auto" : "0px",
                                            }, checkedIcon: React.createElement("img", { src: checkRightIcon }), icon: React.createElement("img", { src: uncheckCircle }) }), labelPlacement: "start", label: React.createElement("span", { style: { fontSize: "20px" } }, t("All")) }),
                                    React.createElement(FormControlLabel, { id: "pendingRadio", onClick: this.props.handleFilterValueChanges, value: "pending", style: {
                                            width: "100%",
                                            marginBottom: 15,
                                        }, checked: (_b = this.props.state.allFiltersBusiness) === null || _b === void 0 ? void 0 : _b.pending, control: React.createElement(Radio, { style: {
                                                marginLeft: webAppDirection === "ltr" ? "auto" : "0px",
                                                marginRight: webAppDirection === "rtl" ? "auto" : "0px",
                                            }, checkedIcon: React.createElement("img", { src: checkRightIcon }), icon: React.createElement("img", { src: uncheckCircle }) }), labelPlacement: "start", label: React.createElement("span", { style: { fontSize: "20px" } }, t("Upcoming")) }),
                                    React.createElement(FormControlLabel, { id: "startedRadio", onClick: this.props.handleFilterValueChanges, value: "started", style: {
                                            width: "100%",
                                            marginBottom: 15,
                                        }, checked: (_c = this.props.state.allFiltersBusiness) === null || _c === void 0 ? void 0 : _c.started, control: React.createElement(Radio, { style: {
                                                marginLeft: webAppDirection === "ltr" ? "auto" : "0px",
                                                marginRight: webAppDirection === "rtl" ? "auto" : "0px",
                                            }, checkedIcon: React.createElement("img", { src: checkRightIcon }), icon: React.createElement("img", { src: uncheckCircle }) }), labelPlacement: "start", label: React.createElement("span", { style: { fontSize: "20px" } }, t("Started")) }),
                                    React.createElement(FormControlLabel, { id: "re_scheduledRadio", value: "re_scheduled", onClick: this.props.handleFilterValueChanges, style: {
                                            width: "100%",
                                            marginBottom: 15,
                                        }, checked: (_d = this.props.state.allFiltersBusiness) === null || _d === void 0 ? void 0 : _d.re_scheduled, control: React.createElement(Radio, { style: {
                                                marginLeft: webAppDirection === "ltr" ? "auto" : "0px",
                                                marginRight: webAppDirection === "rtl" ? "auto" : "0px",
                                            }, icon: React.createElement("img", { src: uncheckCircle }), checkedIcon: React.createElement("img", { src: checkRightIcon }) }), labelPlacement: "start", label: React.createElement("span", { style: { fontSize: "20px" } }, t("Rescheduled")) }),
                                    React.createElement(FormControlLabel, { value: "completed", id: "compRadio", style: { marginBottom: 15, width: "100%" }, checked: (_e = this.props.state.allFiltersBusiness) === null || _e === void 0 ? void 0 : _e.completed, onClick: this.props.handleFilterValueChanges, control: React.createElement(Radio, { style: {
                                                marginLeft: webAppDirection === "ltr" ? "auto" : "0px",
                                                marginRight: webAppDirection === "rtl" ? "auto" : "0px",
                                            }, checkedIcon: React.createElement("img", { src: checkRightIcon }), icon: React.createElement("img", { src: uncheckCircle }) }), labelPlacement: "start", label: React.createElement("span", { style: { fontSize: "20px" } }, t("Completed")) }),
                                    React.createElement(FormControlLabel, { value: "no_show", onClick: this.props.handleFilterValueChanges, id: "noRadio", checked: (_f = this.props.state.allFiltersBusiness) === null || _f === void 0 ? void 0 : _f.no_show, style: { marginBottom: 15, width: "100%" }, control: React.createElement(Radio, { style: {
                                                marginLeft: webAppDirection === "ltr" ? "auto" : "0px",
                                                marginRight: webAppDirection === "rtl" ? "auto" : "0px",
                                            }, checkedIcon: React.createElement("img", { src: checkRightIcon }), icon: React.createElement("img", { src: uncheckCircle }) }), labelPlacement: "start", label: React.createElement("span", { style: { fontSize: "20px" } }, t("No Show")) }),
                                    React.createElement(FormControlLabel, { onClick: this.props.handleFilterValueChanges, id: "canRadio", style: { marginBottom: 15, width: "100%" }, value: "cancelled", checked: (_g = this.props.state.allFiltersBusiness) === null || _g === void 0 ? void 0 : _g.cancelled, control: React.createElement(Radio, { style: {
                                                marginLeft: webAppDirection === "ltr" ? "auto" : "0px",
                                                marginRight: webAppDirection === "rtl" ? "auto" : "0px",
                                            }, checkedIcon: React.createElement("img", { src: checkRightIcon }), icon: React.createElement("img", { src: uncheckCircle }) }), labelPlacement: "start", label: React.createElement("span", { style: { fontSize: "20px" } }, t("Cancelled")) }),
                                    React.createElement(FormControlLabel, { onClick: this.props.handleFilterValueChanges, id: "refRadio", value: "refunded", checked: (_h = this.props.state.allFiltersBusiness) === null || _h === void 0 ? void 0 : _h.refunded, style: { marginBottom: 15, width: "100%" }, control: React.createElement(Radio, { style: {
                                                marginLeft: webAppDirection === "ltr" ? "auto" : "0px",
                                                marginRight: webAppDirection === "rtl" ? "auto" : "0px",
                                            }, checkedIcon: React.createElement("img", { src: checkRightIcon }), icon: React.createElement("img", { src: uncheckCircle }) }), labelPlacement: "start", label: React.createElement("span", { style: { fontSize: "20px" } }, t("Refunded")) }))),
                            React.createElement(Button, { className: "logout-modal-yes-button-style ", id: "clearFilter ", onClick: () => this.props.handleFilterValueChanges({
                                    target: { value: "all" },
                                }), style: { marginBottom: 15, marginTop: 15 } }, t("Clear")),
                            React.createElement(Button, { id: "showFilter ", className: "logout-modal-no-button-style ", onClick: () => {
                                    this.props.getAllAppointments();
                                    this.props.handleFilterModalChange();
                                } }, t("Show results"))))))));
    }
}
//@ts-ignore
export default withTranslation()(ManageBookingsListing);
// Customizable Area End
