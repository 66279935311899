// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase/app';
import '../src/assets/style.css';
import { messaging } from './firebase.js';
import Cookies from 'js-cookie';
import { withTranslation } from 'react-i18next';
import { runEngine } from 'framework/src/RunEngine';
import MessageEnum, { getName } from 'framework/src/Messages/MessageEnum';
import { Message } from 'framework/src/Message';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import { withRouter } from 'react-router-dom';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import SavedCards from '../../blocks/SavedCards/src/SavedCards';
import StripeIntegration from '../../blocks/stripepayments/src/StripeIntegration';
import CustomerHomeScreen from '../../blocks/Squeez_me_in/src/CustomerHomeScreen.web';
import LandingPage from '../../blocks/dashboard/src/LandingPage.web';
import Login from '../../blocks/social-media-account-login/src/Login.web';
import Registration from '../../blocks/social-media-account-registration/src/Registration.web';
import ForgotPasswordMobileNumber from '../../blocks/forgot-password/src/ForgotPasswordMobileNumber.web';
import AccountActivationPending from '../../blocks/forgot-password/src/AccountActivationPending.web';
import VerifyRegistrationDetails from '../../blocks/social-media-account-registration/src/VerifyRegistrationDetails.web';
import HomeScreen from '../../components/src/HomeScreen';
import CustomisableBusinessSetup from '../../blocks/CustomisableUserProfiles/src/CustomisableBusinessSetup.web';
import CustomisableBusinessUserProfiles from '../../blocks/CustomisableUserProfiles/src/CustomisableBusinessUserProfiles.web';
import ProfilePage from '../../blocks/dashboard/src/ProfilePage.web';
import BusinessProfile from '../../blocks/dashboard/src/Profile.web';
import EmployeeHomeScreen from '../../blocks/CustomisableUserProfiles/src/EmployeeProfile/EmployeeHomeScreen.web';
import CustomisableBusinessServices from '../../blocks/CustomisableUserProfiles/src/CustomisableBusinessServices.web';
import AddTeamMembers from '../../blocks/CustomisableUserProfiles/src/AddTeamMembers.web';
import NearbyFriends from '../../blocks/NearbyFriends/src/NearbyFriends.web';
import NearbyFriendLogin from '../../blocks/NearbyFriends/src/NearbyFriendLogin.web';
import NearbyFriendRegistration from '../../blocks/NearbyFriends/src/NearbyFriendRegistration.web';
import CreateBusinessProfile from '../../blocks/NearbyFriends/src/CreateBusinessProfile.web';
import AddLocationNearbyFriends from '../../blocks/NearbyFriends/src/AddLocationNearbyFriends.web';
import TimeTracker from '../../blocks/TimeTracker/src/TimeTracker.web';
import ManageBusiness from '../../blocks/ContentModeration/src/ManageBusiness.web';
import BusinessHomeScreen from '../../blocks/ContentModeration/src/BusinessHomeScreen.web';
import BusinessAppointmentsDetail from '../../blocks/ContentModeration/src/BusinessAppointmentsDetail.web';
import EmployeeAppointmentsDetail from '../../blocks/CustomisableUserProfiles/src/EmployeeProfile/EmployeeAppointmentsDetail.web';
import ManageCatalouge from '../../blocks/ContentModeration/src/ManageCatalouge.web';
import BusinessManageReviews from '../../blocks/ContentModeration/src/BusinessManageReviews.web';
import ManageTeam from '../../blocks/ContentModeration/src/ManageTeam.web';
import EmployeeProfileView from '../../blocks/CustomisableUserProfiles/src/EmployeeProfile/EmployeeViewProfile.web';
import ManageWorkingHours from '../../blocks/ContentModeration/src/ManageWorkingHours.web';
import TimeWorkingDays from '../../blocks/ContentModeration/src/TimeWorkingDays.web';
import BusinessWorkingDaysAndTime from '../../blocks/ContentModeration/src/BusinessWorkingDaysAndTime.web';
import BookingCalender from '../../blocks/Squeez_me_in/src/BookingCalender.web';
import AvailableSlot from '../../blocks/Squeez_me_in/src/AvailableSlot.web';
import CustomerSubcategoryScreen from '../../blocks/Squeez_me_in/src/CustomerSubcategoryScreen.web';
import CategoryListDetailComponent from '../../blocks/Squeez_me_in/src/CategoryListDetailComponent.web';
import customerProfilePage from '../../blocks/Squeez_me_in/src/customerProfilePage.web';
import BusinessServices from '../../blocks/Squeez_me_in/src/BusinessServices.web';
import EmployeeManageTabHome from '../../blocks/CustomisableUserProfiles/src/EmployeeProfile/EmployeeManageTabHome.web';
import ManageEmployee from '../../blocks/CustomisableUserProfiles/src/EmployeeProfile/ManageEmployee.web';
import ManageBlockMyTime from '../../blocks/ContentModeration/src/ManageBlockMyTime.web';
import ManageBusinessHoliday from '../../blocks/ContentModeration/src/ManageBusinessHoliday.web';
import WalkInBooking from '../../blocks/CustomisableUserProfiles/src/EmployeeProfile/WalkInBooking.web';
import WalkInBookingBusiness from '../../blocks/ContentModeration/src/WalkInBookingBusiness.web';
import BookingConfirmed from '../../blocks/Squeez_me_in/src/BookingConfirmed.web';
import PaymentSuccessfull from '../../blocks/Squeez_me_in/src/PaymentSuccessfull.web';
import ConfirmAndPay from '../../blocks/Squeez_me_in/src/ConfirmAndPay.web';
import ManageTeamMember from '../../blocks/TeamBuilder/src/ManageTeamMember.web';
import TeamBuilderLogin from '../../blocks/TeamBuilder/src/TeamBuilderLogin.web';
import TeamBuilderRegistration from '../../blocks/TeamBuilder/src/TeamBuilderRegistration.web';
import AddClientDetails from '../../blocks/CustomisableUserProfiles/src/EmployeeProfile/AddClientDetails.web';
import BusinessManageBookings from '../../blocks/ContentModeration/src/BusinessManageBookings.web';
import EmployeeManageBookings from '../../blocks/CustomisableUserProfiles/src/EmployeeProfile/EmployeeManageBookings.web';
import MyBookings from '../../blocks/categoriessubcategories/src/MyBookings.web';
import MyBookingsDetail from '../../blocks/categoriessubcategories/src/MyBookingsDetail.web';
import EditBusinessProfile from '../../blocks/dashboard/src/EditBusinessProfile.web';
import BookingRescheduled from '../../blocks/Squeez_me_in/src/BookingRescheduled.web';
import NeedHelp from '../../blocks/categoriessubcategories/src/NeedHelp.web';
import { createConsumer } from '@rails/actioncable';
import EditCustomerProfile from '../../blocks/Squeez_me_in/src/EditCustomerProfile.web';
import SqueezMeinRatting from '../../blocks/reviews/src/SqueezMeinRatting.web';
import ReactNotificationComponent from './ReactNotificationComponent';
import NotificationAskComponent from './NotificationAskComponent';
import SubscriptionPaymentSuccessfull from '../../blocks/customisableusersubscriptions/src/SubscriptionPaymentSuccessfull.web';
import LanguageSwitcher from '../../blocks/LanguageSupport/src/LanguageSwitcher.web';
import DiscountAndOffer from '../../blocks/promocodes/src/DiscountAndOffer.web';
import PerformnaceTracker from '../../blocks/PerformanceTracker/src/PerformanceTracker.web';
import Analytics from '../../blocks/analytics/src/Analytics.web';
import { ElasticSearchRoute } from '../../blocks/ElasticSearch/src/ElasticSearch.web';
import PrivacyAndPolicy from '../../blocks/dashboard/src/PrivacyAndPolicy.web';
import TermsAndConditions from '../../blocks/dashboard/src/TermsAndConditions.web';

const routeMap = {
  SavedCards: {
    component: SavedCards,
    path: '/SavedCards'
  },
  StripeIntegration: {
    component: StripeIntegration,
    path: '/StripeIntegration'
  },
  Home: {
    component: LandingPage,
    path: '/',
    exact: true
  },
  EmployeeHomeScreen: {
    component: EmployeeHomeScreen,
    path: '/EmployeeHomeScreen',
    exact: true
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage',
    exact: true
  },
  Login: {
    component: Login,
    path: '/Login',
    exact: true
  },
  Registration: {
    component: Registration,
    path: '/Registration',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  ForgotPasswordMobileNumber: {
    component: ForgotPasswordMobileNumber,
    path: '/ForgotPasswordMobileNumber',
    exact: true
  },
  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  CustomisableBusinessUserProfiles: {
    component: CustomisableBusinessUserProfiles,
    path: '/CustomisableBusinessUserProfiles',
    exact: true
  },
  CustomerHomeScreen: {
    component: CustomerHomeScreen,
    path: '/CustomerHomeScreen'
  },
  AccountActivationPending: {
    component: AccountActivationPending,
    path: '/AccountActivationPending',
    exact: true
  },
  VerifyRegistrationDetails: {
    component: VerifyRegistrationDetails,
    path: '/VerifyRegistrationDetails',
    exact: true
  },
  CustomisableBusinessSetup: {
    component: CustomisableBusinessSetup,
    path: '/CustomisableBusinessSetup',
    exact: true
  },
  ProfilePage: {
    component: ProfilePage,
    path: '/ProfilePage',
    exact: true
  },
  Profile: {
    component: BusinessProfile,
    path: '/BusinessProfile',
    exact: true
  },
  AddTeamMembers: {
    component: AddTeamMembers,
    path: '/AddTeamMembers',
    exact: true
  },
  CustomisableBusinessServices: {
    component: CustomisableBusinessServices,
    path: '/CustomisableBusinessServices',
    exact: true
  },
  ProfileDetail: {
    component: EmployeeProfileView,
    path: '/profile/details',
    exact: true
  },
  customerProfilePage: {
    component: customerProfilePage,
    path: '/customer/profile',
    exact: true
  },
  ManageBusiness: {
    component: ManageBusiness,
    path: '/ManageBusiness',
    exact: true
  },
  TimeTracker: {
    component: TimeTracker,
    path: '/TimeTracker',
    exact: true
  },
  BusinessHomeScreen: {
    component: BusinessHomeScreen,
    path: '/BusinessHomeScreen',
    exact: true
  },
  BusinessAppointmentsDetail: {
    component: BusinessAppointmentsDetail,
    path: '/BusinessAppointmentsDetail',
    exact: true
  },
  EmployeeAppointmentsDetail: {
    component: EmployeeAppointmentsDetail,
    path: '/EmployeeAppointmentsDetail',
    exact: true
  },
  ManageCatalouge: {
    component: ManageCatalouge,
    path: '/ManageBusiness/ManageCatalouge',
    exact: true
  },
  BusinessManageReviews: {
    component: BusinessManageReviews,
    path: '/ManageBusiness/Reviews',
    exact: true
  },
  NearbyFriends: {
    component: NearbyFriends,
    path: '/NearbyFriends',
    exact: true
  },
  NearbyFriendLogin: {
    component: NearbyFriendLogin,
    path: '/NearbyFriends/login',
    exact: true
  },
  NearbyFriendRegistration: {
    component: NearbyFriendRegistration,
    path: '/NearbyFriends/registration',
    exact: true
  },
  CreateBusinessProfile: {
    component: CreateBusinessProfile,
    path: '/NearbyFriends/CreateBusinessprofile',
    exact: true
  },
  AddLocationNearbyFriends: {
    component: AddLocationNearbyFriends,
    path: '/NearbyFriends/addlocation',
    exact: true
  },
  ManageTeam: {
    component: ManageTeam,
    path: '/ManageBusiness/ManageTeam',
    exact: true
  },
  ManageWorkingHours: {
    component: ManageWorkingHours,
    path: '/ManageBusiness/ManageWorkingHours',
    exact: true
  },
  TimeWorkingDays: {
    component: TimeWorkingDays,
    path: '/ManageBusiness/TimeWorkingDays',
    exact: true
  },
  ManageBlockMyTime: {
    component: ManageBlockMyTime,
    path: '/ManageBusiness/ManageBlockMyTime',
    exact: true
  },
  ManageBusinessHoliday: {
    component: ManageBusinessHoliday,
    path: '/ManageBusiness/ManageBusinessHoliday',
    exact: true
  },
  BusinessWorkingDaysAndTime: {
    component: BusinessWorkingDaysAndTime,
    path: '/ManageBusiness/BusinessWorkingDays',
    exact: true
  },
  CustomerSubcategoryScreen: {
    component: CustomerSubcategoryScreen,
    path: '/CustomerSubcategory',
    exact: true
  },
  CategoryListDetailComponent: {
    component: CategoryListDetailComponent,
    path: '/CustomerSubcategoryDetail',
    exact: true
  },
  BusinessServices: {
    component: BusinessServices,
    path: '/CategoryServices',
    exact: true
  },
  EmployeeManageTabHome: {
    component: EmployeeManageTabHome,
    path: '/ManageEmployee/EmployeeManageTabHome',
    exact: true
  },
  EmployeeManageWalkIn: {
    component: WalkInBooking,
    path: '/EmployeeHomeScreen/EmployeeManageWalkIn',
    exact: true
  },
  ManageEmployee: {
    component: ManageEmployee,
    path: '/ManageEmployee',
    exact: true
  },
  BookingCalender: {
    component: BookingCalender,
    path: '/customer/service/booking',
    exact: true
  },
  AvailableSlot: {
    component: AvailableSlot,
    path: '/customer/service/booking/availableSlot',
    exact: true
  },
  ConfirmAndPay: {
    component: ConfirmAndPay,
    path: '/confirmandpay',
    exact: true
  },
  BookingConfirmed: {
    component: BookingConfirmed,
    path: '/bookingConfirm',
    exact: true
  },
  PaymentSuccessfull: {
    component: PaymentSuccessfull,
    path: '/paymentSuccessfull',
    exact: true
  },
  AddClientDetails: {
    component: AddClientDetails,
    path: '/EmployeeHomeScreen/AddClientDetails',
    exact: true
  },
  ManageTeamMember: {
    component: ManageTeamMember,
    path: '/ManageTeamMember',
    exact: true
  },
  TeamBuilderLogin: {
    component: TeamBuilderLogin,
    path: '/TeamBuilderLogin',
    exact: true
  },
  TeamBuilderRegistration: {
    component: TeamBuilderRegistration,
    path: '/TeamBuilderRegistration'
  },
  WalkInBookingBusiness: {
    component: WalkInBookingBusiness,
    path: '/WalkInBookingBusiness',
    exact: true
  },
  BusinessManageBookings: {
    component: BusinessManageBookings,
    path: '/ManageBusiness/ManageBookings',
    exact: true
  },
  EmployeeManageBookings: {
    component: EmployeeManageBookings,
    path: '/ManageEmployee/EmployeeManageBookings',
    exact: true
  },
  EditBusinessProfile: {
    component: EditBusinessProfile,
    path: '/EditBusinessProfile',
    exact: true
  },
  MyBookings: {
    component: MyBookings,
    path: '/MyBookings',
    exact: true
  },
  MyBookingsDetail: {
    component: MyBookingsDetail,
    path: '/MyBookings/Detail',
    exact: true
  },
  BookingRescheduled: {
    component: BookingRescheduled,
    path: '/BookingRescheduled',
    exact: true
  },
  NeedHelp: {
    component: NeedHelp,
    path: '/NeedHelp',
    exact: true
  },
  EditCustomerProfile: {
    component: EditCustomerProfile,
    path: '/customer/myprofile',
    exact: true
  },
  SubscriptionPaymentSuccessfull: {
    component: SubscriptionPaymentSuccessfull,
    path: '/SubscriptionPaymentSuccessfull',
    exact: true
  },
  DiscountAndOffer: {
    component: DiscountAndOffer,
    path: '/ManageBusiness/DiscountAndOffer',
    exact: true
  },
  SqueezMeinRatting: {
    component: SqueezMeinRatting,
    path: '/SqueezMeinRatting',
    exact: true
  },
  PerformnaceTracker: {
    component: PerformnaceTracker,
    path: '/ManageBusiness/PerformnaceTracker',
    exact: true
  },
  Analytics: {
    component: Analytics,
    path: '/ManageBusiness/Analytics',
    exact: true
  },
  ElasticSearchRoute: {
    component: ElasticSearchRoute,
    path: '/search',
    exact: true
  },
  PrivacyAndPolicy: {
    component: PrivacyAndPolicy,
    path: '/PrivacyAndPolicy',
    exact: true
  },
  TermsAndConditions: {
    component: TermsAndConditions,
    path: '/TermsAndConditions',
    exact: true
  }
};

const firebaseAPI = {
  apiKey: 'AIzaSyAsVvsnqcCH2nhpRgyQrpJZYqxRcHkq6xc',
  authDomain: 'sodium-pathway-355305.firebaseapp.com',
  projectId: 'sodium-pathway-355305',
  storageBucket: 'sodium-pathway-355305.appspot.com',
  messagingSenderId: '1009331739934',
  appId: '1:1009331739934:web:2e960d7ac7077f3320fd0f',
  measurementId: 'G-0BEV8TENDM'
};

class App extends Component {
  logoutApiCallId;
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      notification: { title: '', body: '', redirect_to: '' },
      languageModal: false
    };

    this.receive = this.receive.bind(this);
    this.logoutApiCall = this.logoutApiCall.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  componentWillMount() {
    const token = localStorage.getItem('token');
    const baseURL =
      'wss://bookmecomcomex-231235-ruby.b231235.dev.eastus.az.svc.builder.cafe';
    const URL = `${baseURL}/cable?token=${token}`;
    const consumer = createConsumer(URL);
    consumer.subscriptions.create(
      { channel: 'UserChannel' },
      {
        connected: () => console.log('connected'),
        disconnected: () => console.log('disconnected'),
        received: data => this.handleDeactivatedUser(data)
      }
    );
  }

  async componentDidMount() {
    let browserId =
      localStorage.getItem('BrowserUniqueId') ||
      Cookies.get('BrowserUniqueId') ||
      '';
    if (!browserId) {
      this.generateFingerprint();
    }
    this.handleWebAppDirectionChanage();
    this.handleLangaugeModal();
    this.isTokenExpired();
  }

  isTokenExpired = () => {
    const expirationTime = localStorage.getItem('tokenExpiration');
    if (
      expirationTime &&
      new Date().getTime() > JSON.parse(expirationTime || '')
    ) {
      this.logoutApiCall();
    }
  };

  async receive(from, message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJsonData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.logoutApiCallId) {
        this.handleLogoutResponse(responseJsonData);
      }
    }
  }

  handleLogoutResponse = responseJson => {
    if (responseJson) {
      localStorage.clear();
      window.location.replace('/LandingPage');
    }
  };

  logoutApiCall() {
    let device_token =
      localStorage.getItem('device_token') ||
      Cookies.get('Push_NotificationToken') ||
      '';

    let browser_id =
      localStorage.getItem('BrowserUniqueId') ||
      Cookies.get('BrowserUniqueId') ||
      '';

    const header = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('token') || ''
    };

    let body = {
      device_token: device_token,
      platform_id: `${browser_id}${device_token}`
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.logoutApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_login/logins/logout_account'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleWebAppDirectionChanage = () => {
    let webAppDirection =
      localStorage.getItem('webAppDirection') ||
      Cookies.get('webAppDirection') ||
      'ltr';

    let htmlTag = document.getElementsByTagName('html');
    if (htmlTag) {
      htmlTag[0].setAttribute('dir', webAppDirection);
    }
  };

  handleLangaugeModal = () => {
    let webAppLanguage =
      localStorage.getItem('webAppLanguage') ||
      Cookies.get('webAppLanguage') ||
      '';

    if (!webAppLanguage) {
      this.setState({ ...this.state, languageModal: true });
    } else {
      this.setState({ ...this.state, languageModal: false });
    }
  };

  handleDeactivatedUser = data => {
    if (data?.activated === false) {
      localStorage.removeItem('token');
      window.location.replace('/');
    }
  };

  handleCloseToast = () => {
    this.setState({
      ...this.state,
      show: false,
      notification: { title: '', body: '', redirect_to: '', appointmentId: '' }
    });
  };

  getBrowserName() {
    const browserInfo = window.navigator.userAgent;
    console.log(browserInfo, 'BROWSER');
    if (browserInfo.includes('Opera') || browserInfo.includes('Opr')) {
      return 'Opera';
    }
    if (browserInfo.includes('Edg')) {
      return 'Edge';
    }
    if (
      browserInfo.includes('Chrome') &&
      browserInfo.includes('Safari') &&
      browserInfo.includes('Edg')
    ) {
      return 'Edge';
    }
    if (browserInfo.includes('Chrome')) {
      return 'Chrome';
    }
    if (browserInfo.includes('Safari')) {
      return 'Safari';
    }
    if (browserInfo.includes('Firefox')) {
      return 'Firefox';
    }
    return 'unknown';
  }

  generateFingerprint() {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const colorDepth = window.screen.colorDepth;
    const timezoneOffset = new Date().getTimezoneOffset();
    const browser = this.getBrowserName();
    console.log(browser, 'Browser Name');
    const fingerprint = `${browser}${screenWidth}${screenHeight}${colorDepth}${timezoneOffset}`;
    localStorage.setItem('BrowserUniqueId', fingerprint);
    Cookies.set('BrowserUniqueId', fingerprint);
  }

  async requestPermission() {
    if (messaging) {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        // Generate Token
        const token = await messaging.getToken({
          vapidKey:
            'BId8LqcY2o46xpVspxkEqP_mfiOasqjTLrdTIexuXhEHRULmiaX_WKc5qvrjd7xOWKCDdVLjO1qCB0NxJRD_X3Q'
        });
        Cookies.set('Push_NotificationToken', token);
        localStorage.setItem('device_token', token);
        setTimeout(() => {
          let token = localStorage.getItem('token') || '';
          if (token) {
            let browser_id =
              localStorage.getItem('BrowserUniqueId') ||
              Cookies.get('BrowserUniqueId') ||
              '';

            let device_token =
              localStorage.getItem('device_token') ||
              Cookies.get('Push_NotificationToken') ||
              '';

            const header = {
              'Content-Type': 'application/json',
              token: token
            };

            let body = {
              device_token: device_token,
              platform_id: `${browser_id}${device_token}`,
              platform_type: 0
            };

            const requestMessage = new Message(
              getName(MessageEnum.RestAPIRequestMessage)
            );

            requestMessage.addData(
              getName(MessageEnum.RestAPIRequestHeaderMessage),
              JSON.stringify(header)
            );

            requestMessage.addData(
              getName(MessageEnum.RestAPIResponceEndPointMessage),
              `bx_block_login/logins/add_device_token`
            );

            requestMessage.addData(
              getName(MessageEnum.RestAPIRequestMethodMessage),
              'PUT'
            );

            requestMessage.addData(
              getName(MessageEnum.RestAPIRequestBodyMessage),
              JSON.stringify(body)
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
          }
        }, 200);
      }
      if (permission === 'denied') {
        Cookies.remove('Push_NotificationToken', token);
        localStorage.removeItem('device_token', token);
      }
    }
  }

  render() {
    if (messaging) {
      messaging.onMessage(payload => {
        localStorage.setItem(
          'appointmentIdFromIndexDb',
          payload.data.appointment_id
        );
        localStorage.setItem(
          'SubIdFromIndexDb',
          payload?.data?.business_profile_id
        );
        setTimeout(() => {
          console.log(payload, payload.data.appointment_id, 'in-ground');
          this.setState(
            {
              show: true,
              notification: {
                title: payload.data.title || '',
                body: payload.data.body || '',
                redirect_to: payload.data.redirect_to,
                appointmentId: payload.data.appointment_id
              }
            },
            () => {
              setTimeout(() => {
                this.handleCloseToast();
              }, 4000);
            }
          );
        }, 1000);
      });
    }

    return (
      <>
        <NotificationAskComponent
          open={!this.state.languageModal}
          askPermission={this.requestPermission}
        />
        <LanguageSwitcher show={this.state.languageModal} />
        {this.state.show ? (
          <ReactNotificationComponent
            title={this.state.notification.title}
            body={this.state.notification.body}
            redirect_to={this.state.notification.redirect_to}
            appointmentId={this.state.notification.appointmentId}
            handleCloseToast={this.handleCloseToast}
          />
        ) : (
          <></>
        )}
        <View style={{ height: '100vh', width: '100%', overflow: 'hidden' }}>
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
        </View>
      </>
    );
  }
}

export default withTranslation()(withRouter(App));
