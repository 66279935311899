import React from "react";
import CustomerSubcategoryScreenController from "./CustomerSubcategoryScreenController.web";
import { cross } from "./assets";
import "../assets/style/customerSubcategory.web.scss";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import CategoryListComponent from "./CategoryListComponent.web";
import { withRouter } from "react-router-dom";
import HeaderWeb from "./Header.web";
import FooterWeb from "./Footer.web";
import { withTranslation } from "react-i18next";
import Loader from "../../../components/src/Loader.web";
class CustomerSubcategoryScreen extends CustomerSubcategoryScreenController {
    render() {
        this.call_shope_one_times = "call_first";
        let { t } = this.props;
        return (React.createElement(React.Fragment, null,
            React.createElement(Loader, { loading: this.state.isLoading }),
            React.createElement("div", { className: "list-element-div" },
                React.createElement(HeaderWeb
                //@ts-ignore
                , { 
                    //@ts-ignore
                    handleSearch: this.handleSearchText }),
                React.createElement("div", { className: "parent-menu-subcategory-div" },
                    React.createElement("div", { className: "menu-subcategory-div" },
                        React.createElement(Breadcrumbs, { separator: React.createElement(NavigateNextIcon, { fontSize: "small" }), "aria-label": "breadcrumb" },
                            React.createElement(Link, { color: "inherit", id: "link-Home", onClick: () => {
                                    this.props.history.push("/CustomerHomeScreen");
                                } }, t("Home")),
                            React.createElement(Link, { color: "textPrimary", style: { cursor: "default", textDecoration: "none" } }, t(this.state.show_gender)),
                            React.createElement(Typography, { color: "inherit", style: { cursor: "default", textDecoration: "none" } }, t(this.state.currentCategory))))),
                this.state.isActive === false && (React.createElement("div", { className: "reschedul-error-container" },
                    React.createElement("span", { style: { display: "flex", alignItems: "center" } },
                        React.createElement("img", { src: cross, className: "reschedul-error-img" }),
                        React.createElement("span", { className: "reschedul-error-text" }, t("Contact SqueezMeln to make further bookings"))))),
                React.createElement("div", { style: { minHeight: "80%" } }, this.state.shopResponse.length !== 0 ? (this.state.shopResponse.map((element, index) => {
                    return (React.createElement("div", { key: index, className: "business-list-sub-container" },
                        React.createElement(CategoryListComponent
                        //@ts-ignore
                        , { 
                            //@ts-ignore
                            navigation: undefined, id: "", key: index, history: this.props.history, location: this.props.location, data: element }),
                        "\u00A0"));
                })) : (React.createElement("div", { className: "business-list-sub-container" },
                    React.createElement("h3", { style: { marginBottom: "150px" } }, t("No data available."))))),
                React.createElement(FooterWeb, null))));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(CustomerSubcategoryScreen));
