var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Customizable Area Start
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
export const configJSON = require("./config");
export default class ConfirmAndPayWebController extends BlockComponent {
    constructor(props) {
        super(props);
        this.startLoading = () => {
            this.setState({ loading: true });
        };
        this.stopLoading = () => {
            this.setState({ loading: false });
        };
        this.closeModal = () => {
            this.setState({ openDebitCardModal: false });
        };
        this.payByCard = () => {
            var _a, _b;
            this.setState({ loadingPaynowModal: true });
            let { confirmAndpayData } = (_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state;
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.payCardApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.payBycardurl + (confirmAndpayData === null || confirmAndpayData === void 0 ? void 0 : confirmAndpayData.id) + '&card_id=' + ((_b = this.state.checkBoxValue) === null || _b === void 0 ? void 0 : _b.id));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getHttpRequest);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.payByCardAddingCard = () => {
            var _a;
            let { confirmAndpayData } = (_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state;
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.payCardApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.payByAddingcardurl + (confirmAndpayData === null || confirmAndpayData === void 0 ? void 0 : confirmAndpayData.id));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getHttpRequest);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.getCardlist = () => {
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getCardApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getCardsEndUrl);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getHttpRequest);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.addNotes = () => {
            var _a;
            if (this.state.notes) {
                const header = {
                    "Content-Type": configJSON.urlHeaderTypeJSON,
                    token: localStorage.getItem("token") || "",
                };
                const httpBody = {
                    "notes": this.state.notes
                };
                const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
                this.AddNotesApiId = requestMessage.messageId;
                requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.addNotesEndpoint + ((_a = this.state.confirmAndPay) === null || _a === void 0 ? void 0 : _a.id));
                requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
                requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
                requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putMethod);
                runEngine.sendMessage(requestMessage.id, requestMessage);
                return true;
            }
            else {
                return true;
            }
        };
        this.PayAtShop = () => {
            var _a;
            this.setState({ loadingConfirmPAy: true });
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.PayatshopApiId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.payatshopEndpoint + ((_a = this.state.confirmAndPay) === null || _a === void 0 ? void 0 : _a.id));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putMethod);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.handleReceiveCards = (res) => {
            var _a;
            if ((res === null || res === void 0 ? void 0 : res.errors) && ((_a = res === null || res === void 0 ? void 0 : res.errors[0]) === null || _a === void 0 ? void 0 : _a.message) === 'Payment method not found') {
                this.setState({ cardsArr: [] });
            }
            else {
                this.setState({ cardsArr: res === null || res === void 0 ? void 0 : res.data });
            }
        };
        this.handlePayAtShop = (res) => {
            this.setState({ loadingConfirmPAy: false });
            this.props.history.push({
                pathname: '/bookingConfirm',
                state: { confirmAndPay: this.state.confirmAndPay, payAtShop: true }
            });
        };
        this.handleAddNotesApiId = (res) => {
            console.log(res);
        };
        this.handleReceiveCardsPaid = (res) => {
            var _a, _b, _c, _d, _e;
            this.setState({ loadingConfirmPAy: false });
            let { confirmAndpayData } = (_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state;
            localStorage.setItem('paid_sessionId', (_b = res === null || res === void 0 ? void 0 : res.transaction) === null || _b === void 0 ? void 0 : _b.session_id);
            localStorage.setItem('paid_appointmentId', confirmAndpayData === null || confirmAndpayData === void 0 ? void 0 : confirmAndpayData.id);
            localStorage.setItem('paid_BookingId', (_c = confirmAndpayData === null || confirmAndpayData === void 0 ? void 0 : confirmAndpayData.attributes) === null || _c === void 0 ? void 0 : _c.booking_id);
            localStorage.setItem('paid_BookingCode', (_e = (_d = confirmAndpayData === null || confirmAndpayData === void 0 ? void 0 : confirmAndpayData.attributes) === null || _d === void 0 ? void 0 : _d.booking_code) === null || _e === void 0 ? void 0 : _e.pin);
            window.location.href = res === null || res === void 0 ? void 0 : res.url;
        };
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            loading: false,
            showCards: false,
            checkBoxValue: '',
            payAtchopCheck: false,
            cardsArr: [],
            confirmAndPay: '',
            showText: false,
            openDebitCardModal: false,
            loadingConfirmPAy: false,
            loadingPaynowModal: false,
            notes: ''
        };
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    componentDidMount() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let isUserLoggedIn = localStorage.getItem("token") || "";
            let isCustomer = localStorage.getItem("userType") || "";
            if (!isUserLoggedIn || isCustomer != "customer") {
                localStorage.clear();
                window.location.replace("/LandingPage");
            }
            else {
                let { confirmAndpayData } = (_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state;
                this.setState({ confirmAndPay: confirmAndpayData });
                this.getCardlist();
            }
        });
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                if (apiRequestCallId === this.getCardApiCallId) {
                    this.handleReceiveCards(responseJson);
                }
                if (apiRequestCallId === this.payCardApiCallId) {
                    this.setState({ loadingPaynowModal: false });
                    this.handleReceiveCardsPaid(responseJson);
                }
                if (apiRequestCallId === this.PayatshopApiId) {
                    this.handlePayAtShop(responseJson);
                }
                if (apiRequestCallId === this.AddNotesApiId) {
                    this.handleAddNotesApiId(responseJson);
                }
            }
            else {
                runEngine.debugLog("GOIT");
            }
        });
    }
}
// Customizable Area End
