// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { homeImage, manageIcon, selectedHomeImage, imagebuttonManage, } from "../../../ContentModeration/src/assets";
class EmployeeHomeScreenSidebar extends BlockComponent {
    render() {
        const pathname = window.location.pathname;
        return (React.createElement("div", { className: "employee-home-screen-sidebar" },
            React.createElement("img", { src: pathname.includes("/EmployeeHomeScreen") || pathname.includes("EmployeeAppointmentsDetail")
                    ? selectedHomeImage
                    : homeImage, alt: "homeImage", className: "employee-home-screen-sidebar-image", onClick: () => this.props.history.push("/EmployeeHomeScreen") }),
            React.createElement("img", { src: pathname.includes("/ManageEmployee")
                    ? imagebuttonManage
                    : manageIcon, alt: "manageIcon", className: "employee-home-screen-sidebar-image", onClick: () => {
                    this.props.history.push("/ManageEmployee");
                } })));
    }
}
export default EmployeeHomeScreenSidebar;
// Customizable Area End
