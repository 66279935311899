// Customizable Area Start
import React from "react";
import AfterLoginHeader from "../../dashboard/src/AfterLoginHeader.web";
import "../assets/Styles/manage-business.css";
import BusinessHomeScreenSidebar from "./BusinessHomeScreenSidebar.web";
import { withRouter } from "react-router-dom";
import CalendarEvent from "./CalendarEvent.web";
import { Button } from "@material-ui/core";
import moment from "moment";
import { calendarHomeScreen, clipboardIcon, croos, walkInPencil, } from "./assets";
import BusinessHomeScreenWebController from "./BusinessHomeScreenWebController.web";
import SearchClientBusiness from "./SearchClientBusiness.web";
import CountryCodeModal from "../../social-media-account-login/src/CountryCodeModal.web";
import VerifyClientBusiness from "./VerifyClientBusiness.web";
import ClientDetailsBusiness from "./ClientDetailsBusiness.web";
import BookingConfirmedModal from "./BookingConfirmedModal.web";
import { withTranslation } from "react-i18next";
import { defultPng } from "../../Squeez_me_in/src/assets";
import Loader from "../../../components/src/Loader.web";
class BusinessHomeScreen extends BusinessHomeScreenWebController {
    render() {
        let businessName = localStorage.getItem("business_name") || this.state.businessName;
        //@ts-ignore
        let { t } = this.props;
        return (React.createElement(React.Fragment, null,
            React.createElement(Loader, { loading: this.state.isLoading }),
            React.createElement("div", { style: { backgroundColor: "#F2F2F2", overflow: "scroll" } },
                React.createElement(AfterLoginHeader, { navigation: this.props.navigation }),
                React.createElement("div", { style: { display: "flex" } },
                    React.createElement(BusinessHomeScreenSidebar, { history: this.props.history }),
                    React.createElement("div", { className: "business-home-screen-content-container" },
                        this.state.isActive === false && (React.createElement("div", { className: "reschedul-error-container", style: {
                                marginLeft: "50px",
                                marginTop: "50px",
                                backgroundColor: "#ff453a21",
                            } },
                            React.createElement("span", { style: { display: "flex", alignItems: "center" } },
                                React.createElement("img", { src: croos, className: "reschedul-error-img" }),
                                React.createElement("span", { className: "reschedul-error-text", style: { fontSize: "18px", padding: "10px" } }, t("Contact SqueezMeln to make further bookings"))))),
                        React.createElement("div", { className: "business-details-and-buttons-container" },
                            React.createElement("div", { className: "business-name-and-bookings-home-screen-sub-container" },
                                React.createElement("span", { className: "business-name-home-screen-container" },
                                    businessName,
                                    "\u00A0")),
                            React.createElement("div", { className: "calendar-and-buttons-home-screen-sub-container" },
                                React.createElement("span", { className: "business-home-screen-date-container", onClick: () => this.handleNavigation("BusinessAppointmentsDetail") },
                                    React.createElement("img", { src: calendarHomeScreen, alt: "icon" }),
                                    "\u00A0 \u00A0",
                                    moment().format("dddd, MMMM DD YYYY")),
                                React.createElement(Button, { className: "upcoming-bookings-button-style-home-screen", onClick: () => this.handleNavigation("ManageBusiness/ManageBookings", {
                                        from: "listing",
                                    }) },
                                    React.createElement("img", { src: clipboardIcon, alt: "clipboardIcon" }),
                                    "\u00A0 ",
                                    t("Upcoming Bookings")),
                                React.createElement(Button, { className: this.state.isBusinessDayOff || !this.state.isActive
                                        ? "disable-walk-in-button-style-home-screen"
                                        : "walk-in-button-style-home-screen", onClick: this.handleOpenSearchClientModal, disabled: this.state.isBusinessDayOff || !this.state.isActive },
                                    React.createElement("img", { src: walkInPencil, alt: "walkInPencil" }),
                                    " \u00A0",
                                    t("Walk-in")))),
                        React.createElement("div", { className: "all-employees-list-and-bookings-container" },
                            React.createElement("div", { id: "all-booking", className: this.state.selectedEmployee === null
                                    ? "all-booking-business-home-screen-text-container-selected"
                                    : "all-booking-business-home-screen-text-container", onClick: () => this.handleEmployeeClick(null) },
                                t("All"),
                                " ",
                                React.createElement("br", null),
                                " ",
                                t("Bookings")),
                            this.state.teamMembersList.map((employee) => {
                                return (React.createElement("div", { className: this.state.selectedEmployee ===
                                        employee.attributes.employee_account_id
                                        ? "employee-on-home-screen-for-booking-text-container-selected"
                                        : "employee-on-home-screen-for-booking-text-container", key: employee.id, onClick: () => this.handleEmployeeClick(employee.attributes.employee_account_id) },
                                    React.createElement("img", { src: employee.attributes.team_member_profile || defultPng }),
                                    React.createElement("div", { className: "employee-name-in-calender-home-screen" },
                                        employee.attributes.first_name,
                                        React.createElement("br", null),
                                        employee.attributes.last_name)));
                            })),
                        React.createElement(CalendarEvent
                        //@ts-ignore
                        , { 
                            //@ts-ignore
                            data: this.state.calendarData, screen: "home", weekend: this.state.isBusinessDayOff }),
                        this.state.openCountryCodeModal && (React.createElement(CountryCodeModal, { open: this.state.openCountryCodeModal, onSelect: this.handleCountryCodeChange })),
                        this.state.openSearchClientDialog && (React.createElement(SearchClientBusiness, { openDialog: this.state.openSearchClientDialog, handleChange: this.handleChange, onClick: this.handleClick, onClose: this.handleCloseSearchClientModal, responseMsg: this.state.responseMsg, userExistsMsg: this.state.userExistsMsg, userExists: this.state.userExists, openCountryCodeModal: this.openCountryCodeModal, countryCode: this.state.selectedCountryCode, numberUserAsProvideOrEmployee: this.state.numberUserAsProvideOrEmployee, userDoesNotExist: this.state.userDoesNotExist, mobileNumberError: this.state.mobileNumberError, mobileNumber: this.state.clientMobileNumber })),
                        this.state.openVerifyDialog && (React.createElement(VerifyClientBusiness, { countryCode: this.state.selectedCountryCode, state: this.state, clientMobileNumber: this.state.clientMobileNumber, onClose: this.handleCloseVerifyClientModal, onResend: this.resendOtp, handleEnableResend: this.handleEnableResend, handleOtpChange: this.handleOtpChange, openDialog: this.state.openVerifyDialog, otpError: this.state.otpError, verifyOtp: this.verifyOtp, isResendButtonEnabled: this.state.isResendButtonEnabled, otp: this.state.otp, otpExpiredError: this.state.otpExpiredError })),
                        this.state.verifyClientDetailModal && (React.createElement(ClientDetailsBusiness, { openDialog: this.state.verifyClientDetailModal, userExists: this.state.userExists, state: this.state, onClose: this.handleCloseVerifyClientDetailsModal, handleChange: this.handleClientDetailsChange, openCountryCodeModal: this.openCountryCodeModal, countryCode: this.state.selectedCountryCode, onClick: this.handleContinueClientDetails })),
                        this.state.openAssignedDialog && (React.createElement(BookingConfirmedModal, { openDialog: this.state.openAssignedDialog, continueFunction: this.handleOpenVerifyModal, backToHomeFunction: this.handleHomeScreenNavigation, infoMsg: `${this.state.firstName} ${this.state.lastName} ${t("is successfully added as your new client.")}`, isBookingCompleted: false, closeDialog: this.closeBookingConfirmedModal })))))));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(BusinessHomeScreen));
// Customizable Area End
