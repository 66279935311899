var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
export default class SubscriptionBusinessController extends BlockComponent {
    constructor(props) {
        super(props);
        this.handleReceiveCardsPaid = (responseJson) => {
            if ((responseJson === null || responseJson === void 0 ? void 0 : responseJson.message) === 'payment is paid') {
                this.setState({ paymentFailed: false, paymentStatusLoader: false });
            }
            else {
                this.setState({ paymentFailed: true, paymentStatusLoader: false });
            }
        };
        this.handleAddAnotherCardsApi = (responseJson) => {
            if (responseJson) {
                localStorage.setItem("subscription_payment_id", responseJson.transaction.session_id);
                window.location.replace(responseJson.url);
                this.setState({ addAnotherCard: responseJson });
            }
        };
        this.handlePayCardsApi = (responseJson) => {
            if (responseJson) {
                this.setState({ payCardRes: responseJson });
                localStorage.setItem("subscription_payment_id", responseJson.transaction.session_id);
                window.location.replace(responseJson.url);
            }
        };
        this.handleNavigate = () => {
            //@ts-ignore
            this.props.history.push("/BusinessProfile");
        };
        this.handleReceiveCards = (res) => {
            var _a;
            if ((res === null || res === void 0 ? void 0 : res.errors) && ((_a = res === null || res === void 0 ? void 0 : res.errors[0]) === null || _a === void 0 ? void 0 : _a.message) === 'Payment method not found') {
                this.setState({ cardsArr: [] });
            }
            else {
                this.setState({ cardsArr: res === null || res === void 0 ? void 0 : res.data });
            }
        };
        this.closeCancelModal = () => {
            this.setState({ cancelModal: false, cancelSubscription: true });
            this.getSubcriptionApi();
        };
        this.closeModal = () => {
            this.setState({ subscriptionModal: false });
        };
        this.paymantcloseModal = () => {
            this.setState({ paymantModel: false });
        };
        this.openCancelModal = () => {
            this.setState({ cancelModal: true });
        };
        this.openSubscriptionModal = () => {
            this.setState({ subscriptionModal: true });
        };
        this.addAnotherCard = () => {
            this.addAnotherCardApi();
        };
        this.checkPaymentStatus = () => {
            this.setState({ paymentStatusLoader: true });
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.checkPaymentStatusId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.checkPaymentStatusEndPoint + `&session_id=${localStorage.getItem("subscription_payment_id")}&id=${localStorage.getItem("subscription_id")}?type=subscription`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), 'GET');
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.payByCard = (subscriptionId) => {
            var _a;
            this.setState({ loadingPaynowModal: true });
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.payCardApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.payBycardurl + subscriptionId + '&card_id=' + ((_a = this.state.checkBoxValue) === null || _a === void 0 ? void 0 : _a.id) + "&type=subscription");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), 'GET');
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.getCardlist = () => {
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getCardApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getCardsEndUrl);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), 'GET');
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.addAnotherCardApi = () => {
            var _a;
            this.setState({ addAnothercardModal: true });
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const id = (_a = this.state.subscriptionDetails.attributes) === null || _a === void 0 ? void 0 : _a.user_subscribed.id;
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.AddCardApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.addCardApiEndPoint + `${id}?type=subscription`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), 'GET');
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.cancelSubcriptionApi = () => {
            const header = {
                "Content-Type": "application/json",
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.cancelSavedCardsListCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.cancelSubscriptionEndPoint);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.getSubcriptionApi = () => {
            const header = {
                "Content-Type": "application/json",
                token: localStorage.getItem("token") || "",
            };
            const requestApiMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getSavedCardsListCallId = requestApiMessage.messageId;
            requestApiMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_custom_user_subs/user_subscriptions");
            requestApiMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestApiMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            runEngine.sendMessage(requestApiMessage.id, requestApiMessage);
            return true;
        };
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            loading: false,
            cancelModal: false,
            subscriptionDetails: {},
            subscriptionModal: false,
            cancelSubscription: false,
            cardsArr: [],
            showCards: true,
            checkBoxValue: '',
            addAnotherCard: null,
            loadingConfirmPAy: false,
            openDebitCardModal: false,
            loadingPaynowModal: false,
            paymantModel: false,
            subId: '',
            payCardRes: {},
            checkStatus: false,
            paymentFailed: true,
            paymentStatusLoader: false,
            addAnothercardModal: false,
            paymentPrice: ""
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    componentDidMount() {
        const _super = Object.create(null, {
            componentDidMount: { get: () => super.componentDidMount }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.componentDidMount.call(this);
            if (window.location.pathname == '/SubscriptionPaymentSuccessfull') {
                this.checkPaymentStatus();
            }
            let isUserLoggedIn = localStorage.getItem("token") || "";
            let userType = localStorage.getItem("userType") || "";
            if (userType != "business" || !isUserLoggedIn) {
                localStorage.clear();
                window.location.replace("/LandingPage");
            }
            else {
                //istanbul ignore next
                this.getSubcriptionApi();
                this.getCardlist();
            }
        });
    }
    //istanbul ignore next
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                if (apiRequestCallId === this.getSavedCardsListCallId && responseJson) {
                    let subscriptionObj = responseJson.data.find((item) => item.attributes.user_subscribed);
                    let paidObj = responseJson.data.find((item) => item.attributes.price !== 0.0);
                    localStorage.setItem("subscription_id", subscriptionObj === null || subscriptionObj === void 0 ? void 0 : subscriptionObj.attributes.user_subscribed.id);
                    this.setState({ subscriptionDetails: subscriptionObj });
                    this.setState({ paymentPrice: paidObj.attributes.price });
                }
                if (apiRequestCallId === this.cancelSavedCardsListCallId && responseJson.message) {
                    this.closeCancelModal();
                }
                if (apiRequestCallId === this.getCardApiCallId) {
                    this.handleReceiveCards(responseJson);
                }
                if (apiRequestCallId === this.AddCardApiCallId) {
                    this.handleAddAnotherCardsApi(responseJson);
                }
                if (apiRequestCallId === this.payCardApiCallId) {
                    this.handlePayCardsApi(responseJson);
                }
                if (apiRequestCallId === this.checkPaymentStatusId) {
                    this.handleReceiveCardsPaid(responseJson);
                }
            }
            else {
                runEngine.debugLog("GOIT");
            }
        });
    }
}
// Customizable Area End
