import React from "react";
import MyBookingController from "../../../blocks/categoriessubcategories/src/MyBookingController.web";
import { green_check } from "./assets";
import "../assets/style/BookingConfirm.web.scss";
import { withRouter } from "react-router-dom";
import HeaderWeb from "./Header.web";
import { Button } from "@material-ui/core";
import { withTranslation } from "react-i18next";
class BookingRescheduled extends MyBookingController {
    render() {
        var _a, _b, _c, _d, _e, _f;
        let { t } = this.props;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "booking-main-div" },
                React.createElement(HeaderWeb, null),
                React.createElement("div", { className: "booking-confirm-detail-div" },
                    React.createElement("div", { className: "booking-confirm-list-div" },
                        React.createElement("div", { className: "img-div" },
                            React.createElement("img", { src: green_check, alt: "" })),
                        React.createElement("h3", null, ((_b = (_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.cancel) ? t("Booking Cancelled!")
                            : t("Booking rescheduled!")),
                        React.createElement("p", { style: {
                                paddingLeft: "14px",
                                paddingRight: "14px",
                                marginBottom: "100px",
                            } }, ((_d = (_c = this.props.location) === null || _c === void 0 ? void 0 : _c.state) === null || _d === void 0 ? void 0 : _d.cancel) ? t("Your appointment has been cancelled.")
                            : t("Your appointment has been rescheduled.")),
                        ((_f = (_e = this.props.location) === null || _e === void 0 ? void 0 : _e.state) === null || _f === void 0 ? void 0 : _f.cancel) ? (React.createElement("p", { style: {
                                marginTop: "-84px",
                                marginBottom: "30px",
                                padding: "10px 90px 10px 95px",
                            } }, t("Your refund will be processed in 7-14 days."))) : (""),
                        React.createElement("div", null,
                            React.createElement(Button, { className: "book-now-btn-booking-confirmed", onClick: () => {
                                    this.props.history.push({
                                        pathname: "/MyBookings",
                                    });
                                } }, t("Go to bookings"))))))));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(BookingRescheduled));
