var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
// Customizable Area Start
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
export default class ManageBlockMyTimeController extends BlockComponent {
    constructor(props) {
        super(props);
        this.handleModeChange = () => {
            this.setState({ viewMode: false });
        };
        this.getTeamMembers = () => __awaiter(this, void 0, void 0, function* () {
            console.log("getTeamMembers");
            this.setState({ loading: true });
            const token = yield window.localStorage.getItem("token");
            const header = {
                "Content-Type": "application/json",
                token,
            };
            this.apiCall({
                setApiCallId: "getTeamMemberId",
                header,
                method: "GET",
                endPoint: `account_block/team_members`,
            });
        });
        //istanbul ignore next
        this.handaleBackArrowButton = () => {
            switch (this.state.tabName) {
                case "list":
                    this.props.history.push("/ManageBusiness/ManageWorkingHours");
                    break;
                case "none":
                    this.setState({ tabName: "list" });
                    break;
                case "addBlockTime":
                case "editBlockTime":
                    this.setState({ tabName: "none" });
                    break;
                default:
                    break;
            }
        };
        this.getHeaderText = () => {
            //@ts-ignore
            let { t } = this.props;
            if (this.state.tabName === "list") {
                return t("Block employee days & time");
            }
            else {
                return `${this.state.employeeName} ${t("block days & time")}`;
            }
        };
        this.getEmployeeBlockTimeList = (item) => {
            console.log("getEmployeeBlockTimeList :", item, item.attributes.id);
            this.setState({
                employeeName: `${item.attributes.first_name} ${item.attributes.last_name}`,
                team_member_id: item.id,
                tabName: "none",
                loading: true,
            }, () => {
                this.getBlockTime();
            });
        };
        this.handleSearch = (value) => {
            if (!value) {
                this.setState({ employeeList: this.state.employeeData });
            }
            else if (Array.isArray(this.state.employeeList)) {
                // Add this check
                const newServices = this.state.employeeList.filter((item) => {
                    console.log("employee data :", item, item.attributes.first_name, value);
                    return (`${item.attributes.email_address}`
                        .toLowerCase()
                        .includes(value.toLowerCase()) ||
                        `${item.attributes.first_name} ${item.attributes.last_name}`
                            .replace(/\s{2,}/g, " ")
                            .toLowerCase()
                            .includes(value.toLowerCase()));
                });
                this.setState({ searchTerm: value, employeeList: newServices });
            }
            return this.state.employeeList;
        };
        this.handleEditBlockTime = (id) => {
            const blockObject = this.state.blockData.find((obj) => {
                return Number(obj.id) === id; // convert obj.id to a number using Number()
            });
            if (blockObject) {
                this.setState({
                    tabName: "editBlockTime",
                    selectBlockId: id,
                    viewMode: true,
                    startDate: blockObject.attributes.from_date,
                    endDate: blockObject.attributes.to_date,
                    startTime: blockObject.attributes.start_time,
                    endTime: blockObject.attributes.end_time,
                    desc: blockObject.attributes.description,
                }, () => {
                    this.onWeekendFromSelection(this.state.startTime.toLowerCase());
                });
            }
        };
        this.getDate = () => {
            if (this.state.modaltype === "startDate") {
                return this.state.startDate;
            }
            else {
                return this.state.endDate;
            }
        };
        this.onDateChange = (value, type) => {
            const formattedDate = moment(value).format("DD MMM YYYY");
            console.log("onDateChange:", value, type);
            switch (type) {
                case "start":
                    this.setState({
                        startDate: formattedDate,
                        DateError: value ? "" : "Please choose your date",
                    });
                    break;
                case "end":
                    this.setState({
                        endDate: formattedDate,
                        endDateError: value ? "" : "Please choose your date",
                    });
                    break;
                case "startTime":
                    this.setState({
                        TimeError: value ? "" : "Please choose your time",
                    });
                    break;
                case "endTime":
                    this.setState({
                        endTimeError: value ? "" : "Please choose your time",
                    });
                    break;
                case "desc":
                    this.setState({
                        descError: value ? "" : "Please enter the description",
                    });
                    break;
                default:
                    break;
            }
        };
        this.renderErrorMessageforBlock = (Message) => {
            const errorMessage = Message ? Message : null;
            return errorMessage;
        };
        this.optionDisable = (value) => {
            return value === "" ? true : false;
        };
        this.showTimeInUppercase = (value) => {
            return value.toUpperCase();
        };
        this.handaleCancel = () => {
            this.setState({
                tabName: "none",
                DateError: "",
                endDateError: "",
                descError: "",
                TimeError: "",
                endTimeError: "",
                startDate: "",
                endDate: "",
                startTime: "",
                endTime: "",
                desc: "",
                timDataEnd: [],
            });
        };
        this.handaleDone = () => {
            if (this.state.startDate === "" && this.state.modaltype === "startDate") {
                this.setState({ selectDateError: "Please Select date" });
            }
            else if (this.state.endDate === "" &&
                this.state.modaltype === "endDate") {
                this.setState({ selectDateError: "Please Select date" });
            }
            else {
                this.setState({ openCalenderDialog: false, selectDateError: "" });
            }
        };
        this.onWeekendFromSelection = (selectedItem) => {
            this.setState({
                isendTimeDisable: false,
                startTime: selectedItem,
                TimeError: "",
            });
            let Ind;
            this.state.timDataStart.forEach((item, index) => {
                if (item === selectedItem) {
                    Ind = index;
                    let fromIndex = this.state.timDataStart.filter((item, index) => {
                        if (index > Ind) {
                            return item;
                        }
                    });
                    this.setState({ timDataEnd: fromIndex });
                    let lastIndex = this.state.timDataStart.length - 1;
                    if (lastIndex === Ind) {
                        let toTime = this.state.timDataStart.filter((item, index) => {
                            if (index !== Ind) {
                                return item;
                            }
                        });
                        this.setState({ timDataEnd: toTime });
                    }
                }
            });
        };
        this.submitData = (action) => {
            const { startDate, endDate, endTime, startTime, desc } = this.state;
            const errors = {};
            const setError = (state, field, errorMessage) => {
                if (!state) {
                    //@ts-ignore
                    errors[field] = errorMessage;
                }
            };
            setError(startDate, "DateError", "Please choose your date");
            setError(endDate, "endDateError", "Please choose your date");
            setError(startTime, "TimeError", "Please choose your time");
            setError(endTime, "endTimeError", "Please choose your time");
            setError(desc, "descError", "Please enter the description");
            this.setState(errors, () => {
                const { DateError, TimeError, descError, endDateError, endTimeError } = this.state;
                if (!DateError &&
                    !TimeError &&
                    !descError &&
                    !endDateError &&
                    !endTimeError) {
                    console.log("submitData ::");
                    if (action === "add") {
                        this.addBlockTimeData();
                    }
                    if (action === "edit") {
                        this.editBlockTimeDataddData();
                    }
                    if (action === "delete") {
                        this.deleteBlockTime();
                    }
                }
            });
        };
        this.deleteBlockTime = () => __awaiter(this, void 0, void 0, function* () {
            this.setState({ loading: true });
            const token = yield window.localStorage.getItem("token");
            const header = {
                "Content-Type": "application/json",
                token,
            };
            this.apiCall({
                setApiCallId: "deleteBlockId",
                header,
                method: "DELETE",
                endPoint: `account_block/block_employee_time/${this.state.selectBlockId}`,
            });
        });
        this.addBlockTimeData = () => __awaiter(this, void 0, void 0, function* () {
            this.setState({ loading: true });
            const bodyData = {
                data: {
                    start_time: this.state.startTime,
                    end_time: this.state.endTime,
                    description: this.state.desc,
                    team_member_id: this.state.team_member_id,
                    from_date: this.state.startDate,
                    to_date: this.state.endDate,
                },
            };
            console.log("addBlockTimeData :", bodyData, this.state.desc);
            const token = yield window.localStorage.getItem("token");
            const header = {
                "Content-Type": "application/json",
                token,
            };
            this.apiCall({
                setApiCallId: "addBlockId",
                header,
                method: "POST",
                endPoint: `account_block/block_employee_time`,
                body: JSON.stringify(bodyData),
            });
        });
        //istanbul ignore next
        this.editBlockTimeDataddData = () => __awaiter(this, void 0, void 0, function* () {
            this.setState({ loading: true });
            const bodyData = {
                data: {
                    start_time: this.state.startTime,
                    end_time: this.state.endTime,
                    description: this.state.desc,
                    from_date: this.state.startDate,
                    to_date: this.state.endDate,
                    team_member_id: this.state.team_member_id,
                },
            };
            const token = yield window.localStorage.getItem("token");
            const header = {
                "Content-Type": "application/json",
                token,
            };
            this.apiCall({
                setApiCallId: "updateId",
                header,
                method: "PUT",
                endPoint: `account_block/block_employee_time/${this.state.selectBlockId}`,
                body: JSON.stringify(bodyData),
            });
        });
        this.getBlockTime = () => __awaiter(this, void 0, void 0, function* () {
            console.log("getBlockTime", this.state.team_member_id);
            const token = yield window.localStorage.getItem("token");
            const header = {
                "Content-Type": "application/json",
                token,
            };
            this.apiCall({
                setApiCallId: "getBlockId",
                header,
                method: "GET",
                endPoint: `account_block/block_employee_time?team_member_id=${this.state.team_member_id}`,
            });
        });
        this.apiCall = (data) => __awaiter(this, void 0, void 0, function* () {
            const { setApiCallId, header, endPoint, method, body } = data;
            console.log("calling api================================", setApiCallId, endPoint, body, header, method);
            console.log("===========================api call debig================");
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            //istanbul ignore next
            if (setApiCallId === "getBlockId") {
                this.getBlockId = requestMessage.messageId;
            }
            else if (setApiCallId === "addBlockId") {
                this.addBlockId = requestMessage.messageId;
            }
            else if (setApiCallId === "updateId") {
                this.updateId = requestMessage.messageId;
            }
            else if (setApiCallId === "deleteBlockId") {
                this.deleteBlockId = requestMessage.messageId;
            }
            else if (setApiCallId === "getTeamMemberId") {
                this.getTeamMemberId = requestMessage.messageId;
            }
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
            body &&
                requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
            runEngine.sendMessage(requestMessage.id, requestMessage);
        });
        //istanbul ignore next
        this.getOptionText = (slot) => {
            //@ts-ignore
            let { t } = this.props;
            let [time, amPm] = slot.split(" ");
            return `${time} ${t(amPm.toUpperCase())}`;
        };
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            selectDateError: "",
            viewMode: false,
            searchTerm: "",
            employeeName: "",
            employeeData: [],
            employeeList: [],
            selectBlockId: "",
            isendTimeDisable: true,
            team_member_id: "",
            deleteModal: false,
            blockData: [],
            tabName: "list",
            alertDialogOpen: false,
            openCalenderDialog: false,
            isModal: false,
            modaltype: "start",
            startDate: "",
            startDateError: "",
            endDate: "",
            endDateError: "",
            startTime: "",
            startTimeError: "",
            endTime: "",
            endTimeError: "",
            timDataEnd: [],
            desc: "",
            descError: "",
            DateError: "",
            TimeError: "",
            loading: false,
            timDataStart: [
                "12:00 am",
                "12:30 am",
                "1:00 am",
                "1:30 am",
                "2:00 am",
                "2:30 am",
                "3:00 am",
                "3:30 am",
                "4:00 am",
                "4:30 am",
                "5:00 am",
                "5:30 am",
                "6:00 am",
                "6:30 am",
                "7:00 am",
                "7:30 am",
                "8:00 am",
                "8:30 am",
                "9:00 am",
                "9:30 am",
                "10:00 am",
                "10:30 am",
                "11:00 am",
                "11:30 am",
                "12:00 pm",
                "12:30 pm",
                "1:00 pm",
                "1:30 pm",
                "2:00 pm",
                "2:30 pm",
                "3:00 pm",
                "3:30 pm",
                "4:00 pm",
                "4:30 pm",
                "5:00 pm",
                "5:30 pm",
                "6:00 pm",
                "6:30 pm",
                "7:00 pm",
                "7:30 pm",
                "8:00 pm",
                "8:30 pm",
                "9:00 pm",
                "9:30 pm",
                "10:00 pm",
                "10:30 pm",
                "11:00 pm",
                "11:30 pm",
            ],
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    receive(from, message) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            // Customizable Area Start
            runEngine.debugLog("Message Recived", message);
            if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                this.setState({ loading: false });
                //instanbul ignore next
                if (apiRequestCallId === this.getBlockId) {
                    console.log("getBlock data:0", responseJson);
                    if (responseJson.errors !== null && responseJson.errors) {
                        this.setState({ loading: false });
                        // this.handleError(responseJson.errors)
                    }
                    if (responseJson.message !== null && responseJson.message) {
                        this.setState({ blockData: [] });
                        this.setState({ loading: false });
                        // customAlert("Error", JSON.stringify(responseJson.message))
                    }
                    else {
                        console.log("getBlock data:1", responseJson, responseJson.data);
                        this.setState({ blockData: responseJson.data });
                        this.setState({ loading: false });
                    }
                }
                //istanbul ignore next
                else if (apiRequestCallId == this.deleteBlockId) {
                    if (responseJson.errors !== null && responseJson.errors) {
                        // this.handleError(responseJson.errors)
                    }
                    else if (responseJson.message !== null && responseJson.message) {
                        // customAlert("Error", JSON.stringify(responseJson.message))
                        this.setState({
                            startDate: "",
                            endDate: "",
                            startTime: "",
                            endTime: "",
                            desc: "",
                            tabName: "none",
                            alertDialogOpen: false,
                        });
                        this.getBlockTime();
                    }
                    else if (responseJson.data !== null && responseJson.data) {
                        console.log("@@@@======", responseJson);
                        this.setState({
                            startDate: "",
                            endDate: "",
                            startTime: "",
                            endTime: "",
                            desc: "",
                            tabName: "none",
                            alertDialogOpen: false,
                        });
                    }
                }
                else if (apiRequestCallId === this.addBlockId) {
                    if (responseJson.errors !== null && responseJson.errors) {
                        this.setState({ loading: false });
                    }
                    if ((responseJson === null || responseJson === void 0 ? void 0 : responseJson.message) && ((_a = responseJson === null || responseJson === void 0 ? void 0 : responseJson.message) === null || _a === void 0 ? void 0 : _a.base.length)) {
                        toast.error(this.props.t((_b = responseJson === null || responseJson === void 0 ? void 0 : responseJson.message) === null || _b === void 0 ? void 0 : _b.base[0]), {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            style: {
                                direction: "inherit"
                            }
                        });
                    }
                    else {
                        console.log(responseJson);
                        this.setState({ loading: false });
                        this.setState({
                            startDate: "",
                            endDate: "",
                            startTime: "",
                            endTime: "",
                            desc: "",
                            tabName: "none",
                        });
                        this.getBlockTime();
                    }
                }
                else if (apiRequestCallId === this.updateId) {
                    if (responseJson.errors !== null && responseJson.errors) {
                        this.setState({ loading: false });
                    }
                    if ((responseJson === null || responseJson === void 0 ? void 0 : responseJson.message) && ((_c = responseJson === null || responseJson === void 0 ? void 0 : responseJson.message) === null || _c === void 0 ? void 0 : _c.base.length)) {
                        toast.error(this.props.t((_d = responseJson === null || responseJson === void 0 ? void 0 : responseJson.message) === null || _d === void 0 ? void 0 : _d.base[0]), {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            style: {
                                direction: "inherit"
                            }
                        });
                    }
                    else {
                        console.log(responseJson);
                        this.setState({ loading: false, viewMode: true });
                        this.setState({
                            startDate: "",
                            endDate: "",
                            startTime: "",
                            endTime: "",
                            desc: "",
                            tabName: "none",
                        });
                        this.getBlockTime();
                    }
                }
                else if (apiRequestCallId === this.getTeamMemberId) {
                    /* istanbul ignore next */
                    if (responseJson.errors !== null && responseJson.errors) {
                        console.log("employeeData :1", responseJson.errors);
                        this.setState({ loading: false });
                        // handleError(responseJson.errors)
                    }
                    else {
                        console.log("employeeData :2", responseJson);
                        this.setState({
                            employeeData: responseJson.data,
                            employeeList: responseJson.data,
                        });
                        this.setState({ loading: false });
                    }
                }
            }
        });
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            let user = localStorage.getItem("userType") || "";
            let token = localStorage.getItem("token") || "";
            if (user != "business" || !token) {
                localStorage.clear();
                window.location.replace("/LandingPage");
            }
            else {
                this.getTeamMembers();
            }
        });
    }
    calculateMinDate(modalType, startDate, currentDate) {
        if (modalType === "endDate") {
            if (!startDate) {
                return currentDate;
            }
            else {
                return startDate;
            }
        }
        else {
            return currentDate;
        }
    }
}
