var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import moment from "moment";
export const configJSON = require("./config");
export default class appointmentController extends BlockComponent {
    constructor(props) {
        super(props);
        this.changeDate = (event) => {
            var _a, _b, _c;
            this.setState({ availableTime: [] });
            let { shopSubResponse } = (_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state;
            this.setState({ dateState: event, dateRequie: false });
            this.checkSlots((_b = shopSubResponse === null || shopSubResponse === void 0 ? void 0 : shopSubResponse.attributes) === null || _b === void 0 ? void 0 : _b.open_from, (_c = shopSubResponse === null || shopSubResponse === void 0 ? void 0 : shopSubResponse.attributes) === null || _c === void 0 ? void 0 : _c.close_at, event);
        };
        this.disableDays = (data) => {
            var _a, _b;
            let daysArr = ["Thursday", "Sunday", "Monday", "Tuesday", "Wednesday", "Friday", "Saturday"];
            let days = [];
            let weeeknds = (_a = data === null || data === void 0 ? void 0 : data.weekends) === null || _a === void 0 ? void 0 : _a.map((ele) => ele.toLowerCase());
            let working_days = (_b = data === null || data === void 0 ? void 0 : data.working_days) === null || _b === void 0 ? void 0 : _b.map((ele) => ele.toLowerCase());
            daysArr.forEach((day) => {
                if (!(weeeknds === null || weeeknds === void 0 ? void 0 : weeeknds.includes(day.toLowerCase())) && !(working_days === null || working_days === void 0 ? void 0 : working_days.includes(day.toLowerCase()))) {
                    if (day == "Thursday" && !days.includes(4)) {
                        days.push(4);
                    }
                    else if (day == "Sunday" && !days.includes(0)) {
                        days.push(0);
                    }
                    else if (day == "Monday" && !days.includes(1)) {
                        days.push(1);
                    }
                    else if (day == "Tuesday" && !days.includes(2)) {
                        days.push(2);
                    }
                    else if (day == "Wednesday" && !days.includes(3)) {
                        days.push(3);
                    }
                    else if (day == "Friday" && !days.includes(5)) {
                        days.push(5);
                    }
                    else if (day == "Saturday" && !days.includes(6)) {
                        days.push(6);
                    }
                }
            });
            this.setState({ disabledDays: days });
        };
        this.checkSlots = (openFrom, closeAt, currentdat) => {
            var open = moment(currentdat).format('MM/DD/YYYY') + ' ' + openFrom;
            var close = moment(currentdat).format('MM/DD/YYYY') + ' ' + closeAt;
            var openDate = new Date(open).getTime();
            var closeDate = new Date(close).getTime();
            let str = 'am';
            console.log(closeAt.includes(str.toUpperCase()), openDate, closeDate, closeAt);
            if (closeAt.includes(str.toUpperCase()) && openDate > closeDate) {
                close = moment(currentdat).add(1, 'days').format('MM/DD/YYYY') + ' ' + closeAt;
                closeDate = new Date(close).getTime();
            }
            let workingSlot = [
                "12:00 AM",
                "12:30 AM",
                "01:00 AM",
                "01:30 AM",
                "02:00 AM",
                "02:30 AM",
                "03:00 AM",
                "03:30 AM",
                "04:00 AM",
                "04:30 AM",
                "05:00 AM",
                "05:30 AM",
                "06:00 AM",
                "06:30 AM",
                "07:00 AM",
                "07:30 AM",
                "08:00 AM",
                "08:30 AM",
                "09:00 AM",
                "09:30 AM",
                "10:00 AM",
                "10:30 AM",
                "11:00 AM",
                "11:30 AM",
                "12:00 PM",
                "12:30 PM",
                "01:00 PM",
                "01:30 PM",
                "02:00 PM",
                "02:30 PM",
                "03:00 PM",
                "03:30 PM",
                "04:00 PM",
                "04:30 PM",
                "05:00 PM",
                "05:30 PM",
                "06:00 PM",
                "06:30 PM",
                "07:00 PM",
                "07:30 PM",
                "08:00 PM",
                "08:30 PM",
                "09:00 PM",
                "09:30 PM",
                "10:00 PM",
                "10:30 PM",
                "11:00 PM",
                "11:30 PM",
            ];
            let availableSlot = [];
            workingSlot.forEach((ele) => {
                let element = moment(currentdat).format('MM/DD/YYYY') + ' ' + ele;
                let dta = new Date(element).getTime();
                let elementcurrent = moment(currentdat).format('MM/DD/YYYY') + ' ' + moment(currentdat).format('hh:mm A');
                let currentData = new Date(elementcurrent).getTime();
                if (moment(currentdat).format('LL') == moment(new Date()).format('LL')) {
                    if (currentData <= dta && dta < closeDate) {
                        availableSlot.push(ele);
                    }
                }
                else {
                    if (openDate <= dta && dta < closeDate) {
                        availableSlot.push(ele);
                    }
                }
            });
            this.getAvailableSlots(availableSlot, currentdat);
        };
        this.getAvailableSlots = (availableSlot, currentdat) => {
            var _a;
            let { staffId } = (_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state;
            this.setState({
                loading: true
            });
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const httpBody = {
                "service_id": staffId,
                "date": moment(currentdat).format('YYYY-MM-DD'),
                "start_times": availableSlot
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getAvailablestaffDetailId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.endpointAvailablestaffslot);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.startTimer = () => {
            if (this.timer == 0 && this.state.seconds > 0) {
                this.timer = setInterval(this.countDown, 1000);
            }
        };
        this.countDown = () => {
            // Remove one second, set state so a re-render happens.
            let seconds = this.state.seconds - 1;
            this.setState({
                time: this.secondsToTime(seconds),
                seconds: seconds,
            });
            // Check if we're at zero.
            if (seconds == 0) {
                this.setState({ openBookNowModal: true });
                clearInterval(this.timer);
            }
        };
        this.closeBookNowModal = () => {
            this.setState({ openBookNowModal: false });
        };
        this.getStaffDetails = () => {
            var _a;
            this.setState({ openLoading: true });
            let { staffId } = (_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state;
            let dat = moment(this.state.dateState).format('YYYY-MM-DD');
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getStaffApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.endpointStaff + 'service_id=' + staffId + '&date=' + dat + '&start_time=' + this.state.timeslotValue);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getHttpRequest);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.getShopesHolidayDetails = (subid) => {
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getShopSubApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.businessHoliday + subid);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getHttpRequest);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.handleReceiveShopeSubRes = (response) => {
            if (response.length) {
                let leaves = [];
                response === null || response === void 0 ? void 0 : response.forEach((elem) => {
                    leaves.push(elem.date);
                });
                this.setState({ holidays: leaves });
            }
        };
        this.handleReceiveStaffRes = (res) => {
            var _a, _b, _c, _d, _e, _f;
            this.setState({ openLoading: false });
            if (((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.length) == 0) {
                this.setState({ noStaff: true });
            }
            else if (((_b = res === null || res === void 0 ? void 0 : res.data) === null || _b === void 0 ? void 0 : _b.length) != 0) {
                this.setState({ noStaff: false });
                if ((_d = (_c = this.props.location) === null || _c === void 0 ? void 0 : _c.state) === null || _d === void 0 ? void 0 : _d.reschedule) {
                    let { serviceId, shopSubResponse, subServiceid, name, reschedule, appointmentId } = (_e = this.props.location) === null || _e === void 0 ? void 0 : _e.state;
                    this.props.history.push({
                        pathname: '/customer/service/booking/availableSlot',
                        state: { subServiceid, serviceId, shopSubResponse, name: name, StaffData: res === null || res === void 0 ? void 0 : res.data, Date: this.state.dateState, reschedule, appointmentId }
                    });
                }
                else {
                    let { serviceId, shopSubResponse, subServiceid, name, categoryId, gender, currentCategory, staffId } = (_f = this.props.location) === null || _f === void 0 ? void 0 : _f.state;
                    this.props.history.push({
                        pathname: '/customer/service/booking/availableSlot',
                        state: { subServiceid, serviceId, shopSubResponse, name: name, currentCategory, gender, categoryId, staffId, StaffData: res === null || res === void 0 ? void 0 : res.data, Date: this.state.dateState }
                    });
                }
            }
        };
        this.handleReceiveStaffSlotsdetailsRes = (res) => {
            this.setState({ availableTime: res, loading: false });
        };
        //istanbul ignore next
        this.timeSlotValueView = () => {
            let { t } = this.props;
            if (this.state.timeslotValue === "Select your slot") {
                return t(this.state.timeslotValue);
            }
            else {
                let [time, amPm] = this.state.timeslotValue.split(" ");
                return `${time} ${t(amPm)}`;
            }
        };
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            timeSelect: false,
            timeslotValue: 'Select your slot',
            showCountDown: true,
            openBookNowModal: false,
            time: {},
            seconds: 600,
            dateState: '',
            holidays: [],
            disabledDays: [],
            servicename: [],
            availableTime: [],
            timerequire: false,
            dateRequie: false,
            openLoading: false,
            noStaff: false,
            loading: false,
        };
        this.timer = 0;
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    componentDidMount() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let token = localStorage.getItem("token") || "";
            let user = localStorage.getItem("userType") || "";
            if (user != "customer" || !token) {
                localStorage.clear();
                window.location.replace("/LandingPage");
            }
            else {
                let { serviceId, shopSubResponse, subServiceid, name } = (_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state;
                if (!serviceId && !subServiceid && !(shopSubResponse === null || shopSubResponse === void 0 ? void 0 : shopSubResponse.attributes) && !name) {
                    this.props.history.push('/CustomerSubcategoryDetail');
                }
                this.getShopesHolidayDetails(subServiceid);
                this.setState({ servicename: name });
                this.disableDays(shopSubResponse === null || shopSubResponse === void 0 ? void 0 : shopSubResponse.attributes);
                let timeLeftVar = this.secondsToTime(this.state.seconds);
                this.setState({ time: timeLeftVar });
                this.startTimer();
            }
        });
    }
    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));
        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);
        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);
        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                if (apiRequestCallId === this.getShopSubApiCallId) {
                    this.handleReceiveShopeSubRes(responseJson);
                }
                if (apiRequestCallId === this.getStaffApiCallId) {
                    this.handleReceiveStaffRes(responseJson);
                }
                if (apiRequestCallId === this.getAvailablestaffDetailId) {
                    this.handleReceiveStaffSlotsdetailsRes(responseJson);
                }
            }
            else {
                runEngine.debugLog("GOIT");
            }
        });
    }
}
