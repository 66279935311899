import React from "react";
import ProfileDrawerController from "./ProfileDrawerController.web";
import { left_arrow_services, profile_arrow, my_profile, LogOut, my_booking, setting, contact_us, deactivate_account, privacy_policy, language, about_squees, terms_condition, defultPng, } from "./assets";
import { exit } from "../../dashboard/src/assets";
import { Button, Dialog } from "@material-ui/core";
import "../assets/style/ProfileDrawer.web.scss";
import "../../dashboard/assets/Styles/profile.css";
import { withRouter, Link } from "react-router-dom";
import SettingPrivacyTemsaboutContentmyProfile from "../../Settings5/src/SettingPrivacyTemsaboutContentmyProfile.web";
import LanguagemyProfile from "../../Settings5/src/LanguagemyProfile.web";
import DeactivateAccountWeb from "./DeactivateAccount.web";
import ProfileMybookingWeb from "../../categoriessubcategories/src/ProfileMybooking.web";
import { withTranslation } from "react-i18next";
const Cookies = require("js-cookie");
class ProfileDrawer extends ProfileDrawerController {
    constructor() {
        super(...arguments);
        this.mainProfileFunc = () => {
            var _a, _b, _c, _d;
            let { t } = this.props;
            let webAppDirection = localStorage.getItem("webAppDirection") ||
                Cookies.get("webAppDirection") ||
                "ltr";
            return this.state.profileHeaderTitle === "Profile" ||
                this.state.profileHeaderTitle === "My Bookings" ||
                this.state.profileHeaderTitle === "Contact Us" ||
                this.state.profileHeaderTitle === "Deactivate Account" ||
                this.state.profileHeaderTitle === "Log Out" ? (React.createElement("div", null,
                React.createElement("div", { className: "profile-first-div" },
                    React.createElement("img", { src: ((_a = this.state.customerProfile) === null || _a === void 0 ? void 0 : _a.profile_image) ? (_b = this.state.customerProfile) === null || _b === void 0 ? void 0 : _b.profile_image : defultPng, alt: "" }),
                    React.createElement("div", { style: { textAlign: "center", paddingLeft: "0px" } },
                        React.createElement("h1", null, (_c = this.state.customerProfile) === null || _c === void 0 ? void 0 : _c.full_name),
                        React.createElement("p", null, (_d = this.state.customerProfile) === null || _d === void 0 ? void 0 : _d.email))),
                React.createElement(Link, { to: "/customer/myprofile", style: { textDecoration: "none" } },
                    React.createElement("div", { className: "my_profile_div" },
                        React.createElement("img", { src: my_profile, alt: "", className: "img_1-icon" }),
                        "\u00A0\u00A0",
                        React.createElement("h1", null, t("My Profile")),
                        React.createElement("img", { src: profile_arrow, alt: "1", className: "img_2-icon", style: {
                                marginRight: webAppDirection === "rtl" ? "auto" : "0",
                                marginLeft: webAppDirection === "ltr" ? "auto" : "0",
                            } }))),
                React.createElement("div", { className: "my_profile_div", onClick: () => {
                        this.setState({
                            profileHeaderTitle: "My Bookings",
                            setBackgroundSetting: "My Bookings",
                        });
                    }, style: {
                        background: this.state.profileHeaderTitle === "My Bookings"
                            ? "rgba(27,90,221,.2)"
                            : "",
                        borderRadius: "12px",
                    } },
                    React.createElement("img", { src: my_booking, alt: "", className: "img_1-icon" }),
                    "\u00A0\u00A0",
                    React.createElement("h1", null, t("My Bookings")),
                    React.createElement("img", { src: profile_arrow, alt: "2", className: "img_2-icon", style: {
                            marginLeft: webAppDirection === "ltr" ? "auto" : "0",
                            marginRight: webAppDirection === "rtl" ? "auto" : "0",
                        } })),
                React.createElement("div", { className: "my_profile_div", id: "setting_btn", onClick: () => {
                        this.setState({ profileHeaderTitle: "Settings" });
                    } },
                    React.createElement("img", { src: setting, alt: "", className: "img_1-icon" }),
                    "\u00A0\u00A0",
                    React.createElement("h1", null, t("Settings")),
                    React.createElement("img", { alt: "4", className: "img_2-icon", src: profile_arrow, style: {
                            marginRight: webAppDirection === "rtl" ? "auto" : "0",
                            marginLeft: webAppDirection === "ltr" ? "auto" : "0",
                        } })),
                React.createElement("div", { className: "my_profile_div", id: "contact-us", style: {
                        background: this.state.profileHeaderTitle === "Contact Us"
                            ? "rgba(27,90,221,.2)"
                            : "",
                        borderRadius: "12px",
                    }, onClick: () => {
                        this.setState({
                            profileHeaderTitle: "Contact Us",
                            setBackgroundSetting: "Contact Us",
                        });
                        this.getSettingData();
                    } },
                    React.createElement("img", { src: contact_us, alt: "", className: "img_1-icon" }),
                    "\u00A0\u00A0",
                    React.createElement("h1", null, t("Contact Us")),
                    React.createElement("img", { src: profile_arrow, className: "img_2-icon", style: {
                            marginRight: webAppDirection === "rtl" ? "auto" : "0",
                            marginLeft: webAppDirection === "ltr" ? "auto" : "0",
                        }, alt: "5" })),
                React.createElement("div", { className: "my_profile_div", id: "deactivate_btn", style: {
                        background: this.state.profileHeaderTitle === "Deactivate Account"
                            ? "rgba(27,90,221,.2)"
                            : "",
                        borderRadius: "12px",
                    }, onClick: () => {
                        this.setState({
                            profileHeaderTitle: "Deactivate Account",
                            setBackgroundSetting: "Deactivate Account",
                        });
                    } },
                    React.createElement("img", { src: deactivate_account, alt: "", className: "img_1-icon" }),
                    "\u00A0\u00A0",
                    React.createElement("h1", { style: { color: "#ff453a" } }, t("Deactivate your account")),
                    React.createElement("img", { className: "img_2-icon", alt: "6", style: {
                            marginLeft: webAppDirection === "ltr" ? "auto" : "0",
                            marginRight: webAppDirection === "rtl" ? "auto" : "0",
                        }, src: profile_arrow })),
                React.createElement("div", { className: "my_profile_div", id: "log_out", style: {
                        background: this.state.profileHeaderTitle === "Log Out"
                            ? "rgba(27,90,221,.2)"
                            : "",
                        borderRadius: "12px",
                    }, onClick: () => {
                        this.setState({ openLogout: true });
                        this.setState({ profileHeaderTitle: "Log Out" });
                    } },
                    React.createElement("img", { src: LogOut, alt: "", className: "img_1-icon" }),
                    "\u00A0\u00A0",
                    React.createElement("h1", { style: { color: "#ff453a" } }, t("Log Out"))))) : ("");
        };
        this.settingsFunc = () => {
            let { t } = this.props;
            let webAppDirection = localStorage.getItem("webAppDirection") ||
                Cookies.get("webAppDirection") ||
                "ltr";
            return this.state.profileHeaderTitle === "Settings" ||
                this.state.profileHeaderTitle === "Privacy policy" ||
                this.state.profileHeaderTitle === "Terms & conditions" ||
                this.state.setBackgroundSetting === "Change Language" ||
                this.state.profileHeaderTitle === "About SqueezMeIn" ? (React.createElement("div", null,
                React.createElement("div", { className: "my_profile_div", id: "privacy_policy", style: {
                        background: this.state.setBackgroundSetting === "Privacy policy"
                            ? "rgba(27,90,221,.2)"
                            : "",
                        borderRadius: "12px",
                    }, onClick: () => {
                        this.setState({
                            profileHeaderTitle: "Privacy policy",
                            setBackgroundSetting: "Privacy policy",
                        });
                        this.getSettingData();
                    } },
                    React.createElement("img", { src: privacy_policy, alt: "", className: "img_1-icon" }),
                    "\u00A0\u00A0",
                    React.createElement("h1", null, t("Privacy policy")),
                    React.createElement("img", { src: profile_arrow, alt: "7", className: "img_2-icon", style: {
                            marginLeft: webAppDirection === "ltr" ? "auto" : "0",
                            marginRight: webAppDirection === "rtl" ? "auto" : "0",
                        } })),
                React.createElement("div", { className: "my_profile_div", id: "terms_condition", style: {
                        background: this.state.setBackgroundSetting === "Terms & conditions"
                            ? "rgba(27,90,221,.2)"
                            : "",
                        borderRadius: "12px",
                    }, onClick: () => {
                        this.setState({
                            profileHeaderTitle: "Terms & conditions",
                            setBackgroundSetting: "Terms & conditions",
                        });
                        this.getSettingData("Terms & conditions");
                    } },
                    React.createElement("img", { src: terms_condition, alt: "", className: "img_1-icon" }),
                    "\u00A0\u00A0",
                    React.createElement("h1", null, t("Terms & conditions")),
                    React.createElement("img", { src: profile_arrow, alt: "8", className: "img_2-icon", style: {
                            marginLeft: webAppDirection === "ltr" ? "auto" : "0",
                            marginRight: webAppDirection === "rtl" ? "auto" : "0",
                        } })),
                React.createElement("div", { className: "my_profile_div", id: "language", style: {
                        background: this.state.setBackgroundSetting === "Change Language"
                            ? "rgba(27,90,221,.2)"
                            : "",
                        borderRadius: "12px",
                    }, onClick: () => {
                        this.setState({
                            profileHeaderTitle: "Change Language",
                            setBackgroundSetting: "Change Language",
                        });
                        this.getLanguageData();
                    } },
                    React.createElement("img", { src: language, alt: "", className: "img_1-icon" }),
                    "\u00A0\u00A0",
                    React.createElement("h1", null, t("Language")),
                    React.createElement("img", { src: profile_arrow, alt: "9", className: "img_2-icon", style: {
                            marginLeft: webAppDirection === "ltr" ? "auto" : "0",
                            marginRight: webAppDirection === "rtl" ? "auto" : "0",
                        } })),
                React.createElement("div", { className: "my_profile_div", id: "about", style: {
                        background: this.state.setBackgroundSetting === "About SqueezMeIn"
                            ? "rgba(27,90,221,.2)"
                            : "",
                        borderRadius: "12px",
                    }, onClick: () => {
                        this.setState({
                            profileHeaderTitle: "About SqueezMeIn",
                            setBackgroundSetting: "About SqueezMeIn",
                        });
                        this.getSettingData();
                    } },
                    React.createElement("img", { src: about_squees, alt: "", className: "img_1-icon" }),
                    "\u00A0\u00A0",
                    React.createElement("h1", null, t("About SqueezMeIn")),
                    React.createElement("img", { src: profile_arrow, alt: "10", className: "img_2-icon", style: {
                            marginLeft: webAppDirection === "ltr" ? "auto" : "0",
                            marginRight: webAppDirection === "rtl" ? "auto" : "0",
                        } })))) : ("");
        };
        this.privacy_policyFunc = () => {
            return this.state.profileHeaderTitle === "Privacy policy" ? (React.createElement(SettingPrivacyTemsaboutContentmyProfile
            //@ts-ignore
            , { 
                //@ts-ignore
                response: this.state.settingResponse, setBackgroundSettingdata: this.state.setBackgroundSetting })) : ("");
        };
        this.LanguageFunc = () => {
            return this.state.profileHeaderTitle === "Change Language" ? (React.createElement(LanguagemyProfile
            //@ts-ignore
            , { 
                //@ts-ignore
                response: this.state.languageResponse, setBackgroundSettingdata: this.state.setBackgroundSetting, handleBackArrow: this.handleBackArrow })) : ("");
        };
        this.TermsAndConditionFunc = () => {
            return this.state.profileHeaderTitle === "Terms & conditions" ? (React.createElement(SettingPrivacyTemsaboutContentmyProfile
            //@ts-ignore
            , { 
                //@ts-ignore
                setBackgroundSettingdata: this.state.setBackgroundSetting, response: this.state.settingResponse })) : ("");
        };
        this.aboutSqueesFunc = () => {
            return this.state.profileHeaderTitle === "About SqueezMeIn" ? (React.createElement(SettingPrivacyTemsaboutContentmyProfile
            //@ts-ignore
            , { 
                //@ts-ignore
                setBackgroundSettingdata: this.state.setBackgroundSetting, response: this.state.settingResponse })) : ("");
        };
        this.contactFunc = () => {
            return this.state.profileHeaderTitle === "Contact Us" ? (React.createElement(SettingPrivacyTemsaboutContentmyProfile
            //@ts-ignore
            , { 
                //@ts-ignore
                setBackgroundSettingdata: this.state.setBackgroundSetting, response: this.state.settingResponse })) : ("");
        };
        this.deactivate_func = () => {
            return this.state.profileHeaderTitle === "Deactivate Account" ? (React.createElement(DeactivateAccountWeb
            //@ts-ignore
            , { 
                //@ts-ignore
                setBackgroundSettingdata: this.state.setBackgroundSetting, handleBackArrow: this.handleBackArrow })) : ("");
        };
        this.MyBookingFunc = () => {
            return this.state.profileHeaderTitle === "My Bookings" ? (React.createElement(ProfileMybookingWeb
            //@ts-ignore
            , { 
                //@ts-ignore
                setBackgroundSettingdata: this.state.setBackgroundSetting, handleBackArrow: this.handleBackArrow })) : ("");
        };
    }
    render() {
        let { t } = this.props;
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { style: { height: "100%", marginBottom: "160px" }, className: "scroll-div-profile" },
                React.createElement("div", null,
                    React.createElement("div", { className: "profile-header", style: this.state.profileHeaderTitle === "Profile" ||
                            this.state.profileHeaderTitle === "Settings"
                            ? {
                                margin: "auto",
                                paddingLeft: webAppDirection === "ltr" ? 38 : 0,
                                paddingRight: webAppDirection === "rtl" ? 38 : 0,
                            }
                            : {
                                paddingLeft: webAppDirection === "ltr" ? 120 : 0,
                                paddingRight: webAppDirection === "rtl" ? 120 : 0,
                            } },
                        React.createElement("img", { src: left_arrow_services, alt: "", id: "service_left_arrow", onClick: () => {
                                this.handleBackArrow();
                            } }),
                        React.createElement("h1", null, this.state.profileHeaderTitle === "Log Out" ||
                            this.state.profileHeaderTitle === "Contact Us" ||
                            this.state.profileHeaderTitle === "Deactivate Account" ||
                            this.state.profileHeaderTitle === "Profile" ||
                            this.state.profileHeaderTitle === "My Bookings"
                            ? t("Profile")
                            : t("Settings"))),
                    React.createElement("div", { className: webAppDirection === "ltr"
                            ? "whole-main-div"
                            : "arabic-whole-main-div" },
                        React.createElement("div", { style: this.state.profileHeaderTitle === "Profile" ||
                                this.state.profileHeaderTitle === "Settings"
                                ? {
                                    height: this.state.profileHeaderTitle === "Settings"
                                        ? "350px"
                                        : "100%",
                                    margin: "auto",
                                }
                                : {
                                    height: this.state.profileHeaderTitle === "Settings"
                                        ? "350px"
                                        : "100%",
                                }, className: webAppDirection === "ltr"
                                ? "main-profile-div"
                                : "arabic-main-profile-div" },
                            this.mainProfileFunc(),
                            this.settingsFunc()),
                        this.contactFunc(),
                        this.privacy_policyFunc(),
                        this.LanguageFunc(),
                        this.TermsAndConditionFunc(),
                        this.aboutSqueesFunc(),
                        this.deactivate_func(),
                        this.MyBookingFunc()),
                    React.createElement(Dialog, { open: this.state.openLogout },
                        React.createElement("div", { className: "logout_modal" },
                            React.createElement("img", { alt: "exit-icon", className: "modal-icon", src: exit }),
                            React.createElement("div", { className: "logout_confirmation_text" }, t("Are you sure you want to logout?")),
                            React.createElement(Button, { className: "logout-modal-no-button-style", onClick: () => this.setState({
                                    openLogout: false,
                                    profileHeaderTitle: "Profile",
                                }) }, t("No")),
                            React.createElement(Button, { className: "logout-modal-yes-button-style", onClick: () => this.handleLogout() }, t("Yes"))))))));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(ProfileDrawer));
