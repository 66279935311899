// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { otpVerifiedSymbol } from "./assets";
import "../assets/Styles/forgotPassword.css";
import { withTranslation } from "react-i18next";
class OTPResendSuccess extends BlockComponent {
    render() {
        const { resendPlace } = this.props;
        //@ts-ignore
        let { t } = this.props;
        return (React.createElement("div", { className: "mobile-number-container" },
            React.createElement("div", { className: "verified-otp-container" },
                React.createElement("img", { src: otpVerifiedSymbol, alt: "verified", className: "verified-image-style" }),
                React.createElement("span", { className: "verification-message-text" },
                    t("Resend OTP on your"),
                    React.createElement("br", null),
                    " ",
                    t(`${resendPlace}`),
                    " ."))));
    }
}
//@ts-ignore
export default withTranslation()(OTPResendSuccess);
// Customizable Area End
