import React from "react";
import BookingConfirmController from "./BookingConfirmController.web";
import { green_check, red_check } from "./assets";
import "../assets/style/BookingConfirm.web.scss";
import { withRouter } from "react-router-dom";
import HeaderWeb from "./Header.web";
import { withTranslation } from "react-i18next";
import Loader from "../../../components/src/Loader.web";
class PaymentSuccessfull extends BookingConfirmController {
    render() {
        let { t } = this.props;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "booking-main-div" },
                React.createElement(HeaderWeb, null),
                React.createElement("div", { className: "booking-confirm-detail-div" },
                    React.createElement("div", { className: "booking-confirm-list-div" }, this.state.isLoadingData ? (React.createElement(Loader, { loading: this.state.isLoadingData })) : (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "img-div" },
                            React.createElement("img", { src: this.state.paymentFailed ? red_check : green_check, alt: "" })),
                        React.createElement("h3", null, this.state.paymentFailed
                            ? t("Payment unsuccessful!")
                            : t("Payment successful!")),
                        React.createElement("p", { style: { paddingBottom: 50, marginTop: 15 } }, this.state.paymentFailed
                            ? t("Please try again.")
                            : t("An invoice has been sent to your email.")),
                        this.state.paymentFailed && (React.createElement("button", { className: "subcrption-modal-popup", onClick: () => this.props.history.push("/CustomerHomeScreen") }, t("Go Back"))))))))));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(PaymentSuccessfull));
