import React from "react";
import CustomisableServicesWebController from "./CustomisableServicesWebController";
import { Table, TableBody, TableContainer, TableHead, TableRow, TableCell, IconButton, Button, Dialog, DialogContent, DialogTitle, DialogActions, OutlinedInput, Paper, } from "@material-ui/core";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { editIcon, darkAddIcon, cross as img272143ab666beda6ef49e46f654ac7d4c001c332, img7f284a623dad0db2fed9fbe2e3d55e6af5f0cd63, darkEditIcon, alertIcon, ArrowDown, ArrowUp, } from "./assets";
import AfterLoginHeader from "../../dashboard/src/AfterLoginHeader.web";
import "../assets/Styles/Service.css";
import { withTranslation } from "react-i18next";
import Loader from "../../../components/src/Loader.web";
const StyledTableCell = withStyles((theme) => createStyles({
    head: {
        color: "#ffffff",
    },
    body: {
        fontSize: 40,
    },
}))(TableCell);
const StyledTableRow = withStyles((theme) => createStyles({
    root: {
        fontSize: 24,
    },
}))(TableRow);
class CustomisableBusinessServices extends CustomisableServicesWebController {
    constructor(props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        //@ts-ignore
        let { t } = this.props;
        return (React.createElement("div", { className: "service-landing-page-main-container" },
            React.createElement("div", { style: {
                    marginBottom: "40px",
                    marginTop: "45px",
                } },
                React.createElement(AfterLoginHeader, { navigation: this.props.navigation })),
            React.createElement("div", { className: "services-root-page" },
                React.createElement("div", null,
                    React.createElement("span", { className: "services-note-item-text" }, t("Business Setup"))),
                React.createElement("div", { style: {
                        marginTop: 10,
                    } },
                    React.createElement("span", { className: "services-header-item-text" },
                        `${t("Select the")} ${t(window.localStorage.getItem("selectedCategoryName"))} ${t("services to help user find you")}`,
                        " ",
                        "\u00A0\u00A0",
                        React.createElement(Button, { variant: "contained", color: "primary", className: "business-button" }, t(this.getServiceProvidedName())))),
                React.createElement("div", { className: "services-main-container-text" },
                    this.state.serviceCategory.length === 0 && !this.state.loader && (React.createElement("div", { style: {
                            flexDirection: "column",
                            marginTop: 40,
                        } },
                        React.createElement("div", { className: "service-text-label" },
                            React.createElement("span", { style: { fontSize: 22, fontFamily: "Poppins, sans-serif" } }, t("Please start adding services"))),
                        React.createElement("div", { className: "service-text-label" },
                            React.createElement("span", { style: { fontSize: 20, fontFamily: "Poppins, sans-serif" } }, t("to your catalogue"))))),
                    this.state.serviceCategory.map((item, index) => (React.createElement(TableContainer, { style: {
                            borderTopLeftRadius: "15px",
                            borderTopRightRadius: "15px",
                            marginBottom: "30px",
                            marginTop: "30px",
                            boxShadow: "none",
                            width: "100%",
                            maxWidth: "700px",
                            borderBottom: "none",
                            backgroundColor: "#FFFFFF",
                        }, component: Paper, key: index, className: "custom-table-container" },
                        React.createElement(Table, null,
                            React.createElement(TableHead, null,
                                React.createElement(TableRow, { style: {
                                        backgroundColor: "#1E5FEA",
                                    } },
                                    React.createElement(StyledTableCell, null,
                                        React.createElement("div", { style: {
                                                display: "flex",
                                                justifyContent: "space-between",
                                                flexDirection: "row",
                                                alignItems: "center",
                                            } },
                                            React.createElement("div", { className: "service-header-label" }, item.attributes.display_name),
                                            React.createElement("div", { style: {
                                                    alignItems: "flex-end",
                                                } },
                                                React.createElement(IconButton, { style: { marginRight: "20px" }, id: "categoryBtn", "aria-label": "delete", size: "small", color: "secondary", onClick: () => {
                                                        this.setState({ selectedCategoryId: item.attributes.id }, () => {
                                                            this.setStateValue(true, "edit", "category", item.attributes.name, item.attributes.arabic_name);
                                                        });
                                                    } },
                                                    React.createElement("img", { src: editIcon, alt: "type", width: "24px", height: "24px" }))))))),
                            React.createElement(TableBody, null,
                                item.attributes.business_sub_categories.map((data, key) => (React.createElement(StyledTableRow, { key: key },
                                    React.createElement(StyledTableCell, { component: "th", scope: "row" },
                                        React.createElement("div", { style: {
                                                display: "flex",
                                                justifyContent: "space-between",
                                                flexDirection: "row",
                                            } },
                                            React.createElement("div", { style: { flexDirection: "column" } },
                                                React.createElement("div", { className: "service-name" },
                                                    React.createElement("span", null, data.display_name)),
                                                React.createElement("div", { className: "service-note" },
                                                    React.createElement("span", null,
                                                        this.getHoursFromDuration(data),
                                                        " ",
                                                        t("min"),
                                                        " ",
                                                        "- OMR ",
                                                        data.service_cost))),
                                            React.createElement("div", { style: {
                                                    alignItems: "flex-end",
                                                } },
                                                React.createElement(IconButton, { style: { marginRight: "20px" }, id: "serviceBtn", "aria-label": "delete", size: "small", color: "secondary", onClick: () => {
                                                        this.setState({
                                                            selectedCategoryId: item.attributes.id,
                                                        }, () => {
                                                            this.setStateValue(true, "edit", "service", item.attributes.name, item.attributes.arabic_name, data);
                                                        });
                                                    } },
                                                    React.createElement("img", { src: darkEditIcon, alt: "type", width: "24px", height: "24px" })))))))),
                                React.createElement(StyledTableRow, { key: index },
                                    React.createElement(StyledTableCell, { component: "th", scope: "row" },
                                        React.createElement("div", { style: {
                                                display: "flex",
                                                justifyContent: "space-between",
                                                flexDirection: "row",
                                            } },
                                            React.createElement("div", { className: "service-name" },
                                                React.createElement("span", null, t("Add another service"))),
                                            React.createElement("div", { style: { alignItems: "flex-end" } },
                                                React.createElement(IconButton, { style: { marginRight: "20px" }, "aria-label": "delete", size: "small", color: "secondary", id: "addServiceBtn", onClick: () => {
                                                        this.setState({ selectedCategoryId: item.attributes.id }, () => {
                                                            this.unSetServiceData();
                                                            this.setStateValue(true, "add", "service", item.attributes.name, item.attributes.arabic_name);
                                                        });
                                                    } },
                                                    React.createElement("img", { src: darkAddIcon, alt: "type", width: "24px", height: "24px" }))))))))))),
                    React.createElement("div", { className: "services-main-container-button" },
                        React.createElement("div", { style: {
                                marginRight: 20,
                            } },
                            React.createElement(Button, { className: "services-add-category-button", variant: "outlined", onClick: () => {
                                    this.setState({ selectedCategoryId: "" });
                                    this.setStateValue(true, "add", "category", "", "");
                                } },
                                React.createElement("img", { src: darkAddIcon, alt: "type", width: "24px", height: "24px" }),
                                " ",
                                "\u00A0 ",
                                t("Add another category"))),
                        React.createElement("div", null,
                            React.createElement(Button, { onClick: this.updateBusinessProfileDetails, className: "services-next-Step-button", color: "primary" }, t("Next Step")))),
                    React.createElement("div", null,
                        React.createElement(Dialog, { open: this.state.openCategoryDialog, PaperProps: {
                                className: "services-category-dialog",
                            } },
                            React.createElement("span", { style: { textAlign: "right", marginRight: 30, marginTop: 10 } },
                                React.createElement(IconButton, { color: "primary", "aria-label": "upload picture", component: "span", id: "openCategoryBtn", onClick: () => this.setState({ openCategoryDialog: false }) },
                                    React.createElement("img", { src: img272143ab666beda6ef49e46f654ac7d4c001c332, alt: "logo", width: "24px", height: "24px" }))),
                            React.createElement(DialogTitle, { style: {
                                    textAlign: "center",
                                    fontFamily: "Poppins, sans-serif",
                                }, id: "alert-dialog-title" },
                                React.createElement("span", { className: "services-dialog-label", style: {
                                        fontSize: "24px",
                                        color: "#000000",
                                        lineHeight: "30px",
                                    } }, t(this.state.categoryDialogHeader))),
                            React.createElement(DialogContent, null,
                                React.createElement("div", { className: "services-input-container" },
                                    React.createElement("span", { style: {
                                            marginBottom: 10,
                                            fontFamily: "Poppins-Medium, sans-serif",
                                        } }, t("Category Name *")),
                                    React.createElement(OutlinedInput, { type: "text", placeholder: t("Category Name"), value: this.state.categoryName, error: this.state.categoryNameError, className: "service-dialog-input-style", inputProps: {
                                            min: 0,
                                            maxLength: 50,
                                            style: { marginLeft: 5 },
                                        }, onBlur: () => this.validateCategoryName(), onChange: (e) => {
                                            if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                                                this.setState({
                                                    categoryNameError: "",
                                                    categoryName: e.target.value,
                                                });
                                            }
                                        } }),
                                    this.renderErrorMessage(this.state.categoryNameError, t)),
                                React.createElement("div", { className: "services-input-container" },
                                    React.createElement("span", { style: {
                                            marginBottom: 10,
                                            fontFamily: "Poppins-Medium, sans-serif",
                                        } }, t("Category Name Arabic *")),
                                    React.createElement(OutlinedInput, { type: "text", "data-testid": "category_name_arabic", placeholder: t("Category Name Arabic"), value: this.state.arabicCategoryName, error: this.state.arabicCategoryNameError, className: "service-dialog-input-style", inputProps: {
                                            min: 0,
                                            maxLength: 50,
                                            style: { marginLeft: 5 },
                                        }, onBlur: () => this.validateArabicCategoryName(), onChange: (e) => {
                                            if (/^[\u0600-\u06FF\s]*$/.test(e.target.value)) {
                                                this.setState({
                                                    arabicCategoryNameError: "",
                                                    arabicCategoryName: e.target.value,
                                                });
                                            }
                                        } }),
                                    this.renderErrorMessage(this.state.arabicCategoryNameError, t)),
                                React.createElement("div", { className: "services-input-container" },
                                    React.createElement("div", { className: "services-add-container" },
                                        React.createElement("span", { style: {
                                                marginRight: "10px",
                                                fontSize: 20,
                                                whiteSpace: "nowrap",
                                            } }, t("Add another service")),
                                        React.createElement(IconButton, { "aria-label": "delete", size: "small", color: "secondary", id: "addNewServiceBtn", style: {
                                                marginLeft: "250px",
                                                "@media (max-width: 767px)": {
                                                    marginLeft: "20px",
                                                },
                                            }, disabled: this.state.categoryName === "", onClick: () => {
                                                this.validateCategoryDialog();
                                            } },
                                            React.createElement("img", { src: darkAddIcon, alt: "type", width: "24px", height: "24px" }))))),
                            React.createElement(DialogActions, null,
                                React.createElement("div", { className: "service-button-dialog" },
                                    React.createElement("div", null,
                                        React.createElement(Button, { className: "services-dialog-button", style: {
                                                backgroundColor: "#1e5fea",
                                            }, disabled: this.state.loader, onClick: () => {
                                                const { actionType } = this.state;
                                                const parameter = "withoutService";
                                                this.apiCallFunc(actionType, parameter);
                                            }, variant: "outlined" }, t("Save"))),
                                    this.state.actionType === "edit" && (React.createElement("div", null,
                                        React.createElement(Button, { style: {
                                                backgroundColor: "#ec400c",
                                            }, className: "services-delete-button", color: "primary", onClick: () => {
                                                this.setState({
                                                    alertDialog: true,
                                                    setAlertFor: "Category",
                                                });
                                            } }, t("Delete")))),
                                    this.state.actionType === "add" && (React.createElement("div", null,
                                        React.createElement(Button, { onClick: () => {
                                                this.setState({ openCategoryDialog: false });
                                            }, className: "services-cancel-button", "data-testid": "category-cancel-button", variant: "outlined", color: "primary" }, t("Cancel")))))))),
                    React.createElement("div", null,
                        React.createElement(Dialog, { open: this.state.alertDialog, PaperProps: {
                                className: "service-alert-dialog",
                            } },
                            React.createElement(DialogContent, null,
                                React.createElement("div", { className: "services-input-container" },
                                    React.createElement("div", { className: "services-add-container" },
                                        React.createElement(IconButton, { "aria-label": "delete", size: "small", color: "secondary", style: {
                                                marginLeft: 65,
                                                marginTop: 25,
                                            } },
                                            React.createElement("img", { src: alertIcon, alt: "type", width: "120px", height: "120px" }))),
                                    React.createElement("div", { className: "services-dialog-container" },
                                        React.createElement("span", { style: {
                                                textAlign: "center",
                                                fontSize: 20,
                                                lineHeight: "36px",
                                            } }, t("Are you sure you want to")),
                                        React.createElement("span", { style: {
                                                textAlign: "center",
                                                fontSize: 20,
                                                lineHeight: "36px",
                                            } },
                                            t("delete this"),
                                            " ",
                                            t(this.state.setAlertFor),
                                            "?")))),
                            React.createElement(DialogActions, null,
                                React.createElement("div", { className: "service-alert-dialog-button" },
                                    React.createElement("div", null,
                                        React.createElement(Button, { className: "services-cancel-dialog-button", style: {
                                                backgroundColor: "#1e5fea",
                                            }, variant: "outlined", onClick: () => {
                                                this.setState({ alertDialog: false });
                                            } }, t("No"))),
                                    React.createElement("div", null,
                                        React.createElement(Button, { style: {
                                                backgroundColor: "#ec400c",
                                                marginTop: 30,
                                                marginBottom: 30,
                                            }, onClick: () => {
                                                this.callDeleteAPICall();
                                            }, className: "services-cancel-dialog-button", color: "primary" }, t("Confirm"))))))),
                    React.createElement("div", null,
                        React.createElement(Dialog, { open: this.state.serviceDialog, PaperProps: {
                                className: "service-add-service-dialog",
                            } },
                            React.createElement("span", { style: { textAlign: "right", marginRight: 30, marginTop: 10 } },
                                React.createElement(IconButton, { color: "primary", "aria-label": "upload picture", component: "span", id: "closeIcon", onClick: () => {
                                        this.setState({ serviceDialog: false });
                                    } },
                                    React.createElement("img", { src: img272143ab666beda6ef49e46f654ac7d4c001c332, alt: "logo", width: "24px", height: "24px" }))),
                            React.createElement(DialogTitle, { style: { textAlign: "center" }, id: "alert-dialog-title" },
                                React.createElement("span", { className: "services-dialog-label", style: {
                                        fontSize: "24px",
                                        color: "#000000",
                                        lineHeight: "30px",
                                    } }, t(this.state.serviceDialogHeader))),
                            React.createElement(DialogContent, null,
                                React.createElement("div", null,
                                    React.createElement("div", null,
                                        React.createElement("div", { className: "services-input-container" },
                                            React.createElement("span", { className: "services-dialog-label" }, t("Category Name *")),
                                            React.createElement(OutlinedInput, { type: "text", className: "service-dialog-input-style", id: "serviceCategory", error: this.state.categoryNameError, placeholder: t("Category Name"), value: this.state.categoryName, onChange: (e) => {
                                                    if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                                                        this.setState({
                                                            categoryNameError: "",
                                                            categoryName: e.target.value,
                                                        });
                                                    }
                                                }, inputProps: {
                                                    min: 0,
                                                    maxLength: 50,
                                                    style: { marginLeft: 5 },
                                                }, onBlur: () => this.validateCategoryName() }),
                                            this.renderErrorMessage(this.state.categoryNameError, t)),
                                        React.createElement("div", { className: "services-input-container" },
                                            React.createElement("span", { className: "services-dialog-label" }, t("Category Name Arabic *")),
                                            React.createElement(OutlinedInput, { type: "text", className: "service-dialog-input-style", placeholder: t("Category Name Arabic"), id: "serviceCategoryArabic", error: this.state.arabicCategoryNameError, value: this.state.arabicCategoryName, onChange: (e) => {
                                                    if (/^[\u0600-\u06FF\s]*$/.test(e.target.value)) {
                                                        this.setState({
                                                            arabicCategoryName: e.target.value,
                                                            arabicCategoryNameError: "",
                                                        });
                                                    }
                                                }, onBlur: () => this.validateArabicCategoryName(), inputProps: {
                                                    min: 0,
                                                    maxLength: 50,
                                                    style: { marginLeft: 5 },
                                                } }),
                                            this.renderErrorMessage(this.state.arabicCategoryNameError, t)),
                                        React.createElement("div", { className: "services-input-container" },
                                            React.createElement("span", { className: "services-dialog-label" }, t("Service Name *")),
                                            React.createElement(OutlinedInput, { type: "text", id: "serviceName", placeholder: t("Service Name"), value: this.state.serviceName, className: "service-dialog-input-style", error: this.state.serviceNameError, onChange: (e) => {
                                                    if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                                                        this.setState({
                                                            serviceNameError: "",
                                                            serviceName: e.target.value,
                                                        });
                                                    }
                                                }, inputProps: {
                                                    min: 0,
                                                    maxLength: 50,
                                                    style: { marginLeft: 5 },
                                                }, onBlur: () => this.validateField("serviceName", "Please enter service name") }),
                                            this.renderErrorMessage(this.state.serviceNameError, t)),
                                        React.createElement("div", { className: "services-input-container" },
                                            React.createElement("span", { className: "services-dialog-label" }, t("Service Name Arabic *")),
                                            React.createElement(OutlinedInput, { type: "text", id: "serviceNameArabic", placeholder: t("Service Name Arabic"), value: this.state.arabicServiceName, className: "service-dialog-input-style", error: this.state.arabicServiceNameError, onChange: (e) => {
                                                    if (/^[\u0600-\u06FF\s]*$/.test(e.target.value)) {
                                                        this.setState({
                                                            arabicServiceNameError: "",
                                                            arabicServiceName: e.target.value,
                                                        });
                                                    }
                                                }, inputProps: {
                                                    min: 0,
                                                    maxLength: 50,
                                                    style: { marginLeft: 5 },
                                                }, onBlur: () => this.validateField("arabicServiceName", "Please enter service name in arabic") }),
                                            this.renderErrorMessage(this.state.arabicServiceNameError, t))),
                                    React.createElement("div", null,
                                        React.createElement("div", { className: "services-input-container" },
                                            React.createElement("span", { className: "services-dialog-label" }, t("Description *")),
                                            React.createElement(OutlinedInput, { id: "outlined-multiline-static", multiline: true, rows: 4, placeholder: t("Write a short description"), type: "text", className: "service-dialog-input-style", value: this.state.description, error: this.state.descriptionError, onChange: (e) => {
                                                    this.setState({
                                                        descriptionError: "",
                                                        description: e.target.value,
                                                    });
                                                }, inputProps: {
                                                    min: 0,
                                                    maxLength: 100,
                                                    style: { marginLeft: 5 },
                                                }, onBlur: () => this.validateField("description", "Please enter description") }),
                                            this.renderErrorMessage(this.state.descriptionError, t))),
                                    React.createElement("div", { className: "service-add-service-field" },
                                        React.createElement("div", { className: "services-input-container" },
                                            React.createElement("span", { className: "services-dialog-label" }, t("Service Cost *")),
                                            React.createElement(OutlinedInput, { type: "text", id: "serviceCost", placeholder: "OMR", value: this.state.cost, error: this.state.costError, onChange: (e) => {
                                                    this.isValidCostInput(e.target.value);
                                                }, className: "service-add-service-textfield", inputProps: { min: 0, style: { marginLeft: 5 } }, onBlur: () => this.validateField("cost", "Enter service cost") }),
                                            this.renderErrorMessage(this.state.costError, t)),
                                        React.createElement("div", { className: "services-input-container" },
                                            React.createElement("span", { className: "services-dialog-label" }, t("Service Duration *")),
                                            React.createElement("div", { className: "service-form-custom-select" },
                                                React.createElement("select", { id: "outlined-select-currency-native", value: this.state.duration, style: {
                                                        border: this.state.descriptionError
                                                            ? "1px solid #FF453A"
                                                            : "1px solid #dadada",
                                                        borderRadius: 4,
                                                        paddingLeft: 10,
                                                    }, onClick: this.handleSelectToggle, onChange: (e) => {
                                                        this.setState({ duration: e.target.value });
                                                    }, onBlur: () => this.validateField("duration", "select duration") }, this.state.DurationData.map((option, index) => {
                                                    let [time, mins] = option.key.split(" ");
                                                    return (React.createElement("option", { key: index, disabled: option.key === "", value: option.value }, `${time} ${t(mins)}`));
                                                })),
                                                React.createElement("img", { src: this.state.isSelectOpen ? ArrowUp : ArrowDown, alt: "Custom Icon" })),
                                            this.renderErrorMessage(this.state.durationError, t))))),
                            React.createElement(DialogActions, null,
                                React.createElement("div", { className: "service-button-dialog" },
                                    React.createElement("div", { className: "services-button-container" },
                                        React.createElement(Button, { className: "services-row-button", style: {
                                                backgroundColor: "#1e5fea",
                                                color: "#ffffff",
                                            }, disabled: this.state.loader, onClick: () => {
                                                this.handleSave(this.state.serviceDialogHeader);
                                            }, variant: "outlined" }, t("Save"))),
                                    React.createElement("div", { className: "services-button-container" },
                                        React.createElement(Button, { className: "services-row-button", onClick: () => {
                                                this.setState({
                                                    serviceDialog: false,
                                                    setAlertFor: "Service",
                                                    alertDialog: this.state.actionType === "edit",
                                                });
                                            }, style: {
                                                color: this.state.actionType === "edit"
                                                    ? "#ffffff"
                                                    : "#e91212",
                                                borderColor: "#e91212",
                                                marginRight: 10,
                                                backgroundColor: this.state.actionType === "edit"
                                                    ? "#e91212"
                                                    : "#ffffff",
                                            }, variant: "outlined" }, this.state.actionType === "edit"
                                            ? t("Delete")
                                            : t("Cancel"))))))),
                    React.createElement(Loader, { loading: this.state.loader }),
                    React.createElement("div", null,
                        React.createElement(Dialog, { open: this.state.sucess, PaperProps: {
                                className: "service-success-dialog",
                            } },
                            React.createElement(DialogContent, null,
                                React.createElement("div", { style: { marginTop: 10, textAlign: "center" } },
                                    React.createElement("span", { style: {
                                            textAlign: "center",
                                            fontWeight: 800,
                                            fontSize: 22,
                                            fontFamily: "Poppins-Medium, sans-serif",
                                        } }, t("Success"))),
                                React.createElement("div", { style: { marginTop: 20, textAlign: "center" } },
                                    React.createElement("span", { style: {
                                            textAlign: "center",
                                            fontSize: 20,
                                            fontFamily: "Poppins-Medium, sans-serif",
                                        } }, t(this.state.catalogueMsg)))))),
                    React.createElement("div", null,
                        React.createElement(Dialog, { open: this.state.addEmployeesDialog, PaperProps: {
                                className: "service-add-emp-dialog",
                            } },
                            React.createElement(DialogContent, null,
                                React.createElement("div", { className: "services-input-container", style: {
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    } },
                                    React.createElement("div", { className: "services-add-container" },
                                        React.createElement(IconButton, { "aria-label": "delete", size: "small", color: "secondary", className: "service-add-emp-iconbutton" },
                                            React.createElement("img", { src: img7f284a623dad0db2fed9fbe2e3d55e6af5f0cd63, style: {
                                                    height: "160px",
                                                    width: "160px",
                                                }, alt: "type" }))),
                                    React.createElement("div", { className: "services-dialog-container" },
                                        React.createElement("span", { style: {
                                                textAlign: "center",
                                                fontSize: 24,
                                                lineHeight: "30px",
                                                fontWeight: 600,
                                                fontFamily: "Poppins-Medium, sans-serif",
                                            } }, t("Congratulations!")),
                                        React.createElement("span", { style: {
                                                textAlign: "center",
                                                marginTop: 30,
                                                fontWeight: 500,
                                                fontSize: 20,
                                                fontFamily: "Poppins-Medium, sans-serif",
                                            } }, t("Add employees to start")),
                                        React.createElement("span", { style: {
                                                textAlign: "center",
                                                fontSize: 20,
                                                fontWeight: 500,
                                                fontFamily: "Poppins-Medium, sans-serif",
                                            } }, t("your business"))))),
                            React.createElement(DialogActions, null,
                                React.createElement("div", { style: {
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "100%",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    } },
                                    React.createElement("div", null,
                                        React.createElement(Button, { className: "service-add-emp-button", variant: "outlined", onClick: () => {
                                                this.props.navigation.navigate("AddTeamMembers");
                                            } }, t("Add Employees")))))))))));
    }
}
//@ts-ignore
export default withTranslation()(CustomisableBusinessServices);
