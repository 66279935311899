// index.js - WEB
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import i18n from '../../components/src/Resources/i18n/i18n';

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('./firebase-messaging-sw.js')
      .then(registration => {
        // Service worker registration successful
        console.log('Service Worker registered: ', registration);
      })
      .catch(error => {
        // Service worker registration failed
        console.log('Service Worker registration failed: ', error);
      });
  });
}

ReactDOM.render(
  <Router>
    <App />
  </Router>,  
  document.getElementById('root')
);
