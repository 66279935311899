var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Customizable Area Start
import React from "react";
import AfterLoginHeader from "../../dashboard/src/AfterLoginHeader.web";
import "../assets/Styles/walkin-business.web.css";
import BusinessHomeScreenSidebar from "./BusinessHomeScreenSidebar.web";
import { withRouter } from "react-router-dom";
import { Paper, Button, Divider, OutlinedInput, InputAdornment, IconButton, } from "@material-ui/core";
import BusinessHomeScreenWebController from "./BusinessHomeScreenWebController.web";
import Availablestaff from "../../CustomisableUserProfiles/src/EmployeeProfile/Availablestaff.web";
import SelectService from "../../CustomisableUserProfiles/src/EmployeeProfile/SelectService.web";
import { arrowRight, call_us, loading, } from "../../CustomisableUserProfiles/src/assets";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import BookingConfirmedModal from "./BookingConfirmedModal.web";
import { withTranslation } from "react-i18next";
const Cookies = require("js-cookie");
class WalkInBookingBusiness extends BusinessHomeScreenWebController {
    //istanbul ignore next
    constructor(props) {
        super(props);
    }
    //istanbul ignore next
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            let user = localStorage.getItem("userType") || "";
            let token = localStorage.getItem("token") || "";
            let appointementId = localStorage.getItem("appointmentID") || "";
            if (user != "business" || !token) {
                localStorage.clear();
                window.location.replace("/LandingPage");
            }
            else {
                if (appointementId) {
                    this.getAppointmentDetailsById();
                }
                else {
                    this.createAppointmentForBusiness();
                }
                this.getServicesData();
            }
        });
    }
    //istanbul ignore next
    render() {
        let { t } = this.props;
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        return (React.createElement("div", { style: { backgroundColor: "#F2F2F2", overflow: "scroll" } },
            React.createElement(AfterLoginHeader, { navigation: this.props.navigation }),
            React.createElement("div", { style: { display: "flex" } },
                React.createElement(BusinessHomeScreenSidebar, { history: this.props.history }),
                React.createElement("div", { className: "business-home-screen-content-container" }, this.state.appointmentID && (React.createElement("div", { className: webAppDirection === "ltr"
                        ? "booking-container"
                        : "arabic-booking-container" },
                    React.createElement("div", null,
                        React.createElement("span", { className: "booking-container-lable" }, this.state.businessName)),
                    React.createElement("div", { style: { marginTop: "44px" } },
                        React.createElement("span", { className: "booking-container-title" }, t("Walk-in booking"))),
                    React.createElement("div", { className: "booking-main-container" },
                        React.createElement(Paper, { style: {
                                border: 0,
                                borderTop: 0,
                                borderRadius: 0,
                                backgroundColor: "#FFFFFF",
                            }, className: webAppDirection === "ltr"
                                ? "booking-papar-container"
                                : "arabic-booking-papar-container", variant: "outlined" },
                            React.createElement("div", { className: "business-walkin-booking-Paper-left-containers" },
                                React.createElement("div", { className: "service-timer" },
                                    React.createElement("span", { className: "booking-id label" }, `${t("Booking ID")}: ${this.state.bookingID}`),
                                    this.state.status != "Created" &&
                                        this.state.status != "Pending" && (React.createElement("span", { className: "time booking-status" }, t(this.state.status)))),
                                React.createElement("div", { className: "business-walkin-booking-list-item", style: { marginLeft: "0px !important" } },
                                    React.createElement("img", { src: this.state.profile, style: {
                                            marginRight: webAppDirection === "ltr" ? "30px" : "0px",
                                            marginLeft: webAppDirection === "rtl" ? "30px" : "0px",
                                            borderRadius: "50%",
                                        }, width: "56px", height: "56px", alt: "Avatar" }),
                                    React.createElement("div", { className: "user-info", style: { width: "57%" } },
                                        React.createElement("h3", null, this.state.name),
                                        React.createElement("p", null, this.state.email)),
                                    React.createElement("div", { className: "booking-arrow", style: { marginLeft: "25%" } },
                                        React.createElement("a", { id: "contact", href: this.state.clientMobileNumber
                                                ? "tel:" + this.state.clientMobileNumber
                                                : "", className: "walkin-call_us-btn" },
                                            " ",
                                            React.createElement("img", { src: call_us }),
                                            " \u00A0 ",
                                            t("Call Us"),
                                            " "))),
                                React.createElement(Divider, null),
                                React.createElement("div", { style: { marginTop: "30px", marginBottom: "30px" } },
                                    React.createElement("span", { className: "booking-id" }, this.state.bookingDate)),
                                React.createElement(Divider, null),
                                React.createElement("div", { style: { marginTop: "36px", marginBottom: "30px" } },
                                    React.createElement("span", { className: "booking-container-sublable" }, t(`Services`)),
                                    React.createElement("div", { className: "booking-input-container" },
                                        React.createElement("span", { className: "booking-dialog-label" }, t("Services")),
                                        React.createElement(OutlinedInput, { placeholder: t("Select service"), type: "text", value: this.state.selectedServiceName, className: "booking-dialog-input-style", inputProps: { min: 0, style: { marginLeft: 5 } }, readOnly: true, onClick: () => {
                                                this.setState({ openServiceDialog: true });
                                            }, endAdornment: React.createElement(InputAdornment, { position: "end" },
                                                React.createElement(IconButton, { className: "openCalenderstartTime", onClick: () => {
                                                        this.setState({ openServiceDialog: true });
                                                    }, "aria-label": "toggle password visibility", edge: "end" },
                                                    React.createElement("img", { src: arrowRight, alt: "type", width: "25px", height: "26px" }))) })),
                                    React.createElement("div", { className: "booking-input-container" },
                                        React.createElement("span", { className: "booking-dialog-label" }, t("Available staff")),
                                        React.createElement(OutlinedInput, { placeholder: t("Select available staff"), type: "text", readOnly: true, className: "booking-dialog-input-style", value: this.state.selectedEmpName, inputProps: { min: 0, style: { marginLeft: 5 } }, onClick: () => {
                                                this.setState({
                                                    openAvilableStafDialog: true,
                                                });
                                            }, endAdornment: React.createElement(InputAdornment, { position: "end" },
                                                React.createElement(IconButton, { id: "openCalenderstartTime", className: "openCalenderstartTime", 
                                                    //instanbul ignore next
                                                    onClick: () => {
                                                        this.setState({
                                                            openAvilableStafDialog: true,
                                                        });
                                                    }, "aria-label": "toggle password visibility", edge: "end" },
                                                    React.createElement("img", { src: arrowRight, alt: "type", width: "25px", height: "26px" }))) })),
                                    React.createElement("div", { className: "booking-input-container" },
                                        React.createElement("span", { className: "booking-dialog-label" }, t("Booking code:")),
                                        React.createElement(OutlinedInput, { type: "text", className: "booking-dialog-input-style", value: this.state.bookingCode, inputProps: { min: 0, style: { marginLeft: 5 } } }))),
                                React.createElement(Divider, null),
                                React.createElement("div", { style: { marginTop: "36px", marginBottom: "30px" } },
                                    React.createElement("div", { className: "service-timer" },
                                        React.createElement("span", { className: "label booking-container-sublable" }, t("Service timer:")),
                                        React.createElement("span", { className: "time" }, this.renderFormattedDuration())),
                                    this.state.serviceCost && (React.createElement("div", { className: "service-timer" },
                                        React.createElement("span", { className: "label" }, t("Total:")),
                                        React.createElement("span", { className: "time" }, this.state.serviceCost)))))),
                        React.createElement(Paper, { style: {
                                border: 0,
                                borderTop: 0,
                                borderRadius: 16,
                                backgroundColor: "#FFFFFF",
                            }, className: "booking-papar-timer-container", variant: "outlined" },
                            React.createElement("div", { className: "booking-Paper-right-notes-containers" },
                                React.createElement("div", { className: "booking-input-container", style: { marginLeft: "3%" } },
                                    React.createElement("span", { className: "booking-dialog-label" }, t("Notes")),
                                    React.createElement(OutlinedInput, { type: "text", multiline: true, rows: 6, value: this.state.notes, id: "accountOwnerFullName", placeholder: t("Note for staff"), style: { fontFamily: "Poppins", borderRadius: 8 }, onChange: (e) => this.handleNotesChange(e.target.value), inputProps: {
                                            maxLength: 150,
                                            min: 0,
                                            style: { marginLeft: 5 },
                                        } })),
                                React.createElement("div", { className: "booking-input-container", style: {
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    } }, !this.state.startTimerDisable && (React.createElement("span", { className: "booking-dialog-label" }, `${t("Total:")} ${this.state.serviceCost} (${this.renderFormattedDuration()})`))),
                                React.createElement("div", { className: "walkin-booking-cancel-start-button-container" },
                                    !this.state.startTimer &&
                                        !this.state.appointmentCompleted &&
                                        this.state.status != "Started" &&
                                        this.state.status != "Completed" && (React.createElement(Button, { variant: "contained", color: "primary", className: "booking-client-details-dialog-button edit-button", onClick: this.cancelAppointment }, t("Cancel"))),
                                    !this.state.startTimer &&
                                        !this.state.appointmentCompleted &&
                                        this.state.status != "Started" &&
                                        this.state.status != "Completed" && (React.createElement(Button, { variant: "contained", color: "primary", disabled: this.state.startTimerDisable, className: this.state.startTimerDisable
                                            ? "booking-client-details-dialog-button continue-button-disable"
                                            : "booking-client-details-dialog-button continue-button-enable", onClick: this.updateAppointment }, t("Create"))),
                                    (this.state.status === "Started" ||
                                        this.state.status === "Completed") && (React.createElement(Button, { variant: "contained", color: "primary", className: this.state.status === "Completed"
                                            ? "review-button"
                                            : "stop-button", onClick: () => {
                                            this.state.status != "Completed" &&
                                                this.stopTimerApiCall();
                                        } }, this.state.status === "Completed"
                                        ? t("Review Customer")
                                        : t("Stop"))))))),
                    this.state.openAssignedDialog && (React.createElement(BookingConfirmedModal, { openDialog: this.state.openAssignedDialog, continueFunction: () => this.handleNavigation("ManageBusiness/ManageBookings", {
                            from: "listing",
                        }), backToHomeFunction: () => this.handleNavigation("BusinessHomeScreen"), infoMsg: t("The booking has been created."), isBookingCompleted: true, closeDialog: this.closeBookingConfirmedModal })),
                    React.createElement(SelectService, { closeDialog: () => {
                            this.setState(Object.assign(Object.assign({}, this.state), { openServiceDialog: false }));
                        }, openDialog: this.state.openServiceDialog, servicesData: this.state.servicesData, Servicetag: this.state.Servicetag, selectedServiceId: this.state.selectedServiceId, getHoursFromDuration: this.getHoursFromDuration, handleSelectService: this.handleSelectService, formatDuration: this.formatDuration }),
                    React.createElement(Availablestaff, { openDialog: this.state.openAvilableStafDialog, availablestaffData: this.state.AvailablestaffList, handleSelectStaff: this.handleSelectStaff, closeDialog: () => {
                            this.setState(Object.assign(Object.assign({}, this.state), { openAvilableStafDialog: false }));
                        } }),
                    React.createElement(Dialog, { fullWidth: true, maxWidth: "sm", open: this.state.loderDialog, PaperProps: {
                            className: "loder-dialog-Client-Details",
                        } },
                        React.createElement(DialogContent, null,
                            React.createElement("div", { className: "loader-container" },
                                React.createElement("div", null,
                                    React.createElement("img", { src: loading, height: 160, width: 160, alt: "eye" })),
                                React.createElement("div", { style: { marginTop: "20px" } },
                                    React.createElement("span", { className: "booking-container-dialog" }, t("Assigning you available staff"))))),
                        React.createElement(DialogActions, { className: "walkin-dialog-actions" }))))))));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(WalkInBookingBusiness));
// Customizable Area End
