// Customizable Area Start
import React from "react";
import { logo, leftAArrow, eyeOpen, eyeClose } from "./assets";
import { IconButton, InputAdornment, OutlinedInput, Button, Dialog, } from "@material-ui/core";
import "../assets/Styles/login.css";
import LoginWebController from "./LoginWebController";
import CountryCodeModal from "./CountryCodeModal.web";
import { withRouter } from "react-router-dom";
import { cancelIcon, rightBlackAArrow, rightWhiteAArrow, } from "../../dashboard/src/assets";
import { withTranslation } from "react-i18next";
import Loader from "../../../components/src/Loader.web";
const Cookies = require("js-cookie");
class Login extends LoginWebController {
    constructor(props) {
        super(props);
        //istanbul ignore next
        this.borderRight = () => {
            let webAppDirection = localStorage.getItem("webAppDirection") ||
                Cookies.get("webAppDirection") ||
                "ltr";
            return webAppDirection === "ltr"
                ? this.state.phoneNumberError ||
                    this.state.phoneNumberNotRegisteredError ||
                    this.state.mobileNumberEmptyError ||
                    this.state.accountBlockError
                    ? "1px solid #ff453a"
                    : "1px solid #979797"
                : "";
        };
        //istanbul ignore next
        this.borderLeft = () => {
            let webAppDirection = localStorage.getItem("webAppDirection") ||
                Cookies.get("webAppDirection") ||
                "ltr";
            return webAppDirection === "rtl"
                ? this.state.phoneNumberError ||
                    this.state.phoneNumberNotRegisteredError ||
                    this.state.mobileNumberEmptyError ||
                    this.state.accountBlockError
                    ? "1px solid #ff453a"
                    : "1px solid #979797"
                : "";
        };
        this.renderMobileFields = () => {
            let { t } = this.props;
            let webAppDirection = localStorage.getItem("webAppDirection") ||
                Cookies.get("webAppDirection") ||
                "ltr";
            return (React.createElement(React.Fragment, null,
                React.createElement("span", { className: "login-form-label" }, t("Mobile number")),
                React.createElement(OutlinedInput, { type: "text", placeholder: t("Mobile number"), className: "login-form-input-style", name: "phoneNumber", onBlur: this.validatePhoneNumber, value: this.state.phoneNumber, error: this.state.phoneNumberError ||
                        this.state.phoneNumberNotRegisteredError ||
                        this.state.mobileNumberEmptyError ||
                        this.state.accountBlockError, onChange: (e) => this.handleChange(e.target.name, e.target.value), startAdornment: React.createElement(InputAdornment, { position: webAppDirection === "ltr" ? "start" : "end", onClick: () => this.handleCountryCodeChange() },
                        React.createElement(IconButton, { style: {
                                width: 66,
                                height: 54,
                                borderRight: this.borderRight(),
                                borderLeft: this.borderLeft(),
                                cursor: "pointer",
                                justifyContent: "center",
                                borderRadius: "0%",
                            } },
                            React.createElement("span", { className: "country-code-text-style", style: {
                                    color: this.state.phoneNumberError ||
                                        this.state.phoneNumberNotRegisteredError ||
                                        this.state.mobileNumberEmptyError ||
                                        this.state.accountBlockError
                                        ? "#ff453a"
                                        : "#2c2c2e",
                                } }, this.state.countryCode))) }),
                this.state.phoneNumberNotRegisteredError && (React.createElement("span", { className: "error-message-text" }, t("This number is not registered"))),
                this.state.accountBlockError && (React.createElement("span", { className: "error-message-text" }, t("Account Blocked, please signup again with different email"))),
                this.state.phoneNumberError && (React.createElement("span", { className: "error-message-text" }, t("This number is invalid"))),
                this.state.mobileNumberEmptyError && (React.createElement("span", { className: "error-message-text" }, t("Please enter mobile number")))));
        };
        this.renderEmailFields = () => {
            let { t } = this.props;
            return (React.createElement(React.Fragment, null,
                React.createElement("span", { className: "login-form-label" }, t("E-mail address")),
                React.createElement(OutlinedInput, { type: "text", name: "email", placeholder: t("Email"), className: "login-form-input-style email-text-input", value: this.state.email, onBlur: this.validateEmail, error: this.state.emailError ||
                        this.state.emailNotRegisteredError ||
                        this.state.emailEmptyError ||
                        this.state.accountBlockError, onChange: (e) => this.handleChange(e.target.name, e.target.value) }),
                this.state.emailNotRegisteredError && (React.createElement("span", { className: "error-message-text" }, t("This email is not registered"))),
                this.state.accountBlockError && (React.createElement("span", { className: "error-message-text" }, t("Account Blocked, please signup again with different email"))),
                this.state.emailError && (React.createElement("span", { className: "error-message-text" }, t("This email is invalid"))),
                this.state.emailEmptyError && (React.createElement("span", { className: "error-message-text" }, t("Please enter email")))));
        };
    }
    render() {
        let { t } = this.props;
        const { password, isShowPassword, passwordError, passwordIncorreactError, selectedOption, countryCodeModal, } = this.state;
        return (React.createElement(React.Fragment, null,
            React.createElement(Loader, { loading: this.state.loading }),
            React.createElement("div", { className: "login-form-main-container" },
                React.createElement("div", { className: "login-form-fields-main-container" },
                    React.createElement("div", { className: "login-form-container" },
                        React.createElement("div", { className: "form-images-container" },
                            React.createElement("div", { className: "left-arrow-image-container" },
                                React.createElement("img", { src: leftAArrow, "data-testid": "leftArrow", onClick: () => this.handleNavigation("LandingPage") })),
                            React.createElement("div", { style: { width: "58%" } },
                                React.createElement("img", { src: logo, className: "logo-image-style" }))),
                        React.createElement("span", { className: "account-creation-text-style" },
                            t("Create an account or log in to grow and"),
                            React.createElement("br", null),
                            " ",
                            t("manage your business.")),
                        React.createElement("div", { className: "email-or-mobile-choose-container" },
                            React.createElement("div", { className: selectedOption === "mobile"
                                    ? "selected-option"
                                    : "mobile-option", onClick: () => this.handleLoginOptionChange("mobile") }, t("Mobile")),
                            React.createElement("div", { className: selectedOption === "email"
                                    ? "selected-option"
                                    : "email-option", onClick: () => this.handleLoginOptionChange("email") }, t("Email"))),
                        React.createElement("div", { className: "login-form-login-input-container" },
                            React.createElement("div", { className: "login-input-container" }, selectedOption === "email"
                                ? this.renderEmailFields()
                                : this.renderMobileFields()),
                            React.createElement("div", { className: "login-input-container" },
                                React.createElement("span", { className: "password-label-style" },
                                    t("Password"),
                                    " ",
                                    React.createElement("span", { className: "forgot-password-text", onClick: () => this.handleNavigation("ForgotPasswordMobileNumber") }, t("Forgot Password?"))),
                                React.createElement(OutlinedInput, { type: isShowPassword ? "text" : "password", placeholder: t("Password"), className: "login-form-input-style", id: "login-form-input-style", name: "password", value: password, fullWidth: true, onBlur: this.validatePassword, onChange: (e) => this.handleChange(e.target.name, e.target.value), error: passwordError ||
                                        passwordIncorreactError ||
                                        this.state.passwordEmptyError ||
                                        this.state.passwordWithSpaceError, endAdornment: React.createElement(InputAdornment, { className: "showpassword-icon", position: "end" },
                                        React.createElement(IconButton, { "aria-label": "edit mobile number", onClick: this.showPasswordChange, edge: "end", style: { width: 24, height: 24, marginRight: 1 } },
                                            React.createElement("img", { src: isShowPassword ? eyeOpen : eyeClose, height: 24, width: 24, alt: "eye" }))) }),
                                passwordIncorreactError && (React.createElement("span", { className: "error-message-text" }, t("This password you entered is incorrect."))),
                                passwordError && (React.createElement("span", { className: "error-message-text" }, t("This password is invalid."))),
                                this.state.passwordEmptyError && (React.createElement("span", { className: "error-message-text" }, t("Please enter your password"))),
                                this.state.passwordWithSpaceError && (React.createElement("span", { className: "error-message-text" }, t("Please remove space from the password")))),
                            React.createElement(Button, { className: "login-button-style", onClick: () => this.handleLogin() }, t("Login"))),
                        React.createElement("span", { className: "not-a-member-text" },
                            t("Not a member yet?"),
                            " ",
                            React.createElement("span", { className: "register-now-text", onClick: () => this.handleLoginSignupModal() }, t("Register now"))))),
                countryCodeModal && (React.createElement(React.Fragment, null,
                    React.createElement(CountryCodeModal, { open: countryCodeModal, onSelect: this.handleCountryCodeChange }))),
                this.state.loginSignupModal && (React.createElement(LoginSignupModal, { open: this.state.loginSignupModal, onClose: this.handleLoginSignupModal, onSelect: this.handleRedirectPage, t: t })))));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(Login));
export const LoginSignupModal = (props) => {
    const { open, onClose, onSelect, t } = props;
    return (React.createElement(React.Fragment, null,
        React.createElement(Dialog, { open: open, disableAutoFocus: true },
            React.createElement("div", { className: "signup-login-modal-container" },
                React.createElement("div", { className: "signup-login-modal-content-container" },
                    React.createElement("div", { className: "signup-login-modal-header" },
                        React.createElement("span", { className: "signup-login-modal-heading" }, t("Sign Up")),
                        React.createElement("img", { src: cancelIcon, alt: "cancel", style: { cursor: "pointer" }, onClick: () => onClose() })),
                    React.createElement("div", { className: "content-containers for-everyone-container", onClick: () => onSelect("customer") },
                        React.createElement("div", { className: "content-container" },
                            React.createElement("span", { className: "content-container-heading" }, t("For Everyone")),
                            React.createElement("span", { className: "content-container-description" }, t("Book best services near you"))),
                        React.createElement("img", { src: rightBlackAArrow })),
                    React.createElement("div", { className: "content-containers for-business-container", onClick: () => onSelect("business") },
                        React.createElement("div", { className: "content-container" },
                            React.createElement("span", { className: "content-container-heading" }, t("For Business")),
                            React.createElement("span", { className: "content-container-description" }, t("Manage and grow your business"))),
                        React.createElement("img", { src: rightWhiteAArrow })))))));
};
// Customizable Area End
