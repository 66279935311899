var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
//@ts-ignore
import Cookies from "js-cookie";
// Customizable Area Start
// Customizable Area End
export const configJSON = require("./config.js");
export default class ProfileControllerData extends BlockComponent {
    // Customizable Area End
    constructor(props) {
        super(props);
        // Customizable Area Start
        this.apiProfileItemCallId = "";
        this.profileApiCallId = "";
        this.apiGetQueryStrinurl = "";
        this.handleSelectLang = (dir, lng) => {
            localStorage.setItem("webAppDirection", dir);
            localStorage.setItem("webAppLanguage", lng);
            Cookies.set("webAppDirection", dir);
            Cookies.set("webAppLanguage", lng);
        };
        this.getUserName = () => {
            const { attributes } = this.state.profileData;
            return `${attributes.first_name} ${attributes.last_name}`;
        };
        //istanbul ignore next
        this.handleLogoutResponse = (responseJson) => {
            if (responseJson) {
                localStorage.clear();
                this.setState({
                    open: false,
                }, () => {
                    this.props.navigation.navigate("LandingPage");
                });
            }
        };
        //istanbul ignore next
        this.handleRecieve = (apiRequestCallId, responseJson, errorReponse) => {
            if (apiRequestCallId === this.apiProfileItemCallId) {
                this.handleProfileDataResponse(responseJson, errorReponse);
            }
            if (apiRequestCallId === this.logoutApiCallId) {
                this.handleLogoutResponse(responseJson);
            }
            if (apiRequestCallId === this.empUpdateLanguageApiCallId) {
                this.setState({ isLoading: false });
                if ((responseJson === null || responseJson === void 0 ? void 0 : responseJson.message) === "User Updated") {
                    window.location.reload();
                }
            }
        };
        //istanbul ignore next
        this.handleProfileDataResponse = (responseJson, errorReponse) => {
            var _a, _b;
            if (responseJson && !responseJson.errors && responseJson.data) {
                if (responseJson.data.length === 0) {
                    this.setState({
                        errorMsg: "Data Not Found",
                        loading: false,
                    });
                }
                else {
                    this.setState({
                        profileData: responseJson.data,
                        errorMsg: "",
                        loading: false,
                    });
                    localStorage.setItem("profileImage", (_b = (_a = responseJson.data) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.team_member_profile);
                }
            }
            else {
                if (errorReponse === undefined) {
                    this.setState({
                        errorMsg: "Something went wrong",
                        loading: false,
                    });
                }
                else {
                    this.setState({
                        errorMsg: errorReponse,
                        loading: false,
                    });
                }
            }
        };
        // Customizable Area Start
        this.handleUpdateLanguage = (language, direction) => {
            this.setState({ isLoading: true });
            const header = {
                token: localStorage.getItem("token") || "",
                "Content-Type": "application/json",
            };
            let body = {
                language: language,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.empUpdateLanguageApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_login/logins/add_language");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            runEngine.sendMessage(requestMessage.id, requestMessage);
            this.handleSelectLang(direction, language);
            return true;
        };
        this.receive = this.receive.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this._handleWeekdayBtnClick = this._handleWeekdayBtnClick.bind(this);
        console.disableYellowBox = true;
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];
        this.state = {
            profileData: [],
            errorMsg: "",
            token: "",
            loading: false,
            open: false,
            activepage: "profile",
            activeWeekBtn: true,
            languages: [
                {
                    id: 1,
                    name: "Welcome to SqueezMeln",
                    lang_code: "English",
                    code: "en",
                    dir: "ltr",
                },
                {
                    id: 2,
                    name: "SqueezMeIn مرحبا بك الى",
                    lang_code: "Arabic",
                    code: "ar",
                    dir: "rtl",
                },
            ],
            isSelected: "English",
            isLoading: false,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            console.log("getProfileData ::1");
            if (!localStorage.getItem("token")) {
                this.props.navigation.navigate("Login");
            }
            this.setState({
                token: localStorage.getItem("token"),
            }, () => {
                console.log("getProfileData ::2");
                this.getProfileData();
                setTimeout(() => {
                    this.setState({ isLoading: false });
                }, 1000);
            });
            // Customizable Area Start
            let userToken = localStorage.getItem("token");
            let typeOfUser = localStorage.getItem("userType");
            if (!userToken || typeOfUser != "employee") {
                localStorage.clear();
                window.location.replace("/LandingPage");
            }
            // Customizable Area End
        });
    }
    _handleWeekdayBtnClick() {
        this.setState({
            activeWeekBtn: !this.state.activeWeekBtn,
        });
    }
    //istanbul ignore next
    handleLogout() {
        let device_token = localStorage.getItem("device_token") ||
            Cookies.get("Push_NotificationToken") ||
            "";
        let browser_id = localStorage.getItem("BrowserUniqueId") ||
            Cookies.get("BrowserUniqueId") ||
            "";
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("token") || "",
        };
        let body = {
            device_token: device_token,
            platform_id: `${browser_id}${device_token}`,
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.logoutApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_login/logins/logout_account");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
    getProfileData() {
        // Customizable Area Start
        const header = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.apiProfileItemCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/employee_profile");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
        // Customizable Area End
        return true;
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            // Customizable Area Start
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
                if (apiRequestCallId == this.apiProfileItemCallId ||
                    apiRequestCallId === this.logoutApiCallId ||
                    apiRequestCallId === this.empUpdateLanguageApiCallId) {
                    this.handleRecieve(apiRequestCallId, responseJson, errorReponse);
                }
            }
            // Customizable Area End
        });
    }
}
