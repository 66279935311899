import React from "react";
import { Button, Dialog, Avatar, IconButton, CircularProgress, } from "@material-ui/core";
import "../assets/Styles/profile.css";
import ProfileControllerData from "./ProfileControllerData.web";
import { translate, logout, exit, btnext } from "./assets";
import { backdrop } from "../../CustomisableUserProfiles/src/assets";
import { Link } from "react-router-dom";
import AfterLoginHeader from "./AfterLoginHeader.web";
import { withTranslation } from "react-i18next";
import { defultPng } from "../../Squeez_me_in/src/assets";
const Cookies = require("js-cookie");
class ProfilePage extends ProfileControllerData {
    constructor(props) {
        super(props);
    }
    render() {
        const { attributes } = this.state.profileData;
        let { t } = this.props;
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        let webAppLanguage = localStorage.getItem("webAppLanguage") ||
            Cookies.get("webAppLanguage") ||
            "en";
        const businessUser = attributes && attributes.employee_account_id === null;
        console.log("profileComponent ::", attributes, this.state.profileData, businessUser);
        const profileComponent = () => {
            return (React.createElement(React.Fragment, null,
                this.state.isLoading ? (React.createElement("div", { className: "loader" },
                    " ",
                    React.createElement(CircularProgress, { size: 80 }),
                    " ")) : (""),
                React.createElement("div", { className: "profile-container" },
                    React.createElement("div", { className: "subheader-container" },
                        React.createElement("div", { className: "employee-icon" },
                            React.createElement(IconButton, { style: {
                                    width: "24px",
                                    height: "24px",
                                }, onClick: () => {
                                    this.props.navigation.navigate("EmployeeHomeScreen");
                                }, id: "removeImage" },
                                React.createElement("img", { src: backdrop, alt: "logo", width: "24px", height: "27px" }))),
                        React.createElement("span", { id: "card-label" }, t("Profile"))),
                    React.createElement("div", { className: "card card-wrapper" },
                        React.createElement("div", { className: "card-icon" },
                            React.createElement("img", { src: (attributes === null || attributes === void 0 ? void 0 : attributes.team_member_profile) || defultPng, style: {
                                    width: "160px",
                                    height: "160px",
                                    borderRadius: "100%",
                                }, alt: "temp" })),
                        React.createElement("div", { id: "profile-name" }, attributes && this.getUserName()),
                        React.createElement("div", { style: { textAlign: "center" } },
                            React.createElement(Link, { to: businessUser ? "/profile" : "/profile/details", className: "view-profile-link" }, businessUser ? t("Edit profile") : t("View profile"))),
                        !businessUser && (React.createElement("div", { id: "language-btn", className: "profilepage-list-item btn-wrapper", onClick: () => this.setState({ activepage: "language" }) },
                            React.createElement(Avatar, { alt: "profile-icon", className: "btn-icon", src: translate }),
                            React.createElement("div", { className: "user-info" },
                                React.createElement("span", { className: "profile-font" }, t("Language"))),
                            React.createElement("div", { className: "profilepage-arrow", style: {
                                    marginRight: webAppDirection === "rtl" ? "auto" : "0",
                                    marginLeft: webAppDirection === "rtl" ? "0" : "auto",
                                } },
                                React.createElement("img", { src: btnext, alt: "Right arrow" })))),
                        businessUser && (React.createElement("div", { className: "btn-wrapper", onClick: () => { } },
                            React.createElement(Avatar, { alt: "profile-icon", className: "btn-icon", src: translate }),
                            "Subscription")),
                        businessUser && (React.createElement("div", { className: "btn-wrapper", onClick: () => { } },
                            React.createElement(Avatar, { alt: "profile-icon", className: "btn-icon", src: translate }),
                            "Submit your advertisement")),
                        businessUser && (React.createElement("div", { className: "btn-wrapper", onClick: () => { } },
                            React.createElement(Avatar, { alt: "profile-icon", className: "btn-icon", src: translate }),
                            "Payment methods")),
                        businessUser && (React.createElement("div", { className: "btn-wrapper", onClick: () => { } },
                            React.createElement(Avatar, { alt: "profile-icon", className: "btn-icon", src: translate }),
                            "Settings")),
                        businessUser && (React.createElement("div", { className: "btn-wrapper", onClick: () => { } },
                            React.createElement(Avatar, { alt: "profile-icon", className: "btn-icon", src: translate }),
                            "Contact SqueezMeIn")),
                        businessUser && (React.createElement("div", { className: "btn-wrapper", onClick: () => { } },
                            React.createElement(Avatar, { alt: "profile-icon", className: "btn-icon", src: translate }),
                            "Deactivate your account")),
                        React.createElement("div", { className: "btn-logout profilepage-list-item btn-wrapper", onClick: () => this.setState({ open: true }) },
                            React.createElement(Avatar, { alt: "profile-icon", className: "btn-icon", src: logout }),
                            React.createElement("div", { className: "user-info" },
                                React.createElement("span", { className: "profile-font profile-font-color" }, t("Logout"))))))));
        };
        const languageComponent = () => {
            return (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "profile-Language-container" },
                    React.createElement("div", { className: "card card_language_left" },
                        React.createElement("div", { className: "card-icon" },
                            React.createElement("div", { className: "avatar_container" },
                                React.createElement("img", { src: attributes && attributes.team_member_profile, style: {
                                        width: "120px",
                                        height: "120px",
                                        borderRadius: "100%",
                                    }, className: "large_icon" })),
                            React.createElement("div", { id: "profile-name" }, attributes && attributes.full_name),
                            React.createElement(Link, { to: "/profile/details", className: "view-profile-link" }, t("View Profile"))),
                        React.createElement("div", { className: "btn-wrapper", style: {
                                background: "#D2DFF8",
                                borderRadius: "12px",
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#2C2C2E",
                                padding: "11px 0 11px 20px",
                            } },
                            React.createElement(Avatar, { alt: "profile-icon", className: "btn-icon", src: translate }),
                            t("Language")),
                        React.createElement("div", { className: "btn-wrapper btn-logout", onClick: () => {
                                this.setState({ open: true });
                            }, style: {
                                borderRadius: "12px",
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#FF453A",
                                padding: "11px 0 11px 20px",
                            } },
                            React.createElement(Avatar, { alt: "profile-icon", className: "btn-icon", src: logout }),
                            t("Logout"))),
                    React.createElement("div", { style: {
                            marginRight: webAppDirection === "rtl" ? "2%" : "0",
                            marginLeft: webAppDirection === "rtl" ? "0" : "2%",
                        } },
                        React.createElement("h1", { className: "card_title" }, t("Change Language")),
                        React.createElement("div", { className: "card card_language_right" },
                            React.createElement("div", { className: "btn-language-wrapper" }, this.state.languages.map((item, index) => {
                                return (React.createElement("div", { onClick: () => this.handleUpdateLanguage(item.code, item.dir), id: `languagebtn${index}`, className: `btn-language-option ${webAppLanguage === item.code ? "active-btn" : ""}` },
                                    React.createElement("div", { style: { marginBottom: "12px" } }, item.name),
                                    React.createElement("div", null,
                                        "/",
                                        item.lang_code)));
                            })))))));
        };
        return (React.createElement("div", { style: { overflow: "scroll", height: "100vh" } },
            React.createElement("div", { className: "main-header" },
                React.createElement(AfterLoginHeader, { navigation: this.props.navigation })),
            attributes && (React.createElement(React.Fragment, null,
                this.state.activepage === "profile" && profileComponent(),
                this.state.activepage === "language" && languageComponent())),
            React.createElement(Dialog, { open: this.state.open },
                React.createElement("div", { className: "logout_modal" },
                    React.createElement("img", { alt: "exit-icon", className: "modal-icon", src: exit }),
                    React.createElement("div", { className: "logout_confirmation_text" }, t("Are you sure you want to logout?")),
                    React.createElement(Button, { className: "logout-modal-no-button-style", onClick: () => this.setState({ open: false }) }, t("No")),
                    React.createElement(Button, { className: "logout-modal-yes-button-style", onClick: () => this.handleLogout() }, t("Yes"))))));
    }
}
//@ts-ignore
export default withTranslation()(ProfilePage);
