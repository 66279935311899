// Customizable Area Start
import React from "react";
import AfterLoginHeader from "../../../dashboard/src/AfterLoginHeader.web";
import "../../../ContentModeration/assets/Styles/manage-business.css";
import EmployeeHomeScreenSidebar from "../EmployeeProfile/EmployeeHomeScreenSidebar.web";
import { withRouter } from "react-router-dom";
import CalendarEvent from "../../../ContentModeration/src/CalendarEvent.web";
import AppointmentmanagementController from "../EmployeeProfile/AppointmentmanagementController.web";
//@ts-ignore
import DatePicker from "react-horizontal-datepicker";
import moment from "moment";
import { clipboardIcon, walkInPencil, } from "../../../ContentModeration/src/assets";
import { Button } from "@material-ui/core";
import { leftAArrow } from "../../../dashboard/src/assets";
import SearchClient from "./SearchClient.web";
import VerifyClient from "./VerifyClient.web";
import ClientDetails from "./ClientDetails.web";
import { hexogon } from "../assets";
import { withTranslation } from "react-i18next";
class EmployeeAppointmentsDetail extends AppointmentmanagementController {
    render() {
        //@ts-ignore
        let { t } = this.props;
        return (React.createElement("div", { style: { backgroundColor: "#F2F2F2", overflow: "scroll" } },
            React.createElement(AfterLoginHeader, { navigation: this.props.navigation }),
            React.createElement("div", { style: { display: "flex" } },
                React.createElement(EmployeeHomeScreenSidebar, { history: this.props.history }),
                React.createElement("div", { className: "business-home-screen-content-container" },
                    this.state.isActive === false && (React.createElement("div", { className: "reschedul-error-container", style: {
                            marginLeft: "50px",
                            marginTop: "50px",
                            backgroundColor: "#ff453a21",
                        } },
                        React.createElement("span", { style: { display: "flex", alignItems: "center" } },
                            React.createElement("img", { src: hexogon, className: "reschedul-error-img" }),
                            React.createElement("span", { className: "reschedul-error-text", style: { fontSize: "18px", padding: "10px" } }, t("Contact SqueezMeln to make further bookings"))))),
                    React.createElement("div", { className: "calendar-and-buttons-details-screen-sub-container" },
                        React.createElement("span", { className: "business-detail-screen-date-container" },
                            React.createElement("img", { src: leftAArrow, alt: "arrow", id: "back-button", onClick: () => {
                                    this.setState(Object.assign(Object.assign({}, this.state), { selectedDate: new Date() }));
                                    this.handleNavigation("EmployeeHomeScreen");
                                } }),
                            moment(this.state.selectedDate).format("dddd, MMMM DD YYYY")),
                        React.createElement("div", { className: "upcoming-button-and-walkin-detail-page-container" },
                            React.createElement(Button, { className: "upcoming-bookings-button-style-home-screen", onClick: () => {
                                    this.handleNavigation("ManageEmployee/EmployeeManageBookings", { from: "listing" });
                                }, id: "upcoming-detail-button" },
                                React.createElement("img", { src: clipboardIcon, alt: "clipboardIcon" }),
                                "\u00A0 ",
                                t("Upcoming Bookings")),
                            React.createElement(Button, { onClick: this.handleOpenSearchClientModal, className: this.state.isBusinessDayOff || !this.state.isActive
                                    ? "disable-walk-in-button-style-home-screen"
                                    : "walk-in-button-style-home-screen", disabled: !this.state.isActive || this.state.isBusinessDayOff },
                                React.createElement("img", { src: walkInPencil, alt: "walkInPencil" }),
                                " \u00A0",
                                t("Walk-in")))),
                    React.createElement("div", { style: { padding: "2% 5%" } },
                        React.createElement(DatePicker, { getSelectedDay: (e) => this.handleDateClick(e), selectDate: this.state.selectedDate, labelFormat: "MMMM", color: "#1E5FEA", enableScroll: true, enableDays: 180 })),
                    React.createElement(CalendarEvent //@ts-ignore
                    , { data: this.state.calendarData, screen: "details", weekend: this.state.isBusinessDayOff }),
                    React.createElement(SearchClient, { openDialog: this.state.openSearchClientDialog, handleChange: this.handleChange, onClick: this.handleClick, onClose: this.closeDialog, clientNumber: this.state.contactNumber, responseMsg: this.state.responseMsg, userExistsMsg: this.state.userExistsMsg, userExists: this.state.userExists, renderErrorMessageforProfile: this.renderErrorMessageforProfile, handleCountryCodeChange: this.handleCountryCodeChange, openCountryCodeModal: this.changeStateValue, countryCode: this.state.selectedCountryCode }),
                    React.createElement(VerifyClient, { value: this.state, renderErrorMessageforProfile: this.renderErrorMessageforProfile, onClick: this.verifyOtp, onClose: this.closeDialog, onResend: this.handleResendOtp, handleOtpChange: this.handleOtpChange, openDialog: this.state.openVerifyDialog }),
                    React.createElement(ClientDetails, { openDialog: this.state.openDialogForClientDetails, value: this.state, renderErrorMessageforProfile: this.renderErrorMessageforProfile, handleContinue: this.handleContinue, onClose: this.closeDialog })))));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(EmployeeAppointmentsDetail));
// Customizable Area End
