var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Customizable Area Start
import React from "react";
import "../assets/Styles/walkin-business.web.css";
import { calender_prev_arrow, calender_next_arrow, accordian_arrow, } from "../../../blocks/Squeez_me_in/src/assets";
import "../assets/Styles/daterange.web.scss";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, AccordionDetails, AccordionSummary, Accordion, Typography, } from "@material-ui/core";
import moment from "moment";
import { cancellatioNoteIcon } from "./assets";
import CloseIcon from "@material-ui/icons/Close";
import { BlockComponent } from "framework/src/BlockComponent";
import { withTranslation } from "react-i18next";
class RescheduleModal extends BlockComponent {
    constructor(props) {
        super(props);
        //istanbul ignore next
        this.timeSlotValueView = () => {
            var _a, _b, _c;
            //@ts-ignore
            let { t } = this.props;
            if (((_a = this.props) === null || _a === void 0 ? void 0 : _a.state.timeslotValue) === "Select your slot") {
                return t((_b = this.props) === null || _b === void 0 ? void 0 : _b.state.timeslotValue);
            }
            else {
                let [time, amPm] = (_c = this.props) === null || _c === void 0 ? void 0 : _c.state.timeslotValue.split(" ");
                return `${time} ${t(amPm)}`;
            }
        };
        this.state = {
            timer: 600,
            time: {
                minutes: 0,
                seconds: 0,
            },
        };
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
    }
    secondsToTime(secs) {
        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);
        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);
        let obj = {
            minutes: minutes < 10 ? "0" + minutes : minutes,
            seconds: seconds < 10 ? "0" + seconds : seconds,
        };
        return obj;
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            let timeLeftVar = this.secondsToTime(600);
            this.setState({ time: timeLeftVar, timer: 600 }, () => {
                this.startTimer();
                this.props.getShopHolidayDetails();
            });
        });
    }
    startTimer() {
        clearInterval(this.timer);
        this.timer = 0;
        if (this.timer == 0 && this.state.timer > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }
    countDown() {
        var _a;
        let seconds = this.state.timer - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            timer: seconds,
        });
        //istanbul ignore next
        if (seconds == 0) {
            (_a = this.props) === null || _a === void 0 ? void 0 : _a.sessionExpiredChange(true);
            clearInterval(this.timer);
        }
    }
    render() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2;
        let appointment = JSON.parse(localStorage.getItem("appointment") || "{}");
        //@ts-ignore
        let { t } = this.props;
        return (React.createElement(React.Fragment, null,
            React.createElement(Dialog, { open: (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.isReScheduleModalOpen, maxWidth: "sm", fullWidth: true, PaperProps: {
                    style: {
                        height: "970px !important",
                        width: "573px !important",
                        display: "flex",
                        alignItems: "center",
                    },
                } },
                React.createElement(DialogTitle, { disableTypography: true, className: "reschedule-dialog-title" },
                    React.createElement(CloseIcon, { className: "reschedule-dialog-close-icon", onClick: (_c = this.props) === null || _c === void 0 ? void 0 : _c.closeRescheduleModal }),
                    React.createElement("span", { className: "select-date-range-text" }, t("Select Date Range"))),
                React.createElement(DialogContent, { style: { maxWidth: "100%" } },
                    React.createElement("div", { className: "manage-booking-reschdule-calendar", "data-test-id": "manage-booking-reschdule-calendar" },
                        React.createElement("div", { className: "manage-booking-reschdule-calendar-sub-div" },
                            React.createElement(Calendar, { minDate: new Date(), goToRangeStartOnSelect: false, value: (_d = this.props) === null || _d === void 0 ? void 0 : _d.state.selectedRescheduleDate, onChange: (_e = this.props) === null || _e === void 0 ? void 0 : _e.handleRescheduleDateChange, tileDisabled: ({ date }) => { var _a; return (_a = this.props) === null || _a === void 0 ? void 0 : _a.state.disabledDays.includes(date.getDay()); }, prevLabel: React.createElement("img", { src: calender_prev_arrow }), nextLabel: React.createElement("img", { src: calender_next_arrow }), tileClassName: ({ date, view }) => {
                                    var _a, _b;
                                    //istanbul ignore next
                                    if ((_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.state.holidays) === null || _b === void 0 ? void 0 : _b.find((x) => x == moment(date).format("YYYY-MM-DD"))) {
                                        return "disable-date-highlight";
                                    }
                                    //istanbul ignore next
                                    if (moment(date).format("LL") ==
                                        moment(new Date()).format("LL")) {
                                        return "current-date-highlight";
                                    }
                                }, calendarType: "US", formatMonthYear: (locale, date) => date.toLocaleString(locale, {
                                    month: "short",
                                    year: "numeric",
                                }) }),
                            ((_f = this.props) === null || _f === void 0 ? void 0 : _f.state.dateRequiredError) && (React.createElement("p", { className: "p-require-tag" }, t("Please select date")))),
                        React.createElement("div", { style: { marginTop: "30px" }, className: "manage-booking-business-element-conatiner", "data-test-id": "manage-booking-business-element-conatiner-1", onClick: () => {
                                var _a, _b;
                                if ((_a = this.props) === null || _a === void 0 ? void 0 : _a.state.isTimeSelectOpen) {
                                    (_b = this.props) === null || _b === void 0 ? void 0 : _b.closeTimeSelect();
                                }
                            } },
                            React.createElement("span", { className: "manage-bookings-business-total-price-text" }, t("Availability")),
                            React.createElement("span", { className: "reschedule-ten-minutes-timer" }, `${this.state.time.minutes} : ${this.state.time.seconds}`)),
                        React.createElement("div", { className: "timeslot-div" },
                            React.createElement("span", { className: "manage-bookings-business-total-price-text", "data-testid": "closeTimeSelect", onClick: () => {
                                    var _a, _b;
                                    if ((_a = this.props) === null || _a === void 0 ? void 0 : _a.state.isTimeSelectOpen) {
                                        (_b = this.props) === null || _b === void 0 ? void 0 : _b.closeTimeSelect();
                                    }
                                } }, t("Time slot")),
                            React.createElement(Accordion, { className: "accordian-main-div", style: ((_g = this.props) === null || _g === void 0 ? void 0 : _g.state.timeRequiredError) ? { border: "2px solid red" }
                                    : { border: "2px solid #757575" }, expanded: (_h = this.props) === null || _h === void 0 ? void 0 : _h.state.isTimeSelectOpen },
                                React.createElement(AccordionSummary, { onClick: () => {
                                        var _a, _b, _c, _d;
                                        ((_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.state.availableTime) === null || _b === void 0 ? void 0 : _b.length) !== 0
                                            ? (_c = this.props) === null || _c === void 0 ? void 0 : _c.openTimeSelect() : (_d = this.props) === null || _d === void 0 ? void 0 : _d.closeTimeSelect();
                                    }, expandIcon: React.createElement("img", { style: ((_j = this.props) === null || _j === void 0 ? void 0 : _j.state.timeRequiredError) ? {
                                            filter: " invert(67%) sepia(89%) saturate(7492%) hue-rotate(346deg) brightness(84%) contrast(146%)",
                                        }
                                            : { filter: "initial" }, src: accordian_arrow }), "aria-controls": "panel1a-content", id: "panel1a-header" },
                                    React.createElement(Typography, { style: ((_k = this.props) === null || _k === void 0 ? void 0 : _k.state.timeRequiredError) ? { color: "red" }
                                            : { color: "#2c2c2e" } }, ((_m = (_l = this.props) === null || _l === void 0 ? void 0 : _l.state.availableTime) === null || _m === void 0 ? void 0 : _m.length) !== 0
                                        ? this.timeSlotValueView()
                                        : t("No slot available"))), (_p = (_o = this.props) === null || _o === void 0 ? void 0 : _o.state.availableTime) === null || _p === void 0 ? void 0 :
                                _p.map((element, index) => {
                                    let [time, amPm] = element === null || element === void 0 ? void 0 : element.time.split(" ");
                                    return (React.createElement(React.Fragment, { key: element === null || element === void 0 ? void 0 : element.id },
                                        React.createElement("hr", null),
                                        React.createElement(AccordionDetails, { style: { cursor: "pointer" }, id: "accordian_detail_div", onClick: () => {
                                                this.props.onSlotClick(element);
                                            } },
                                            React.createElement(Typography, { style: (element === null || element === void 0 ? void 0 : element.enable) ? { color: "#2c2c2e" }
                                                    : { color: "rgba(0,0,0,0.2)" } }, `${time} ${t(amPm)}`))));
                                })),
                            ((_q = this.props) === null || _q === void 0 ? void 0 : _q.state.timeRequiredError) && (React.createElement("p", { className: "p-require-tag" }, t("Please select time slot"))),
                            React.createElement("hr", { style: { marginTop: "30px" }, className: "hr-tag" })),
                        React.createElement("div", { style: { marginTop: "30px" }, className: "manage-booking-business-element-conatiner", "data-test-id": "manage-booking-business-element-conatiner-2", onClick: () => {
                                var _a, _b;
                                if ((_a = this.props) === null || _a === void 0 ? void 0 : _a.state.isTimeSelectOpen) {
                                    (_b = this.props) === null || _b === void 0 ? void 0 : _b.closeTimeSelect();
                                }
                            } },
                            React.createElement("span", { className: "manage-bookings-business-total-price-text" }, `${(_v = (_u = (_t = (_s = (_r = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _r === void 0 ? void 0 : _r.business_profile) === null || _s === void 0 ? void 0 : _s.data) === null || _t === void 0 ? void 0 : _t.attributes) === null || _u === void 0 ? void 0 : _u.service_provided) === null || _v === void 0 ? void 0 : _v.name}'s ${(_x = (_w = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _w === void 0 ? void 0 : _w.service) === null || _x === void 0 ? void 0 : _x.display_name}`),
                            React.createElement("span", { className: "manage-bookings-business-total-price-text" }, `${(_z = (_y = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _y === void 0 ? void 0 : _y.service) === null || _z === void 0 ? void 0 : _z.currency} ${(_1 = (_0 = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _0 === void 0 ? void 0 : _0.service) === null || _1 === void 0 ? void 0 : _1.service_cost}`)),
                        React.createElement("div", { style: { marginTop: "30px" } },
                            React.createElement("div", { className: "manage-booking-cancellation-container" },
                                React.createElement("img", { src: cancellatioNoteIcon, alt: "cancellatioNoteIcon" }),
                                React.createElement("span", { className: "manage-booking-cancellation-message" }, t(`Confirm from the customer before rescheduling the appointment. Re-schedule should be done atleast 24 hours before the appointment.`)))))),
                React.createElement(DialogActions, null,
                    React.createElement("div", { style: {
                            marginTop: "30px",
                            marginBottom: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        } },
                        React.createElement(Button, { style: {
                                width: "413px",
                                height: "56px",
                                backgroundColor: "#1e5fea",
                                borderRadius: "28px",
                                color: "#FFFFFF",
                                textTransform: "none",
                                maxWidth: "70%",
                                fontSize: "18px",
                            }, className: "reschedule-confirm-button-style", onClick: (_2 = this.props) === null || _2 === void 0 ? void 0 : _2.getAvailableStaffApi }, t("Confirm")))))));
    }
}
//@ts-ignore
export default withTranslation()(RescheduleModal);
// Customizable Area End
