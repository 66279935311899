import React from "react";
import { Dialog, DialogContent, CircularProgress } from "@material-ui/core";
export default function Loader(props) {
    return props.loading ? (React.createElement(Dialog, { open: true, PaperProps: {
            className: "service-loader-dialog",
        } },
        React.createElement(DialogContent, null,
            React.createElement("div", { style: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                } },
                React.createElement(CircularProgress, null))))) : (React.createElement("div", null));
}
