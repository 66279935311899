import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { OutlinedInput, IconButton, InputAdornment } from "@material-ui/core";
import { countryCodeSearchIcon } from "../../CustomisableUserProfiles/src/assets";
import { BlockComponent } from "framework/src/BlockComponent";
import { withTranslation } from "react-i18next";
import { defultPng } from "../../Squeez_me_in/src/assets";
class RescheduleEmployeeModal extends BlockComponent {
    //istanbul ignore next
    render() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        let appointment = JSON.parse(localStorage.getItem("appointment") || "{}");
        //@ts-ignore
        let { t } = this.props;
        return (React.createElement(Dialog, { open: (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.isRescheduleEmployeeSelecting, maxWidth: "sm", fullWidth: true, PaperProps: {
                className: "availablestaff-dialog-Client-Details",
            } },
            React.createElement(DialogTitle, { disableTypography: true, className: "booking-dialog-title" },
                React.createElement("div", { className: "booking-form-title" }, t("Available staff")),
                React.createElement("div", { style: {
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        color: "#2C2C2E",
                        marginTop: "1%",
                        marginLeft: "4%",
                    } },
                    t(`For ${(_g = (_f = (_e = (_d = (_c = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _c === void 0 ? void 0 : _c.business_profile) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.attributes) === null || _f === void 0 ? void 0 : _f.service_provided) === null || _g === void 0 ? void 0 : _g.name}'s`),
                    ` ${(_j = (_h = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _h === void 0 ? void 0 : _h.service) === null || _j === void 0 ? void 0 : _j.display_name}`),
                React.createElement("div", { className: "booking-search-textfield" },
                    React.createElement(OutlinedInput, { type: "text", placeholder: t("Search for Staff"), className: "booking-search-service-input-style", 
                        // onChange={(e) => this.handleSearch(e.target.value)}
                        startAdornment: React.createElement(InputAdornment, { position: "start" },
                            React.createElement(IconButton, { "aria-label": "edit mobile number", edge: "end", style: {
                                    width: 24,
                                    height: 24,
                                    marginRight: 2,
                                    marginLeft: 10,
                                } },
                                React.createElement("img", { src: countryCodeSearchIcon, height: 14, width: 14, alt: "eye" }))) }))),
            React.createElement(DialogContent, { style: { marginBottom: "50px" } },
                React.createElement("div", { className: "availablestaff-main-container" }, (_l = (_k = this.props) === null || _k === void 0 ? void 0 : _k.state) === null || _l === void 0 ? void 0 :
                    _l.availablestaffData.map((item) => {
                        var _a, _b, _c, _d;
                        return (React.createElement("div", { style: { cursor: "pointer" }, className: "list-item", onClick: () => {
                                var _a;
                                (_a = this.props) === null || _a === void 0 ? void 0 : _a.onEmployeeSelect(item);
                            }, key: item.id },
                            React.createElement("img", { src: ((_a = item === null || item === void 0 ? void 0 : item.attributes) === null || _a === void 0 ? void 0 : _a.team_member_profile) || defultPng, alt: "Avatar" }),
                            React.createElement("div", { className: "user-info" },
                                React.createElement("h3", null, (_b = item === null || item === void 0 ? void 0 : item.attributes) === null || _b === void 0 ? void 0 :
                                    _b.first_name,
                                    " ", (_c = item === null || item === void 0 ? void 0 : item.attributes) === null || _c === void 0 ? void 0 :
                                    _c.last_name),
                                React.createElement("p", null, (_d = item === null || item === void 0 ? void 0 : item.attributes) === null || _d === void 0 ? void 0 : _d.title))));
                    }),
                    ((_o = (_m = this.props) === null || _m === void 0 ? void 0 : _m.state) === null || _o === void 0 ? void 0 : _o.availablestaffData.length) === 0 && (React.createElement("span", { className: "add-new-categories-message-text" }, t("No staff available"))))),
            React.createElement(DialogActions, { className: "walkin-dialog-actions" })));
    }
}
//@ts-ignore
export default withTranslation()(RescheduleEmployeeModal);
