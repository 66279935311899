var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";
// Customizable Area Start
// import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import React from "react";
export default class CustomisableServicesWebController extends BlockComponent {
    constructor(props) {
        super(props);
        this.unSetServiceData = () => {
            this.setState({
                serviceName: "",
                arabicServiceName: "",
                description: "",
                cost: "",
                duration: "",
                serviceDetailId: "",
                serviceNameError: "",
                descriptionError: "",
                arabicServiceNameError: "",
                durationError: "",
                costError: "",
            });
        };
        this.setServiceData = (servieData) => {
            console.log("setServiceData :", servieData);
            this.setState({
                serviceName: servieData.service_name,
                arabicServiceName: servieData.arabic_name,
                serviceNameError: "",
                arabicServiceNameError: "",
                descriptionError: "",
                durationError: "",
                costError: "",
                description: servieData.description,
                cost: servieData.service_cost,
                duration: servieData.duration,
                serviceDetailId: servieData.id
            }, () => {
                console.log("state :::-> ", this.state);
            });
        };
        // web events
        this.setStateValue = (value, action, type, categoryName, categoryArabicName, servieData) => {
            if (servieData !== undefined) {
                this.setServiceData(servieData);
            }
            this.setState({ selectionType: type, categoryName: categoryName, arabicCategoryName: categoryArabicName, categoryNameError: "", arabicCategoryNameError: "" }, () => {
                console.log("categoryName ::", this.state.selectedCategoryId);
            });
            if (type === 'category') {
                const dialogHeader = action === 'add' ? "Add new category" : "Edit Category";
                this.setState({ openCategoryDialog: value, actionType: action, categoryDialogHeader: dialogHeader });
            }
            else {
                const dialogHeader = action === 'add' ? "Add another service" : "Edit service";
                this.setState({ serviceDialog: value, actionType: action, serviceDialogHeader: dialogHeader });
            }
        };
        this.validateCategoryDialog = () => {
            if (this.state.categoryName === "") {
                this.setState({ categoryNameError: "Please enter category name" });
            }
            else if (this.state.arabicCategoryName === "") {
                this.setState({ arabicCategoryNameError: "Please enter category name in arabic" });
            }
            else {
                this.unSetServiceData();
                this.setStateValue(true, 'add', 'service', this.state.categoryName, this.state.arabicCategoryName);
            }
        };
        // web events
        this.setInputValue = (text) => {
            this.setState({ txtInputValue: text });
        };
        this.getServiceProvidedName = () => {
            if (typeof window !== "undefined") {
                return window.localStorage.getItem("serviceProvideFor");
            }
        };
        this.handleSelectToggle = () => {
            this.setState(prevState => ({ isSelectOpen: !prevState.isSelectOpen }));
        };
        this.apiCallFunc = (type, param) => {
            if (type === 'edit') {
                this.UpdateCategoryAPICall("category", param);
            }
            else if (type === 'add') {
                this.addBusinessCategoryServices(param);
            }
        };
        this.isValidCostInput = (inputValue) => {
            const costRegex = /^\d+(\.\d{0,3})?$/;
            if (costRegex.test(inputValue)) {
                this.setState({ cost: inputValue, costError: "" });
            }
            else if (inputValue === "") {
                this.setState({ cost: "", costError: "Please enter service cost" });
            }
        };
        this.getHoursFromDuration = (data) => {
            if (moment(data.duration, 'HH:mm:ss').hours() === 1) {
                return "60";
            }
            else {
                return moment(data.duration, 'HH:mm:ss').minutes();
            }
        };
        this.callDeleteAPICall = () => {
            if (this.state.setAlertFor === "Category") {
                this.DeleteCategoryAPICall();
            }
            if ((this.state.setAlertFor === "Service")) {
                this.DeleteServiceAPICall();
            }
        };
        this.validateCategoryName = () => {
            if (this.state.categoryName === "") {
                this.setState({ categoryNameError: "Please enter category name" });
            }
            else {
                this.setState({ categoryNameError: "" });
            }
        };
        this.validateArabicCategoryName = () => {
            if (this.state.arabicCategoryName === "") {
                this.setState({ arabicCategoryNameError: "Please enter category name in arabic" });
            }
            else {
                this.setState({ arabicCategoryNameError: "" });
            }
        };
        this.validateField = function (fieldName, errorMessage) {
            if (this.state[fieldName] === "") {
                this.setState({ [`${fieldName}Error`]: errorMessage });
            }
            else {
                this.setState({ [`${fieldName}Error`]: "" });
            }
        };
        this.handleSave = (serviceAction) => {
            const { categoryName, serviceName, description, cost, duration, arabicCategoryName, arabicServiceName } = this.state;
            let isError = false;
            const setFieldError = (field, message) => {
                this.setState(prevState => (Object.assign(Object.assign({}, prevState), { [`${field}Error`]: message })));
                isError = true;
            };
            if (!categoryName)
                setFieldError("categoryName", "Please enter category name");
            if (!arabicCategoryName)
                setFieldError("arabicCategoryName", "Please enter category name in arabic");
            if (!serviceName)
                setFieldError("serviceName", "Please enter service name");
            if (!arabicServiceName)
                setFieldError("arabicServiceName", "Please enter service name in arabic");
            if (!description)
                setFieldError("description", "Please enter description");
            if (!cost)
                setFieldError("cost", "Please enter service cost");
            if (!duration)
                setFieldError("duration", "select duration");
            this.callApiFunction(isError, serviceAction);
        };
        this.fetchBusinessCategoryServices = () => __awaiter(this, void 0, void 0, function* () {
            const header = {
                "Content-Type": "application/json",
                token: this.state.token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.apiGetBusinessCategoryServicesCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/show_business_category");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.addBusinessCategoryServices = (value) => {
            this.setState({ loader: true, catalogueMsg: "Added Successfully!" });
            const header = {
                "Content-Type": "application/json",
                token: this.state.token,
            };
            if (this.state.categoryName === "") {
                this.setState({ categoryNameError: "Please enter category name", loader: false });
                return;
            }
            if (this.state.arabicCategoryName === "") {
                this.setState({ categoryNameError: "Please enter category name in arabic", loader: false });
                return;
            }
            let httpBody;
            if (value === "withoutService") {
                httpBody = {
                    name: this.state.categoryName,
                    arabic_name: this.state.arabicCategoryName
                };
            }
            else {
                httpBody = {
                    name: this.state.categoryName,
                    arabic_name: this.state.arabicCategoryName,
                    user_business_sub_categories_attributes: [
                        {
                            service_name: this.state.serviceName,
                            arabic_name: this.state.arabicServiceName,
                            service_cost: this.state.cost,
                            duration: this.state.duration,
                            description: this.state.description,
                        },
                    ],
                };
            }
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.apiAddBusinessCategoryServicesCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/business_categories");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.UpdateCategoryAPICall = (updateType, value) => {
            this.setState({ loader: true, catalogueMsg: "Updated Successfully!" });
            const header = {
                "Content-Type": "application/json",
                token: this.state.token,
            };
            if (this.state.categoryName === "") {
                this.setState({ categoryNameError: "Please enter category name", loader: false });
                return;
            }
            if (this.state.arabicCategoryName === "") {
                this.setState({ loader: false, arabicCategoryNameError: "Please enter category name in arabic" });
                return;
            }
            let httpBody;
            if (updateType === "category") {
                if (value === "withoutService") {
                    httpBody = {
                        name: this.state.categoryName,
                        arabic_name: this.state.arabicCategoryName
                    };
                }
                else {
                    httpBody = {
                        name: this.state.categoryName,
                        arabic_name: this.state.arabicCategoryName,
                        user_business_sub_categories_attributes: [
                            {
                                service_name: this.state.serviceName,
                                arabic_name: this.state.arabicServiceName,
                                service_cost: this.state.cost,
                                duration: this.state.duration,
                                description: this.state.description,
                                _destroy: false,
                            },
                        ],
                    };
                }
            }
            else {
                httpBody = {
                    name: this.state.categoryName,
                    arabic_name: this.state.arabicCategoryName,
                    user_business_sub_categories_attributes: [
                        {
                            id: this.state.serviceDetailId,
                            service_name: this.state.serviceName,
                            arabic_name: this.state.arabicServiceName,
                            service_cost: this.state.cost,
                            duration: this.state.duration,
                            description: this.state.description,
                            _destroy: false,
                        },
                    ],
                };
            }
            console.log("http body==>3", httpBody);
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.apiUpdateServiceCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/business_categories" + "/" + this.state.selectedCategoryId);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.AddServiceAPICall = () => __awaiter(this, void 0, void 0, function* () {
            this.setState({ loader: true, catalogueMsg: "Added Successfully!" });
            const header = {
                "Content-Type": "application/json",
                token: this.state.token,
            };
            const httpBody = {
                name: this.state.categoryName,
                arabic_name: this.state.arabicCategoryName,
                user_business_sub_categories_attributes: [
                    {
                        service_name: this.state.serviceName,
                        arabic_name: this.state.arabicServiceName,
                        service_cost: this.state.cost,
                        duration: this.state.duration,
                        description: this.state.description,
                    },
                ],
            };
            console.log("http body==>", httpBody);
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.apiAddCategoryCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/business_categories" + "/" + this.state.selectedCategoryId);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.DeleteCategoryAPICall = () => __awaiter(this, void 0, void 0, function* () {
            const header = {
                "Content-Type": "application/json",
                token: this.state.token,
            };
            this.setState({ loader: true, catalogueMsg: "Deleted Successfully!" });
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.apiDeleteCategoryAPICall = requestMessage.messageId;
            console.log("selectedCategoryId :", this.state.selectedCategoryId);
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/business_categories" + "/" + this.state.selectedCategoryId);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.DeleteServiceAPICall = () => __awaiter(this, void 0, void 0, function* () {
            this.setState({ loader: true, catalogueMsg: "Deleted Successfully!" });
            const header = {
                "Content-Type": "application/json",
                token: this.state.token,
            };
            let hr;
            let min;
            if (this.state.duration.includes('Hr')) {
                hr = this.state.duration.substring(0, 4);
                min = this.state.duration.substring(5);
            }
            else {
                if (this.state.duration !== "") {
                    hr = 0;
                    min = this.state.duration;
                }
            }
            const httpBody = {
                name: this.state.categoryName,
                arabic_name: this.state.arabicCategoryName,
                user_business_sub_categories_attributes: [
                    {
                        id: this.state.serviceDetailId,
                        service_name: this.state.serviceName,
                        service_cost: this.state.cost,
                        arabic_name: this.state.arabicServiceName,
                        hour: hr,
                        minutes: min,
                        description: this.state.description,
                        _destroy: true,
                    },
                ],
            };
            console.log("http body==>333", httpBody, this.state.serviceDetailId, this.state.selectedCategoryId);
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.apiDeleteServiceCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/business_categories" + "/" + this.state.selectedCategoryId);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.callApiFunction = (isError, serviceAction) => {
            if (!isError) {
                if (serviceAction === "Add another service" && this.state.selectedCategoryId) {
                    this.AddServiceAPICall();
                    return;
                }
                if (this.state.actionType === "edit") {
                    this.UpdateCategoryAPICall(this.state.selectionType === "category" ? this.state.selectionType : undefined);
                    return;
                }
                this.addBusinessCategoryServices();
            }
        };
        this.updateBusinessProfileDetails = () => {
            this.setState({ loader: true });
            const header = {
                "Content-Type": "application/json",
                token: localStorage.getItem("token") || "",
            };
            let httpBody = {
                is_user_categories_flow: true,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.updateBusinessProfileApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/business_profiles/update_profile");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.handleUpdateProfileResponse = (responseJson, errorReponse) => {
            if ((responseJson === null || responseJson === void 0 ? void 0 : responseJson.message) === "Busienss Profile update successfully") {
                this.setState({
                    addEmployeesDialog: true,
                    loader: false
                });
            }
            else {
                console.log(errorReponse);
            }
        };
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            isSelectOpen: false,
            fetchdatadialog: true,
            catalogueMsg: "",
            loader: false,
            sucess: false,
            serviceProvided: "",
            token: "",
            setAlertFor: "",
            selectedCategoryId: "",
            serviceDetailId: "",
            disableAddCategory: true,
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            openCategoryDialog: false,
            actionType: '',
            categoryDialogHeader: '',
            serviceDialogHeader: '',
            addEmployeesDialog: false,
            alertDialog: false,
            serviceDialog: false,
            serviceObj: {},
            serviceCategory: [],
            categoryName: "",
            categoryNameError: "",
            serviceName: "",
            serviceNameError: "",
            description: "",
            descriptionError: "",
            cost: "",
            costError: "",
            duration: "",
            durationError: "",
            selectionType: "",
            DurationData: [
                {
                    key: ""
                },
                {
                    key: "15 Mins",
                    value: "00:15:00"
                },
                {
                    key: "30 Mins",
                    value: "00:30:00"
                },
                {
                    key: "45 Mins",
                    value: "00:45:00"
                },
                {
                    key: "60 Mins",
                    value: "01:00:00"
                },
            ]
            // Customizable Area Start
            ,
            arabicCategoryName: "",
            arabicCategoryNameError: "",
            arabicServiceName: "",
            arabicServiceNameError: "",
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            // Customizable Area Start
            console.log("receive :", getName(MessageEnum.RestAPIResponceMessage), message.id);
            if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
                return;
            }
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
            if (apiRequestCallId === this.apiGetBusinessCategoryServicesCallId) {
                yield this.handleGetBusinessCategoryServicesResponse(responseJson, errorResponse);
            }
            else if (apiRequestCallId === this.apiAddBusinessCategoryServicesCallId) {
                yield this.handleAddBusinessCategoryServicesResponse(responseJson, errorResponse);
            }
            else if (apiRequestCallId === this.apiUpdateServiceCallId) {
                yield this.handleUpdateServiceResponse(responseJson, errorResponse);
            }
            else if (apiRequestCallId === this.apiDeleteCategoryAPICall) {
                yield this.handleDeleteCategoryResponse(responseJson, errorResponse);
            }
            else if (apiRequestCallId === this.apiAddCategoryCallId) {
                yield this.handleAddCategoryResponse(responseJson, errorResponse);
            }
            else if (apiRequestCallId === this.apiDeleteServiceCallId) {
                yield this.handleDeleteServiceResponse(responseJson, errorResponse);
            }
            else if (apiRequestCallId === this.updateBusinessProfileApiCallId) {
                this.handleUpdateProfileResponse(responseJson, errorResponse);
            }
        });
    }
    handleGetBusinessCategoryServicesResponse(responseJson, errorResponse) {
        return __awaiter(this, void 0, void 0, function* () {
            this.setState({ loader: false });
            if (responseJson === null) {
                alert(errorResponse);
                return;
            }
            if (responseJson.errors) {
                if (responseJson.errors[0].token) {
                    console.log("error responseJson :", responseJson.errors);
                }
                return;
            }
            console.log("category service response==>", responseJson.data);
            if (this.state.fetchdatadialog) {
                this.setState({ sucess: true });
            }
            this.setState({ serviceCategory: responseJson.data, loader: false, fetchdatadialog: true });
        });
    }
    handleAddBusinessCategoryServicesResponse(responseJson, errorResponse) {
        return __awaiter(this, void 0, void 0, function* () {
            if (responseJson === null) {
                alert(errorResponse);
                return;
            }
            if (responseJson.errors) {
                if (responseJson.errors[0].token) {
                    console.log("error responseJson :", responseJson.errors);
                }
                return;
            }
            console.log("Business Category Services==>", responseJson.data);
            this.setState({ openCategoryDialog: false, serviceDialog: false });
            yield this.fetchBusinessCategoryServices();
        });
    }
    handleUpdateServiceResponse(responseJson, errorResponse) {
        return __awaiter(this, void 0, void 0, function* () {
            if (responseJson === null) {
                alert(errorResponse);
                return;
            }
            if (responseJson.errors) {
                if (responseJson.errors[0].token) {
                    console.log("error responseJson :", responseJson.errors);
                }
                return;
            }
            console.log("update Services call ==>", responseJson.data);
            this.setState({ serviceDialog: false, openCategoryDialog: false });
            yield this.fetchBusinessCategoryServices();
        });
    }
    handleDeleteCategoryResponse(responseJson, errorResponse) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log("Delete Category call ==>1", responseJson);
            this.setState({ alertDialog: false, openCategoryDialog: false });
            if (responseJson === null) {
                alert(errorResponse);
            }
            if (responseJson.errors) {
                if (responseJson.errors[0].token) {
                    console.log("error responseJson :", responseJson.errors);
                }
            }
            console.log("Delete Category call ==>2", responseJson.data);
            yield this.fetchBusinessCategoryServices();
        });
    }
    handleAddCategoryResponse(responseJson, errorReponse) {
        return __awaiter(this, void 0, void 0, function* () {
            if (responseJson === null) {
                alert(errorReponse);
                return;
            }
            if (responseJson.errors && responseJson.errors[0].token) {
                console.log("responseJson fetchBusinessCategory token error=>", responseJson.errors[0].token);
                return;
            }
            console.log("responseJson=>", responseJson);
            this.setState({ serviceDialog: false, openCategoryDialog: false });
            console.log(" dkvdf", this.state.duration);
            this.fetchBusinessCategoryServices();
        });
    }
    handleDeleteServiceResponse(responseJson, errorReponse) {
        return __awaiter(this, void 0, void 0, function* () {
            if (responseJson !== null) {
                if (!responseJson.errors) {
                    console.log("responseJson Delete service=>", responseJson);
                    this.setState({ alertDialog: false, openCategoryDialog: false });
                    yield this.fetchBusinessCategoryServices();
                }
                else if (responseJson.errors[0].token) {
                    console.log("responseJson Delete service=>", responseJson.errors);
                }
                else {
                    console.log("Error", responseJson.errors);
                }
            }
            else {
                alert(errorReponse);
            }
        });
    }
    componentDidUpdate(prevProps, prevState) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.state.sucess) {
                setTimeout(() => {
                    this.setState({ sucess: false });
                }, 2000);
            }
        });
    }
    componentDidMount() {
        const _super = Object.create(null, {
            componentDidMount: { get: () => super.componentDidMount }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.componentDidMount.call(this);
            if (typeof window !== "undefined") {
                let token = window.localStorage.getItem("token");
                this.setState({ token: token, loader: true, fetchdatadialog: false, catalogueMsg: "Data fetched successfully!" }, () => __awaiter(this, void 0, void 0, function* () {
                    yield this.fetchBusinessCategoryServices();
                }));
            }
            // Customizable Area Start
            setTimeout(() => {
                let userType = localStorage.getItem("userType") || "";
                let token = localStorage.getItem("token") || "";
                if (!token || userType != "business") {
                    localStorage.clear();
                    window.location.replace("/LandingPage");
                }
            }, 100);
            // Customizable Area End
        });
    }
    // Customizable Area Start
    renderErrorMessage(error, translate) {
        return (error && (React.createElement("span", { className: "service-error-message-text" }, translate(error))));
    }
}
