// Customizable Area Start
import React from "react";
import { leftAArrow, eyeClose, eyeOpen } from "./assets";
import { IconButton, InputAdornment, OutlinedInput, Button, } from "@material-ui/core";
import "../assets/Styles/forgotPassword.css";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { withTranslation } from "react-i18next";
class CreateNewPassword extends BlockComponent {
    constructor(props) {
        super(props);
    }
    render() {
        const { isShowPassword, isShowConfirmPassword, isSetNewPassword, passwordNotMatchedError, passwordError, newPassword, confirmNewPassword, passwordEmptyError, passwordWithSpaceError, } = this.props;
        const { onBack, handleChange, onSubmit, showConfirmPasswordChange, showPasswordChange, } = this.props;
        //@ts-ignore
        let { t } = this.props;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "mobile-number-container" },
                React.createElement("div", { className: "mobile-number-content-container" },
                    React.createElement("div", { className: "mobile-number-modal-header" },
                        React.createElement("span", { style: { width: "25%" } },
                            React.createElement("img", { src: leftAArrow, alt: "arrow", onClick: onBack })),
                        React.createElement("span", { className: "verify-number-heading" }, t("Create new password"))),
                    React.createElement("div", { className: "confirm-password-main-input-container" },
                        React.createElement("div", { className: "confirm-password-input-container" },
                            React.createElement("span", { className: "forgot-password-form-label" }, t("Enter new password")),
                            React.createElement(OutlinedInput, { type: isShowPassword ? "text" : "password", placeholder: "Enter new password", name: "newPassword", value: newPassword, error: passwordError ||
                                    passwordNotMatchedError ||
                                    passwordEmptyError ||
                                    passwordWithSpaceError, onChange: (e) => handleChange(e.target.name, e.target.value), className: "forgot-password-form-input-style new-password-text-input", endAdornment: React.createElement(InputAdornment, { position: "end" },
                                    React.createElement(IconButton, { "aria-label": "edit mobile number", onClick: showPasswordChange, edge: "end", style: { width: 24, height: 24, marginRight: 1 } },
                                        React.createElement("img", { src: isShowPassword ? eyeOpen : eyeClose, height: 24, width: 24, alt: "eye" }))) }),
                            passwordWithSpaceError && (React.createElement("span", { className: "error-message-text" }, t("Please remove space from the password"))),
                            passwordError && (React.createElement("span", { className: "error-message-text" }, t("This password is invalid."))),
                            passwordEmptyError && (React.createElement("span", { className: "error-message-text" }, t("Please enter password")))),
                        React.createElement("div", { className: "confirm-password-input-container" },
                            React.createElement("span", { className: "forgot-password-form-label" }, t("Confirm password")),
                            React.createElement(OutlinedInput, { type: isShowConfirmPassword ? "text" : "password", placeholder: t("Enter new password"), error: passwordNotMatchedError, name: "confirmNewPassword", value: confirmNewPassword, onChange: (e) => handleChange(e.target.name, e.target.value), className: "forgot-password-form-input-style confirm-password-text-input", endAdornment: React.createElement(InputAdornment, { position: "end" },
                                    React.createElement(IconButton, { "aria-label": "edit mobile number", onClick: showConfirmPasswordChange, edge: "end", style: { width: 24, height: 24, marginRight: 1 } },
                                        React.createElement("img", { src: isShowConfirmPassword ? eyeOpen : eyeClose, height: 24, width: 24, alt: "eye" }))) })),
                        passwordNotMatchedError && (React.createElement("span", { className: "error-message-text" }, t("This password do not match.")))),
                    React.createElement(Button, { className: "submit-button", onClick: onSubmit }, t("Submit"))))));
    }
}
//@ts-ignore
export default withTranslation()(CreateNewPassword);
// Customizable Area End
