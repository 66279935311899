export const imgic_password_invisible = require("../../global_assets/ic_password_invisible.png");
export const imgic_password_visible = require("../../global_assets/ic_password_visible.png");
export const cancelIcon = require("../assets/cancel_icon.png");
export const rightAArrow = require("../assets/right-arrow.png");
export const rightBlackAArrow = require("../assets/right-arrow-black.png");
export const rightWhiteAArrow = require("../assets/right-arrow-white.png");
export const logo = require("../assets/SqueezeMeInLogo.png");
export const whiteLogo = require("../assets/white_logo.png");
export const barber_service_icon = require("../assets/barber_service_icon.png");
export const salon_service_icon = require("../assets/salon_service_icon.png");
export const fitness_service_icon = require("../assets/fitness_service_icon.png");
export const spa_service_icon = require("../assets/spa_service_icon.png");
export const play_store = require("../assets/googleplay_store.png");
export const app_store = require("../assets/app_store.png");
export const landingPageImage1 = require("../assets/landingPageImageMobile1.jpg");
export const landingPageImage2 = require("../assets/landingPageImageMobile2.jpg");
export const landingPageImage3 = require("../assets/landingPageImageMobile3.jpg");
export const landingPageImage4 = require("../assets/landingPageImageMobile4.jpg");
export const landingPageImage5 = require("../assets/landingPageImageMobile5.png");
export const leftAArrow = require("../assets/left-arrow.png");
export const translate = require("../assets/translate.png");
export const logout = require("../assets/Icon_24.png");
export const warningIcon = require("../assets/warningIcon.png");
export const profileImage = require("../assets/profile-image.png");
export const exit = require("../assets/exit.png");
export const call = require("../assets/call.png");
export const contactCall = require("../assets/contactCall.png");
export const contactMail = require("../assets/contactMail.png");
export const contactWeb = require("../assets/contactWeb.png");
export const info = require("../assets/info.png");
export const map = require("../assets/map.png");
export const saloon = require("../assets/saloon.png");
export const searchIcon = require("../assets/searchIcon.png");
export const searchSaloonIcon = require("../assets/ic_search_normal.png");
export const Path = require("../assets/Path.png");
export const Location = require("../assets/Location1.png");
export const ShopImage = require("../assets/ShopImage.png");
export const filter = require("../assets/filter.png");
export const booking_profile = require("../assets/booking_profile.png");
export const myProfile = require("../assets/myProfile.png");
export const myBooking = require("../assets/myBooking.png");
export const settingsImg = require("../assets/image_Bitmap+(1).png");
export const paymentImg = require("../assets/image_Bitmap.png");
export const contactusImg = require("../assets/contactusImg.png");
export const cuslogOut = require("../assets/cuslogOut.png");
export const dangerImg = require("../assets/dangerImg.png");
export const nextArrow = require("../assets/nextArrow.png");
export const uploadimg = require("../assets/Group+3465062.png");
export const subscribed = require("../assets/image_Bitmap+(3).png");
export const advtImg = require("../assets/Group+3465059.png");
export const contactImg = require("../assets/image_Bitmap+3.png");
export const deactivate = require("../assets/image_Bitmap+4.png");
export const location = require("../assets/location.png");
export const homeBIcon = require("../assets/homeBIcon.png");
export const profileBIcon = require("../assets/profileBIcon.png");
export const manageBIcon = require("../assets/manageBIcon.png");
export const businessImage = require("../assets/businessImage.png");
export const contactIcon = require("../assets/contactIcon.png");
export const deactivateIcon = require("../assets/deactivateIcon.png");
export const logoutIcon = require("../assets/logoutIcon.png");
export const paymentIcon = require("../assets/paymentIcon.png");
export const promoteIcon = require("../assets/promoteIcon.png");
export const settingIcon = require("../assets/settingIcon.png");
export const businessSetting = require("../assets/businessSetting.png");
export const SubscriptionIcon = require("../assets/SubscriptionIcon.png");
export const setting_privacy1 = require("../assets/setting_privacy1.png");
export const setting_privacy = require("../assets/setting_privacy.png");
export const RIghtArrow = require("../assets/RIghtArrowSmall.png");
export const forwordBlack = require("../assets/forwordBlack.png");
export const Addsymbol = require("../assets/Addsymbol.png");
export const Addsymbol2 = require("../assets/Addsymbol2.png");
export const penSym = require("../assets/penSym.png");
export const Pensymwhite = require("../assets/Pensymwhite.png");
export const goback = require("../assets/goback.png");
export const hexogon = require("../assets/hexogon.png");
export const modalImg = require("../assets/modalImg.png");
export const employeeEditIcon = require("../assets/employeeEdit.png");
export const employeeAvtar = require("../assets/avtar.png");
export const employeeCalendarIcon = require("../assets/employeeCalender.png");
export const employeeCalendar = require("../assets/calender.png");
export const employeeCross = require("../assets/cross.png");
export const backArrow = require("../assets/back.png");
export const leftArrow = require("../assets/leftArrow.png");
export const next = require("../assets/next1.png");
export const prev = require("../assets/prev.png");
export const btnext = require("../assets/btnext.png");
export const plus = require("../assets/plus.png");
export const alert = require("../assets/alert.png");
export const subscription = require("../assets/subscribed.png");
export const TeamWorking = require("../assets/TeamWD.png");
export const BWD = require("../assets/BWD.png");
export const BlockEmp = require("../assets/BlockEmp.png");
export const Bh = require("../assets/BH.png");
export const search = require("../assets/search.png");
export const defaultProfile = require("../assets/user.png");
export const profile = require("../assets/profile.png");
export const rightArrow = require("../assets/rightArrow.png");
export const oman = require("../assets/flags_style1_small/Oman.png");
export const india = require("../assets/flags_style1_small/India.png");
export const edit = require("../assets/shapeEdit.png");
export const navigationImage = require("../assets/navigation_image.png");
export const warningRedIcon = require("../assets/warningRed.png");
export const saloonBg = require("../assets/saloon.png");
export const callIcon = require("../assets/call.png");
export const mapIcon = require("../assets/map.png");
export const contactWebIcon = require("../assets/contactWeb.png");
export const contactCallIcon = require("../assets/contactCall.png");
export const contactCellIcon = require("../assets/contactCell.png");
export const contactMailIcon = require("../assets/contactMail.png");
export const cross = require("../assets/imagebutton_Cancel.png");
export const ArrowDown = require("../assets/image_ArrowDown.png");
export const ArrowUp = require("../assets/image_ArrowUp.png");
export const togleFalse = require("../assets/togleFalse.png");
export const togleTrue = require("../assets/togleTrue.png");
export const uploadImage = require("../assets/uploadimage.png");
export const arrowRight = require("../assets/image_BitmapCopy3.png");
export const wright = require("../../global_assets/7f284a623dad0db2fed9fbe2e3d55e6af5f0cd63.png");
export const imageFalse = require("../assets/imageFalse.png");
export const no_saved_card_image = require("../assets/no_saved_card_image.png");
export const defultprofile = require("../assets/profilealt.png");
