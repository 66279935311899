import React from "react";
import { Button, Modal } from "@material-ui/core";
import "../assets/style/BookNowModal.scss";
import { close_modal_icon } from "./assets";
import moment from "moment";
import { withTranslation } from "react-i18next";
function BookServicesModal({ openBookNowModal, closeBookNowModal, selectServiceForbooking, history, subServiceid, shopSubResponse, currentCategory, gender, categoryId, t, }) {
    var _a, _b, _c, _d, _e;
    return (React.createElement("div", { className: "bookNow-main-div" },
        React.createElement(Modal, { open: openBookNowModal, onClose: closeBookNowModal, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" },
            React.createElement("div", { className: "modal-booknow" },
                React.createElement("div", { className: "first-div-of-modal" },
                    React.createElement("h2", { id: "modal-modal-title" }, (_a = selectServiceForbooking[0]) === null || _a === void 0 ? void 0 : _a.display_name),
                    React.createElement("img", { src: close_modal_icon, alt: "", id: "close_dialog_img", onClick: closeBookNowModal })),
                React.createElement("p", null, (_b = selectServiceForbooking[0]) === null || _b === void 0 ? void 0 : _b.description),
                React.createElement("h1", null, ((_c = selectServiceForbooking[0]) === null || _c === void 0 ? void 0 : _c.service_cost) +
                    " " + ((_d = selectServiceForbooking[0]) === null || _d === void 0 ? void 0 : _d.currency)),
                React.createElement("h3", null, moment.duration((_e = selectServiceForbooking[0]) === null || _e === void 0 ? void 0 : _e.duration).asMinutes() +
                    +" " +
                    t("min")),
                React.createElement("div", null,
                    React.createElement(Button, { className: "book-now-btn-modal", onClick: () => {
                            var _a, _b, _c, _d;
                            history.push({
                                pathname: "/customer/service/booking",
                                state: {
                                    subServiceid,
                                    serviceId: selectServiceForbooking[0].id,
                                    shopSubResponse,
                                    name: [
                                        (_a = selectServiceForbooking[0]) === null || _a === void 0 ? void 0 : _a.display_name,
                                        (_b = selectServiceForbooking[0]) === null || _b === void 0 ? void 0 : _b.service_cost,
                                        (_c = selectServiceForbooking[0]) === null || _c === void 0 ? void 0 : _c.currency,
                                    ],
                                    currentCategory,
                                    gender,
                                    categoryId,
                                    staffId: (_d = selectServiceForbooking[0]) === null || _d === void 0 ? void 0 : _d.id,
                                },
                            });
                        } }, t("Book Now")))))));
}
//@ts-ignore
export default withTranslation()(BookServicesModal);
