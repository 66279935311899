// Customizable Area Start
//@ts-nocheck
import React from "react";
import { leftAArrow, forgotPasswordBackground } from "./assets";
import { IconButton, InputAdornment, OutlinedInput, Button, } from "@material-ui/core";
import "../assets/Styles/forgotPassword.css";
import CountryCodeModal from "../../social-media-account-login/src/CountryCodeModal.web";
import OtpContainer from "./OtpContainer.web";
import ForgotPasswordWebController from "./ForgotPasswordWebController";
import CreateNewPassword from "./CreateNewPassword.web";
import OtpVerifiedSuccess from "./OtpVerifiedSuccess.web";
import BlockedAccount from "./BlockedAccount.web";
import PasswordCreatedSuccess from "./PasswordCreatedSuccess.web";
import OTPResendSuccess from "./OTPResendSuccess.web";
import { withTranslation } from "react-i18next";
//@ts-ignore
import Cookies from "js-cookie";
import Loader from "../../../components/src/Loader.web";
class ForgotPasswordMobileNumber extends ForgotPasswordWebController {
    constructor(props) {
        super(props);
        //istanbul ignore next
        this.borderRight = () => {
            let webAppDirection = localStorage.getItem("webAppDirection") ||
                Cookies.get("webAppDirection") ||
                "ltr";
            return webAppDirection === "ltr"
                ? this.state.mobileNumberError ||
                    this.state.mobileNumberNotRegisteredError
                    ? "1px solid #ff453a"
                    : "1px solid #979797"
                : "";
        };
        //istanbul ignore next
        this.borderLeft = () => {
            let webAppDirection = localStorage.getItem("webAppDirection") ||
                Cookies.get("webAppDirection") ||
                "ltr";
            return webAppDirection === "rtl"
                ? this.state.mobileNumberError ||
                    this.state.mobileNumberNotRegisteredError
                    ? "1px solid #ff453a"
                    : "1px solid #979797"
                : "";
        };
    }
    render() {
        const { countryCodeModal, countryCode, mobileNumberError, mobileNumber, mobileNumberNotRegisteredError, isOtpSent, isSetNewPassword, otpSuccessMessageShow, isAccountBlocked, isNewPasswordCreated, resendOtpSuccess, passwordEmptyError, passwordWithSpaceError, } = this.state;
        //@ts-ignore
        let { t } = this.props;
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        return (React.createElement(React.Fragment, null,
            React.createElement(Loader, { loading: this.state.loading }),
            React.createElement("div", { className: "forgot-password-container" },
                React.createElement("img", { src: forgotPasswordBackground, className: "forgot-password-background-image", alt: "background" }),
                !countryCodeModal &&
                    !isOtpSent &&
                    !isSetNewPassword &&
                    !otpSuccessMessageShow &&
                    !isAccountBlocked &&
                    !isNewPasswordCreated &&
                    !resendOtpSuccess && (React.createElement("div", { className: "mobile-number-container" },
                    React.createElement("div", { className: "mobile-number-content-container" },
                        React.createElement("div", { className: "mobile-number-modal-header" },
                            React.createElement("span", { style: { width: "25%", cursor: "pointer" }, "data-testid": "back-click", onClick: () => this.handleNavigation("Login") },
                                React.createElement("img", { src: leftAArrow, alt: "arrow" })),
                            React.createElement("span", { className: "verify-number-heading" }, t("Verify your number"))),
                        React.createElement("span", { className: "mobile-number-modal-description" }, t("To protect your account, we’ll send a text message with a 4-digit code to the mobile number below.")),
                        React.createElement("div", { className: "phone-number-input-container" },
                            React.createElement("span", { className: "forgot-password-form-label" }, t("Phone Number")),
                            React.createElement(OutlinedInput, { type: "text", name: "mobileNumber", placeholder: t("Enter your number"), className: "forgot-password-form-input-style", error: mobileNumberError || mobileNumberNotRegisteredError, value: mobileNumber, onChange: (e) => this.handleChange(e.target.name, e.target.value), startAdornment: React.createElement(InputAdornment, { position: webAppDirection === "ltr" ? "start" : "end" },
                                    React.createElement(IconButton, { style: {
                                            width: 66,
                                            height: 54,
                                            borderRight: this.borderRight(),
                                            borderLeft: this.borderLeft(),
                                            cursor: "pointer",
                                            justifyContent: "center",
                                            borderRadius: "0%",
                                        }, onClick: () => this.handleCountryCodeChange() },
                                        React.createElement("span", { className: "country-code-text-style", style: {
                                                color: mobileNumberError ||
                                                    mobileNumberNotRegisteredError
                                                    ? "#ff453a"
                                                    : "#2c2c2e",
                                            } }, countryCode))) }),
                            mobileNumberError && (React.createElement("span", { className: "error-message-text" }, t("This number is invalid"))),
                            mobileNumberNotRegisteredError && (React.createElement("span", { className: "error-message-text" }, t("This account does not exist.")))),
                        React.createElement(Button, { className: "verify-button", onClick: this.sendOtpApi }, t("Verify your number"))))),
                React.createElement(CountryCodeModal, { onSelect: this.handleCountryCodeChange, open: countryCodeModal }),
                isOtpSent &&
                    !isSetNewPassword &&
                    !otpSuccessMessageShow &&
                    !isAccountBlocked &&
                    !isNewPasswordCreated &&
                    !resendOtpSuccess && (React.createElement(OtpContainer, { handleChange: this.handleOtpChange, onResend: this.sendOtpApi, onBack: this.closeOtpModal, handleEnableResend: this.handleEnableResend, isResendButtonEnabled: this.state.isResendButtonEnabled, otpError: this.state.otpError, countryCode: countryCode, mobileNumber: mobileNumber, isOtpSent: isOtpSent, otp: this.state.otp })),
                isSetNewPassword &&
                    isOtpSent &&
                    !otpSuccessMessageShow &&
                    !isAccountBlocked &&
                    !isNewPasswordCreated &&
                    !resendOtpSuccess && (React.createElement(CreateNewPassword, { handleChange: this.handleChange, showPasswordChange: this.showPasswordChange, showConfirmPasswordChange: this.showConfirmPasswordChange, onSubmit: this.createNewPasswordApi, onBack: this.closeNewPasswodModal, isShowPassword: this.state.isShowPassword, isShowConfirmPassword: this.state.isShowConfirmPassword, isSetNewPassword: this.state.isSetNewPassword, passwordNotMatchedError: this.state.passwordNotMatchedError, passwordError: this.state.passwordError, newPassword: this.state.newPassword, confirmNewPassword: this.state.confirmNewPassword, passwordEmptyError: passwordEmptyError, passwordWithSpaceError: passwordWithSpaceError })),
                otpSuccessMessageShow &&
                    !isAccountBlocked &&
                    !isNewPasswordCreated &&
                    !resendOtpSuccess && (React.createElement(OtpVerifiedSuccess, { verificationType: "number" })),
                isAccountBlocked && !isNewPasswordCreated && !resendOtpSuccess && (React.createElement(BlockedAccount, { navigation: this.props.navigation })),
                isNewPasswordCreated && !resendOtpSuccess && (React.createElement(PasswordCreatedSuccess, null)),
                resendOtpSuccess && React.createElement(OTPResendSuccess, { resendPlace: "phone number" }))));
    }
}
//@ts-ignore
export default withTranslation()(ForgotPasswordMobileNumber);
// Customizable Area End
