// Customizable Area Start
import React from "react";
import ManageEmployee from "./ManageEmployee.web";
import { withRouter } from "react-router-dom";
import EmployeeManageBookingsWebController from "../../src/EmployeeProfile/EmployeeManageBookingsWebController.web";
import Loader from "../../../../../packages/components/src/Loader.web";
import "../../../ContentModeration/assets/Styles/manage-bookings-business.web.css";
import ManageBookingsListing from "../../../ContentModeration/src/ManageBookingsListing.web";
import ManageBookingsAppointmentDetails from "../../../ContentModeration/src/ManageBookingsAppointmentDetails.web";
import ReviewSuccessModal from "../../../ContentModeration/src/ReviewSuccessModal.web";
const Cookies = require("js-cookie");
class EmployeeManageBookings extends EmployeeManageBookingsWebController {
    render() {
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        return (React.createElement(React.Fragment, null,
            React.createElement(Loader, { loading: this.state.loading }),
            React.createElement("div", { className: "manage-catalouge-main-container" },
                React.createElement(ManageEmployee, null),
                React.createElement("div", { className: webAppDirection === "ltr"
                        ? "manage-booking-business-content-container"
                        : "arabic-manage-booking-business-content-container" },
                    !this.state.isViewingAppointment ? (React.createElement(ManageBookingsListing, { state: this.state, data: this.state.selectedDataView === "upcoming"
                            ? this.state.upcomingAppointments
                            : this.state.pastAppointments, handleSearchOpenClose: this.handleSearchOpenClose, handlePastBookingsView: this.handlePastBookingsView, handleUpcomingBookingsView: this.handleUpcomingBookingsView, handleAppointmentOnclick: this.handleAppointmentOnclick, handleFilterModalChange: this.handleFilterModalChangeInEmployee, handleFilterValueChanges: this.handleFilterValueChangesInEmployee, getAllAppointments: this.getAllAppointments })) : (React.createElement(ManageBookingsAppointmentDetails
                    //@ts-ignore
                    , { 
                        //@ts-ignore
                        handleBack: this.handleBackOnListing, storeTime: this.storeTime, getTime: this.getTime, onStart: this.startTimerApiCall, onStop: this.stopTimerApiCall, onCancel: this.cancelAppointment, onResechdule: this.storeTime, onNoshow: this.noShowApiCall, onReview: this.reviewApiCall, handleBookingCodeChange: this.handleBookingCodeChange, handleRatingStarChange: this.handleRatingStarChange, handleReviewTextChange: this.handleReviewTextChange, remainingTime: this.state.remainingTime, restart: this.state.restart, startParams: this.state.startParams, appointmentData: this.state.selectedAppointmentForView, enableStartButton: this.state.enableStartButton, enableCancelButton: this.state.enableCancelButton, enableNoShowButton: this.state.enableNoShowButton, enableResechduleButton: this.state.enableResechduleButton, otpCode: this.state.otpCode, bookingCodeError: this.state.bookingCodeError, givenRating: this.state.givenRating, reviewText: this.state.reviewText, bookingCodeErrorMessage: this.state.bookingCodeErrorMessage })),
                    this.state.reviewSuccessModal && (React.createElement(ReviewSuccessModal, { openDialog: this.state.reviewSuccessModal, reviewAlreadySubmitted: this.state.isReviewAlreadySubmitted, goHome: this.handleGoHomeAfterReview }))))));
    }
}
//@ts-ignore
export default withRouter(EmployeeManageBookings);
// Customizable Area End
