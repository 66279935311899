// Customizable Area Start
//@ts-nocheck
import React from "react";
import "../assets/Styles/manage-catalouge.css";
import { withRouter } from "react-router-dom";
import ManageBusiness from "./ManageBusiness.web";
import BusinessManageBookingsWebController from "./BusinessManageBookingsWebController";
import Loader from "../../../components/src/Loader.web";
import "../assets/Styles/manage-bookings-business.web.css";
import ManageBookingsListing from "./ManageBookingsListing.web";
import ManageBookingsAppointmentDetails from "./ManageBookingsAppointmentDetails.web";
import ReviewSuccessModal from "./ReviewSuccessModal.web";
import RescheduleModal from "./RescheduleModal.web";
import RescheduleEmployeeModal from "./RescheduleEmployeeModal.web";
import RescheduleSuccessModal from "./RescheduleSuccessModal.web";
import { sesssionExpired } from "../../Squeez_me_in/src/assets";
import { Button, Modal } from "@material-ui/core";
import Cookies from "js-cookie";
import { withTranslation } from "react-i18next";
class BusinessManageBookings extends BusinessManageBookingsWebController {
    render() {
        var _a;
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        //@ts-ignore
        let { t } = this.props;
        return (React.createElement(React.Fragment, null,
            React.createElement(Loader, { loading: this.state.loading }),
            React.createElement("div", { className: "manage-catalouge-main-container" },
                React.createElement(ManageBusiness, null),
                React.createElement("div", { className: webAppDirection === "ltr"
                        ? "manage-booking-business-content-container "
                        : "arabic-manage-booking-business-content-container " },
                    !this.state.isViewingAppointment ? (React.createElement(ManageBookingsListing, { state: this.state, data: this.state.selectedDataView === "upcoming"
                            ? this.state.upcomingAppointments
                            : this.state.pastAppointments, handleSearchOpenClose: this.handleSearchOpenClose, handlePastBookingsView: this.handlePastBookingsView, handleUpcomingBookingsView: this.handleUpcomingBookingsView, handleAppointmentOnclick: this.handleAppointmentOnclick, handleFilterModalChange: this.handleFilterModalChange, handleFilterValueChanges: this.handleFilterValueChanges, getAllAppointments: this.getAllAppointments })) : (React.createElement(ManageBookingsAppointmentDetails
                    //@ts-ignore
                    , { 
                        //@ts-ignore
                        handleBack: this.handleBackOnListing, storeTime: this.storeTime, getTime: this.getTime, onStart: this.startTimerApiCall, onStop: this.stopTimerApiCall, onCancel: this.cancelAppointment, onResechdule: this.handleOpenRescheduleModal, onNoshow: this.noShowApiCall, onReview: this.reviewApiCall, handleBookingCodeChange: this.handleBookingCodeChange, handleRatingStarChange: this.handleRatingStarChange, handleReviewTextChange: this.handleReviewTextChange, remainingTime: this.state.remainingTime, restart: this.state.restart, startParams: this.state.startParams, appointmentData: this.state.selectedAppointmentForView, enableStartButton: this.state.enableStartButton, enableCancelButton: this.state.enableCancelButton, enableNoShowButton: this.state.enableNoShowButton, enableResechduleButton: this.state.enableResechduleButton, otpCode: this.state.otpCode, bookingCodeError: this.state.bookingCodeError, bookingCodeErrorMessage: this.state.bookingCodeErrorMessage, givenRating: this.state.givenRating, reviewText: this.state.reviewText })),
                    React.createElement(ReviewSuccessModal, { openDialog: this.state.reviewSuccessModal, reviewAlreadySubmitted: this.state.isReviewAlreadySubmitted, goHome: this.handleGoHomeAfterReview }),
                    this.state.isReScheduleModalOpen && (React.createElement(RescheduleModal, { state: this.state, closeRescheduleModal: this.closeRescheduleModal, handleRescheduleDateChange: this.handleRescheduleDateChange, changeToUtc: this.changeToUtc, getAvailableStaffApi: this.getAvailableStaffApi, onSlotClick: this.onSlotClick, openTimeSelect: this.openTimeSelect, closeTimeSelect: this.closeTimeSelect, sessionExpiredChange: this.sessionExpiredChange, getShopHolidayDetails: this.getShopHolidayDetails })),
                    this.state.isRescheduleEmployeeSelecting && (React.createElement(RescheduleEmployeeModal, { state: this.state, onEmployeeSelect: this.onEmployeeSelect })),
                    this.state.openRescheduleSuccessModal && (React.createElement(RescheduleSuccessModal, { openDialog: this.state.openRescheduleSuccessModal, goToBookings: this.handleGoToBookings })),
                    this.state.isSessionExpired && (React.createElement(Modal, { open: (_a = this.state) === null || _a === void 0 ? void 0 : _a.isSessionExpired, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" },
                        React.createElement("div", { className: "modal-booknow", style: { height: "418px" } },
                            React.createElement("div", { style: { textAlign: "center", marginTop: "70px" } },
                                React.createElement("img", { src: sesssionExpired, alt: "" })),
                            React.createElement("div", null,
                                React.createElement("h1", { className: "h1-tag-modal" }, t("Session expired!")),
                                React.createElement(Button, { className: "book-now-btn-modal", onClick: () => {
                                        this.sessionExpiredChange(false);
                                    } }, t("Continue"))))))))));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(BusinessManageBookings));
// Customizable Area End
