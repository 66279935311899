var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import moment from "moment";
//@ts-ignore
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
export const configJSON = require("./config");
export default class AvailableController extends BlockComponent {
    constructor(props) {
        super(props);
        this.handleReceiveCreateAppointmentRes = (res) => {
            var _a, _b;
            this.setState({ loadingButton: false });
            this.setState({ skipLoadingButton: false });
            if (res === null || res === void 0 ? void 0 : res.errors) {
                toast.error((_a = res === null || res === void 0 ? void 0 : res.errors) === null || _a === void 0 ? void 0 : _a.message[0], { position: toast.POSITION.BOTTOM_RIGHT });
            }
            if (res === null || res === void 0 ? void 0 : res.data) {
                let { serviceId, shopSubResponse, subServiceid, name, categoryId, gender, currentCategory, staffId, StaffData, Date } = (_b = this.props.location) === null || _b === void 0 ? void 0 : _b.state;
                this.props.history.push({
                    pathname: '/confirmandpay',
                    state: { subServiceid, serviceId, shopSubResponse, name: name, currentCategory, gender, categoryId, staffId, StaffData, Date, confirmAndpayData: res === null || res === void 0 ? void 0 : res.data }
                });
            }
        };
        this.createAppointmentAccount = () => {
            var _a, _b, _c, _d, _e, _f;
            let { serviceId, Date } = (_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state;
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const httpBody = {
                service_id: serviceId,
                booking_date: moment(Date).format('YYYY-MM-DD'),
                employee_id: (_c = (_b = this.state.checkBoxValue) === null || _b === void 0 ? void 0 : _b.attributes) === null || _c === void 0 ? void 0 : _c.employee_account_id,
                slot_id: (_f = (_e = (_d = this.state.checkBoxValue) === null || _d === void 0 ? void 0 : _d.attributes) === null || _e === void 0 ? void 0 : _e.available_slots) === null || _f === void 0 ? void 0 : _f.id
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.sendAppointmentcreation = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.endpointAvailable);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.updateAppointmentAccount = () => {
            var _a, _b, _c, _d, _e, _f;
            let { serviceId, Date, appointmentId } = (_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state;
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const httpBody = {
                service_id: serviceId,
                booking_date: moment(Date).format('YYYY-MM-DD'),
                employee_id: (_c = (_b = this.state.checkBoxValue) === null || _b === void 0 ? void 0 : _b.attributes) === null || _c === void 0 ? void 0 : _c.employee_account_id,
                slot_id: (_f = (_e = (_d = this.state.checkBoxValue) === null || _d === void 0 ? void 0 : _d.attributes) === null || _e === void 0 ? void 0 : _e.available_slots) === null || _f === void 0 ? void 0 : _f.id
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.editAppointmentcreation = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.endpointAvailable + '/' + appointmentId);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putMethod);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.handleReceiveupdate = (res) => {
            if ((res === null || res === void 0 ? void 0 : res.message) === "Contact SqueezeMeIn for further bookings") {
                this.props.history.push('/CustomerHomeScreen');
            }
            else {
                this.props.history.push('/BookingRescheduled');
            }
        };
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            timeSelect: false,
            checkBoxValue: '',
            StaffData: [],
            loadingButton: false,
            skipLoadingButton: false
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    componentDidMount() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let token = localStorage.getItem("token") || "";
            let user = localStorage.getItem("userType") || "";
            if (user != "customer" || !token) {
                localStorage.clear();
                window.location.replace("/LandingPage");
            }
            else {
                let { StaffData } = (_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state;
                this.setState({ StaffData: StaffData, checkBoxValue: StaffData[0] });
            }
        });
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                if (apiRequestCallId === this.sendAppointmentcreation) {
                    this.handleReceiveCreateAppointmentRes(responseJson);
                }
                if (apiRequestCallId === this.editAppointmentcreation) {
                    this.handleReceiveupdate(responseJson);
                }
            }
            else {
                runEngine.debugLog("GOIT");
            }
        });
    }
}
