// Customizable Area Start
//@ts-nocheck
import React from "react";
import { withRouter } from "react-router-dom";
import ManageBusiness from "../../ContentModeration/src/ManageBusiness.web";
import DiscountAndOfferWebController from "./DiscountAndOfferWebController";
import "../assets/Styles/discountAndOffer.css";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { addIcon, search_Bitmap, left_time_ar } from "../../ContentModeration/src/assets";
import { OutlinedInput, IconButton, FormControl, InputAdornment, Dialog, Modal, Button, TextField, Checkbox } from "@material-ui/core";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import { calender } from "../../CustomisableUserProfiles/src/assets";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import { close_modal_icon } from "../../Squeez_me_in/src/assets";
import DateRangePicker from "../../../components/src/DateRangePicker.web";
import { exit, warningIcon } from "../../dashboard/src/assets";
import { withTranslation } from "react-i18next";
//@ts-ignore
import Cookies from "js-cookie";
export class DiscountAndOffer extends DiscountAndOfferWebController {
    render() {
        //@ts-ignore
        let { t } = this.props;
        const { activeOfferList, expiredOfferList, expirData } = this.state;
        const isExpireFalse = () => {
            return (React.createElement("div", { className: "add-member-buttons-container" },
                React.createElement(Button, { id: "cancelDiscountButton", className: "add-member-cancel-button responsiveBtn", onClick: this.handleBack }, t("Cancel")),
                React.createElement(Button, { id: "addDiscountButton", className: "add-member-save-button responsiveBtn", onClick: this.addDiscount }, t("Save"))));
        };
        const discountDetailsFalse = () => {
            return React.createElement(React.Fragment, null,
                React.createElement("div", { className: "discount-offer-details" },
                    React.createElement("div", null,
                        React.createElement("p", { className: "discount-offer-title" }, t("Discount")),
                        React.createElement("p", { className: "discount-offer-p-tag", id: "add-percent" }, t("Percent off you wanna offer"))),
                    React.createElement(OutlinedInput, { id: 'Percent-id', "data-test-id": "txtPercent", name: "Percent", onBlur: () => this.validatePercent(), fullWidth: true, style: {
                            marginTop: 20
                        }, onChange: (e) => this.handleInputChange(e), readOnly: this.state.isEdit || this.state.isExpire, value: this.state.percentValue, endAdornment: React.createElement(IconButton, { "aria-label": "toggle password visibility", edge: "end" },
                            React.createElement("span", { style: { color: "#000" } }, "%")) }),
                    this.state.validationError && React.createElement("span", { id: "validationError", style: { color: "red" } }, t(this.state.validationError)),
                    React.createElement("div", { className: "discount-offer-title", style: { marginTop: "20px" } }, t("Days")),
                    React.createElement("div", { className: "offer-dicount-flex-container" },
                        React.createElement("div", { className: "offer-flex-left-sub-container" },
                            React.createElement("label", { htmlFor: "outlined-adornment-password-1", className: "employee-add-block-my-time-sublabel" }, t("From")),
                            React.createElement(FormControl, { variant: "outlined" },
                                React.createElement(OutlinedInput, { id: "calendar1", type: "text", placeholder: "Select date", value: this.state.startDate, style: { borderRadius: '8px', fontFamily: "Poppins-Medium, sans-serif" }, endAdornment: React.createElement(InputAdornment, { position: "end" },
                                        React.createElement(IconButton, { id: 'openCalenderstartTime', className: 'openCalenderstartTime', disabled: this.state.isEdit || this.state.isExpire, onClick: () => { this.setState({ openCalenderDialog: true, modaltype: "startDate" }); }, "aria-label": "toggle password visibility", edge: "end" },
                                            React.createElement("img", { width: "25px", alt: "type", src: calender, height: "26px" }))) })),
                            this.state.startDateError && React.createElement("span", { id: "startDateError", style: { color: "red" } }, t(this.state.startDateError))),
                        React.createElement("div", { className: "offer-flex-right-sub-container" },
                            React.createElement("label", { htmlFor: "outlined-adornment-password-2", className: "employee-add-block-my-time-sublabel" }, t("To")),
                            React.createElement(FormControl, { variant: "outlined" },
                                React.createElement(OutlinedInput, { id: "calender-endtime", type: "text", value: this.state.endDate, readOnly: true, placeholder: "Select date", style: { fontFamily: "Poppins-Medium, sans-serif", borderRadius: '8px' }, endAdornment: React.createElement(InputAdornment, { position: "end" },
                                        React.createElement(IconButton, { disabled: true, onClick: () => { this.setState({ openCalenderDialog: true, modaltype: "endDate" }); }, "aria-label": "toggle password visibility", edge: "end" },
                                            React.createElement("img", { src: calender, alt: "type", width: "25px", height: "26px" }))) })),
                            this.state.endDateError && React.createElement("span", { id: "endDateError", style: { color: "red" } }, t(this.state.endDateError)))),
                    React.createElement("div", null,
                        React.createElement("p", { className: "discount-offer-title-1" }, t("Services")),
                        React.createElement("p", { className: "discount-offer-p-tag-1" }, t("Select on which you wanna give discount"))),
                    React.createElement("div", { style: { width: '100%', marginTop: 15 } },
                        React.createElement("div", { className: "services-container", style: { cursor: 'pointer' }, onClick: () => this.setState({ showServiceList: true }) },
                            React.createElement("div", null,
                                React.createElement("span", { style: { fontSize: "16px", color: "#2C2C2E", fontWeight: 500 } },
                                    t("All services"),
                                    " (",
                                    this.getAllServicesLength(),
                                    ")")),
                            React.createElement("div", null,
                                React.createElement("span", { "data-test-id": "serviceBtn", style: { fontSize: "16px", color: "#999999" } }, this.state.isEdit || this.state.isExpire ? "" : t("Edit")))),
                        this.state.serviceError && React.createElement("span", { id: "serviceError", style: { color: "red" } }, t(this.state.serviceError))),
                    this.state.isEdit ?
                        isEditTrue() : handleButton()));
        };
        const handleButton = () => {
            return this.state.isExpire === false ? isExpireFalse() : "";
        };
        const discountDetailsTrue = () => {
            return (React.createElement("div", { className: "discount-and-offer-container" },
                React.createElement(ToggleButtonGroup, { style: { display: "flex", justifyContent: "center" } },
                    React.createElement(ToggleButton, { style: {
                            borderRadius: 24,
                            width: 100,
                            border: 0,
                            height: 44,
                            fontWeight: 500,
                            textTransform: "none",
                            fontSize: 18,
                            backgroundColor: this.getBackGroundColor(this.state.getSelectionMode, "active"),
                            color: this.getTextColor(this.state.getSelectionMode, "active"),
                        }, className: "weekdays", onClick: () => { this.setState({ getSelectionMode: "active" }); }, value: "left", id: 'btnSelect', "aria-label": "left aligned" },
                        React.createElement("span", { className: "fontfamily-profile", id: "active-txt" }, t("Active"))),
                    React.createElement(ToggleButton, { onClick: () => { this.setState({ getSelectionMode: "expried" }); }, value: "center", style: {
                            border: 0,
                            width: 100,
                            borderRadius: 24,
                            fontSize: 18,
                            height: 44,
                            textTransform: "none",
                            fontWeight: 500,
                            color: this.getTextColor(this.state.getSelectionMode, "expried"),
                            fontFamily: "Poppins",
                            backgroundColor: this.getBackGroundColor(this.state.getSelectionMode, "expried")
                        }, "aria-label": "centered", id: 'expireToggle' },
                        React.createElement("span", { className: "fontfamily-profile", id: "expireText" }, t("Expired")))),
                this.state.getSelectionMode === "active" ?
                    React.createElement(React.Fragment, null, (activeOfferList === null || activeOfferList === void 0 ? void 0 : activeOfferList.length) ? activeOfferList.map((value) => {
                        return React.createElement("div", { className: "discount-and-offer-details", id: "discountOffer1", onClick: () => this.getOfferDetails(value.id) },
                            React.createElement("div", { className: "discount-and-offer-main" },
                                React.createElement("p", { className: "discount-and-offer-text", id: "discount" }, t("Discount")),
                                React.createElement("p", { id: "per", className: "discount-and-offer-pr" },
                                    value.discount_per,
                                    "%")),
                            React.createElement("div", { className: "discount-and-offer-main-1", id: "main" },
                                React.createElement("p", { className: "discount-and-offer-text", id: "days" }, t("Days")),
                                React.createElement("p", { className: "discount-and-offer-pr" },
                                    moment(value.start_at).format("DD MMM YYYY"),
                                    " - ",
                                    moment(value.end_at).format("DD MMM YYYY"))));
                    }) : React.createElement("h2", { id: "nodataFound", className: "nodata" }, t("No data available")))
                    :
                        React.createElement(React.Fragment, null, (expiredOfferList === null || expiredOfferList === void 0 ? void 0 : expiredOfferList.length) ? expiredOfferList.map((value) => {
                            return React.createElement("div", { className: "discount-and-offer-details", id: "discountAndOffer", onClick: () => this.getOfferDetails(value.id) },
                                React.createElement("div", { className: "discount-and-offer-main" },
                                    React.createElement("p", { className: "discount-and-offer-text" }, t("Discount")),
                                    React.createElement("p", { className: "discount-and-offer-pr", id: "expirePre" },
                                        value.discount_per,
                                        "%")),
                                React.createElement("div", { className: "discount-and-offer-main-1" },
                                    React.createElement("p", { className: "discount-and-offer-text" }, t("Days")),
                                    React.createElement("p", { className: "discount-and-offer-pr" },
                                        moment(value.start_at).format("DD MMM YYYY"),
                                        " - ",
                                        moment(value.end_at).format("DD MMM YYYY"))));
                        }) : React.createElement("h2", { className: "nodata" }, t("No data available")))));
        };
        const isEditTrue = () => (React.createElement("div", { className: "delete-discount-Button-container" },
            React.createElement(Button, { id: "deleteDiscountButton", className: "delete-discount-Button", "data-test-id": "delete-button", onClick: () => this.setState({ deletemodal: true }) }, t("Delete"))));
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        return (React.createElement("div", { className: "manage-team-main-container" },
            React.createElement(ManageBusiness, null),
            React.createElement("div", { className: webAppDirection === "ltr"
                    ? "manage-catalouge-content-container"
                    : "arabic-manage-catalouge-content-container" },
                this.state.discountDetails ? React.createElement("div", { className: "discount-and-offer-hadiing" },
                    React.createElement("h1", { id: "heading" }, t("Offers & Discounts")),
                    React.createElement("img", { src: addIcon, style: { cursor: "pointer" }, id: "Add", onClick: this.handleDiscount })) :
                    React.createElement("div", { className: "discount-and-offer-subtital" },
                        React.createElement("img", { src: left_time_ar, style: { cursor: "pointer", width: 30 }, id: "back", onClick: this.handleBack }),
                        React.createElement("h1", { id: "text", style: { marginLeft: 15 } }, t("Offers & Discounts"))),
                this.state.discountDetails ?
                    discountDetailsTrue() : discountDetailsFalse(),
                React.createElement(Dialog, { open: this.state.openCalenderDialog }, React.createElement(DateRangePicker, { onClose: this.handleCancle, getDate: this.handleDateChange })),
                this.state.showServiceList && (React.createElement(React.Fragment, null,
                    React.createElement("div", { style: { position: "fixed", content: " ", width: "100%", height: "100vh", background: "rgba(0,0,0, 0.4)", zIndex: 0, top: "0", left: "0" } }, "\u00A0"),
                    (this.state.isExpire || this.state.isEdit) ?
                        React.createElement(Modal, { id: "serviceModalClose", open: this.state.showServiceList, className: "add-member-service-modal", onClose: () => this.setState({ showServiceList: !this.state.showServiceList }) },
                            React.createElement("div", { className: webAppDirection === "ltr"
                                    ? "add-member-service-modal-container"
                                    : "arabic-add-member-service-modal-container", style: { marginTop: 15 } },
                                React.createElement("div", { className: "add-member-service-category", style: { justifyContent: "space-between" } },
                                    React.createElement("span", { className: "service-header" }, t("Services")),
                                    React.createElement("img", { src: close_modal_icon, className: "img-debit-1", alt: "", onClick: () => { this.setState({ showServiceList: false }); } })),
                                React.createElement("div", { className: "add-member-search-service-input" },
                                    React.createElement(TextField, { InputProps: {
                                            startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                                React.createElement("img", { className: "search-icon", src: search_Bitmap, alt: "" }))),
                                        }, onChange: (e) => this.searchFilterFunction(e.target.value), id: "outlined-basic", className: "searchClassname", variant: "outlined", placeholder: "Search for Services" })),
                                expirData.map((item) => (React.createElement("div", { style: { marginTop: 20 } },
                                    React.createElement("div", { className: "add-member-manage-services-item-container" },
                                        React.createElement("div", { className: "add-member-user-details" },
                                            React.createElement("span", { className: "add-member-service-sub-category" },
                                                this.state.selectedServices.includes(item.id),
                                                item.display_name),
                                            React.createElement("span", { className: "add-member-service-sub-category-details" },
                                                item.duration.slice(3, -3),
                                                " ",
                                                t("min - OMR"),
                                                " ",
                                                item.service_cost)))))))) :
                        React.createElement(Modal, { open: this.state.showServiceList, id: "serviceModalClose", onClose: () => this.setState({ showServiceList: !this.state.showServiceList }), className: webAppDirection === "ltr"
                                ? "add-member-service-modal"
                                : "arabic-add-member-service-modal" },
                            React.createElement("div", { className: webAppDirection === "ltr"
                                    ? "add-member-service-modal-container"
                                    : "arabic-add-member-service-modal-container" },
                                React.createElement("div", { className: "add-member-service-category" },
                                    React.createElement("span", { className: "service-header", id: "service-header" }, t("Services")),
                                    React.createElement("span", { className: "service-category" }, this.state.serviceProvider)),
                                React.createElement("div", { className: "add-member-search-service-input" },
                                    React.createElement(TextField, { InputProps: {
                                            startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                                React.createElement("img", { className: "search-icon", src: search_Bitmap, alt: "" }))),
                                        }, className: "servicesClass", id: "outlined-basic1", placeholder: "Search for Services", onChange: (e) => this.handleSearch(e.target.value), variant: "outlined" })),
                                this.state.serviceList.map((service, index) => {
                                    var _a;
                                    return (React.createElement("div", { key: index },
                                        React.createElement("div", { className: "add-member-service-header" },
                                            React.createElement("h4", null, service.attributes.name),
                                            !this.state.selectAll.includes((service.attributes.id)) ? React.createElement("div", { id: 'selectAllId', "data-test-id": "selectAll", onClick: () => this.selectAll(service.attributes.business_sub_categories, service.attributes.id, true) }, t("Select All")) :
                                                React.createElement("div", { id: 'deselectall', "data-test-id": "deselectAll", onClick: () => this.selectAll(service.attributes.business_sub_categories, service.attributes.id, false) }, t("Deselect All"))), (_a = service.attributes.business_sub_categories) === null || _a === void 0 ? void 0 :
                                        _a.map((serviceItem) => {
                                            var _a;
                                            return (React.createElement("div", { key: serviceItem.service_name, className: "add-member-manage-services-item-container" },
                                                React.createElement("div", { className: "add-member-user-details" },
                                                    React.createElement("span", { className: "add-member-service-sub-category" },
                                                        this.state.selectedServices.includes(serviceItem.id),
                                                        serviceItem.display_name),
                                                    React.createElement("span", { className: "add-member-service-sub-category-details" },
                                                        serviceItem.duration.slice(3, -3),
                                                        " ",
                                                        t("min"),
                                                        " - OMR",
                                                        " ",
                                                        serviceItem.service_cost)),
                                                React.createElement("span", { className: "caret-right" },
                                                    React.createElement(Checkbox, { icon: React.createElement(CircleUnchecked, null), checkedIcon: React.createElement(CircleCheckedFilled, null), id: "serviceCheckbox" + serviceItem.id, key: serviceItem.service_name, checked: (((_a = this.state.selectedServices) === null || _a === void 0 ? void 0 : _a.length) > 0) ? this.state.selectedServices.includes(serviceItem.id) : false, onClick: () => this.handleRadioButton(this.state.selectedServices.includes((serviceItem.id)), serviceItem.id), name: serviceItem.service_name, value: serviceItem.id, inputProps: { "aria-label": "A" } }))));
                                        })));
                                }),
                                React.createElement("div", { className: "add-member-buttons-container" },
                                    React.createElement(Button, { id: "serviceButtonCancel", className: "add-member-cancel-button responsiveBtn", onClick: () => this.setState({
                                            showServiceList: !this.state.showServiceList,
                                            selectedServices: [],
                                            selectAll: []
                                        }, () => {
                                            this.getServicesListApi("");
                                        }) }, t("Cancel")),
                                    React.createElement(Button, { id: "serviceButtonSave", className: "add-member-save-button responsiveBtn .rgtBtn", onClick: () => this.setState({
                                            showServiceList: !this.state.showServiceList,
                                            serviceError: ""
                                        }, () => {
                                            this.getServicesListApi("");
                                        }) }, t("Save"))))))),
                React.createElement(Dialog, { open: this.state.deletemodal },
                    React.createElement("div", { className: "logout_modal" },
                        React.createElement("img", { alt: "exit-icon", className: "modal-icon", src: exit }),
                        React.createElement("div", { className: "logout_confirmation_text", id: "confirmation" }, t("Are you sure you want to delete this?")),
                        React.createElement(Button, { className: "logout-modal-no-button-style", id: "delete-cancel", onClick: () => this.setState({ deletemodal: false }) }, t("No")),
                        React.createElement(Button, { className: "logout-modal-yes-button-style", id: "confirm-delete", onClick: () => this.deleteDiscount() }, t("Yes")))),
                React.createElement(Dialog, { open: this.state.warningModal },
                    React.createElement("div", { className: "logout_modal", style: { height: "auto" } },
                        React.createElement("img", { alt: "exit-icon", className: "modal-icon", src: exit }),
                        React.createElement("div", { className: "logout_confirmation_text" }, t(this.state.warningMessage)),
                        React.createElement(Button, { className: "logout-modal-yes-button-style", id: "closeBtn", onClick: () => this.setState({ warningModal: false, deletemodal: false, selectAll: [] }) }, t("Close")))),
                React.createElement(Dialog, { open: this.state.showAlert, 
                    // className={"delete-member-modal"}
                    onClose: () => this.setState({ showAlert: false }) },
                    React.createElement("div", { className: "del-modal-container", style: {
                            width: "400px"
                        } },
                        React.createElement("img", { src: warningIcon, alt: "logo", width: "120", height: "120" }),
                        React.createElement("p", { id: "errorMsg" }, this.state.errorMessage),
                        React.createElement(Button, { className: "add-member-cancel-button", id: "errorClose", onClick: () => {
                                this.setState({
                                    showAlert: false,
                                    discountDetails: true,
                                    selectAll: []
                                });
                            } }, t("Close")))))));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(DiscountAndOffer));
