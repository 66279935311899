import React from "react";
import appointmentController from "./appointmentController.web";
import { white_left_arrow, calender_prev_arrow, calender_next_arrow, accordian_arrow, sesssionExpired, no_staff, } from "./assets";
import "../assets/style/BusinessSevices.web.scss";
import "../assets/style/BookNowModal.scss";
import { withRouter } from "react-router-dom";
import { Button, Modal, Typography, AccordionDetails, AccordionSummary, Accordion, } from "@material-ui/core";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { FadeLoader } from "react-spinners";
import moment from "moment";
import { withTranslation } from "react-i18next";
import Loader from "../../../components/src/Loader.web";
const Cookies = require("js-cookie");
class BookingCalender extends appointmentController {
    render() {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        let { t } = this.props;
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        return (React.createElement(React.Fragment, null,
            React.createElement(Loader, { loading: this.state.loading }),
            React.createElement("div", { className: "main-div", onClick: (e) => {
                    if (this.state.timeSelect) {
                        this.setState({ timeSelect: false });
                    }
                } },
                React.createElement("div", { style: { maxWidth: "100%", backgroundColor: "#3d40c3" } },
                    React.createElement("div", { className: "services-header" },
                        React.createElement("img", { src: white_left_arrow, alt: "", id: "service_left_arrow", onClick: () => {
                                var _a;
                                let { subServiceid, currentCategory, gender, categoryId, show_gender } = (_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state;
                                this.props.history.push({
                                    pathname: "/CategoryServices",
                                    state: {
                                        gender,
                                        show_gender,
                                        subCategory: currentCategory,
                                        id: categoryId,
                                        subId: subServiceid,
                                    },
                                });
                            } }),
                        React.createElement("h1", null, t("Select Slot")))),
                React.createElement("div", { className: "calendar-div" },
                    React.createElement(Calendar, { minDate: new Date(), goToRangeStartOnSelect: false, value: this.state.dateState, onChange: this.changeDate, tileDisabled: ({ date }) => this.state.disabledDays.includes(date.getDay()), nextLabel: React.createElement("img", { src: calender_next_arrow }), prevLabel: React.createElement("img", { src: calender_prev_arrow }), tileClassName: ({ date, view }) => {
                            var _a;
                            if ((_a = this.state.holidays) === null || _a === void 0 ? void 0 : _a.find((x) => x == moment(date).format("YYYY-MM-DD"))) {
                                return "disable-date-highlight";
                            }
                            if (moment(date).format("LL") == moment(new Date()).format("LL")) {
                                return "current-date-highlight";
                            }
                        }, calendarType: "US", formatMonthYear: (locale, date) => date.toLocaleString(locale, { month: "short", year: "numeric" }) }),
                    this.state.dateRequie ? (React.createElement("p", { className: "p-require-tag" }, t("Please select date"))) : ("")),
                React.createElement("div", { className: "available-div" },
                    React.createElement("h1", null, t("Availability")),
                    React.createElement("p", { className: webAppDirection === "rtl" ? "arabic-p-tag" : "" },
                        this.state.time.m != 10
                            ? "0" + this.state.time.m
                            : this.state.time.m,
                        ":",
                        this.state.time.s <= 9
                            ? "0" + this.state.time.s
                            : this.state.time.s)),
                React.createElement("div", { className: "timeslot-div" },
                    React.createElement("h1", null, t("Time slot")),
                    React.createElement(Accordion, { className: "accordian-main-div", style: this.state.timerequire
                            ? { border: "2px solid red" }
                            : { border: "2px solid #757575" }, expanded: this.state.timeSelect },
                        React.createElement(AccordionSummary, { onClick: () => {
                                var _a;
                                ((_a = this.state.availableTime) === null || _a === void 0 ? void 0 : _a.length) !== 0
                                    ? this.setState({ timeSelect: true })
                                    : this.setState({ timeSelect: false });
                            }, expandIcon: React.createElement("img", { style: this.state.timerequire
                                    ? {
                                        filter: " invert(67%) sepia(89%) saturate(7492%) hue-rotate(346deg) brightness(84%) contrast(146%)",
                                    }
                                    : { filter: "initial" }, src: accordian_arrow }), "aria-controls": "panel1a-content", id: "panel1a-header" },
                            React.createElement(Typography, { style: this.state.timerequire
                                    ? { color: "red" }
                                    : { color: "#2c2c2e" } }, ((_a = this.state.availableTime) === null || _a === void 0 ? void 0 : _a.length) !== 0
                                ? this.timeSlotValueView()
                                : t("No slot available"))), (_b = this.state.availableTime) === null || _b === void 0 ? void 0 :
                        _b.map((ele, index) => {
                            let [time, amPm] = ele === null || ele === void 0 ? void 0 : ele.time.split(" ");
                            return (React.createElement("div", { key: index },
                                React.createElement("hr", null),
                                React.createElement(AccordionDetails, { style: { cursor: "pointer" }, id: "accordian_detail_div", onClick: (e) => {
                                        if (ele === null || ele === void 0 ? void 0 : ele.enable) {
                                            this.setState({
                                                timeSelect: false,
                                                timeslotValue: ele === null || ele === void 0 ? void 0 : ele.time,
                                                timerequire: false,
                                            });
                                        }
                                    } },
                                    React.createElement(Typography, { style: (ele === null || ele === void 0 ? void 0 : ele.enable) ? { color: "#2c2c2e" }
                                            : { color: "rgba(0,0,0,0.2)" } }, `${time} ${t(amPm)}`))));
                        })),
                    this.state.timerequire ? (React.createElement("p", { className: "p-require-tag" }, t("Please select time slot"))) : (""),
                    React.createElement("hr", { className: "hr-tag" }),
                    React.createElement("div", null,
                        React.createElement("h1", null, (_d = (_c = this.props.location) === null || _c === void 0 ? void 0 : _c.state) === null || _d === void 0 ? void 0 : _d.name[0]),
                        React.createElement("p", { className: `p-tag ${webAppDirection === "rtl" &&
                                "arabic-p-tag"}` }, ((_f = (_e = this.props.location) === null || _e === void 0 ? void 0 : _e.state) === null || _f === void 0 ? void 0 : _f.name[2]) +
                            " " + ((_h = (_g = this.props.location) === null || _g === void 0 ? void 0 : _g.state) === null || _h === void 0 ? void 0 : _h.name[1]))),
                    this.state.noStaff ? (React.createElement("div", { className: "no_staff" },
                        React.createElement("img", { src: no_staff, alt: "" }),
                        React.createElement("p", null, t("No staff available")))) : (""),
                    React.createElement(Button, { className: "book-now-btn-continue", onClick: () => {
                            if (this.state.timeslotValue === "Select your slot") {
                                this.setState({ timerequire: true });
                            }
                            if (!this.state.dateState) {
                                this.setState({ dateRequie: true });
                            }
                            if (this.state.dateState &&
                                this.state.timeslotValue != "Select your slot") {
                                this.getStaffDetails();
                            }
                        } }, t("Continue")))),
            React.createElement("div", { className: "bookNow-main-div" },
                React.createElement(Modal, { open: this.state.openBookNowModal, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" },
                    React.createElement("div", { className: "modal-booknow", style: { height: "418px" } },
                        React.createElement("div", { style: { textAlign: "center", marginTop: "70px" } },
                            React.createElement("img", { src: sesssionExpired, alt: "" })),
                        React.createElement("div", null,
                            React.createElement("h1", { className: "h1-tag-modal" }, t("Session expired!")),
                            React.createElement(Button, { className: "book-now-btn-modal", onClick: () => {
                                    this.timer = 0;
                                    this.setState({ seconds: 600, openBookNowModal: false }, () => {
                                        this.startTimer();
                                    });
                                } }, t("Continue")))))),
            React.createElement("div", { className: "bookNow-main-div" },
                React.createElement(Modal, { open: this.state.openLoading, id: "onclose_modal", onClose: () => this.setState({ openLoading: false }), "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" },
                    React.createElement("div", { className: "modal-booknow", style: { height: "300px", width: "341px" } },
                        React.createElement("div", { style: { textAlign: "center", marginTop: "70px" } },
                            React.createElement(FadeLoader, { className: webAppDirection === "ltr"
                                    ? "fader-Loader"
                                    : "arabic-fader-Loader", radius: 12, height: 30, width: 11, color: "#3D6DEB", margin: "20px" })),
                        React.createElement("div", null,
                            React.createElement("h1", { className: "h1-tag-modal-1" }, t("Assigning you available staff"))))))));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(BookingCalender));
