var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
export const configJSON = require("./config");
export default class CustomerSubcategoryScreenController extends BlockComponent {
    constructor(props) {
        super(props);
        this.closeBookNowModal = () => {
            this.setState({ openBookNowModal: false });
        };
        this.manageRoute = () => __awaiter(this, void 0, void 0, function* () {
            let { gender, subCategory, id, subId, show_gender } = (yield this.props.location.state) || { '': '' };
            if (gender === '' && subCategory === '' && id === '' && subId) {
                this.setState({ sendToHome: true });
                this.getShopesDetails(subId);
            }
            if (gender && subCategory) {
                this.setState({
                    currentCategory: subCategory,
                    gender: gender,
                    show_gender: show_gender,
                    categoryId: id,
                });
                if (this.call_shope_one_times === 'call_first') {
                    this.getShopes(this.state.categoryId);
                }
                if (subId && this.call_shope_one_times === 'call_last') {
                    this.setState({ subServiceid: subId });
                    this.getShopesDetails(subId);
                }
            }
            if (!subId && !gender && !subCategory && !id) {
                this.getAppointmentFromindexDb();
                let SubIdFromIndexDb = localStorage.getItem("SubIdFromIndexDb") || '';
                this.getShopesDetails(SubIdFromIndexDb);
            }
            let userId = localStorage.getItem("userId");
            this.checkActiveStatus(userId);
        });
        this.getAppointmentFromindexDb = () => {
            function connectDB(f) {
                const request = self.indexedDB.open('squeezMein', 1);
                request.onsuccess = function () {
                    f(request.result);
                };
                request.onupgradeneeded = function (e) {
                    //@ts-ignore
                    e.currentTarget.result.createObjectStore('squeez', {
                        autoIncrement: true,
                    });
                    connectDB(f);
                };
            }
            connectDB((db) => {
                const transition = db.transaction(['squeez'], 'readwrite');
                const objectStore = transition.objectStore('squeez');
                const objectStoreRequest = objectStore.getAll();
                objectStoreRequest.onsuccess = function () {
                    var _a, _b, _c;
                    localStorage.setItem("SubIdFromIndexDb", (_c = (_b = (_a = objectStoreRequest === null || objectStoreRequest === void 0 ? void 0 : objectStoreRequest.result[0]) === null || _a === void 0 ? void 0 : _a.payload) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.business_profile_id);
                    objectStore.delete(0);
                };
            });
        };
        this.checkActiveStatus = (id) => {
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getUserStatusCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.checkUserStatusURL + id);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getHttpRequest);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.changeSize = () => {
            if (window.screen.width < 1282) {
                this.setState({ slideItem: 2 });
            }
            if (window.screen.width < 1000) {
                this.setState({ slideItem: 1 });
            }
            if (window.screen.width > 1282) {
                this.setState({ slideItem: 3 });
            }
        };
        this.getShopes = (categoryid) => {
            let latitude;
            let longitude;
            navigator.geolocation.getCurrentPosition((position) => {
                latitude = position.coords.latitude;
                localStorage.setItem("Latitude", latitude);
                longitude = position.coords.longitude;
                localStorage.setItem("Longitude", longitude);
                this.getDataByLocation(latitude, longitude);
            }, (error) => {
                this.getDataByLocation("", "");
            });
            return true;
        };
        this.getDataByLocation = (latitude, longitude) => {
            let gender = this.state.gender === "Men & Women" ? "Men %26 Women" : this.state.gender;
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getShopApiCallId = requestMessage.messageId;
            if (longitude && latitude) {
                requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.particular_shope_endPoint + '?service_provided=' + gender + '&business_type=' + this.state.currentCategory + '&coordinate[lat]=' + latitude + '&coordinate[lon]=' + longitude);
            }
            else {
                requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.particular_shope_endPoint + '?service_provided=' + gender + '&business_type=' + this.state.currentCategory);
            }
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getHttpRequest);
            runEngine.sendMessage(requestMessage.id, requestMessage);
        };
        this.handleSearchText = (text) => {
            this.props.history.push({
                pathname: "/search",
                state: {
                    gender: this.state.gender,
                    subCategory: this.state.currentCategory,
                    searchValue: text,
                },
            });
        };
        this.handleReceiveShopeRes = (response) => {
            this.setState({ shopResponse: response.data, isLoading: false });
        };
        this.handleReceiveShopeSubRes = (response) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
            this.setState({ shopSubResponse: response.data, isLoading: false, gender: (_c = (_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.service_provided) === null || _c === void 0 ? void 0 : _c.name, show_gender: (_f = (_e = (_d = response === null || response === void 0 ? void 0 : response.data) === null || _d === void 0 ? void 0 : _d.attributes) === null || _e === void 0 ? void 0 : _e.service_provided) === null || _f === void 0 ? void 0 : _f.display_name, currentCategory: (_j = (_h = (_g = response === null || response === void 0 ? void 0 : response.data) === null || _g === void 0 ? void 0 : _g.attributes) === null || _h === void 0 ? void 0 : _h.business_type) === null || _j === void 0 ? void 0 : _j.business_type, });
            if (((_k = response === null || response === void 0 ? void 0 : response.data) === null || _k === void 0 ? void 0 : _k.attributes.business_hours) && ((_l = response === null || response === void 0 ? void 0 : response.data) === null || _l === void 0 ? void 0 : _l.attributes.business_weekend_hours)) {
                const objectResp = response.data.attributes.business_hours;
                const objectweekend = response.data.attributes.business_weekend_hours;
                let givenDays = [];
                for (const property in objectResp) {
                    let timeObj = { day: property, time: objectResp[property]["open_from"] + ' - ' + objectResp[property]["close_at"] };
                    this.setState({ clock_Array: [...this.state.clock_Array, timeObj] });
                    givenDays.push(property.toLowerCase());
                }
                for (const property in objectweekend) {
                    let timeObj = { day: property, time: objectweekend[property]["open_from"] + ' - ' + objectweekend[property]["close_at"] };
                    this.setState({ clock_Array: [...this.state.clock_Array, timeObj] });
                    givenDays.push(property.toLowerCase());
                }
                let days_arr = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
                days_arr.forEach((allDay) => {
                    if (!givenDays.includes(allDay)) {
                        let timeElmObj = { day: allDay, time: 'Closed' };
                        this.setState({ clock_Array: [...this.state.clock_Array, timeElmObj] });
                    }
                });
            }
            this.getShopesDetailsRatingReview((_o = (_m = response === null || response === void 0 ? void 0 : response.data) === null || _m === void 0 ? void 0 : _m.attributes) === null || _o === void 0 ? void 0 : _o.account_id);
        };
        // convertToLocal =(item:any) =>{
        //   const utcTime = moment.utc(item, 'h:mm A');
        //   const localTime = utcTime.local();
        //   const formattedTimeOpen = localTime.format('h:mm A');
        //   return formattedTimeOpen;
        // }
        this.getShopesDetails = (subid) => {
            this.setState({ isLoading: true });
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getShopSubApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.subDetailsEndpoint + subid);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getHttpRequest);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.getShopesDetailsRatingReview = (accountId) => {
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getShopSubRatingAndReviewApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.ratingAndreviewEndpoint + accountId);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getHttpRequest);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.handleUserStatus = (responseJson) => {
            if (responseJson) {
                localStorage.setItem("isActive", responseJson.activated);
                this.setState({
                    isActive: responseJson.activated
                });
            }
        };
        this.handleReviewAndRatingRes = (responseJson) => {
            var _a, _b, _c, _d;
            this.setState({ noOfrating: ((_a = responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) === null || _a === void 0 ? void 0 : _a.no_of_ratings) || 0, overAllRating: ((_b = responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) === null || _b === void 0 ? void 0 : _b.overall_rating) || 0, reviewResponse: ((_d = (_c = responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) === null || _c === void 0 ? void 0 : _c.review) === null || _d === void 0 ? void 0 : _d.data) || [] });
        };
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            // getName(MessageEnum.NavigationPayLoadMessage),
            // getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            currentCategory: '',
            gender: '',
            show_gender: '',
            clock_Array: [],
            open_now: false,
            shopResponse: [],
            categoryId: '',
            shopSubResponse: [],
            isLoading: true,
            open_now_timeline: '',
            slideItem: 3,
            sendToHome: false,
            openBookNowModal: false,
            selectServiceForbooking: [],
            openSessionModal: 'No',
            subServiceid: '',
            isActive: true,
            noOfrating: '',
            overAllRating: '',
            reviewResponse: ''
        };
        this.call_shope_one_times = '';
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            let userType = localStorage.getItem("userType") || "";
            let token = localStorage.getItem("token") || "";
            setTimeout(() => {
                if (userType != "customer" || !token) {
                    localStorage.clear();
                    window.location.replace("/LandingPage");
                }
                else {
                    this.manageRoute();
                    this.changeSize();
                }
            }, 1000);
        });
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                if (apiRequestCallId === this.getShopApiCallId) {
                    this.handleReceiveShopeRes(responseJson);
                }
                if (apiRequestCallId === this.getShopSubApiCallId) {
                    this.handleReceiveShopeSubRes(responseJson);
                }
                if (apiRequestCallId === this.getUserStatusCallId) {
                    this.handleUserStatus(responseJson);
                }
                if (apiRequestCallId === this.getShopSubRatingAndReviewApiCallId) {
                    this.handleReviewAndRatingRes(responseJson);
                }
            }
            else {
                runEngine.debugLog("GOIT");
            }
        });
    }
}
