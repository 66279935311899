var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Message } from "framework/src/Message";
import MessageEnum, { getName, } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { BlockComponent } from "framework/src/BlockComponent";
//@ts-ignore
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
toast.configure();
export const configJSON = require("../../../blocks/Squeez_me_in/src/config");
export default class MyBookingController extends BlockComponent {
    constructor(props) {
        super(props);
        //istanbul ignore next
        this.callallApi = (bookingscreen) => {
            var _a, _b, _c, _d;
            if (bookingscreen == 'yes') {
                this.getMyallAppoitments();
            }
            if ((_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state) {
                localStorage.removeItem("appointmentIdFromIndexDb");
                let { appointmentId } = (_b = this.props.location) === null || _b === void 0 ? void 0 : _b.state;
                if (appointmentId) {
                    this.getOneAppoitments(appointmentId);
                    this.getIsues();
                }
            }
            if (!((_d = (_c = this.props.location) === null || _c === void 0 ? void 0 : _c.state) === null || _d === void 0 ? void 0 : _d.appointmentId)) {
                this.getAppointmentFromindexDb(false);
                setTimeout(() => {
                    let appointmentId = localStorage.getItem("appointmentIdFromIndexDb") || localStorage.getItem("appointmentIdFromIndexDb1") || '';
                    if (appointmentId) {
                        this.getOneAppoitments(appointmentId);
                        this.getIsues();
                    }
                }, 1000);
            }
        };
        this.gotoThatPage = () => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
            this.props.history.push({
                pathname: '/customer/service/booking',
                state: { serviceId: (_b = (_a = this.state.appointmentdata) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.service_id, gender: this.state.appointmentdata.attributes.business_profile.data.attributes.service_provided.name, show_gender: this.state.appointmentdata.attributes.business_profile.data.attributes.service_provided.display_name, staffId: (_d = (_c = this.state.appointmentdata) === null || _c === void 0 ? void 0 : _c.attributes) === null || _d === void 0 ? void 0 : _d.service_id, shopSubResponse: (_g = (_f = (_e = this.state.appointmentdata) === null || _e === void 0 ? void 0 : _e.attributes) === null || _f === void 0 ? void 0 : _f.business_profile) === null || _g === void 0 ? void 0 : _g.data, subServiceid: (_l = (_k = (_j = (_h = this.state.appointmentdata) === null || _h === void 0 ? void 0 : _h.attributes) === null || _j === void 0 ? void 0 : _j.business_profile) === null || _k === void 0 ? void 0 : _k.data) === null || _l === void 0 ? void 0 : _l.id, name: [this.state.appointmentdata.attributes.service.display_name || "", (_p = (_o = (_m = this.state.appointmentdata) === null || _m === void 0 ? void 0 : _m.attributes) === null || _o === void 0 ? void 0 : _o.service) === null || _p === void 0 ? void 0 : _p.service_cost, (_s = (_r = (_q = this.state.appointmentdata) === null || _q === void 0 ? void 0 : _q.attributes) === null || _r === void 0 ? void 0 : _r.service) === null || _s === void 0 ? void 0 : _s.currency], reschedule: true, appointmentId: (_t = this.state.appointmentdata) === null || _t === void 0 ? void 0 : _t.id }
            });
        };
        this.checkDisablity = () => {
            var _a, _b;
            if (((_b = (_a = this.state.appointmentdata) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.customer_rescheduled_count) >= 5) {
                let btn = document.querySelector('#updateData_btn');
                btn && btn.setAttribute("style", 'color: #ffffff63; cursor: no-drop;');
                return true;
            }
            else {
                let btn = document.querySelector('#updateData_btn');
                btn && btn.setAttribute("style", 'color: #ffffff; cursor: pointer;');
                return false;
            }
        };
        //istanbul ignore next
        this.getAppointmentFromindexDb = (isDelete) => {
            function connectDB(f) {
                const request = self.indexedDB.open('squeezMein', 1);
                request.onsuccess = function () {
                    f(request.result);
                };
                request.onupgradeneeded = function (e) {
                    //@ts-ignore
                    e.currentTarget.result.createObjectStore('squeez', {
                        autoIncrement: true,
                    });
                    connectDB(f);
                };
            }
            connectDB((db) => {
                const transition = db.transaction(['squeez'], 'readwrite');
                const objectStore = transition.objectStore('squeez');
                const objectStoreRequest = objectStore.getAll();
                objectStoreRequest.onsuccess = function () {
                    var _a, _b, _c;
                    if (isDelete) {
                        objectStore.delete(0);
                        localStorage.removeItem("appointmentIdFromIndexDb");
                        localStorage.removeItem("appointmentIdFromIndexDb1");
                    }
                    else {
                        localStorage.setItem("appointmentIdFromIndexDb", ((_c = (_b = (_a = objectStoreRequest === null || objectStoreRequest === void 0 ? void 0 : objectStoreRequest.result[0]) === null || _a === void 0 ? void 0 : _a.payload) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.appointment_id) || "");
                    }
                };
            });
        };
        this.CancelAppointmentAccount = () => {
            var _a;
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const httpBody = {
                id: (_a = this.state.appointmentdata) === null || _a === void 0 ? void 0 : _a.id,
            };
            const cancelAppointmentRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.editAppointmentcreation = cancelAppointmentRequestMessage.messageId;
            cancelAppointmentRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.cancelAppoitment);
            cancelAppointmentRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            cancelAppointmentRequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            cancelAppointmentRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putMethod);
            runEngine.sendMessage(cancelAppointmentRequestMessage.id, cancelAppointmentRequestMessage);
            return true;
        };
        this.senEmailInvoice = () => {
            var _a;
            this.setState({ invoiceorCancel: 'invoice', isloading: true });
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const emailInvoiceRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.invoiceApiCallId = emailInvoiceRequestMessage.messageId;
            emailInvoiceRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.sendEmil + '/' + ((_a = this.state.appointmentdata) === null || _a === void 0 ? void 0 : _a.id));
            emailInvoiceRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            emailInvoiceRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getHttpRequest);
            runEngine.sendMessage(emailInvoiceRequestMessage.id, emailInvoiceRequestMessage);
            return true;
        };
        this.handleSearchBookings = (value) => {
            //istanbul ignore next
            if (this.debounceTimer) {
                clearTimeout(this.debounceTimer);
            }
            this.debounceTimer = setTimeout(() => {
                this.searchAppoitments(value);
            }, 500);
        };
        this.searchAppoitments = (value) => {
            this.setState({ isloading: true });
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getMyAppointmentApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.endpointAvailable + `?booking_id=${value}`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getHttpRequest);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.getMyallAppoitments = (getStatus) => {
            this.setState({ isloading: true });
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getMyAppointmentApiCallId = requestMessage.messageId;
            if (getStatus && getStatus !== 'All') {
                requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.endpointAvailable + '?status=' + getStatus);
            }
            else {
                requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.endpointAvailable);
            }
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getHttpRequest);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.getOneAppoitments = (id) => {
            this.setState({ isloading: true });
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const getOneAppointMentRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getOneAppointmentApiCallId = getOneAppointMentRequestMessage.messageId;
            getOneAppointMentRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.endpointAvailable + '/' + id);
            getOneAppointMentRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            getOneAppointMentRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getHttpRequest);
            console.log("calling");
            runEngine.sendMessage(getOneAppointMentRequestMessage.id, getOneAppointMentRequestMessage);
            return true;
        };
        this.CreateReview = () => {
            var _a, _b, _c, _d, _e, _f;
            let { appointmentId } = ((_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state) || "";
            let appointmentIdFromNotification = localStorage.getItem("appointmentIdFromIndexDb") || localStorage.getItem("appointmentIdFromIndexDb1") || '';
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const httpBody = {
                "account_id": (_f = (_e = (_d = (_c = (_b = this.state.appointmentdata) === null || _b === void 0 ? void 0 : _b.attributes) === null || _c === void 0 ? void 0 : _c.employee_details) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.attributes) === null || _f === void 0 ? void 0 : _f.employee_account_id,
                "rating": this.state.ratingvalue,
                "description": this.state.reviewInput,
                "appointment_id": appointmentId || appointmentIdFromNotification
            };
            const createReviewRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.createReviewApiId = createReviewRequestMessage.messageId;
            createReviewRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.reviewApi);
            createReviewRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            createReviewRequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            createReviewRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
            runEngine.sendMessage(createReviewRequestMessage.id, createReviewRequestMessage);
            return true;
        };
        this.getIsues = () => {
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const getIssuesRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getallIssueApiCallId = getIssuesRequestMessage.messageId;
            getIssuesRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.inquireType);
            getIssuesRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getHttpRequest);
            getIssuesRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            runEngine.sendMessage(getIssuesRequestMessage.id, getIssuesRequestMessage);
            return true;
        };
        this.handleResponseAppointment = (res) => {
            if ((res === null || res === void 0 ? void 0 : res.notice) === 'No appointments found.') {
                this.setState({ appointmentArr: [], isloading: false });
            }
            else {
                this.setState({ appointmentArr: [res], isloading: false });
            }
        };
        this.handleResponseOneAppointment = (res) => {
            var _a, _b, _c, _d;
            if (res === null || res === void 0 ? void 0 : res.data) {
                let aDate = moment((_b = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.start_time).format('MM/DD/YYYY') + ' ' + moment((_d = (_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c.attributes) === null || _d === void 0 ? void 0 : _d.start_time).format('hh:mm A');
                let bDate = moment().format('MM/DD/YYYY') + ' ' + moment().format('hh:mm A');
                aDate = new Date(aDate).getTime();
                bDate = new Date(bDate).getTime();
                let ms = aDate - bDate;
                let seconds = Math.floor(ms / 1000);
                let minutes = Math.floor(seconds / 60);
                let hours = minutes / 60;
                this.setState({ appointmentdata: res === null || res === void 0 ? void 0 : res.data, hoursBrefore: hours, isloading: false });
            }
        };
        this.handleResponseinvoice = (res) => {
            this.setState({ openModalInvoice: true, isloading: false });
        };
        this.handleResponseIssues = (res) => {
            this.setState({ issueArr: res });
        };
        this.handleResponseCancel = (res) => {
            this.props.history.push({
                pathname: '/BookingRescheduled',
                state: { cancel: true }
            });
        };
        this.handleResponseReview = (res) => {
            var _a;
            if ((res === null || res === void 0 ? void 0 : res.message) == 'account id already reviewed') {
                this.setState({ showSubmittedresp: true, alreadySubmitted: true });
            }
            else if (((_a = res === null || res === void 0 ? void 0 : res.message) === null || _a === void 0 ? void 0 : _a.account[0]) == 'must exist') {
                toast.error('Employee does not exist.', { position: toast.POSITION.BOTTOM_RIGHT });
            }
            else {
                this.setState({ showSubmittedresp: true, alreadySubmitted: false });
            }
        };
        this.moveToissueScreen = (screen) => {
            this.state.issueArr.forEach((element) => {
                var _a;
                if ((element === null || element === void 0 ? void 0 : element.name.trim()) == screen.trim()) {
                    let { clickFromProfile } = ((_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state) || "";
                    if (clickFromProfile) {
                        this.props.history.push({
                            pathname: '/NeedHelp',
                            state: { clickFromProfile: true, issue: screen, issueId: element === null || element === void 0 ? void 0 : element.id, appointmentId: this.state.appointmentdata.id }
                        });
                    }
                    else {
                        this.props.history.push({
                            pathname: '/NeedHelp',
                            state: { issue: screen, issueId: element === null || element === void 0 ? void 0 : element.id, appointmentId: this.state.appointmentdata.id }
                        });
                    }
                }
            });
        };
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            slectActiveBtn: true,
            shoInput: false,
            appointmentArr: [],
            isloading: false,
            appointmentdata: [],
            openModalInvoice: false,
            invoiceorCancel: '',
            issueArr: [],
            openModalReview: false,
            ratingvalue: '',
            requireRating: false,
            reviewInput: '',
            showSubmittedresp: false,
            alreadySubmitted: '',
            hoursBrefore: '',
            openFilter: false,
            filterRadioVal: '',
            allFiltermanage: {
                'all': true,
                'completed': false,
                'no_show': false,
                'cancelled': false,
                'refunded': false,
                'pending': false,
                'started': false,
                're_scheduled': false
            }
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            let user = localStorage.getItem("userType") || "";
            let token = localStorage.getItem("token") || "";
            setTimeout(() => {
                if (user != "customer" || !token) {
                    localStorage.clear();
                    window.location.replace("/LandingPage");
                }
                else {
                    this.callallApi(this.bookingScreen);
                }
            }, 1000);
        });
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                if (apiRequestCallId === this.getMyAppointmentApiCallId) {
                    this.handleResponseAppointment(responseJson);
                }
                if (apiRequestCallId === this.getOneAppointmentApiCallId) {
                    console.log("calling");
                    this.handleResponseOneAppointment(responseJson);
                }
                if (apiRequestCallId === this.invoiceApiCallId) {
                    this.handleResponseinvoice(responseJson);
                }
                if (apiRequestCallId === this.editAppointmentcreation) {
                    this.handleResponseCancel(responseJson);
                }
                if (apiRequestCallId === this.getallIssueApiCallId) {
                    this.handleResponseIssues(responseJson);
                }
                if (apiRequestCallId === this.createReviewApiId) {
                    this.handleResponseReview(responseJson);
                }
            }
            else {
                runEngine.debugLog("GOIT");
            }
        });
    }
}
