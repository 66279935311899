import React from "react";
import { Container, Box, Input, Button, InputLabel, Typography, InputAdornment, IconButton, } from "@material-ui/core";
// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End
import SavedCardsController, { configJSON, } from "./SavedCardsController";
export default class SavedCards extends SavedCardsController {
    constructor(props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        return (
        // Customizable Area Start
        React.createElement(ThemeProvider, { theme: theme },
            React.createElement(Container, { maxWidth: "sm" },
                React.createElement(Box, { sx: webStyle.mainWrapper },
                    React.createElement(Typography, { variant: "h6" }, configJSON.labelTitleText),
                    React.createElement(Typography, { variant: "subtitle1", component: "div" }, configJSON.labelBodyText),
                    React.createElement(Box, { sx: webStyle.inputStyle },
                        React.createElement(InputLabel, { id: "service-shop-name" },
                            "This is the reviced value:",
                            this.state.txtSavedValue,
                            " "),
                        React.createElement(Input, { "data-test-id": "txtInput", type: this.state.enableField ? "password" : "text", placeholder: configJSON.txtInputPlaceholder, fullWidth: true, disableUnderline: true, value: this.state.txtInputValue, onChange: (e) => this.setInputValue(e.target.value), endAdornment: React.createElement(InputAdornment, { position: "end" },
                                React.createElement(IconButton, { "aria-label": "toggle password visibility", onClick: this.setEnableField, edge: "end" }, this.state.enableField ? (React.createElement(Visibility, null)) : (React.createElement(VisibilityOff, null)))) })),
                    React.createElement(Box, { "data-test-id": "btnAddExample", onClick: () => this.doButtonPressed(), component: "button", sx: webStyle.buttonStyle },
                        React.createElement(Button, { color: "primary" }, configJSON.btnExampleTitle)))))
        // Customizable Area End
        );
    }
}
// Customizable Area Start
const webStyle = {
    mainWrapper: {
        display: "flex",
        fontFamily: "Roboto-Medium",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "30px",
        background: "#fff",
    },
    inputStyle: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
        width: "100%",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    buttonStyle: {
        width: "100%",
        height: "45px",
        marginTop: "40px",
        border: "none",
        backgroundColor: "rgb(98, 0, 238)",
    },
};
// Customizable Area End
