import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import "../../assets/Styles/walkinflow.css";
import { img7f284a623dad0db2fed9fbe2e3d55e6af5f0cd63, } from "../../../CustomisableUserProfiles/src/assets";
import { Button } from "@material-ui/core";
const EmployeeAssigne = (props) => {
    return (React.createElement(Dialog, { open: props.openDialog, maxWidth: "sm", fullWidth: true, PaperProps: {
            className: "booking-employee-assign-dialog"
        } },
        React.createElement(DialogTitle, { disableTypography: true, className: "booking-dialog-title" }),
        React.createElement("div", { style: { marginBottom: '10px', marginTop: '40px' } },
            React.createElement("div", { className: "assign-container" },
                React.createElement("div", null,
                    React.createElement("img", { src: img7f284a623dad0db2fed9fbe2e3d55e6af5f0cd63, alt: "Congrats!", width: 120, height: 120 })),
                React.createElement("div", { style: { marginTop: '22px' } },
                    React.createElement("span", { className: "assign-container-lable" }, ("Congrats!"))),
                React.createElement("div", { style: { marginTop: '12px' } },
                    React.createElement("span", { className: "assign-container-sublable" }, props.infoMsg)),
                React.createElement("div", { style: { marginTop: '8px' } },
                    React.createElement("span", { className: "assign-container-sublable" }, `${props.employeeName}`)),
                React.createElement("div", { style: { marginTop: '30px' } },
                    React.createElement(Button, { variant: "contained", color: "primary", fullWidth: true, className: "walkin-dialog-button-assign", style: { marginBottom: 0 }, onClick: props.showBackButton ? props.handleContinueButton : props.closeDialog }, ("Continue"))),
                props.showBackButton &&
                    React.createElement("div", { style: { marginTop: '15px' } },
                        React.createElement(Button, { variant: "contained", color: "primary", fullWidth: true, className: "walkin-dialog-buttons home-button", onClick: props.handleBackButton },
                            React.createElement("span", { className: "button-name" }, ("Home"))))))));
};
export default EmployeeAssigne;
