import React from "react";
import CustomerSubcategoryScreenController from "./CustomerSubcategoryScreenController.web";
import { left_arrow_services, right_arrow_services, search_icon_services, info_icon_services, } from "./assets";
import "../assets/style/BusinessSevices.web.scss";
import { withRouter } from "react-router-dom";
import { TextField, InputAdornment } from "@material-ui/core";
import moment from "moment";
import BookServicesModal from "./BookServicesModal.web";
import { withTranslation } from "react-i18next";
import Loader from "../../../components/src/Loader.web";
class BusinessServices extends CustomerSubcategoryScreenController {
    constructor() {
        super(...arguments);
        this.subCategoryServices = () => {
            let { t } = this.props;
            return this.state.shopSubResponse.attributes.categories.data &&
                this.state.shopSubResponse.attributes.categories.data.length !== 0 ? (this.state.shopSubResponse.attributes.categories.data.map((elm, ind) => {
                return (React.createElement("div", { className: "main-service-div", key: elm.id },
                    React.createElement("div", { className: "pill-main" },
                        React.createElement("p", null, elm.attributes.display_name)),
                    elm.attributes.business_sub_categories &&
                        elm.attributes.business_sub_categories.length !== 0 ? (elm.attributes.business_sub_categories.map((element, index) => {
                        return (React.createElement("div", { className: "service-item", key: element.id, onClick: () => {
                                this.props.history.push({
                                    pathname: "/customer/service/booking",
                                    state: {
                                        subServiceid: this.state.subServiceid,
                                        serviceId: element.id,
                                        shopSubResponse: this.state.shopSubResponse,
                                        name: [
                                            element === null || element === void 0 ? void 0 : element.display_name,
                                            element.discount_price
                                                ? element.discount_price
                                                : element === null || element === void 0 ? void 0 : element.service_cost,
                                            element === null || element === void 0 ? void 0 : element.currency,
                                        ],
                                        currentCategory: this.state.currentCategory,
                                        gender: this.state.gender,
                                        show_gender: this.state.show_gender,
                                        categoryId: this.state.categoryId,
                                        staffId: element.id,
                                    },
                                });
                            } },
                            React.createElement("div", { className: "first-service" },
                                React.createElement("p", null, element.display_name),
                                " \u00A0",
                                React.createElement("img", { src: info_icon_services, onClick: () => {
                                        this.setState({
                                            openBookNowModal: true,
                                            selectServiceForbooking: [element],
                                        });
                                    }, alt: "" }),
                                "\u00A0",
                                React.createElement("h6", null, element.discount_per
                                    ? element.discount_per + t("% OFF")
                                    : "")),
                            React.createElement("div", { className: "sec-service" },
                                React.createElement("p", null),
                                "\u00A0\u00A0",
                                React.createElement("h3", null, element.currency +
                                    " " +
                                    (element.discount_price
                                        ? element.discount_price
                                        : element.service_cost)),
                                "\u00A0\u00A0",
                                React.createElement("img", { src: right_arrow_services, alt: "" })),
                            React.createElement("div", { className: "discount-price" },
                                React.createElement("h6", null, element.discount_price
                                    ? element.currency + " " + element.service_cost
                                    : " ")),
                            React.createElement("p", { style: element.discount_price
                                    ? { marginTop: "-30px" }
                                    : { marginTop: -6 } }, moment.duration(element.duration).asMinutes() +
                                " " +
                                t("mins"))));
                    })) : (React.createElement("h3", { style: { padding: "40px" } }, t("No data available.")))));
            })) : (React.createElement("h3", { style: { padding: "40px" } }, t("No data available.")));
        };
    }
    render() {
        this.call_shope_one_times = "call_last";
        let { t } = this.props;
        return (React.createElement(React.Fragment, null,
            React.createElement(Loader, { loading: this.state.isLoading }),
            React.createElement("div", { className: "main-div" },
                React.createElement("div", { style: { maxWidth: "100%", backgroundColor: "#3d40c3" } },
                    React.createElement("div", { className: "services-header" },
                        React.createElement("img", { src: left_arrow_services, style: { filter: "brightness(0) invert(1)" }, alt: "", id: "service_left_arrow", onClick: () => {
                                this.props.history.push({
                                    pathname: "/CustomerSubcategoryDetail",
                                    state: {
                                        gender: this.state.gender,
                                        show_gender: this.state.show_gender,
                                        subCategory: this.state.currentCategory,
                                        id: this.state.categoryId,
                                        subId: this.state.shopSubResponse.id,
                                    },
                                });
                            } }),
                        React.createElement("h1", null, t("Select Services")),
                        React.createElement("div", null,
                            React.createElement("p", null, t(this.state.show_gender))))),
                React.createElement("div", { className: "input-div" },
                    React.createElement(TextField, { InputProps: {
                            startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                React.createElement("img", { src: search_icon_services, className: "input-icon", alt: "" }))),
                        }, id: "outlined-basic", className: "seache-sevice-input", variant: "outlined", placeholder: t("Search for Services") })),
                this.state.shopSubResponse.attributes
                    ? this.subCategoryServices()
                    : ""),
            React.createElement(BookServicesModal, { currentCategory: this.state.currentCategory, gender: this.state.gender, categoryId: this.state.categoryId, shopSubResponse: this.state.shopSubResponse, subServiceid: this.state.subServiceid, history: this.props.history, openBookNowModal: this.state.openBookNowModal, selectServiceForbooking: this.state.selectServiceForbooking, closeBookNowModal: this.closeBookNowModal })));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(BusinessServices));
