import firebase from 'firebase/app';
import 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyAsVvsnqcCH2nhpRgyQrpJZYqxRcHkq6xc',
  authDomain: 'sodium-pathway-355305.firebaseapp.com',
  projectId: 'sodium-pathway-355305',
  storageBucket: 'sodium-pathway-355305.appspot.com',
  messagingSenderId: '1009331739934',
  appId: '1:1009331739934:web:2e960d7ac7077f3320fd0f',
  measurementId: 'G-0BEV8TENDM'
};

firebase.initializeApp(firebaseConfig);
export let messaging = firebase.messaging();

export const onMessageListener = () =>
  new Promise(resolve => {
    messaging.onMessage(messaging, payload => {
      resolve(payload);
    });
  });
