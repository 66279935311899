var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Customizable Area Start
import React from "react";
import { withRouter } from "react-router-dom";
import { BlockComponent } from "framework/src/BlockComponent";
import { IconButton, Divider, OutlinedInput, Button, Dialog, } from "@material-ui/core";
import { backdrop, call_us } from "../../CustomisableUserProfiles/src/assets";
import { exit } from "../../dashboard/src/assets";
import moment from "moment";
import { appointmentOval, cancellatioNoteIcon, disabledNoShow, editblack, enabledNoShow, } from "./assets";
import "../assets/Styles/manage-bookings-business.web.css";
import ManageBookingTimer from "../../../components/src/ManageBookingTimer.web";
import { astar, dstar, quotes } from "../../appointmentmanagement/src/assets";
import { errorSymbol } from "../../social-media-account-registration/src/assets";
import { withTranslation } from "react-i18next";
//@ts-ignore
import Cookies from "js-cookie";
import { defultPng } from "../../Squeez_me_in/src/assets";
class ManageBookingsAppointmentDetails extends BlockComponent {
    //istanbul ignore next
    constructor(props) {
        super(props);
        //istanbul ignore next
        this.renderCancelModal = () => {
            //@ts-ignore
            let { t } = this.props;
            return (React.createElement(Dialog, { open: this.state.cancelModal },
                React.createElement("div", { className: "logout_modal" },
                    React.createElement("img", { alt: "exit-icon", className: "modal-icon", src: exit }),
                    React.createElement("div", { className: "logout_confirmation_text" }, t("Are you sure you want to cancel this booking ?")),
                    React.createElement(Button, { className: "logout-modal-no-button-style", variant: "outlined", color: "secondary", style: { width: "100%", marginTop: 1 }, onClick: this.handleCanceModalChange }, t("No")),
                    React.createElement(Button, { className: "logout-modal-yes-button-style", variant: "contained", color: "primary", style: { width: "100%", marginTop: 1 }, onClick: this.handleConfirmCancel }, t("Yes")))));
        };
        //istanbul ignore next
        this.renderReviewModal = () => {
            //@ts-ignore
            let { t } = this.props;
            return (React.createElement(Dialog, { open: this.state.isReviewModalOpen },
                React.createElement("div", { className: "customer-review-manage-booking-modal" },
                    React.createElement("div", { className: "customer-review-manage-booking-header", style: {
                            marginBottom: "6%",
                        } }, t("So what do you think?")),
                    !this.state.isInputOpen ? (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "customer-review-manage-booking-default-text-div", onClick: this.handleReviewInputOpen },
                            React.createElement("img", { src: quotes, alt: "quotes" }),
                            React.createElement("span", null, t("Tell people about your experience: the services, the environment, the staff, or anything, etc."))))) : (React.createElement(React.Fragment, null,
                        React.createElement(OutlinedInput, { type: "text", multiline: true, rows: 4, autoFocus: true, id: "accountOwnerFullName", className: "customer-review-manage-booking-input-style", inputProps: {
                                min: 0,
                                style: { marginLeft: 5, height: "100%" },
                            }, error: this.state.reviewTextError, value: this.props.reviewText || "", onChange: (e) => { var _a; return this.props.handleReviewTextChange((_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value); }, onBlur: this.validateReviewText }))),
                    this.state.reviewTextError && (React.createElement("span", { className: "manage-business-error-message-text" }, t("Please enter review."))),
                    React.createElement("div", { className: "customer-review-manage-booking-header", style: {
                            margin: "6% 0%",
                        } }, t("Ratings")),
                    React.createElement("div", { className: "rating-star-image-container" }, this.state.ratingStars.map((rating) => {
                        return (React.createElement("img", { src: rating <= this.props.givenRating ? astar : dstar, alt: "star", onClick: () => {
                                //istanbul ignore next
                                if (this.state.ratingError) {
                                    this.setState({ ratingError: false });
                                }
                                this.props.handleRatingStarChange(rating);
                            }, className: "rating-star-image", key: rating }));
                    })),
                    this.state.ratingError && (React.createElement("span", { className: "manage-business-error-message-text" }, t("Please give rating."))),
                    React.createElement("div", { className: "customer-review-manage-booking-button-container" },
                        React.createElement(Button, { className: "customer-review-manage-booking-cancel-button-style", variant: "outlined", color: "secondary", style: { width: "100%", marginTop: 1 }, onClick: this.handleReviewModal }, t("Cancel")),
                        React.createElement(Button, { className: "customer-review-manage-booking-submit-button-style", variant: "contained", color: "primary", style: { width: "100%", marginTop: 1 }, onClick: this.handleSubmitReview }, t("Submit"))))));
        };
        //istanbul ignore next
        this.renderStatus = (status) => {
            var _a;
            if (status === "pending") {
                return `Upcoming`;
            }
            else {
                return (((_a = status === null || status === void 0 ? void 0 : status.replace("_", "-").charAt(0)) === null || _a === void 0 ? void 0 : _a.toUpperCase()) + (status === null || status === void 0 ? void 0 : status.replace("_", "-").slice(1)));
            }
        };
        //istanbul ignore next
        this.handleStart = () => {
            if (this.props.otpCode.length < 3) {
                this.setState(Object.assign(Object.assign({}, this.state), { pinError: true }));
            }
            else {
                this.setState(Object.assign(Object.assign({}, this.state), { pinError: false }));
                this.props.onStart();
            }
        };
        //istanbul ignore next
        this.handleNoShowChange = () => {
            this.setState({ state: true }, () => this.props.onNoshow());
        };
        //istanbul ignore next
        this.handleCanceModalChange = () => {
            this.setState(Object.assign(Object.assign({}, this.state), { cancelModal: !this.state.cancelModal }));
        };
        //istanbul ignore next
        this.handleConfirmCancel = () => {
            this.props.onCancel();
            this.setState(Object.assign(Object.assign({}, this.state), { cancelModal: !this.state.cancelModal }));
        };
        //istanbul ignore next
        this.handleReviewInputOpen = () => {
            this.setState(Object.assign(Object.assign({}, this.state), { isInputOpen: true }));
        };
        //istanbul ignore next
        this.handleReviewModal = () => {
            //istanbul ignore next
            if (this.state.isReviewModalOpen) {
                setTimeout(() => {
                    this.props.handleRatingStarChange(0);
                    this.props.handleReviewTextChange("");
                }, 1000);
                this.setState(Object.assign(Object.assign({}, this.state), { isReviewModalOpen: false, isInputOpen: false }));
            }
            else {
                this.setState(Object.assign(Object.assign({}, this.state), { isReviewModalOpen: !this.state.isReviewModalOpen }));
            }
        };
        //istanbul ignore next
        this.validateReviewText = () => {
            //istanbul ignore next
            if (this.props.reviewText === "") {
                this.setState(Object.assign(Object.assign({}, this.state), { reviewTextError: true }));
            }
            else {
                this.setState(Object.assign(Object.assign({}, this.state), { reviewTextError: false, ratingError: false }));
                return true;
            }
        };
        //istanbul ignore next
        this.validateRatings = () => {
            var _a;
            if (((_a = this.props) === null || _a === void 0 ? void 0 : _a.givenRating) === 0) {
                this.setState(Object.assign(Object.assign({}, this.state), { ratingError: true }));
            }
            else {
                this.setState(Object.assign(Object.assign({}, this.state), { ratingError: false, reviewTextError: false }));
                return true;
            }
        };
        //istanbul ignore next
        this.handleSubmitReview = () => {
            if (this.validateReviewText() && this.validateRatings()) {
                this.props.onReview();
                this.setState(Object.assign(Object.assign({}, this.state), { isReviewModalOpen: false, isInputOpen: false }));
            }
        };
        this.state = {
            state: false,
            pinError: false,
            cancelModal: false,
            isReviewModalOpen: false,
            isInputOpen: false,
            reviewTextError: false,
            ratingError: false,
            ratingStars: [1, 2, 3, 4, 5],
        };
    }
    //istanbul ignore next
    componentDidMount() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            if (((_b = (_a = this.props.appointmentData) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.status) === "no_show") {
                this.setState({ state: true });
            }
            else {
                this.setState({ state: false });
            }
        });
    }
    //istanbul ignore next
    render() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43, _44, _45, _46, _47, _48, _49, _50, _51, _52, _53, _54, _55, _56, _57, _58, _59, _60, _61, _62, _63, _64, _65, _66, _67, _68, _69, _70, _71, _72, _73, _74, _75, _76, _77, _78, _79, _80, _81, _82, _83, _84, _85, _86, _87, _88, _89, _90, _91, _92, _93, _94, _95, _96, _97, _98, _99, _100, _101, _102, _103, _104, _105;
        //@ts-ignore
        const { appointmentData, t } = this.props;
        let appointmentTime = moment((_a = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _a === void 0 ? void 0 : _a.start_time).format("hh:mm A");
        let [time, amPm] = appointmentTime.split(" ");
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "manage-booking-business-appointment-details-header-container" },
                React.createElement("div", { className: "manage-booking-business-appointment-details-header-container", style: { width: "55%" } },
                    React.createElement(IconButton, { style: { width: "30px", height: "30px" }, id: "removeImage", onClick: this.props.handleBack },
                        React.createElement("img", { src: backdrop, alt: "logo", width: "30px", height: "30px" })),
                    React.createElement("span", { className: "manage-employee-header-text" }, `${t("Booking ID:")} ${(_b = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _b === void 0 ? void 0 : _b.booking_id}`)),
                React.createElement("span", { className: `manage-booking-business-appointment-details-status-text manage-booking-business-orange-color ${(((_c = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _c === void 0 ? void 0 : _c.status) === "cancelled" ||
                        ((_d = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _d === void 0 ? void 0 : _d.status) === "no_show") &&
                        "manage-booking-business-red-color"} ${((_e = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _e === void 0 ? void 0 : _e.status) === "refunded" &&
                        "manage-booking-business-purple-color"} ${((_f = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _f === void 0 ? void 0 : _f.status) === "refund_initiated" &&
                        "manage-booking-business-grey-color"} ${(((_g = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _g === void 0 ? void 0 : _g.status) === "started" ||
                        ((_h = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _h === void 0 ? void 0 : _h.status) === "completed") &&
                        "manage-booking-business-green-color"}` }, t(this.renderStatus((_j = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _j === void 0 ? void 0 : _j.status)))),
            React.createElement("div", { className: "business-manage-booking-details-main-container" },
                React.createElement("div", { className: "booking-Paper-left-containers" },
                    React.createElement("div", { className: webAppDirection === "ltr"
                            ? "booking-list-item"
                            : "arabic-booking-list-item", style: { alignItems: "center" } },
                        React.createElement("img", { src: ((_o = (_m = (_l = (_k = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _k === void 0 ? void 0 : _k.customer) === null || _l === void 0 ? void 0 : _l.data) === null || _m === void 0 ? void 0 : _m.attributes) === null || _o === void 0 ? void 0 : _o.profile_image) || defultPng, alt: "Avatar", className: "manage-booking-business-details-image-style" }),
                        React.createElement("div", { className: "user-info" },
                            ((_s = (_r = (_q = (_p = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _p === void 0 ? void 0 : _p.customer) === null || _q === void 0 ? void 0 : _q.data) === null || _r === void 0 ? void 0 : _r.attributes) === null || _s === void 0 ? void 0 : _s.full_name) ? (React.createElement("h3", null, `${(_w = (_v = (_u = (_t = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _t === void 0 ? void 0 : _t.customer) === null || _u === void 0 ? void 0 : _u.data) === null || _v === void 0 ? void 0 : _v.attributes) === null || _w === void 0 ? void 0 : _w.full_name}`)) : (React.createElement("h3", null, `${(_0 = (_z = (_y = (_x = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _x === void 0 ? void 0 : _x.customer) === null || _y === void 0 ? void 0 : _y.data) === null || _z === void 0 ? void 0 : _z.attributes) === null || _0 === void 0 ? void 0 : _0.first_name} ${(_4 = (_3 = (_2 = (_1 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _1 === void 0 ? void 0 : _1.customer) === null || _2 === void 0 ? void 0 : _2.data) === null || _3 === void 0 ? void 0 : _3.attributes) === null || _4 === void 0 ? void 0 : _4.last_name}`)),
                            React.createElement("p", null, (_8 = (_7 = (_6 = (_5 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _5 === void 0 ? void 0 : _5.customer) === null || _6 === void 0 ? void 0 : _6.data) === null || _7 === void 0 ? void 0 : _7.attributes) === null || _8 === void 0 ? void 0 : _8.email)),
                        React.createElement("div", { className: webAppDirection === "ltr"
                                ? "booking-arrow"
                                : "arabic-booking-arrow" },
                            React.createElement("a", { id: "contact", href: ((_12 = (_11 = (_10 = (_9 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _9 === void 0 ? void 0 : _9.customer) === null || _10 === void 0 ? void 0 : _10.data) === null || _11 === void 0 ? void 0 : _11.attributes) === null || _12 === void 0 ? void 0 : _12.phone_number) ? "tel:" + ((_16 = (_15 = (_14 = (_13 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _13 === void 0 ? void 0 : _13.customer) === null || _14 === void 0 ? void 0 : _14.data) === null || _15 === void 0 ? void 0 : _15.attributes) === null || _16 === void 0 ? void 0 : _16.phone_number)
                                    : "", className: "walkin-call_us-btn" },
                                " ",
                                React.createElement("img", { src: call_us }),
                                " \u00A0 ",
                                t("Contact")))),
                    React.createElement(Divider, { className: "custom-divider" }),
                    React.createElement("div", { style: {
                            margin: "5% 7%",
                        } },
                        React.createElement("div", { className: "manage-booking-business-element-conatiner" },
                            React.createElement("span", { className: "label booking-id" }, moment((_17 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _17 === void 0 ? void 0 : _17.start_time).format("dddd , DD MMMM ")),
                            React.createElement("span", { className: "manage-booking-business-appointment-type-container" },
                                React.createElement("img", { src: appointmentOval, alt: "appointmentOval" }),
                                t(((_18 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _18 === void 0 ? void 0 : _18.appointment_type.replace(/[. _]/g, "-").charAt(0).toUpperCase()) + ((_19 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _19 === void 0 ? void 0 : _19.appointment_type.replace(/[. _]/g, "-").slice(1))))),
                        React.createElement("span", { style: { color: "#757575" } }, `${time} ${t(amPm)}`)),
                    React.createElement(Divider, { className: "custom-divider" }),
                    React.createElement("div", { style: {
                            margin: "5% 7%",
                        } },
                        React.createElement("div", { className: "manage-booking-business-element-conatiner" },
                            React.createElement("span", { className: "label booking-container-sublable" }, t("Service")),
                            React.createElement("span", { className: `manage-booking-business-payment-method-details-text ${((_20 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _20 === void 0 ? void 0 : _20.paid_by) === "pay_at_shop"
                                    ? "manage-booking-business-orange-color"
                                    : "manage-booking-business-green-color"}` }, t(`${((_21 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _21 === void 0 ? void 0 : _21.paid_by.replace(/[. _-]/g, " ").charAt(0).toUpperCase()) + ((_22 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _22 === void 0 ? void 0 : _22.paid_by.replace(/[. _-]/g, " ").slice(1))}`))),
                        React.createElement("div", { className: "manage-booking-business-element-conatiner" },
                            React.createElement("span", null, (_24 = (_23 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _23 === void 0 ? void 0 : _23.service) === null || _24 === void 0 ? void 0 : _24.display_name),
                            React.createElement("span", null, `${(_26 = (_25 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _25 === void 0 ? void 0 : _25.service) === null || _26 === void 0 ? void 0 : _26.currency} ${(_28 = (_27 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _27 === void 0 ? void 0 : _27.service) === null || _28 === void 0 ? void 0 : _28.service_cost}`)),
                        React.createElement("div", { className: "manage-booking-business-element-conatiner" },
                            React.createElement("span", { className: "label Manage-booking-container-note" }, `${moment
                                .duration((_30 = (_29 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _29 === void 0 ? void 0 : _29.service) === null || _30 === void 0 ? void 0 : _30.duration)
                                .asMinutes()} ${t("min")}`)),
                        ((_31 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _31 === void 0 ? void 0 : _31.appointment_type) === "online" && (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: "manage-booking-business-element-conatiner" },
                                React.createElement("span", { className: "manage-bookings-business-service-tax-text" }, t("Service fee")),
                                React.createElement("span", { className: "manage-bookings-business-service-tax-text" }, `${(_33 = (_32 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _32 === void 0 ? void 0 : _32.service) === null || _33 === void 0 ? void 0 : _33.currency} ${(_34 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _34 === void 0 ? void 0 : _34.service_fee}`)),
                            React.createElement("div", { className: "manage-booking-business-element-conatiner" },
                                React.createElement("span", { className: "manage-bookings-business-service-tax-text" }, t("Taxes")),
                                React.createElement("span", { className: "manage-bookings-business-service-tax-text" }, `${(_36 = (_35 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _35 === void 0 ? void 0 : _35.service) === null || _36 === void 0 ? void 0 : _36.currency} ${(_37 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _37 === void 0 ? void 0 : _37.tax}`)),
                            React.createElement("div", { className: "manage-booking-business-element-conatiner" },
                                React.createElement("span", { className: "manage-bookings-business-total-price-text" }, t("Total Amount")),
                                React.createElement("span", { className: "manage-bookings-business-total-price-text" }, `${(_39 = (_38 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _38 === void 0 ? void 0 : _38.service) === null || _39 === void 0 ? void 0 : _39.currency} ${(_40 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _40 === void 0 ? void 0 : _40.total_price}`)))),
                        React.createElement("div", { className: "employee-card-container" },
                            React.createElement("div", { className: "booking-input-container", style: { width: "100%" } },
                                React.createElement("span", { className: "booking-dialog-label" }, t("Stylist")),
                                React.createElement(OutlinedInput, { type: "text", className: "booking-dialog-input-style unclickable", value: ((_42 = (_41 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _41 === void 0 ? void 0 : _41.employee_details) === null || _42 === void 0 ? void 0 : _42.data) ? `${(_46 = (_45 = (_44 = (_43 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _43 === void 0 ? void 0 : _43.employee_details) === null || _44 === void 0 ? void 0 : _44.data) === null || _45 === void 0 ? void 0 : _45.attributes) === null || _46 === void 0 ? void 0 : _46.first_name} ${(_50 = (_49 = (_48 = (_47 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _47 === void 0 ? void 0 : _47.employee_details) === null || _48 === void 0 ? void 0 : _48.data) === null || _49 === void 0 ? void 0 : _49.attributes) === null || _50 === void 0 ? void 0 : _50.last_name}`
                                        : t(`Employee is deleted by business.`), inputProps: { min: 0, style: { marginLeft: 5 } } })))),
                    React.createElement(Divider, { className: "custom-divider" }),
                    ((_51 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _51 === void 0 ? void 0 : _51.status) != "completed" && (React.createElement("div", { style: {
                            margin: "5% 7%",
                        } },
                        React.createElement("div", { className: "booking-input-container" },
                            React.createElement("span", { className: "booking-dialog-label" }, t("Booking code:")),
                            ((_52 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _52 === void 0 ? void 0 : _52.appointment_type) === "online" &&
                                ((_53 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _53 === void 0 ? void 0 : _53.status) != "pending" &&
                                ((_54 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _54 === void 0 ? void 0 : _54.status) != "scheduled" &&
                                ((_55 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _55 === void 0 ? void 0 : _55.status) != "re_scheduled" ? (React.createElement(OutlinedInput, { type: "text", className: "booking-dialog-input-style", placeholder: t("Enter 4 Digit Code"), disabled: ((_56 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _56 === void 0 ? void 0 : _56.appointment_type) !=
                                    "online", error: this.state.pinError || this.props.bookingCodeError, value: `${this.props.otpCode}`, readOnly: true, inputProps: {
                                    min: 0,
                                    maxLength: 4,
                                    style: { marginLeft: 5 },
                                } })) : (React.createElement(OutlinedInput, { type: "text", className: "booking-dialog-input-style", placeholder: t("Enter 4 Digit Code"), disabled: ((_57 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _57 === void 0 ? void 0 : _57.appointment_type) !=
                                    "online", error: this.state.pinError || this.props.bookingCodeError, value: `${this.props.otpCode}`, onChange: (e) => this.props.handleBookingCodeChange(e.target.value), inputProps: {
                                    min: 0,
                                    maxLength: 4,
                                    style: { marginLeft: 5 },
                                } })),
                            this.state.pinError && (React.createElement("span", { className: "manage-business-error-message-text" }, t("Please enter 4 digit pin"))),
                            this.props.bookingCodeError && (React.createElement("span", { className: "manage-business-error-message-text" }, t(this.props.bookingCodeErrorMessage)))))),
                    ((_58 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _58 === void 0 ? void 0 : _58.status) != "completed" && (React.createElement("div", { style: {
                            margin: "5% 7%",
                        } },
                        React.createElement("div", { className: "manage-booking-business-element-conatiner" },
                            React.createElement("span", { className: "label booking-container-sublable" }, t("Service timer:")),
                            ((_59 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _59 === void 0 ? void 0 : _59.remaining_time) != 0 &&
                                ((_60 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _60 === void 0 ? void 0 : _60.status) === "started" ? (React.createElement(ManageBookingTimer, { initialTime: (_61 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _61 === void 0 ? void 0 : _61.remaining_time, storeTime: this.props.storeTime, getTime: this.props.getTime, startParams: this.props.startParams, restart: this.props.restart })) : (React.createElement("span", { style: { direction: "ltr" } }, (_64 = (_63 = (_62 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _62 === void 0 ? void 0 : _62.service) === null || _63 === void 0 ? void 0 : _63.duration) === null || _64 === void 0 ? void 0 : _64.slice(3)))),
                        ((_65 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _65 === void 0 ? void 0 : _65.status) != "completed" &&
                            ((_66 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _66 === void 0 ? void 0 : _66.status) != "cancelled" &&
                            ((_67 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _67 === void 0 ? void 0 : _67.status) != "refunded" &&
                            ((_68 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _68 === void 0 ? void 0 : _68.status) != "refund_initiated" &&
                            ((_69 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _69 === void 0 ? void 0 : _69.appointment_type) ===
                                "online" && (React.createElement("div", { className: "manage-booking-business-element-conatiner" },
                            React.createElement("span", null, t("No-Show ?")),
                            React.createElement("img", { src: this.state.state ? enabledNoShow : disabledNoShow, onClick: () => !this.state.state &&
                                    this.props.enableNoShowButton &&
                                    this.handleNoShowChange(), alt: "icon", className: `${this.props.enableNoShowButton
                                    ? "no-show-icon-image-web"
                                    : "no-show-icon-image-web-disabled"}` }))))),
                    React.createElement("div", { style: {
                            margin: "5% 7%",
                        } },
                        React.createElement("div", { className: "booking-input-container" },
                            React.createElement("span", { className: "booking-dialog-label" }, t("Notes")),
                            React.createElement(OutlinedInput, { type: "text", multiline: true, rows: 4, style: {
                                    height: "150px",
                                }, readOnly: true, id: "accountOwnerFullName", placeholder: t("Note for staff"), className: "booking-dialog-input-style unclickable", value: ((_70 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _70 === void 0 ? void 0 : _70.notes) || "", inputProps: {
                                    min: 0,
                                    style: { marginLeft: 5, height: "100%" },
                                } }))),
                    ((_71 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _71 === void 0 ? void 0 : _71.appointment_type) === "online" &&
                        ((_72 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _72 === void 0 ? void 0 : _72.status) != "refunded" &&
                        ((_73 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _73 === void 0 ? void 0 : _73.status) != "refund_initiated" &&
                        ((_74 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _74 === void 0 ? void 0 : _74.status) != "cancelled" &&
                        ((_75 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _75 === void 0 ? void 0 : _75.status) != "completed" && (React.createElement("div", { style: {
                            margin: "5% 6%",
                        } },
                        React.createElement("div", { className: "manage-booking-cancellation-container" },
                            React.createElement("img", { src: cancellatioNoteIcon, alt: "cancellatioNoteIcon" }),
                            React.createElement("span", { className: "manage-booking-cancellation-message" }, `${t("Kindly inform the customer before cancelling or rescheduling the appointment.")} ${(_77 = (_76 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _76 === void 0 ? void 0 : _76.reschedule_cantellation_time) === null || _77 === void 0 ? void 0 : _77.message}`)))),
                    ((_78 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _78 === void 0 ? void 0 : _78.appointment_type) === "online" &&
                        !((_79 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _79 === void 0 ? void 0 : _79.business_reschedule_option) &&
                        ((_80 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _80 === void 0 ? void 0 : _80.business_rescheduled_count) > 10 && (React.createElement("div", { style: {
                            margin: "1% 6%",
                        } },
                        React.createElement("div", { className: "manage-booking-cancellation-container" },
                            React.createElement("img", { src: errorSymbol, alt: "errorSymbol" }),
                            React.createElement("span", { className: "manage-booking-cancellation-message" }, t(`Contact SqueezMeIn to make further bookings.`))))),
                    ((_81 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _81 === void 0 ? void 0 : _81.status) != "refunded" &&
                        ((_82 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _82 === void 0 ? void 0 : _82.status) != "refund_initiated" &&
                        ((_83 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _83 === void 0 ? void 0 : _83.status) != "cancelled" &&
                        ((_84 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _84 === void 0 ? void 0 : _84.status) != "completed" && (React.createElement("div", { className: "booking-input-container", style: {
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        } },
                        React.createElement("span", { className: "booking-dialog-label" }, `${t("Total:")} ${(_86 = (_85 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _85 === void 0 ? void 0 : _85.service) === null || _86 === void 0 ? void 0 : _86.currency} ${(_87 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _87 === void 0 ? void 0 : _87.total_price} ( ${moment
                            .duration((_89 = (_88 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _88 === void 0 ? void 0 : _88.service) === null || _89 === void 0 ? void 0 : _89.duration)
                            .asMinutes()} ${t("min")} )`))),
                    ((_90 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _90 === void 0 ? void 0 : _90.status) != "cancelled" &&
                        ((_91 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _91 === void 0 ? void 0 : _91.status) != "refunded" &&
                        ((_92 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _92 === void 0 ? void 0 : _92.status) != "refund_initiated" && (React.createElement("div", { className: "manage-bookings-business-details-buttons-container", style: {
                            marginTop: "40px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                        } },
                        ((_93 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _93 === void 0 ? void 0 : _93.status) != "started" &&
                            ((_94 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _94 === void 0 ? void 0 : _94.status) != "completed" &&
                            ((_95 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _95 === void 0 ? void 0 : _95.status) != "cancelled" && (React.createElement(Button, { variant: "contained", color: "primary", className: `manage-booking-start-button  ${!this.props.enableStartButton &&
                                "manage-booking-button-disabled"}`, disabled: !this.props.enableStartButton, onClick: this.handleStart }, t("Start"))),
                        ((_96 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _96 === void 0 ? void 0 : _96.status) != "started" &&
                            ((_97 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _97 === void 0 ? void 0 : _97.status) != "completed" &&
                            ((_98 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _98 === void 0 ? void 0 : _98.status) != "cancelled" &&
                            localStorage.getItem("userType") != "employee" &&
                            ((_99 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _99 === void 0 ? void 0 : _99.appointment_type) ===
                                "online" && (React.createElement(Button, { variant: "contained", color: "primary", className: `manage-booking-schedule-button ${!this.props.enableResechduleButton &&
                                "manage-booking-button-disabled"}`, disabled: !this.props.enableResechduleButton, onClick: this.props.onResechdule }, t("Reschedule"))),
                        ((_100 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _100 === void 0 ? void 0 : _100.status) != "started" &&
                            ((_101 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _101 === void 0 ? void 0 : _101.status) != "completed" &&
                            ((_102 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _102 === void 0 ? void 0 : _102.status) != "cancelled" &&
                            localStorage.getItem("userType") != "employee" &&
                            ((_103 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _103 === void 0 ? void 0 : _103.appointment_type) ===
                                "online" && (React.createElement(Button, { variant: "contained", color: "primary", className: `manage-booking-cancel-stop-button ${!this.props.enableCancelButton &&
                                "manage-booking-button-disabled"}`, disabled: !this.props.enableCancelButton, onClick: this.handleCanceModalChange }, t("Cancel"))),
                        ((_104 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _104 === void 0 ? void 0 : _104.status) === "started" && (React.createElement(Button, { variant: "contained", color: "primary", className: "manage-booking-cancel-stop-button", onClick: this.props.onStop }, t("Stop"))),
                        ((_105 = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _105 === void 0 ? void 0 : _105.status) === "completed" && (React.createElement(Button, { variant: "contained", color: "primary", className: "manage-booking-review-button", onClick: this.handleReviewModal },
                            React.createElement("img", { src: editblack, alt: "edit" }),
                            t("Review Customer"))))))),
            this.renderCancelModal(),
            this.renderReviewModal()));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(ManageBookingsAppointmentDetails));
// Customizable Area End
