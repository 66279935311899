var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Customizable Area Start
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import "../assets/Styles/walkin-business.web.css";
import { BlockComponent } from "framework/src/BlockComponent";
import ReactCodeInput from "react-code-input";
import { withTranslation } from "react-i18next";
class VerifyClientBusiness extends BlockComponent {
    constructor(props) {
        super(props);
        this.handleResend = () => {
            this.props.onResend();
            clearInterval(this.timer);
            setTimeout(() => {
                this.componentDidMount();
            }, 1000);
        };
        this.state = {
            timer: 120,
            time: {
                minutes: 0,
                seconds: 0,
            },
        };
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
    }
    secondsToTime(secs) {
        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);
        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);
        let obj = {
            minutes: minutes < 10 ? "0" + minutes : minutes,
            seconds: seconds < 10 ? "0" + seconds : seconds,
        };
        return obj;
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            let timeLeftVar = this.secondsToTime(120);
            this.setState({ time: timeLeftVar, timer: 120 }, () => this.startTimer());
        });
    }
    startTimer() {
        clearInterval(this.timer);
        this.timer = 0;
        if (this.timer == 0 && this.state.timer > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }
    countDown() {
        let seconds = this.state.timer - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            timer: seconds,
        });
        if (seconds == 0) {
            this.props.handleEnableResend();
            clearInterval(this.timer);
        }
    }
    render() {
        const otpProps = {
            inputStyle: {
                fontFamily: "Poppins",
                margin: "16px",
                MozAppearance: "textfield",
                width: "60px",
                height: "60px",
                fontSize: "18px",
                textAlign: "center",
                borderRadius: "8px",
                color: this.props.otpError ? "#FF453A" : "black",
                border: this.props.otpError ? "1px solid #FF453A" : "1px solid #757575",
            },
        };
        const { time } = this.state;
        //@ts-ignore
        let { t } = this.props;
        return (React.createElement(Dialog, { open: this.props.openDialog, maxWidth: "sm", fullWidth: true, disableAutoFocus: true, PaperProps: {
                className: "otp-dialog-container",
            } },
            React.createElement(DialogTitle, { disableTypography: true, className: "walkin-dialog-title" },
                React.createElement(CloseIcon, { className: "walkin-dialog-close-icon", onClick: this.props.onClose }),
                React.createElement("div", { className: "walkin-form-title" }, t("Verify Client"))),
            React.createElement(DialogContent, { className: "walkin-dialog-content" },
                React.createElement("div", { style: { flexDirection: "column" } },
                    React.createElement("div", { style: {
                            flexDirection: "column",
                            display: "flex",
                            marginBottom: "20px",
                            marginLeft: "20px",
                        } }, this.props.state.verifyEmail ? (React.createElement(React.Fragment, null,
                        React.createElement("span", { className: "walkin-form subtitle" }, t("The 4-digit code has been sent to your email")),
                        React.createElement("span", { className: "walkin-form subtitle" },
                            t(`at`),
                            t(` ${this.props.state.email}`)))) : (React.createElement(React.Fragment, null,
                        React.createElement("span", { className: "walkin-form subtitle" }, t("The 4-digit code has been sent to your number")),
                        React.createElement("span", { className: "walkin-form subtitle" },
                            t(`at`),
                            t(` ${this.props.countryCode}-${this.props.clientMobileNumber}`))))),
                    React.createElement("div", { className: "otp-input-container" },
                        React.createElement(ReactCodeInput, Object.assign({}, otpProps, { value: this.props.otp, disabled: this.timer === 0 && this.props.otpExpiredError, onChange: (value) => this.props.handleOtpChange(value), inputMode: "numeric", type: "number", name: "otp", fields: 4 }))),
                    React.createElement("div", { className: "walkin-otp-input-container" },
                        this.props.otpError && (React.createElement("span", { className: "profile-error-message-text" }, t("OTP is incorrect."))),
                        this.props.otpExpiredError && (React.createElement("span", { className: "profile-error-message-text" }, t("The OTP has expired")))),
                    React.createElement("div", { style: {
                            marginTop: "30px",
                            marginLeft: "20px",
                            marginBottom: "20px",
                        } },
                        React.createElement("div", { className: "resend-otp-timer-container" },
                            this.props.isResendButtonEnabled ? (React.createElement("span", { className: "resend-otp-text-enabled", style: { marginTop: 0, marginBottom: 20 }, id: "resendlink", onClick: this.handleResend }, t("Resend 4-digit Code"))) : (React.createElement("span", { className: "resend-otp-text", style: { marginTop: 0, marginBottom: 20 } }, t("Resend 4-digit Code"))),
                            React.createElement("span", { className: "resend-otp-text", style: { marginTop: 0, marginBottom: 20, marginRight: 20, direction: "ltr" } }, `${time.minutes} : ${time.seconds}`))))),
            React.createElement(DialogActions, { className: "walkin-dialog-actions" },
                React.createElement(Button, { variant: "contained", color: "primary", fullWidth: true, className: "walkin-dialog-button", onClick: this.props.verifyOtp }, ("Verify")))));
    }
}
//@ts-ignore
export default withTranslation()(VerifyClientBusiness);
// Customizable Area End
