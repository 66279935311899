var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { AsyncStorage } from "react-native";
import resetNav from "../../../components/src/reset";
export default class BusinessManageController extends BlockComponent {
    constructor(props) {
        super(props);
        this.getSubscriptionSuccessCallback = (responseJson) => {
            if ((responseJson === null || responseJson === void 0 ? void 0 : responseJson.is_subscription_on) === true) {
                this.setState({ checkSubscription: true, isLoader: false });
            }
            else {
                this.setState({ checkSubscription: false, isLoader: false });
                const filteredData = this.state.businessManageData.filter(item => {
                    const title = item.title.toLowerCase();
                    return title !== "view analytics" && title !== "performance tracker" && title !== "offer & discount";
                });
                this.setState({ businessManageData: filteredData
                });
            }
        };
        this.getSubscriptionFailureCallback = (responseJson) => {
            this.setState({ isLoader: false });
            if (responseJson === null || responseJson === void 0 ? void 0 : responseJson.errors[0].token) {
                this.setState({ isLoader: false });
                resetNav(this.props.navigation);
            }
        };
        this.VerifySubscriptionAPICall = () => __awaiter(this, void 0, void 0, function* () {
            this.setState({ isLoader: true });
            const header = {
                "Content-Type": "application/json",
                token: yield AsyncStorage.getItem("token"),
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.apiVerifySubscriptionCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), 'account_block/check_subsciption');
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), 'GET');
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
        ];
        this.state = {
            checkSubscription: false,
            isLoader: false,
            businessManageData: [
                {
                    uniqueid: 1,
                    title: "Manage bookings",
                    subTitle: "List of all the bookings",
                    navigate: "ManageBooking",
                },
                {
                    uniqueid: 2,
                    title: "Manage team",
                    subTitle: "Add, edit and delete your team member",
                    navigate: "ManageTeam",
                },
                {
                    uniqueid: 3,
                    title: "Manage catalogue",
                    subTitle: "List of all the services",
                    navigate: "ManageCatalogue",
                },
                {
                    uniqueid: 4,
                    title: "Manage working days & time",
                    subTitle: "Manage working hours of your team",
                    navigate: "ManageWorkingHours",
                },
                {
                    uniqueid: 5,
                    title: "Reviews",
                    subTitle: "View feedbacks submitted by clients",
                    navigate: "ClientFeedback",
                },
                {
                    uniqueid: 6,
                    title: "View analytics",
                    subTitle: "See your performance",
                    navigate: "ViewAnalytics",
                },
                {
                    uniqueid: 7,
                    title: "Offer & discount",
                    subTitle: "Manage offer and discount",
                    navigate: "Discount_Offer",
                },
                {
                    title: "Performance tracker",
                    subTitle: "See employee performance",
                    navigate: "PerformanceTracker",
                },
            ]
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            // Customizable Area Start
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                if (responseJson && !responseJson.errors) {
                    if (apiRequestCallId === this.apiVerifySubscriptionCallId) {
                        this.getSubscriptionSuccessCallback(responseJson);
                    }
                }
                else if (responseJson && responseJson.errors) {
                    if (apiRequestCallId === this.apiVerifySubscriptionCallId) {
                        this.getSubscriptionFailureCallback(responseJson);
                    }
                }
                // Customizable Area End
            }
        });
    }
}
