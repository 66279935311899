export const employeeAvtar = require("../assets/avtar.png");
export const next = require("../assets/next.png");
export const call = require("../assets/call.png");
export const search = require("../assets/search.png");
export const plus = require("../assets/plus.png");
export const defaultProfile = require("../assets/user.png");
export const success = require("../assets/success.png");
export const edit = require("../assets/edit.png");
export const astar = require("../assets/astar.png");
export const dstar = require("../assets/dstar.png");
export const quotes = require("../assets/quote.png");
export const alert = require("../assets/alert.png");
