var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
export default class PaymentMethodsInBusinessWebController extends BlockComponent {
    constructor(props) {
        super(props);
        this.startLoading = () => {
            this.setState({ loading: true });
        };
        this.stopLoading = () => {
            this.setState({ loading: false });
        };
        this.handleReceive = (apiRequestCallId, responseJson, errorJson) => {
            //istanbul ignore next
            if (apiRequestCallId === this.getSavedCardsListCallId) {
                this.handleReceiveCardsResponse(responseJson, errorJson);
            }
        };
        this.handleReceiveCardsResponse = (responseJson, errorJson) => {
            var _a;
            if (responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) {
                this.setState(Object.assign(Object.assign({}, this.state), { savedCardsList: responseJson.data }));
            }
            else if (((_a = responseJson === null || responseJson === void 0 ? void 0 : responseJson.errors[0]) === null || _a === void 0 ? void 0 : _a.message) === "Payment method not found") {
                this.setState(Object.assign(Object.assign({}, this.state), { savedCardsList: [] }));
            }
            else {
                //istanbul ignore next
                console.log(errorJson);
            }
        };
        this.getSavedCardsListApi = () => {
            this.startLoading();
            const header = {
                "Content-Type": "application/json",
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getSavedCardsListCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_payments/thawanis");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            loading: false,
            savedCardsList: [],
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    componentDidMount() {
        const _super = Object.create(null, {
            componentDidMount: { get: () => super.componentDidMount }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.componentDidMount.call(this);
            let isUserLoggedIn = localStorage.getItem("token") || "";
            if (isUserLoggedIn && isUserLoggedIn.length > 0) {
                //istanbul ignore next
                this.getSavedCardsListApi();
            }
        });
    }
    //istanbul ignore next
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            this.stopLoading();
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
                if (apiRequestCallId === this.getSavedCardsListCallId) {
                    this.stopLoading();
                    this.handleReceive(apiRequestCallId, responseJson, errorReponse);
                }
            }
            else {
                runEngine.debugLog("GOIT");
            }
        });
    }
}
// Customizable Area End
