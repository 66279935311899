import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { OutlinedInput, InputAdornment, IconButton } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import "../../assets/Styles/walkinflow.css";
import { useTranslation } from "react-i18next";
const Cookies = require("js-cookie");
const SearchClient = (props) => {
    var _a;
    //@ts-ignore
    let { t } = useTranslation();
    let webAppDirection = localStorage.getItem("webAppDirection") ||
        Cookies.get("webAppDirection") ||
        "ltr";
    return (React.createElement(Dialog, { open: props.openDialog, maxWidth: "sm", fullWidth: true, PaperProps: {
            className: "walkin-dialog-container"
        } },
        React.createElement(DialogTitle, { disableTypography: true, className: "walkin-dialog-title" },
            React.createElement(CloseIcon, { className: "walkin-dialog-close-icon", onClick: props.onClose })),
        React.createElement(DialogContent, { className: "walkin-dialog-content" },
            React.createElement("div", { style: { flexDirection: "column" } },
                React.createElement("div", { className: "walkin-form title" }, t("Walk-in booking")),
                React.createElement("div", { style: { flexDirection: "column", display: 'flex' } },
                    React.createElement("span", { className: "walkin-form subtitle" }, t("Search client")),
                    React.createElement(OutlinedInput, { type: "number", placeholder: (_a = t("Enter clients number")) !== null && _a !== void 0 ? _a : "", className: `walkin-form-input-style ${props.userExists ? "unclickable" : ""}`, name: "phoneNumber", value: props.clientNumber, onChange: props.handleChange, startAdornment: React.createElement(InputAdornment, { position: "start" },
                            React.createElement(IconButton, { style: {
                                    width: 66,
                                    height: 45,
                                    cursor: "pointer",
                                    justifyContent: "center",
                                    borderRight: webAppDirection === "ltr" ? "1px solid #979797" : "",
                                    borderLeft: webAppDirection === "rtl" ? "1px solid #979797" : "",
                                    borderRadius: "0%",
                                }, onClick: props.openCountryCodeModal },
                                React.createElement("span", { className: "walkin-country-code-text-style", style: {
                                        color: "#2c2c2e"
                                    } }, props.countryCode))) }),
                    React.createElement("span", { className: "walkin-suceess-message-text" }, t(props.renderErrorMessageforProfile(props.userExistsMsg))),
                    React.createElement("span", { className: "profile-error-message-text" }, t(props.renderErrorMessageforProfile(props.responseMsg)))))),
        React.createElement(DialogActions, { className: "walkin-dialog-actions" },
            React.createElement(Button, { variant: "contained", color: "primary", fullWidth: true, className: "walkin-dialog-button", onClick: props.onClick }, props.userExists ? t("Continue & Verify") : t("Continue")))));
};
export default SearchClient;
