// Customizable Area Start
import React from "react";
import MyBookingController from "./MyBookingController.web";
import { Button, CircularProgress, Modal } from "@material-ui/core";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { checkRightIcon, uncheckCircle } from "./assets";
import "../../../blocks/Squeez_me_in/assets/style/ProfileDrawer.web.scss";
import "../../../blocks/Squeez_me_in/assets/style/MyBookings.web.scss";
import "../../../blocks/Squeez_me_in/assets/style/BusinessSevices.web.scss";
import { withRouter } from "react-router-dom";
import { filterBookings, close_modal_icon, defultPng } from "../../../blocks/Squeez_me_in/src/assets";
import moment from "moment";
export const configJSON = require("./config");
import { withTranslation } from "react-i18next";
import Loader from "../../../components/src/Loader.web";
const Cookies = require("js-cookie");
class ProfileMybooking extends MyBookingController {
    constructor() {
        super(...arguments);
        this.getcolor = (status) => {
            switch (status) {
                case 'pending':
                    return '#ff9f0a';
                case 'scheduled':
                    return '#ff9f0a';
                case 'started':
                    return '#34C759';
                case 're_scheduled':
                    return '#ff9f0a';
                case 'completed':
                    return '#34C759';
                case 'created':
                    return '#02AFCE';
                case 'refund_initiated':
                    return '#777777';
                case 'no_show':
                    return '#de350b';
                case 'refunded':
                    return '#8D35FF';
                case 'cancelled':
                    return '#de350b';
                default:
                    break;
            }
        };
        this.getStatus = (status) => {
            switch (status) {
                case 'completed':
                    return 'Completed';
                case 'scheduled':
                    return 'Upcoming';
                case 'started':
                    return 'Started';
                case 'pending':
                    return 'Upcoming';
                case 're_scheduled':
                    return 'Re-scheduled';
                case 'refund_initiated':
                    return 'Refund-initiated';
                case 'no_show':
                    return 'No-show';
                case 'created':
                    return 'Created';
                case 'refunded':
                    return 'Refunded';
                case 'cancelled':
                    return 'Cancelled';
                default:
                    break;
            }
        };
        this.getFirstLoading = () => {
            let { t } = this.props;
            return this.state.isloading ? React.createElement(Loader, { loading: this.state.isloading }) : React.createElement("h1", { style: { fontSize: '18px', textAlign: 'center', margin: 50 } }, t("No data available."));
        };
        //istanbul ignore next
        this.getFirstPart = () => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
            let { t } = this.props;
            let webAppDirection = Cookies.get("webAppDirection") ||
                localStorage.getItem("webAppDirection") ||
                "ltr";
            return ((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.appointmentArr) === null || _b === void 0 ? void 0 : _b.length) && ((_f = (_e = (_d = (_c = this.state) === null || _c === void 0 ? void 0 : _c.appointmentArr[0]) === null || _d === void 0 ? void 0 : _d.pending_appointments) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.length) != 0 ? (_k = (_j = (_h = (_g = this.state) === null || _g === void 0 ? void 0 : _g.appointmentArr[0]) === null || _h === void 0 ? void 0 : _h.pending_appointments) === null || _j === void 0 ? void 0 : _j.data) === null || _k === void 0 ? void 0 : _k.map((element, index) => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0;
                return (React.createElement("div", { className: "listitem-div", key: element === null || element === void 0 ? void 0 : element.id },
                    React.createElement("div", { className: "sub-item", style: { cursor: 'pointer' }, id: 'upcoming-btn', onClick: () => {
                            this.props.history.push({
                                pathname: '/MyBookings/Detail',
                                state: { appointmentId: element === null || element === void 0 ? void 0 : element.id, upcoming: this.state.slectActiveBtn, clickFromProfile: true }
                            });
                        } },
                        React.createElement("p", null,
                            t("Booking ID"),
                            ": ", (_a = element === null || element === void 0 ? void 0 : element.attributes) === null || _a === void 0 ? void 0 :
                            _a.booking_id),
                        React.createElement("p", { style: { marginLeft: webAppDirection === "ltr" ? 'auto' : 0, marginRight: webAppDirection === "rtl" ? 'auto' : 0 } },
                            t("Booking code:"),
                            " ", (_c = (_b = element === null || element === void 0 ? void 0 : element.attributes) === null || _b === void 0 ? void 0 : _b.booking_code) === null || _c === void 0 ? void 0 :
                            _c.pin)),
                    React.createElement("div", { className: "item-detail-div" },
                        React.createElement("div", { className: "profile-detail", style: { cursor: 'pointer' }, id: 'profile-detail-div', onClick: () => {
                                this.props.history.push({
                                    state: { appointmentId: element === null || element === void 0 ? void 0 : element.id, clickFromProfile: true, upcoming: this.state.slectActiveBtn },
                                    pathname: '/MyBookings/Detail'
                                });
                            } },
                            React.createElement("img", { src: ((_g = (_f = (_e = (_d = element === null || element === void 0 ? void 0 : element.attributes) === null || _d === void 0 ? void 0 : _d.employee_details) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.attributes) === null || _g === void 0 ? void 0 : _g.team_member_profile) || defultPng }),
                            React.createElement("div", { className: "profile-detail-sub", id: "xyz" },
                                React.createElement("div", { className: "title-names" },
                                    React.createElement("h3", { id: "hTag" }, ((_l = (_k = (_j = (_h = element === null || element === void 0 ? void 0 : element.attributes) === null || _h === void 0 ? void 0 : _h.employee_details) === null || _j === void 0 ? void 0 : _j.data) === null || _k === void 0 ? void 0 : _k.attributes) === null || _l === void 0 ? void 0 : _l.first_name) + ' ' + ((_q = (_p = (_o = (_m = element === null || element === void 0 ? void 0 : element.attributes) === null || _m === void 0 ? void 0 : _m.employee_details) === null || _o === void 0 ? void 0 : _o.data) === null || _p === void 0 ? void 0 : _p.attributes) === null || _q === void 0 ? void 0 : _q.last_name))),
                                React.createElement("h4", { id: "zzz" }, moment((_r = element === null || element === void 0 ? void 0 : element.attributes) === null || _r === void 0 ? void 0 : _r.start_time).format('hh:mm A') + ' on ' + moment((_s = element === null || element === void 0 ? void 0 : element.attributes) === null || _s === void 0 ? void 0 : _s.start_time).format('dddd').substring(0, 3) + ', ' + moment((_t = element === null || element === void 0 ? void 0 : element.attributes) === null || _t === void 0 ? void 0 : _t.booking_date).format("DD MMM YYYY"))),
                            React.createElement("h2", { style: { marginLeft: webAppDirection === "ltr" ? 'auto' : 0, marginRight: webAppDirection === "rtl" ? 'auto' : 0 } },
                                t("Bill"),
                                ": OMR ", (_u = element === null || element === void 0 ? void 0 : element.attributes) === null || _u === void 0 ? void 0 :
                                _u.total_price)),
                        React.createElement("hr", { style: { marginLeft: webAppDirection === "ltr" ? "-30px" : "0px", marginRight: webAppDirection === "rtl" ? "-30px" : "0px" } }),
                        React.createElement("div", { className: "service-div", style: { cursor: 'pointer' }, id: 'service-div', onClick: () => {
                                this.props.history.push({
                                    pathname: '/MyBookings/Detail',
                                    state: { appointmentId: element === null || element === void 0 ? void 0 : element.id, clickFromProfile: true, upcoming: this.state.slectActiveBtn }
                                });
                            } },
                            React.createElement("p", null,
                                t("Service"),
                                ": ", (_w = (_v = element === null || element === void 0 ? void 0 : element.attributes) === null || _v === void 0 ? void 0 : _v.service) === null || _w === void 0 ? void 0 :
                                _w.display_name),
                            React.createElement("h6", { style: { color: this.getcolor((_x = element === null || element === void 0 ? void 0 : element.attributes) === null || _x === void 0 ? void 0 : _x.status), marginLeft: webAppDirection === "ltr" ? 'auto' : 0, marginRight: webAppDirection === "rtl" ? 'auto' : 0 } }, t(this.getStatus((_y = element === null || element === void 0 ? void 0 : element.attributes) === null || _y === void 0 ? void 0 : _y.status)))),
                        React.createElement("hr", { style: { marginLeft: webAppDirection === "ltr" ? "-30px" : "0px", marginRight: webAppDirection === "rtl" ? "-30px" : "0px" } }),
                        React.createElement("div", { className: "Need-div" },
                            React.createElement("p", { onClick: () => {
                                    this.props.history.push({
                                        pathname: '/NeedHelp',
                                        state: { mainBooking: false, clickFromProfile: true, appointmentId: element === null || element === void 0 ? void 0 : element.id }
                                    });
                                } }, t("Need help?")),
                            React.createElement("h6", { id: 'service-div-h6', onClick: () => {
                                    this.props.history.push({
                                        pathname: '/MyBookings/Detail',
                                        state: { appointmentId: element === null || element === void 0 ? void 0 : element.id, clickFromProfile: true, upcoming: this.state.slectActiveBtn, }
                                    });
                                }, style: { cursor: 'pointer', marginLeft: webAppDirection === "ltr" ? 'auto' : 0, marginRight: webAppDirection === "rtl" ? 'auto' : 0, color: ((_z = element === null || element === void 0 ? void 0 : element.attributes) === null || _z === void 0 ? void 0 : _z.paid_by) === 'pay_at_shop' ? '#ff9f0a' : '#34c759' } }, ((_0 = element === null || element === void 0 ? void 0 : element.attributes) === null || _0 === void 0 ? void 0 : _0.paid_by) === 'pay_at_shop' ? t('Pay at shop') : t('Pay by card'))))));
            }) : (this.getFirstLoading());
        };
        this.getSecLoading = () => {
            return this.state.isloading ? React.createElement("div", { style: { textAlign: 'center', margin: 50 } },
                React.createElement(CircularProgress, null)) : React.createElement("h1", { style: { fontSize: '18px', textAlign: 'center', margin: 50 } }, this.props.t("No data available."));
        };
        //istanbul ignore next
        this.getSecAPart = () => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
            let { t } = this.props;
            let webAppDirection = localStorage.getItem("webAppDirection") ||
                Cookies.get("webAppDirection") ||
                "ltr";
            return ((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.appointmentArr) === null || _b === void 0 ? void 0 : _b.length) && ((_f = (_e = (_d = (_c = this.state) === null || _c === void 0 ? void 0 : _c.appointmentArr[0]) === null || _d === void 0 ? void 0 : _d.completed_appointments) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.length) != 0 ? (_k = (_j = (_h = (_g = this.state) === null || _g === void 0 ? void 0 : _g.appointmentArr[0]) === null || _h === void 0 ? void 0 : _h.completed_appointments) === null || _j === void 0 ? void 0 : _j.data) === null || _k === void 0 ? void 0 : _k.map((element, index) => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0;
                return (React.createElement("div", { className: "listitem-div", key: element === null || element === void 0 ? void 0 : element.id },
                    React.createElement("div", { className: "sub-item", style: { cursor: 'pointer' }, id: 'upcoming-btn-else', onClick: () => {
                            this.props.history.push({
                                pathname: '/MyBookings/Detail',
                                state: { appointmentId: element === null || element === void 0 ? void 0 : element.id, upcoming: this.state.slectActiveBtn, clickFromProfile: true }
                            });
                        } },
                        React.createElement("p", null,
                            t("Booking ID"),
                            ": ", (_a = element === null || element === void 0 ? void 0 : element.attributes) === null || _a === void 0 ? void 0 :
                            _a.booking_id),
                        React.createElement("p", { style: { marginLeft: webAppDirection === "ltr" ? 'auto' : 0, marginRight: webAppDirection === "rtl" ? 'auto' : 0 } },
                            t("Booking code:"),
                            " ", (_c = (_b = element === null || element === void 0 ? void 0 : element.attributes) === null || _b === void 0 ? void 0 : _b.booking_code) === null || _c === void 0 ? void 0 :
                            _c.pin)),
                    React.createElement("div", { className: "item-detail-div" },
                        React.createElement("div", { className: "profile-detail", style: { cursor: 'pointer' }, id: 'profile-detail-div-else', onClick: () => {
                                this.props.history.push({
                                    state: { upcoming: this.state.slectActiveBtn, appointmentId: element === null || element === void 0 ? void 0 : element.id, clickFromProfile: true },
                                    pathname: '/MyBookings/Detail'
                                });
                            } },
                            React.createElement("img", { src: ((_g = (_f = (_e = (_d = element === null || element === void 0 ? void 0 : element.attributes) === null || _d === void 0 ? void 0 : _d.employee_details) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.attributes) === null || _g === void 0 ? void 0 : _g.team_member_profile) || defultPng }),
                            React.createElement("div", { className: "profile-detail-sub", id: "detai_xyz" },
                                React.createElement("div", { className: "title-names" },
                                    React.createElement("h3", { id: "xyz_h3" }, ((_l = (_k = (_j = (_h = element === null || element === void 0 ? void 0 : element.attributes) === null || _h === void 0 ? void 0 : _h.employee_details) === null || _j === void 0 ? void 0 : _j.data) === null || _k === void 0 ? void 0 : _k.attributes) === null || _l === void 0 ? void 0 : _l.first_name) + ' ' + ((_q = (_p = (_o = (_m = element === null || element === void 0 ? void 0 : element.attributes) === null || _m === void 0 ? void 0 : _m.employee_details) === null || _o === void 0 ? void 0 : _o.data) === null || _p === void 0 ? void 0 : _p.attributes) === null || _q === void 0 ? void 0 : _q.last_name))),
                                React.createElement("h4", { id: "xyxx" }, moment((_r = element === null || element === void 0 ? void 0 : element.attributes) === null || _r === void 0 ? void 0 : _r.start_time).format('hh:mm A') + ' on ' + moment((_s = element === null || element === void 0 ? void 0 : element.attributes) === null || _s === void 0 ? void 0 : _s.start_time).format('dddd').substring(0, 3) + ', ' + moment((_t = element === null || element === void 0 ? void 0 : element.attributes) === null || _t === void 0 ? void 0 : _t.booking_date).format("DD MMM YYYY"))),
                            React.createElement("h2", { style: { marginLeft: webAppDirection === "ltr" ? 'auto' : 0, marginRight: webAppDirection === "rtl" ? 'auto' : 0 } },
                                t("Bill"),
                                ": OMR ", (_u = element === null || element === void 0 ? void 0 : element.attributes) === null || _u === void 0 ? void 0 :
                                _u.total_price)),
                        React.createElement("hr", { style: { marginLeft: webAppDirection === "ltr" ? "-30px" : "0px", marginRight: webAppDirection === "rtl" ? "-30px" : "0px" } }),
                        React.createElement("div", { className: "service-div", style: { cursor: 'pointer' }, id: 'service-div-else', onClick: () => {
                                this.props.history.push({
                                    state: { upcoming: this.state.slectActiveBtn, appointmentId: element === null || element === void 0 ? void 0 : element.id, clickFromProfile: true },
                                    pathname: '/MyBookings/Detail'
                                });
                            } },
                            React.createElement("p", null,
                                t("Service"),
                                ": ", (_w = (_v = element === null || element === void 0 ? void 0 : element.attributes) === null || _v === void 0 ? void 0 : _v.service) === null || _w === void 0 ? void 0 :
                                _w.display_name),
                            React.createElement("h6", { style: { color: this.getcolor((_x = element === null || element === void 0 ? void 0 : element.attributes) === null || _x === void 0 ? void 0 : _x.status), marginLeft: webAppDirection === "ltr" ? 'auto' : 0, marginRight: webAppDirection === "rtl" ? 'auto' : 0 } }, t(this.getStatus((_y = element === null || element === void 0 ? void 0 : element.attributes) === null || _y === void 0 ? void 0 : _y.status)))),
                        React.createElement("hr", { style: { marginLeft: webAppDirection === "ltr" ? "-30px" : "0px", marginRight: webAppDirection === "rtl" ? "-30px" : "0px" } }),
                        React.createElement("div", { className: "Need-div" },
                            React.createElement("p", { onClick: () => {
                                    this.props.history.push({
                                        state: { mainBooking: false, clickFromProfile: true, appointmentId: element === null || element === void 0 ? void 0 : element.id },
                                        pathname: '/NeedHelp'
                                    });
                                } }, t("Need help?")),
                            React.createElement("h6", { id: 'service-div-h6-else', onClick: () => {
                                    this.props.history.push({
                                        pathname: '/MyBookings/Detail',
                                        state: { appointmentId: element === null || element === void 0 ? void 0 : element.id, upcoming: this.state.slectActiveBtn, clickFromProfile: true }
                                    });
                                }, style: { cursor: 'pointer', marginLeft: webAppDirection === "ltr" ? 'auto' : 0, marginRight: webAppDirection === "rtl" ? 'auto' : 0, color: ((_z = element === null || element === void 0 ? void 0 : element.attributes) === null || _z === void 0 ? void 0 : _z.paid_by) === 'pay_at_shop' ? '#ff9f0a' : '#34c759' } }, ((_0 = element === null || element === void 0 ? void 0 : element.attributes) === null || _0 === void 0 ? void 0 : _0.paid_by) === 'pay_at_shop' ? t('Pay at shop') : t('Pay by card'))))));
            }) : (this.getSecLoading());
        };
        this.profileAllDataRender = () => {
            let { t } = this.props;
            let webAppDirection = localStorage.getItem("webAppDirection") ||
                Cookies.get("webAppDirection") ||
                "ltr";
            return React.createElement("div", { className: "main-div main-profile-booking", style: { marginRight: webAppDirection === "rtl" ? 50 : 0, marginLeft: webAppDirection === "ltr" ? 50 : 0, marginTop: -50 } },
                React.createElement("div", { className: "div-profile-book", style: { marginBottom: 35, display: 'flex', cursor: 'pointer' } },
                    React.createElement("h1", { className: "h1-tag-profile" }, t(this.props.setBackgroundSettingdata)),
                    React.createElement("img", { src: filterBookings, id: "filter-bookings", style: { marginLeft: webAppDirection === "ltr" ? 'auto' : 0, marginRight: webAppDirection === "rtl" ? 'auto' : 0, width: 24, height: 24 }, alt: "", onClick: () => {
                            this.setState({ openFilter: true });
                        } })),
                React.createElement("div", { className: "mybooking-main-div" },
                    React.createElement("div", { className: "btn-pair-of-filters", style: { marginTop: 10, width: 452, height: 'auto', padding: "1% 2%" } },
                        React.createElement(Button, { className: "up-btn", style: this.state.slectActiveBtn ? { background: '#1e5fea', height: 50 } : { background: '#F9F9F8', color: '#2c2c2e', height: 50 }, onClick: () => {
                                this.setState({ slectActiveBtn: true });
                            } }, t("UPCOMING")),
                        React.createElement(Button, { className: "past-btn", style: this.state.slectActiveBtn ? { background: '#F9F9F8', color: '#2c2c2e', height: 50 } : { background: '#1e5fea', height: 50 }, onClick: () => {
                                this.setState({ slectActiveBtn: false });
                            } }, t("PAST"))),
                    React.createElement("div", { className: "list-of-all-booking-div" }, this.state.slectActiveBtn ? (this.getFirstPart()) : (this.getSecAPart()))));
        };
    }
    render() {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        this.bookingScreen = 'yes';
        let { t } = this.props;
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        return (React.createElement(React.Fragment, null,
            this.profileAllDataRender(),
            React.createElement(Modal, { open: this.state.openFilter, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" },
                React.createElement("div", { className: "modal-booknow-confirmed-review" },
                    React.createElement("div", { className: "img-debit-card-div-confirmed-review" },
                        React.createElement("div", { className: "img-debit-card-confirmed-review", style: {
                                padding: "20px",
                                paddingTop: "0px",
                                paddingBottom: 32,
                            } },
                            React.createElement("div", { style: { display: "flex" } },
                                React.createElement("h1", { style: {
                                        paddingLeft: "15px",
                                        marginBottom: "24px",
                                        fontSize: "32px",
                                    } }, t("Filters")),
                                " ",
                                React.createElement("img", { src: close_modal_icon, id: "closeFilterModal", style: {
                                        marginLeft: webAppDirection === "ltr" ? "auto" : "0",
                                        marginRight: webAppDirection === "rtl" ? "auto" : "0",
                                        width: "48px",
                                        cursor: "pointer",
                                    }, onClick: () => this.setState({
                                        openFilter: false,
                                        allFiltermanage: {
                                            all: true,
                                            completed: false,
                                            no_show: false,
                                            started: false,
                                            cancelled: false,
                                            refunded: false,
                                            pending: false,
                                            re_scheduled: false
                                        },
                                    }), alt: "" })),
                            React.createElement(FormControl, { className: "filter-buttons-main-conatiner" },
                                React.createElement(RadioGroup, { "aria-labelledby": "demo-radio-buttons-group-label", defaultValue: this.state.filterRadioVal, name: "radio-buttons-group", style: { alignItems: "baseline", width: "100%" } },
                                    React.createElement(FormControlLabel, { id: "allRadio", onClick: () => {
                                            this.setState({
                                                filterRadioVal: "All",
                                                allFiltermanage: {
                                                    all: true,
                                                    completed: false,
                                                    no_show: false,
                                                    cancelled: false,
                                                    refunded: false,
                                                    pending: false,
                                                    started: false,
                                                    re_scheduled: false
                                                },
                                            });
                                        }, value: "All", style: {
                                            marginBottom: 15,
                                            marginTop: 18,
                                            width: "100%",
                                        }, checked: (_a = this.state.allFiltermanage) === null || _a === void 0 ? void 0 : _a.all, control: React.createElement(Radio, { style: {
                                                marginRight: webAppDirection === "rtl" ? "auto" : "0px",
                                                marginLeft: webAppDirection === "ltr" ? "auto" : "0px",
                                            }, icon: React.createElement("img", { src: uncheckCircle }), checkedIcon: React.createElement("img", { src: checkRightIcon }) }), label: React.createElement("span", { style: { fontSize: "20px" } }, t("All")), labelPlacement: "start" }),
                                    React.createElement(FormControlLabel, { id: "pendingRadio", onClick: () => {
                                            this.setState({
                                                filterRadioVal: "pending",
                                                allFiltermanage: {
                                                    all: false,
                                                    completed: false,
                                                    started: false,
                                                    no_show: false,
                                                    cancelled: false,
                                                    refunded: false,
                                                    pending: true,
                                                    re_scheduled: false
                                                },
                                            });
                                        }, value: "pending", style: {
                                            marginBottom: 15,
                                            width: "100%",
                                        }, checked: (_b = this.state.allFiltermanage) === null || _b === void 0 ? void 0 : _b.pending, control: React.createElement(Radio, { style: {
                                                marginRight: webAppDirection === "rtl" ? "auto" : "0px",
                                                marginLeft: webAppDirection === "ltr" ? "auto" : "0px",
                                            }, icon: React.createElement("img", { src: uncheckCircle }), checkedIcon: React.createElement("img", { src: checkRightIcon }) }), label: React.createElement("span", { style: { fontSize: "20px" } }, t("Upcoming")), labelPlacement: "start" }),
                                    React.createElement(FormControlLabel, { id: "startedRadio", onClick: () => {
                                            this.setState({
                                                filterRadioVal: "started",
                                                allFiltermanage: {
                                                    all: false,
                                                    completed: false,
                                                    no_show: false,
                                                    cancelled: false,
                                                    refunded: false,
                                                    started: true,
                                                    pending: false,
                                                    re_scheduled: false
                                                },
                                            });
                                        }, value: "started", style: {
                                            marginBottom: 15,
                                            width: "100%",
                                        }, checked: (_c = this.state.allFiltermanage) === null || _c === void 0 ? void 0 : _c.started, control: React.createElement(Radio, { style: {
                                                marginRight: webAppDirection === "rtl" ? "auto" : "0px",
                                                marginLeft: webAppDirection === "ltr" ? "auto" : "0px",
                                            }, icon: React.createElement("img", { src: uncheckCircle }), checkedIcon: React.createElement("img", { src: checkRightIcon }) }), label: React.createElement("span", { style: { fontSize: "20px" } }, t("Started")), labelPlacement: "start" }),
                                    React.createElement(FormControlLabel, { id: "re_scheduledRadio", onClick: () => {
                                            this.setState({
                                                filterRadioVal: "re_scheduled",
                                                allFiltermanage: {
                                                    all: false,
                                                    completed: false,
                                                    no_show: false,
                                                    cancelled: false,
                                                    refunded: false,
                                                    re_scheduled: true,
                                                    started: false,
                                                    pending: false
                                                },
                                            });
                                        }, value: "re_scheduled", style: {
                                            marginBottom: 15,
                                            width: "100%",
                                        }, checked: (_d = this.state.allFiltermanage) === null || _d === void 0 ? void 0 : _d.re_scheduled, control: React.createElement(Radio, { style: {
                                                marginRight: webAppDirection === "rtl" ? "auto" : "0px",
                                                marginLeft: webAppDirection === "ltr" ? "auto" : "0px",
                                            }, icon: React.createElement("img", { src: uncheckCircle }), checkedIcon: React.createElement("img", { src: checkRightIcon }) }), label: React.createElement("span", { style: { fontSize: "20px" } }, t("Rescheduled")), labelPlacement: "start" }),
                                    React.createElement(FormControlLabel, { id: "compRadio", onClick: () => {
                                            this.setState({
                                                filterRadioVal: "completed",
                                                allFiltermanage: {
                                                    all: false,
                                                    completed: true,
                                                    no_show: false,
                                                    cancelled: false,
                                                    started: false,
                                                    refunded: false,
                                                    pending: false,
                                                    re_scheduled: false
                                                },
                                            });
                                        }, value: "completed", style: { marginBottom: 15, width: "100%" }, checked: (_e = this.state.allFiltermanage) === null || _e === void 0 ? void 0 : _e.completed, control: React.createElement(Radio, { style: {
                                                marginRight: webAppDirection === "rtl" ? "auto" : "0px",
                                                marginLeft: webAppDirection === "ltr" ? "auto" : "0px",
                                            }, icon: React.createElement("img", { src: uncheckCircle }), checkedIcon: React.createElement("img", { src: checkRightIcon }) }), label: React.createElement("span", { style: { fontSize: "20px" } }, t("Completed")), labelPlacement: "start" }),
                                    React.createElement(FormControlLabel, { id: "noRadio", onClick: () => {
                                            this.setState({
                                                filterRadioVal: "no_show",
                                                allFiltermanage: {
                                                    all: false,
                                                    completed: false,
                                                    no_show: true,
                                                    cancelled: false,
                                                    refunded: false,
                                                    pending: false,
                                                    started: false,
                                                    re_scheduled: false
                                                },
                                            });
                                        }, value: "no_show", style: { marginBottom: 15, width: "100%" }, checked: (_f = this.state.allFiltermanage) === null || _f === void 0 ? void 0 : _f.no_show, control: React.createElement(Radio, { style: {
                                                marginRight: webAppDirection === "rtl" ? "auto" : "0px",
                                                marginLeft: webAppDirection === "ltr" ? "auto" : "0px",
                                            }, icon: React.createElement("img", { src: uncheckCircle }), checkedIcon: React.createElement("img", { src: checkRightIcon }) }), label: React.createElement("span", { style: { fontSize: "20px" } }, t("No Show")), labelPlacement: "start" }),
                                    React.createElement(FormControlLabel, { id: "canRadio", onClick: () => {
                                            this.setState({
                                                filterRadioVal: "cancelled",
                                                allFiltermanage: {
                                                    started: false,
                                                    all: false,
                                                    completed: false,
                                                    no_show: false,
                                                    cancelled: true,
                                                    refunded: false,
                                                    pending: false,
                                                    re_scheduled: false
                                                },
                                            });
                                        }, value: "cancelled", style: { marginBottom: 15, width: "100%" }, checked: (_g = this.state.allFiltermanage) === null || _g === void 0 ? void 0 : _g.cancelled, control: React.createElement(Radio, { style: {
                                                marginRight: webAppDirection === "rtl" ? "auto" : "0px",
                                                marginLeft: webAppDirection === "ltr" ? "auto" : "0px",
                                            }, icon: React.createElement("img", { src: uncheckCircle }), checkedIcon: React.createElement("img", { src: checkRightIcon }) }), label: React.createElement("span", { style: { fontSize: "20px" } }, t("Cancelled")), labelPlacement: "start" }),
                                    React.createElement(FormControlLabel, { id: "refRadio", onClick: () => {
                                            this.setState({
                                                filterRadioVal: "refunded",
                                                allFiltermanage: {
                                                    all: false,
                                                    started: false,
                                                    completed: false,
                                                    no_show: false,
                                                    cancelled: false,
                                                    refunded: true,
                                                    pending: false,
                                                    re_scheduled: false
                                                },
                                            });
                                        }, value: "refunded", style: { marginBottom: 15, width: "100%" }, checked: (_h = this.state.allFiltermanage) === null || _h === void 0 ? void 0 : _h.refunded, control: React.createElement(Radio, { style: {
                                                marginRight: webAppDirection === "rtl" ? "auto" : "0px",
                                                marginLeft: webAppDirection === "ltr" ? "auto" : "0px",
                                            }, icon: React.createElement("img", { src: uncheckCircle }), checkedIcon: React.createElement("img", { src: checkRightIcon }) }), label: React.createElement("span", { style: { fontSize: "20px" } }, t("Refunded")), labelPlacement: "start" }))),
                            React.createElement(Button, { id: "clearFilter", className: "logout-modal-yes-button-style", onClick: () => {
                                    this.setState({
                                        filterRadioVal: "",
                                        allFiltermanage: {
                                            all: true,
                                            started: false,
                                            completed: false,
                                            no_show: false,
                                            cancelled: false,
                                            refunded: false,
                                            pending: false,
                                            re_scheduled: false
                                        },
                                    });
                                }, style: { marginBottom: 15, marginTop: 15 } }, t("Clear")),
                            React.createElement(Button, { id: "showFilter", className: "logout-modal-no-button-style", onClick: () => {
                                    this.setState({
                                        appointmentArr: [],
                                        openFilter: false,
                                    });
                                    this.getMyallAppoitments(this.state.filterRadioVal);
                                } }, t("Show results"))))))));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(ProfileMybooking));
// Customizable Area End
