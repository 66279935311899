// Customizable Area Start
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { OutlinedInput, InputAdornment, IconButton } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import "../assets/Styles/walkin-business.web.css";
import { BlockComponent } from "framework/src/BlockComponent";
import { withTranslation } from "react-i18next";
//@ts-ignore
import Cookies from "js-cookie";
class SearchClientBusiness extends BlockComponent {
    constructor(props) {
        super(props);
        //istanbul ignore next
        this.validateMobileNumber = () => {
            const phoneNumberRegex = /^[0-9 \-\(\)\.]{5,13}$/;
            //istanbul ignore next
            if (this.props.mobileNumber === "") {
                this.setState({ mobileNumberEmpty: true, mobileNumberError: false });
            }
            else if (!this.props.mobileNumber.match(phoneNumberRegex)) {
                this.setState({ mobileNumberError: true, mobileNumberEmpty: false });
            }
            else {
                this.setState({
                    mobileNumberError: false,
                    mobileNumberEmpty: false,
                });
            }
        };
        //istanbul ignore next
        this.borderRight = () => {
            let webAppDirection = localStorage.getItem("webAppDirection") ||
                Cookies.get("webAppDirection") ||
                "ltr";
            return webAppDirection === "ltr"
                ? this.props.mobileNumberError ||
                    this.state.mobileNumberError ||
                    this.state.mobileNumberEmpty
                    ? "1px solid #ff453a"
                    : "1px solid #979797"
                : "";
        };
        //istanbul ignore next
        this.borderLeft = () => {
            let webAppDirection = localStorage.getItem("webAppDirection") ||
                Cookies.get("webAppDirection") ||
                "ltr";
            return webAppDirection === "rtl"
                ? this.props.mobileNumberError ||
                    this.state.mobileNumberError ||
                    this.state.mobileNumberEmpty
                    ? "1px solid #ff453a"
                    : "1px solid #979797"
                : "";
        };
        this.state = {
            mobileNumberError: false,
            mobileNumberEmpty: false,
        };
    }
    render() {
        //@ts-ignore
        let { t } = this.props;
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        return (React.createElement(Dialog, { open: this.props.openDialog, maxWidth: "sm", fullWidth: true, disableAutoFocus: true, PaperProps: {
                className: "walkin-dialog-container",
            } },
            React.createElement(DialogTitle, { disableTypography: true, className: "walkin-dialog-title" },
                React.createElement(CloseIcon, { className: "walkin-dialog-close-icon", onClick: this.props.onClose })),
            React.createElement(DialogContent, { className: "walkin-dialog-content" },
                React.createElement("div", { style: { flexDirection: "column" } },
                    React.createElement("div", { className: "walkin-form title" }, t("Walk-in booking")),
                    React.createElement("div", { style: { flexDirection: "column", display: "flex" } },
                        React.createElement("span", { className: webAppDirection === "ltr"
                                ? "walkin-form subtitle"
                                : "walkin-form arabic_subtitle" }, t("Search client")),
                        React.createElement(OutlinedInput, { type: "number", placeholder: t("Enter clients number"), className: "walkin-form-input-style", name: "phoneNumber", value: this.props.mobileNumber, error: this.props.mobileNumberError ||
                                this.state.mobileNumberError ||
                                this.state.mobileNumberEmpty, onChange: (e) => this.props.handleChange(e.target.value), onBlur: this.validateMobileNumber, startAdornment: React.createElement(InputAdornment, { position: webAppDirection === "ltr" ? "start" : "end" },
                                React.createElement(IconButton, { style: {
                                        width: 66,
                                        height: 45,
                                        cursor: "pointer",
                                        justifyContent: "center",
                                        borderRight: this.borderRight(),
                                        borderLeft: this.borderLeft(),
                                        borderRadius: "0%",
                                    }, onClick: this.props.openCountryCodeModal },
                                    React.createElement("span", { className: "walkin-country-code-text-style", style: {
                                            color: "#2c2c2e",
                                        } }, this.props.countryCode))) }),
                        this.props.userExists && (React.createElement("span", { className: "walkin-suceess-message-text " }, t("Client is already registered."))),
                        this.props.userDoesNotExist && (React.createElement("span", { className: "walkin-suceess-message-text", style: { color: "#FF453A" } }, t("Entered number does not exists in system. Continue to add client."))),
                        this.props.numberUserAsProvideOrEmployee && (React.createElement("span", { className: "walkin-suceess-message-text", style: { color: "#FF453A" } }, t("You can't use mobile number registered with provider/employee."))),
                        this.state.mobileNumberEmpty && (React.createElement("span", { className: "walkin-suceess-message-text", style: { color: "#FF453A" } }, t("Please enter mobile number."))),
                        (this.state.mobileNumberError ||
                            this.props.mobileNumberError) && (React.createElement("span", { className: "walkin-suceess-message-text", style: { color: "#FF453A" } }, t("Please enter valid mobile number.")))))),
            React.createElement(DialogActions, { className: "walkin-dialog-actions" },
                React.createElement(Button, { variant: "contained", color: "primary", fullWidth: true, className: "walkin-dialog-button", onClick: this.props.onClick, disabled: this.props.numberUserAsProvideOrEmployee ||
                        this.state.mobileNumberEmpty ||
                        this.state.mobileNumberError }, this.props.userExists ? t("Verify & Continue") : t("Continue")))));
    }
}
//@ts-ignore
export default withTranslation()(SearchClientBusiness);
// Customizable Area End
