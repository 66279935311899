// Customizable Area Start
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { OutlinedInput, InputAdornment, IconButton } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import "../assets/Styles/walkin-business.web.css";
import { BlockComponent } from "framework/src/BlockComponent";
import { ArrowDown } from "../../CustomisableUserProfiles/src/assets";
import { withTranslation } from "react-i18next";
//@ts-ignore
import Cookies from "js-cookie";
class ClientDetailsBusiness extends BlockComponent {
    constructor(props) {
        super(props);
        this.validateFirstName = () => {
            if (this.props.state.full_name.trim() === "") {
                this.setState(Object.assign(Object.assign({}, this.state), { firstNameError: true }));
            }
            else {
                this.setState(Object.assign(Object.assign({}, this.state), { firstNameError: false }));
            }
        };
        this.validateEmail = () => {
            const emailRegExr = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (this.props.state.email === "") {
                this.setState({ emailError: true });
            }
            else if (!this.props.state.email.match(emailRegExr)) {
                this.setState({ emailInvalidError: true, emailError: false });
            }
            else {
                this.setState(Object.assign(Object.assign({}, this.state), { emailError: false, emailInvalidError: false }));
            }
        };
        this.validateGender = () => {
            //istanbul ignore next
            if (this.props.state.gender.trim() === "") {
                this.setState(Object.assign(Object.assign({}, this.state), { genderError: true }));
            }
            else {
                this.setState(Object.assign(Object.assign({}, this.state), { genderError: false }));
            }
        };
        this.state = {
            firstNameError: false,
            lastNameError: false,
            emailError: false,
            genderError: false,
            emailInvalidError: false,
        };
    }
    render() {
        //@ts-ignore
        let { t } = this.props;
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        const errorMessage = (condition, message) => condition && (React.createElement("span", { className: "business-walkin-error-message-text" }, t(message)));
        return (React.createElement(Dialog, { open: this.props.openDialog, maxWidth: "sm", fullWidth: true, disableAutoFocus: true, PaperProps: {
                className: "walkin-dialog-Client-Details",
            } },
            React.createElement(DialogTitle, { disableTypography: true, className: "walkin-dialog-title" },
                React.createElement(CloseIcon, { className: "walkin-dialog-close-icon", onClick: this.props.onClose }),
                React.createElement("div", { className: "walkin-form-title" }, this.props.userExists
                    ? t("Client Details")
                    : t("Add Client Details"))),
            React.createElement(DialogContent, { className: "walkin-dialog-content" },
                React.createElement("div", { style: { flexDirection: "column" } },
                    React.createElement("div", { className: "walkin-input-container" },
                        React.createElement("span", { className: "walkin-dialog-label" }, t("Full name")),
                        React.createElement(OutlinedInput, { name: "full_name", error: this.state.firstNameError, onChange: (e) => this.props.handleChange(e.target.name, e.target.value), value: this.props.state.full_name, type: "text", id: "accountOwnerFullName", className: "walkin-dialog-input-style", placeholder: t("Enter client’s full name"), onBlur: () => this.validateFirstName(), inputProps: { min: 0, maxLength: 50, style: { marginLeft: 5 } } }),
                        errorMessage(this.state.firstNameError, "Please enter full name")),
                    React.createElement("div", { className: "walkin-input-container" },
                        React.createElement("span", { className: "walkin-dialog-label" }, t("Email Address")),
                        React.createElement(OutlinedInput, { value: this.props.state.email, error: this.state.emailError ||
                                this.state.emailInvalidError ||
                                this.props.state.emailAlreadyUsedError, name: "email", type: "text", placeholder: t("Enter client’s email address"), id: "accountOwnerFullName", className: "walkin-dialog-input-style", inputProps: { min: 0, style: { marginLeft: 5 } }, onChange: (e) => this.props.handleChange(e.target.name, e.target.value), disabled: this.props.state.email.length > 0 && this.props.userExists, onBlur: () => this.validateEmail() }),
                        errorMessage(this.state.emailError, "Please enter email address"),
                        errorMessage(this.state.emailInvalidError, "Please enter valid email address"),
                        errorMessage(this.props.state.emailAlreadyUsedError, "Account already present with this email id.")),
                    React.createElement("div", { className: "walkin-input-container" },
                        React.createElement("span", { className: "walkin-dialog-label" }, t("Mobile number")),
                        React.createElement(OutlinedInput, { type: "number", placeholder: t("Mobile number"), disabled: true, value: this.props.state.clientMobileNumber, className: "walkin-dialog-input-style", name: "phoneNumber", startAdornment: React.createElement(InputAdornment, { position: webAppDirection === "ltr" ? "start" : "end" },
                                React.createElement(IconButton, { style: {
                                        width: 66,
                                        height: 45,
                                        cursor: "pointer",
                                        justifyContent: "center",
                                        borderRight: webAppDirection === "ltr" ? "1px solid #979797" : "",
                                        borderLeft: webAppDirection === "rtl" ? "1px solid #979797" : "",
                                        borderRadius: "0%",
                                    } },
                                    React.createElement("span", { className: "walkin-country-code-text-style", style: {
                                            color: "#2c2c2e",
                                        } }, this.props.countryCode))) })),
                    React.createElement("div", { className: "walkin-input-container" },
                        React.createElement("span", { className: "walkin-dialog-label" }, t("Gender")),
                        React.createElement("div", { className: "add-member-form-custom-select large-input form-custom-select", style: {
                                marginBottom: 0,
                            } },
                            React.createElement("select", { style: {
                                    color: this.state.genderError ? "#FF453A" : "#757575",
                                }, className: "add-member-form-input-style", name: "gender", id: "Genderclient", onChange: (e) => this.props.handleChange(e.target.name, e.target.value), value: this.props.state.gender, onBlur: () => this.validateGender() },
                                React.createElement("option", { value: "", disabled: true }, t("Select client’s gender")),
                                React.createElement("option", { value: "male" }, t("Male")),
                                React.createElement("option", { value: "female" }, t("Female"))),
                            React.createElement("img", { src: ArrowDown, alt: "Custom Icon", style: {
                                    right: webAppDirection === "ltr" ? 10 : "auto",
                                    left: webAppDirection === "rtl" ? 10 : "auto",
                                } })),
                        errorMessage(this.state.genderError, "Please choose gender")))),
            React.createElement(DialogActions, { className: "walkin-dialog-actions" },
                React.createElement(Button, { variant: "contained", color: "primary", className: "walkin-client-details-dialog-button continue-button", disabled: this.state.emailError ||
                        this.state.emailInvalidError ||
                        this.state.firstNameError ||
                        this.state.lastNameError ||
                        this.state.genderError, onClick: () => this.props.onClick() }, t("Continue")))));
    }
}
//@ts-ignore
export default withTranslation()(ClientDetailsBusiness);
// Customizable Area End
