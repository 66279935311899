var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Customizable Area Start
import React from "react";
import BusinessManageController from "../../dashboard/src/BusinessManageController";
import "../assets/Styles/manage-business.css";
import { rightBlackArrow, subscribedUserLogo } from "./assets";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
class ManageBusinessList extends BusinessManageController {
    //istanbul ignore next
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            let isUserLoggedIn = localStorage.getItem("token") || "";
            if (!isUserLoggedIn) {
                this.props.navigation.navigate("Login");
            }
            else {
                this.VerifySubscriptionAPICall();
            }
        });
    }
    //istanbul ignore next
    render() {
        let pathname = window.location.pathname;
        const { checkSubscription } = this.state;
        let { t } = this.props;
        return (React.createElement("div", { className: "manage-business-list-main-container" },
            React.createElement("div", { className: "manage-business-list-heading-container" },
                React.createElement("span", { className: "manage-business-list-heading-text" }, t("Manage business")),
                checkSubscription ? (React.createElement("span", { className: "business-list-heading-subscribed-user-container" },
                    React.createElement("img", { src: subscribedUserLogo, alt: "subscribedUserLogo", className: "business-list-heading-subscribed-user-logo" }),
                    t("Subscribed"))) : (React.createElement("span", { className: "business-list-heading-subscribed-user-container" }))),
            React.createElement("div", { className: "manage-services-list-item-container" },
                React.createElement("div", { className: pathname === "/ManageBusiness/ManageBookings"
                        ? "active-manage-services-list-item"
                        : "manage-services-list-item", onClick: () => 
                    //@ts-ignore
                    this.props.history.push({
                        pathname: "/ManageBusiness/ManageBookings",
                        state: { from: "listing" },
                    }) },
                    React.createElement("div", { className: "manage-services-list-details-container" },
                        React.createElement("span", { className: "manage-services-list-item-heading" }, t("Manage bookings")),
                        React.createElement("span", { className: "manage-services-list-item-details" }, t("List of all the bookings"))),
                    React.createElement("div", { className: "manage-services-list-item-arrow-image-container" },
                        React.createElement("img", { src: rightBlackArrow, alt: "arrow", className: "manage-services-list-item-arrow-image" }))),
                React.createElement("div", { className: pathname === "/ManageBusiness/ManageTeam"
                        ? "active-manage-services-list-item"
                        : "manage-services-list-item", onClick: () => 
                    //@ts-ignore
                    this.props.history.push("/ManageBusiness/ManageTeam") },
                    React.createElement("div", { className: "manage-services-list-details-container" },
                        React.createElement("span", { className: "manage-services-list-item-heading" }, t("Manage team")),
                        React.createElement("span", { className: "manage-services-list-item-details" }, t("Add, edit and delete your team member"))),
                    React.createElement("div", { className: "manage-services-list-item-arrow-image-container" },
                        React.createElement("img", { src: rightBlackArrow, alt: "arrow", className: "manage-services-list-item-arrow-image" }))),
                React.createElement("div", { className: pathname === "/ManageBusiness/ManageCatalouge"
                        ? "active-manage-services-list-item"
                        : "manage-services-list-item", onClick: () => 
                    //@ts-ignore
                    this.props.history.push("/ManageBusiness/ManageCatalouge") },
                    React.createElement("div", { className: "manage-services-list-details-container" },
                        React.createElement("span", { className: "manage-services-list-item-heading" }, t("Manage catalogue")),
                        React.createElement("span", { className: "manage-services-list-item-details" }, t("List of all the services"))),
                    React.createElement("div", { className: "manage-services-list-item-arrow-image-container" },
                        React.createElement("img", { src: rightBlackArrow, alt: "arrow", className: "manage-services-list-item-arrow-image" }))),
                React.createElement("div", { className: pathname === "/ManageBusiness/ManageWorkingHours" ||
                        pathname === "/ManageBusiness/TimeWorkingDays" ||
                        pathname === "/ManageBusiness/BusinessWorkingDays" ||
                        pathname === "/ManageBusiness/ManageBlockMyTime" ||
                        pathname === "/ManageBusiness/ManageBusinessHoliday"
                        ? "active-manage-services-list-item"
                        : "manage-services-list-item", onClick: () => 
                    //@ts-ignore
                    this.props.history.push("/ManageBusiness/ManageWorkingHours") },
                    React.createElement("div", { className: "manage-services-list-details-container" },
                        React.createElement("span", { className: "manage-services-list-item-heading" }, t("Manage working days & time")),
                        React.createElement("span", { className: "manage-services-list-item-details" }, t("Manage working hours of your team"))),
                    React.createElement("div", { className: "manage-services-list-item-arrow-image-container" },
                        React.createElement("img", { src: rightBlackArrow, alt: "arrow", className: "manage-services-list-item-arrow-image" }))),
                React.createElement("div", { className: pathname === "/ManageBusiness/Reviews"
                        ? "active-manage-services-list-item"
                        : "manage-services-list-item", onClick: () => 
                    //@ts-ignore
                    this.props.history.push("/ManageBusiness/Reviews") },
                    React.createElement("div", { className: "manage-services-list-details-container" },
                        React.createElement("span", { className: "manage-services-list-item-heading" }, t("Reviews")),
                        React.createElement("span", { className: "manage-services-list-item-details" }, t("View feedbacks submitted by clients"))),
                    React.createElement("div", { className: "manage-services-list-item-arrow-image-container" },
                        React.createElement("img", { src: rightBlackArrow, alt: "arrow", className: "manage-services-list-item-arrow-image" }))),
                checkSubscription && (React.createElement("div", { className: pathname === "/ManageBusiness/Analytics"
                        ? "active-manage-services-list-item"
                        : "manage-services-list-item", onClick: () => 
                    //@ts-ignore
                    this.props.history.push("/ManageBusiness/Analytics"), id: "Analytics" },
                    React.createElement("div", { className: "manage-services-list-details-container" },
                        React.createElement("span", { className: "manage-services-list-item-heading" }, t("View analytics")),
                        React.createElement("span", { className: "manage-services-list-item-details" }, t("See your performance"))),
                    React.createElement("div", { className: "manage-services-list-item-arrow-image-container" },
                        React.createElement("img", { src: rightBlackArrow, alt: "arrow", className: "manage-services-list-item-arrow-image" })))),
                checkSubscription && (React.createElement("div", { className: pathname === "/ManageBusiness/DiscountAndOffer"
                        ? "active-manage-services-list-item"
                        : "manage-services-list-item", onClick: () => 
                    //@ts-ignore
                    this.props.history.push("/ManageBusiness/DiscountAndOffer") },
                    React.createElement("div", { className: "manage-services-list-details-container" },
                        React.createElement("span", { className: "manage-services-list-item-heading" }, t("Offer & discount")),
                        React.createElement("span", { className: "manage-services-list-item-details" }, t("Manage offer and discount"))),
                    React.createElement("div", { className: "manage-services-list-item-arrow-image-container" },
                        React.createElement("img", { src: rightBlackArrow, alt: "arrow", className: "manage-services-list-item-arrow-image" })))),
                checkSubscription && (React.createElement("div", { onClick: () => 
                    //@ts-ignore
                    this.props.history.push("/ManageBusiness/PerformnaceTracker"), className: pathname === "/ManageBusiness/PerformnaceTracker"
                        ? "active-manage-services-list-item"
                        : "manage-services-list-item" },
                    React.createElement("div", { className: "manage-services-list-details-container" },
                        React.createElement("span", { className: "manage-services-list-item-heading" }, t("Performance tracker")),
                        React.createElement("span", { className: "manage-services-list-item-details" }, t("See employee performance"))),
                    React.createElement("div", { className: "manage-services-list-item-arrow-image-container" },
                        React.createElement("img", { src: rightBlackArrow, alt: "arrow", className: "manage-services-list-item-arrow-image" })))))));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(ManageBusinessList));
// Customizable Area End
