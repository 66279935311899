var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Customizable Area Start
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import moment from "moment";
export const configJSON = require("./config");
export default class ManageCatalougeWebController extends BlockComponent {
    constructor(props) {
        super(props);
        this.handleReceive = (apiRequestCallId, responseJson, errorJson) => {
            if (apiRequestCallId === this.getCatalougeListCallId) {
                this.handleGetCatalougeResponse(responseJson, errorJson);
            }
            if (apiRequestCallId === this.createCatalogeCategoryCallId ||
                apiRequestCallId === this.updateCatalogeCategoryCallId ||
                apiRequestCallId === this.createCatalogeServiceCallId) {
                this.handleCreateCatalogeCategory(responseJson, errorJson);
            }
            if (apiRequestCallId === this.deleteCatalougeCategoryCallId ||
                apiRequestCallId === this.deleteCatalougeServiceCallId) {
                this.handleDeleteCatalogeCategory(responseJson, errorJson);
            }
        };
        this.handleGetCatalougeResponse = (responseJson, errorReponse) => {
            if (responseJson &&
                responseJson.data &&
                responseJson.metadata &&
                !responseJson.errors) {
                this.setState(Object.assign(Object.assign({}, this.state), { allCatergories: responseJson.data, serviceDetails: responseJson.metadata, unsubscribedUserModal: !responseJson.metadata.is_subscribed, deleteModal: false }));
            }
            else {
                console.log(errorReponse);
            }
        };
        this.handleCreateCatalogeCategory = (responseJson, errorReponse) => {
            if (((responseJson && responseJson.data) || responseJson.message) &&
                !responseJson.errors) {
                this.setState(Object.assign(Object.assign({}, this.state), { isAddEditCatergory: "none", isAddEditService: "none", editableCategoryName: "", editableArabicCategoryName: "" }));
                this.getCatalougeListApi();
            }
            else {
                console.log(errorReponse);
            }
        };
        this.handleDeleteCatalogeCategory = (responseJson, errorReponse) => {
            if ((responseJson && responseJson.errors) || responseJson.message) {
                this.setState(Object.assign(Object.assign({}, this.state), { isAddEditCatergory: "none", isAddEditService: "none", editableCategoryName: "", editableArabicCategoryName: "", deleteModal: false }));
                this.getCatalougeListApi();
            }
            else {
                console.log(errorReponse);
            }
        };
        this.startLoading = () => {
            this.setState({ loading: true });
        };
        this.stopLoading = () => {
            this.setState({ loading: false });
        };
        this.serviceDataValidation = () => {
            const { editableCategoryName, editableServiceData, editableArabicCategoryName, } = this.state;
            this.setState(Object.assign(Object.assign({}, this.state), { serviceCostError: false, serviceDescriptionError: false, serviceDurationError: false, serviceNameError: false, arabicServiceNameError: false, categoryNameError: false, arabicCategoryNameError: false }));
            if (editableCategoryName == "" && editableCategoryName.length <= 0) {
                this.setState(Object.assign(Object.assign({}, this.state), { categoryNameError: true }));
                return false;
            }
            if (editableArabicCategoryName == "" &&
                editableArabicCategoryName.length <= 0) {
                this.setState(Object.assign(Object.assign({}, this.state), { arabicCategoryNameError: true, categoryNameError: false }));
                return false;
            }
            if (!editableServiceData.service_name &&
                editableServiceData.service_name == "" &&
                editableServiceData.service_name.length <= 0) {
                this.setState(Object.assign(Object.assign({}, this.state), { serviceCostError: false, arabicServiceNameError: false, serviceDescriptionError: false, serviceDurationError: false, serviceNameError: true, arabicCategoryNameError: false, categoryNameError: false }));
                return false;
            }
            if (!editableServiceData.arabic_name &&
                editableServiceData.arabic_name == "" &&
                editableServiceData.arabic_name.length <= 0) {
                this.setState(Object.assign(Object.assign({}, this.state), { serviceCostError: false, serviceDescriptionError: false, serviceDurationError: false, serviceNameError: false, arabicServiceNameError: true, arabicCategoryNameError: false, categoryNameError: false }));
                return false;
            }
            if (!editableServiceData.description &&
                editableServiceData.description == "" &&
                editableServiceData.description.length <= 0) {
                this.setState(Object.assign(Object.assign({}, this.state), { serviceCostError: false, serviceDescriptionError: true, serviceDurationError: false, serviceNameError: false, arabicServiceNameError: false, arabicCategoryNameError: false, categoryNameError: false }));
                return false;
            }
            if (!editableServiceData.service_cost &&
                editableServiceData.service_cost == "" &&
                editableServiceData.service_cost.length <= 0) {
                this.setState(Object.assign(Object.assign({}, this.state), { serviceCostError: true, serviceDescriptionError: false, serviceDurationError: false, serviceNameError: false, arabicServiceNameError: false, arabicCategoryNameError: false, categoryNameError: false }));
                return false;
            }
            if (!editableServiceData.duration &&
                editableServiceData.duration == "" &&
                editableServiceData.duration.length <= 0) {
                this.setState(Object.assign(Object.assign({}, this.state), { serviceCostError: false, serviceDescriptionError: false, serviceDurationError: true, serviceNameError: false, arabicServiceNameError: false, arabicCategoryNameError: false, categoryNameError: false }));
                return false;
            }
            return true;
        };
        this.getCatalougeListApi = () => {
            const header = {
                "Content-Type": configJSON.validationApiContentType,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getCatalougeListCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getBusinessCategoryServiceEndPoint);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethod);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.createCatalogeCategoryApi = () => {
            const { editableCategoryName, isAddEditService, editableServiceData, editableArabicCategoryName, } = this.state;
            if (editableCategoryName == "" && editableCategoryName.length <= 0) {
                this.setState(Object.assign(Object.assign({}, this.state), { categoryNameError: true }));
                return false;
            }
            else {
                this.setState(Object.assign(Object.assign({}, this.state), { categoryNameError: false, arabicCategoryNameError: false }));
            }
            if (editableArabicCategoryName == "" &&
                editableArabicCategoryName.length <= 0) {
                this.setState(Object.assign(Object.assign({}, this.state), { arabicCategoryNameError: true, categoryNameError: false }));
                return false;
            }
            else {
                this.setState(Object.assign(Object.assign({}, this.state), { arabicCategoryNameError: false, categoryNameError: false }));
            }
            let validation = true;
            if (isAddEditService === "add") {
                validation = this.serviceDataValidation();
            }
            if (validation) {
                const header = {
                    "Content-Type": configJSON.validationApiContentType,
                    token: localStorage.getItem("token") || "",
                };
                const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
                this.createCatalogeCategoryCallId = requestMessage.messageId;
                const httpBody = {
                    name: editableCategoryName,
                    arabic_name: editableArabicCategoryName,
                    user_business_sub_categories_attributes: isAddEditService === "add" ? [editableServiceData] : [],
                };
                requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
                requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.createCatalogeCategoryEndPoint);
                requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
                requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postApiMethod);
                runEngine.sendMessage(requestMessage.id, requestMessage);
                return true;
            }
            return false;
        };
        this.createCatalogeServiceApi = () => {
            const { editableCategoryName, editableServiceData, editableCategoryId, editableArabicCategoryName, } = this.state;
            const validation = this.serviceDataValidation();
            if (validation) {
                if (editableCategoryId === null) {
                    return false;
                }
                const header = {
                    "Content-Type": configJSON.validationApiContentType,
                    token: localStorage.getItem("token") || "",
                };
                const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
                this.createCatalogeServiceCallId = requestMessage.messageId;
                const httpBody = {
                    name: editableCategoryName,
                    arabic_name: editableArabicCategoryName,
                    user_business_sub_categories_attributes: [editableServiceData],
                };
                requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
                requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.createCatalogeCategoryEndPoint}/${editableCategoryId}`);
                requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
                requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putApiMethod);
                runEngine.sendMessage(requestMessage.id, requestMessage);
                return true;
            }
            return false;
        };
        this.updateCatalogeCategoryApi = () => {
            const { editableCategoryName, editableArabicCategoryName, editableCategoryId, } = this.state;
            if (editableCategoryName == "" && editableCategoryName.length <= 0) {
                this.setState(Object.assign(Object.assign({}, this.state), { categoryNameError: true }));
                return false;
            }
            else {
                this.setState(Object.assign(Object.assign({}, this.state), { categoryNameError: false }));
            }
            if (editableArabicCategoryName == "" &&
                editableArabicCategoryName.length <= 0) {
                this.setState(Object.assign(Object.assign({}, this.state), { arabicCategoryNameError: true, categoryNameError: false }));
                return false;
            }
            else {
                this.setState(Object.assign(Object.assign({}, this.state), { arabicCategoryNameError: false }));
            }
            if (editableCategoryId === null) {
                return false;
            }
            const header = {
                "Content-Type": configJSON.validationApiContentType,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.updateCatalogeCategoryCallId = requestMessage.messageId;
            const httpBody = {
                name: editableCategoryName,
                arabic_name: editableArabicCategoryName,
            };
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.createCatalogeCategoryEndPoint}/${editableCategoryId}`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putApiMethod);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.deleteCatalogeCategoryApi = () => {
            const { editableCategoryId } = this.state;
            if (editableCategoryId === null) {
                return false;
            }
            const header = {
                "Content-Type": "application/json",
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.deleteCatalougeCategoryCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.createCatalogeCategoryEndPoint + "/" + editableCategoryId);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.deleteApiMethod);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.deleteCatalogeServiceApi = () => {
            const { editableServiceId, editableCategoryId } = this.state;
            if (editableCategoryId === null || editableServiceId === null) {
                return false;
            }
            const header = {
                "Content-Type": configJSON.validationApiContentType,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.createCatalogeServiceCallId = requestMessage.messageId;
            const httpBody = {
                user_business_sub_categories_attributes: [
                    {
                        id: editableServiceId,
                        _destroy: true,
                    },
                ],
            };
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.createCatalogeCategoryEndPoint}/${editableCategoryId}`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putApiMethod);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.timeDurationFormat = (time) => {
            if (moment(time, "HH:mm:ss").hours() === 1) {
                //istanbul ignore next
                return "60";
            }
            else {
                return moment(time, "HH:mm:ss").minutes();
            }
        };
        this.handleAddEditCategory = (type, category, arabic_category, editId) => {
            if (editId) {
                //istanbul ignore next
                this.setState(Object.assign(Object.assign({}, this.state), { isAddEditCatergory: type, editableCategoryName: category, editableArabicCategoryName: arabic_category, editableCategoryId: editId, serviceCostError: false, serviceDescriptionError: false, serviceDurationError: false, serviceNameError: false, arabicServiceNameError: false, categoryNameError: false, arabicCategoryNameError: false }));
            }
            else {
                this.setState(Object.assign(Object.assign({}, this.state), { isAddEditCatergory: type, serviceCostError: false, serviceDescriptionError: false, serviceDurationError: false, serviceNameError: false, arabicServiceNameError: false, categoryNameError: false, arabicCategoryNameError: false }));
            }
        };
        this.handleCategoryChange = (value) => {
            if (/^[A-Za-z\s]*$/.test(value)) {
                this.setState(Object.assign(Object.assign({}, this.state), { editableCategoryName: value }));
            }
        };
        this.handleArabicCategoryChange = (value) => {
            if (/^[\u0600-\u06FF\s]*$/.test(value)) {
                this.setState(Object.assign(Object.assign({}, this.state), { editableArabicCategoryName: value }));
            }
        };
        this.handleDeletePopupChange = () => {
            this.setState(Object.assign(Object.assign({}, this.state), { deleteModal: !this.state.deleteModal }));
        };
        this.handleAddService = (type, category, arabic_category, categoryId, editId, serviceData) => {
            if (serviceData) {
                this.setState(Object.assign(Object.assign({}, this.state), { isAddEditService: type, isAddEditCatergory: "none", editableCategoryId: categoryId, editableCategoryName: category, editableArabicCategoryName: arabic_category, editableServiceId: editId, editableServiceData: serviceData, serviceCostError: false, serviceDescriptionError: false, serviceDurationError: false, serviceNameError: false, arabicServiceNameError: false, categoryNameError: false, arabicCategoryNameError: false }));
            }
            else {
                //istanbul ignore next
                this.setState(Object.assign(Object.assign({}, this.state), { isAddEditService: type, isAddEditCatergory: "none", serviceCostError: false, serviceDescriptionError: false, serviceDurationError: false, serviceNameError: false, arabicServiceNameError: false, categoryNameError: false, arabicCategoryNameError: false }));
            }
        };
        this.handleServiceChange = (name, value) => {
            let isValidInput = true;
            if (name === "service_cost") {
                isValidInput = /^\d*(\.\d{0,3})?$/.test(value);
            }
            if (name === "service_name") {
                isValidInput = /^[A-Za-z\s]*$/.test(value);
            }
            if (name === "arabic_name") {
                isValidInput = /^[\u0600-\u06FF\s]*$/.test(value);
            }
            if (isValidInput) {
                const tempObj = Object.assign(Object.assign({}, this.state.editableServiceData), { [name]: value });
                this.setState(Object.assign(Object.assign({}, this.state), { editableServiceData: tempObj }));
            }
        };
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            loading: false,
            allCatergories: [],
            serviceDetails: {},
            isAddEditCatergory: "none",
            isAddEditService: "none",
            editableCategoryName: "",
            editableArabicCategoryName: "",
            categoryNameError: false,
            arabicCategoryNameError: false,
            deleteModal: false,
            editableCategoryId: null,
            editableServiceId: null,
            editableServiceData: {
                service_name: "",
                service_cost: "",
                arabic_name: "",
                duration: "",
                description: "",
            },
            serviceCostError: false,
            serviceDescriptionError: false,
            serviceDurationError: false,
            serviceNameError: false,
            arabicServiceNameError: false,
            unsubscribedUserModal: false,
        };
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    componentDidMount() {
        const _super = Object.create(null, {
            componentDidMount: { get: () => super.componentDidMount }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.componentDidMount.call(this);
            let isUserLoggedIn = localStorage.getItem("token") || "";
            let businessUser = localStorage.getItem("userType") || "";
            setTimeout(() => {
                if (!isUserLoggedIn || businessUser != "business") {
                    localStorage.clear();
                    window.location.replace("/LandingPage");
                }
                else {
                    this.getCatalougeListApi();
                }
            }, 1000);
        });
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            this.stopLoading();
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
                if (apiRequestCallId === this.getCatalougeListCallId ||
                    apiRequestCallId === this.createCatalogeCategoryCallId ||
                    apiRequestCallId === this.updateCatalogeCategoryCallId ||
                    apiRequestCallId === this.deleteCatalougeCategoryCallId ||
                    apiRequestCallId === this.createCatalogeServiceCallId ||
                    apiRequestCallId === this.deleteCatalougeServiceCallId) {
                    this.handleReceive(apiRequestCallId, responseJson, errorReponse);
                }
            }
            else {
                runEngine.debugLog("GOIT");
            }
        });
    }
}
// Customizable Area End
