// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Dialog } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { squeezMein } from "../../Settings5/src/assets";
import { leftAArrow } from "./assets";
import "../../Squeez_me_in/assets/style/ProfileDrawer.web.scss";
const Cookies = require("js-cookie");
class CustomerSupport extends BlockComponent {
    constructor() {
        super(...arguments);
        //istanbul ignore next
        this.handleMail = () => {
            let div = document.getElementsByClassName("hiperlink");
            let mail = div[0].textContent || "squeezmein.tech@gmail.com";
            if (mail) {
                const mailtoLink = `mailto:${mail}`;
                window.location.href = mailtoLink;
            }
        };
    }
    render() {
        let { t } = this.props;
        let data = localStorage.getItem("contactUs") ||
            Cookies.get("contactUs") ||
            "<p>squeezmein.tech@gmail.com</p>";
        return (React.createElement(Dialog, { open: this.props.open, disableAutoFocus: true },
            React.createElement("div", { className: "request-demo-container whole-main-div", style: { margin: "auto" } },
                React.createElement("div", { className: "request-demo-modal-header", style: { width: "85%" } },
                    React.createElement("span", { style: {
                            width: "20%",
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                        } },
                        React.createElement("img", { src: leftAArrow, alt: "arrow", onClick: this.props.onClose })),
                    React.createElement("span", { className: "request-demo-heading", style: { width: "65%" } }, t("Customer Support"))),
                React.createElement("div", { className: "contact-div" },
                    React.createElement("div", { style: {
                            height: "274px",
                            margin: "auto",
                            padding: 0,
                        }, className: "setting-div" },
                        React.createElement("img", { src: squeezMein, alt: "" }),
                        React.createElement("h2", null,
                            t("Contact Details"),
                            ":"),
                        React.createElement("div", { className: "contact-email-div", style: { justifyContent: "center" } },
                            React.createElement("p", null,
                                t("Email"),
                                ": "),
                            React.createElement("a", { onClick: this.handleMail, href: "mailto:" + data, className: "hiperlink", dangerouslySetInnerHTML: {
                                    __html: data,
                                } })))))));
    }
}
//@ts-ignore
export default withTranslation()(CustomerSupport);
// Customizable Area End
