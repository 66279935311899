import React from "react";
import { Button, Modal, CircularProgress } from "@material-ui/core";
import "../assets/style/BookNowModal.scss";
import { close_modal_icon, payNow, visa_icon } from "./assets";
import { withTranslation } from "react-i18next";
function DebitCardPayment({ loadingPaynowModal, confirmAndPay, openDebitCardModal, checkBoxValue, payByCard, closeModal, t }) {
    var _a, _b, _c, _d, _e, _f;
    return (React.createElement("div", { className: "bookNow-main-div" },
        React.createElement(Modal, { open: openDebitCardModal, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" },
            React.createElement("div", { className: "modal-booknow" },
                React.createElement("div", { className: "debit-first-div" },
                    React.createElement("img", { src: close_modal_icon, id: "img-debit", onClick: () => {
                            closeModal();
                        }, alt: "" })),
                React.createElement("div", { className: "img-debit-card-div" },
                    React.createElement("div", { className: "img-debit-card" },
                        React.createElement("div", { style: { display: "flex", alignItems: "center" } },
                            React.createElement("h1", { style: { color: "#ffffff" } }, checkBoxValue === null || checkBoxValue === void 0 ? void 0 : checkBoxValue.nickname),
                            React.createElement("img", { style: { marginLeft: "auto" }, src: visa_icon, alt: "" })),
                        React.createElement("h1", { style: { color: "#ffffff", marginTop: 20, marginBottom: 20 } }, checkBoxValue === null || checkBoxValue === void 0 ? void 0 : checkBoxValue.masked_card),
                        React.createElement("div", { style: {
                                display: "flex",
                                paddingLeft: "26px",
                                paddingRight: "20px",
                            } },
                            React.createElement("div", null,
                                React.createElement("h6", { style: { color: "#ffffff" } }, t("EXP DATE")),
                                React.createElement("h6", { style: { color: "#ffffff" } }, (checkBoxValue === null || checkBoxValue === void 0 ? void 0 : checkBoxValue.expiry_month) +
                                    "/" + (checkBoxValue === null || checkBoxValue === void 0 ? void 0 : checkBoxValue.expiry_year))),
                            React.createElement("div", { style: { marginLeft: "auto" } },
                                React.createElement("h6", { style: { color: "#ffffff" } }, t("CVV")),
                                React.createElement("h6", { style: { color: "#ffffff" } }, t("no cvv")))))),
                React.createElement("h3", { className: "h3-tag-debit" }, t("Make a payment for booking")),
                React.createElement("h1", { className: "h1-tag-debit" }, ((_b = (_a = confirmAndPay === null || confirmAndPay === void 0 ? void 0 : confirmAndPay.attributes) === null || _a === void 0 ? void 0 : _a.service) === null || _b === void 0 ? void 0 : _b.currency) +
                    " " + ((_c = confirmAndPay === null || confirmAndPay === void 0 ? void 0 : confirmAndPay.attributes) === null || _c === void 0 ? void 0 : _c.total_price)),
                React.createElement("p", { className: "p-tag-debit" },
                    t("You will be charged"),
                    " ",
                    ((_e = (_d = confirmAndPay === null || confirmAndPay === void 0 ? void 0 : confirmAndPay.attributes) === null || _d === void 0 ? void 0 : _d.service) === null || _e === void 0 ? void 0 : _e.currency) +
                        " " + ((_f = confirmAndPay === null || confirmAndPay === void 0 ? void 0 : confirmAndPay.attributes) === null || _f === void 0 ? void 0 : _f.total_price),
                    "(",
                    t("VAT Included"),
                    ")."),
                React.createElement("div", null,
                    React.createElement(Button, { className: "book-now-btn-modal-debit", onClick: () => {
                            payByCard();
                        } },
                        " ",
                        loadingPaynowModal ? (React.createElement(CircularProgress, { style: { color: "#ffffff" } })) : (React.createElement(React.Fragment, null,
                            React.createElement("img", { src: payNow }),
                            "\u00A0 ",
                            t("Pay Now")))))))));
}
//@ts-ignore
export default withTranslation()(DebitCardPayment);
