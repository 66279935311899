// Customizable Area Start
import React from "react";
import '../../assets/Styles/Walkinbooking.css';
import { OutlinedInput, InputAdornment, IconButton, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ArrowDown, loading } from '../assets';
import AfterLoginHeader from "../../../dashboard/src/AfterLoginHeader.web";
import EmployeeHomeScreenSidebar from "./EmployeeHomeScreenSidebar.web";
import AppointmentmanagementController from '../../src/EmployeeProfile/AppointmentmanagementController.web';
import VerifyClient from "./VerifyClient.web";
import { withRouter } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import EmployeeAssigne from './EmployeeAssigne.web';
import { withTranslation } from "react-i18next";
const Cookies = require("js-cookie");
class AddClientDetails extends AppointmentmanagementController {
    constructor(props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        let { t } = this.props;
        const phoneNumber = window.localStorage.getItem("UnregisteredPhoneNumber");
        const countryCode = window.localStorage.getItem("UnregisteredPhoneNumberCountryCode");
        return (React.createElement("div", { className: "manage-business-main-conainer" },
            React.createElement(AfterLoginHeader, { navigation: this.props.navigation }),
            React.createElement("div", { className: "manage-business-main-content-conainer" },
                React.createElement(EmployeeHomeScreenSidebar, { history: this.props.history }),
                React.createElement("div", { className: "walkin-manage-employee-service-main-content-container" },
                    React.createElement("div", { className: "booking-container" },
                        React.createElement("div", { style: { marginLeft: '15px' } },
                            React.createElement("span", { className: "booking-container-lable" }, window.localStorage.getItem("businessName"))),
                        React.createElement("div", { style: { marginTop: '44px', marginLeft: '20px' } },
                            React.createElement("span", { className: "booking-container-title" }, t('Walk-in booking'))),
                        React.createElement("div", { className: "booking-main-container" },
                            React.createElement("div", { className: "add-client-paper-box" },
                                React.createElement("div", { className: "centered-label" },
                                    React.createElement("span", null, t("Add Client Details"))),
                                React.createElement("div", { className: "addclient-input-container" },
                                    React.createElement("div", { className: "addclient-input-row" },
                                        React.createElement("span", { className: "addclient-dialog-label" }, t("Full name")),
                                        React.createElement(OutlinedInput, { type: "text", error: this.state.full_nameErr, id: "addclient_firstName", onChange: (e) => this.setState({ full_name: e.target.value, full_nameErr: "" }), onBlur: () => this.handleBlur("full_name", this.state.full_name), placeholder: t("Enter client’s full name"), className: "addclient-dialog-input-style", inputProps: { min: 0, maxLength: 50 } }),
                                        React.createElement("span", { className: "addclient-error-message-text" }, t(this.renderErrorMessageforProfile(this.state.full_nameErr)))),
                                    React.createElement("div", { className: "addclient-input-row" },
                                        React.createElement("span", { className: "addclient-dialog-label" }, t("Mobile number")),
                                        React.createElement(OutlinedInput, { type: "number", placeholder: t("Enter client’s mobile number"), value: phoneNumber, className: "addclient-dialog-input-style unclickable", name: "phoneNumber", startAdornment: React.createElement(InputAdornment, { position: "start" },
                                                React.createElement(IconButton, { style: {
                                                        width: 66,
                                                        height: 45,
                                                        cursor: "pointer",
                                                        justifyContent: "center",
                                                        borderRight: webAppDirection === "ltr" ? "1px solid #979797" : "",
                                                        borderLeft: webAppDirection === "rtl" ? "1px solid #979797" : "",
                                                        borderRadius: "0%",
                                                    } },
                                                    React.createElement("span", { className: "walkin-country-code-text-style", style: {
                                                            color: "#2c2c2e"
                                                        } }, countryCode))) })),
                                    React.createElement("div", { className: "addclient-input-row" },
                                        React.createElement("span", { className: "addclient-dialog-label" }, t("Email Address")),
                                        React.createElement(OutlinedInput, { type: "text", id: 'addclient_email', value: this.state.email, error: this.state.emailErr, onChange: (e) => {
                                                if (/^[a-zA-Z0-9 !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]*$/.test(e.target.value)) {
                                                    this.setState({
                                                        email: e.target.value,
                                                        emailErr: "",
                                                    });
                                                }
                                            }, onBlur: () => this.handleBlur("email", this.state.email), placeholder: t("Enter client’s email address"), className: "addclient-dialog-input-style", inputProps: { min: 0 } }),
                                        React.createElement("span", { className: "addclient-error-message-text" }, t(this.renderErrorMessageforProfile(this.state.emailErr)))),
                                    React.createElement("div", { className: "addclient-input-row" },
                                        React.createElement("span", { className: "addclient-dialog-label" }, t("Gender")),
                                        React.createElement(Autocomplete, { id: "addclient_gender", options: ["male", "female"], className: "my-autocomplete", style: { width: '476px', height: "56px", border: "1px solid #757575", borderRadius: "8px" }, getOptionLabel: (option) => t(option.charAt(0).toUpperCase() + option.slice(1)), value: t(this.state.gender), onChange: (event, value) => {
                                                this.setState({ gender: value, genderErr: "" });
                                            }, popupIcon: React.createElement("img", { src: ArrowDown, alt: "Dropdown Icon", width: 24, height: 24 }), renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { id: "client_gender", placeholder: t("Select client’s gender"), variant: "outlined", inputProps: Object.assign(Object.assign({}, params.inputProps), { readOnly: true }), error: this.state.genderErr, onBlur: () => this.handleBlur("gender", this.state.gender) }))) }),
                                        React.createElement("span", { className: "addclient-error-message-text" }, t(this.renderErrorMessageforProfile(this.state.genderErr)))),
                                    React.createElement("div", { className: "addclient-input-row" },
                                        React.createElement("span", { className: "addclient-error-message-text" }, t(this.renderErrorMessageforProfile(this.state.responseMsg)))),
                                    React.createElement("div", { className: "button-container" },
                                        React.createElement("button", { className: "button-style cancel-button-style", onClick: () => { this.handleBackButton(); } }, t("Cancel")),
                                        React.createElement("button", { className: "button-style save-button-style", onClick: () => { this.handleSaveAndContinue(); } },
                                            t("Save"),
                                            " & ",
                                            t("Continue")))))))),
                React.createElement(VerifyClient, { value: this.state, renderErrorMessageforProfile: this.renderErrorMessageforProfile, onClick: this.verifyOtp, onClose: this.closeDialog, onResend: this.handleResendOtp, handleOtpChange: this.handleOtpChange, openDialog: this.state.openVerifyDialog }),
                React.createElement(EmployeeAssigne, { openDialog: this.state.openAssignedDialog, closeDialog: this.navigateWalkin, infoMsg: `${this.state.firstName} ${this.state.lastName} ${t("is successfully added")}`, employeeName: t('as your new client.') }),
                React.createElement(Dialog, { open: this.state.loderDialog, maxWidth: "sm", fullWidth: true, PaperProps: {
                        className: "loder-dialog-Client-Details"
                    } },
                    React.createElement(DialogContent, null,
                        React.createElement("div", { className: "loader-container" },
                            React.createElement("img", { src: loading, height: 60, width: 60, alt: "eye" })))))));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(AddClientDetails));
// Customizable Area End
